import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'styled-minimal';

import HeaderTitle from '../../components/HeaderTitle';
import SectionTabs from './SectionTabs';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const DefaultSection = styled.div`
  flex: 1;
  width: calc(100% - ${props => props.vertical ? 38 : 0}px);
`;

const Header = styled.div`
  display: flex;
  padding-right: 100px;
  justify-content: space-between;
  align-items: flex-end;
`;

const HeaderLeftContainer = styled.div`

`;

const VerticalTabSection = styled.div`
  width: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 270px;
`;

const Content = styled.div`
  padding: 10px;
  border: solid  1px ${props => props.theme.palette.light};
  border-radius: 4px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  min-height: ${props => props.vertical ? 500 : 0}px;
  background: white;
  overflow: auto;
`;


export default class TabWrapper extends Component {

  render() {
    const { headerLeft, hActiveTab, vActiveTab, horizonalTabOptions, onHorizonalTabChange, verticalTabOptions, onVerticalTabChange, children } = this.props;
    return (
      <Container>
        <DefaultSection vertical={verticalTabOptions}>
          <Header>
            <HeaderLeftContainer>
              {headerLeft}
            </HeaderLeftContainer>
            <SectionTabs options={horizonalTabOptions} onChange={onHorizonalTabChange} value={hActiveTab}/>
          </Header>
          <Content vertical={verticalTabOptions}>
            {children}
          </Content>
        </DefaultSection>
        {verticalTabOptions &&
          <VerticalTabSection>
            <SectionTabs 
              vertical
              value={vActiveTab}
              options={verticalTabOptions} 
              onChange={onVerticalTabChange}
            />
          </VerticalTabSection>
        }
      </Container>
      )
  };
}
