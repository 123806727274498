import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import {
  getUsers,
  getLoanOfficers,
  getPortalAdminLoanOfficer,
} from 'modules/api';
import { NavLink } from 'react-router-dom';
import Button from 'components/Button';
import { media } from 'modules/theme';
import Image from 'components/Image';

import ChangeLoanOfficerModal from './MyAgents/ChangeLoanOfficerModal';
import MyAgents from './MyAgents';
import AgentList from './NKAgents/AgentList';

const PlaceholderAvatar = require('assets/media/images/sample-avatar.png');

const AgentType = {
  MyAgents: 'my-agents',
  NKA: 'nka',
}
class Agents extends Component {
  state = {
    selectedLO: null,
    allLoanOfficers: [],
    showChangeLOModal: false,
  };

  componentDidMount() {
    getUsers().then(res => {
      const user = res.find(u => u.PK === this.props.user.data.PK);
      if (user?.LOID) {
        this.setState({ selectedLO: user });
        getPortalAdminLoanOfficer(user.LOID).then(loanOfficers => {
          const loanOfficer = loanOfficers[0];
          if (loanOfficer) {
            const selectedLO = {
              ...loanOfficer,
              Name: `${loanOfficer.FirstName} ${loanOfficer.LastName}`,
              LOID: loanOfficer.ID,
            };
            this.setState({ selectedLO });
          }
        });
      }

      getLoanOfficers().then(res2 => {
        const allLoanOfficers = res2
          .filter(r => r.Status === 'Active')
          .map(r => ({
            ...r,
            Name: `${r.FirstName} ${r.LastName}`,
            LOID: r.ID,
          }));
        this.setState({ allLoanOfficers });
      });
    }).catch(() => {});
  }

  handleChangeLO = selectedLO => {
    this.setState({ selectedLO });
  };


  render() {
    const {
      selectedLO,
      showChangeLOModal,
      allLoanOfficers,
    } = this.state;

    const { user, match, dispatch } = this.props;
    const { agentType } = match?.params || {}

    return (
      <Fragment>
        <Helmet>
          <title>My Agents</title>
          <meta
            name="description"
            content="This is the Keller Mortgage Admin Portal My-Agents page"
          />
        </Helmet>
        <div>
          <Body>
            <SideBar>
              <ProfileWrapper>
                <Avatar
                  src={
                    selectedLO && selectedLO.ProfilePhotoLink
                      ? selectedLO.ProfilePhotoLink
                      : PlaceholderAvatar
                  }
                />
                <Name>{selectedLO ? selectedLO.Name : 'N/A'}</Name>
                {selectedLO && <ID>#{selectedLO?.LOID}</ID>}
                {user.data.PermissionsLevel !== 3 && (
                  <StyledButton onClick={() => this.setState({ showChangeLOModal: true })}>
                    {selectedLO ? 'Change' : 'Choose Loan Officer'}
                  </StyledButton>
                )}
              </ProfileWrapper>
              <MenuLink
                to={`/private/agents/${AgentType.MyAgents}`}
                exact
                activeClassName="active"
              >
                My Agents
              </MenuLink>
              <MenuLink
                to={`/private/agents/${AgentType.NKA}/agent-list`}
                exact
                activeClassName="active"
              >
                Non KW Agents
              </MenuLink>
            </SideBar>
            <ContentWrapper>
              {agentType === AgentType.NKA ? (
                <AgentList selectedLO={selectedLO} dispatch={dispatch} />
              ) : (
                <MyAgents selectedLO={selectedLO} allLoanOfficers={allLoanOfficers} />
              )}
            </ContentWrapper>
          </Body>
        </div>
        <ChangeLoanOfficerModal
          allLoanOfficers={allLoanOfficers}
          showModal={showChangeLOModal}
          onClose={() => this.setState({ showChangeLOModal: false })}
          onSelect={this.handleChangeLO}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(Agents);

const Body = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  color: #4d4d4f;
  flex-flow: wrap;

  ${media.mobile`
    padding: 1rem;
  `};
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  height: 30px;
  color: white;
  min-width: unset;
  font-size: 1.3rem;
  height: 27px;
  text-decoration: underline;
`;

const SideBar = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 30%;
  min-width: 180px;
  max-width: 270px;
  background-color: ${props => props.theme.palette.gray};
  box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 4.4rem 0 4.4rem 0;
  position: relative;
  text-align: center;

  ${media.mobile`
    max-width: unset;
    width: 100%;
    box-shadow: none;
    padding: 1rem;
    margin-bottom: 0;
  `};
`;

const ProfileWrapper = styled.div`
  ${media.mobile`
    flex: 1;
  `};
`;

const Avatar = styled(Image)`
  width: 16rem;
  height: 16rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;

  ${media.mobile`
    width: 8rem;
    height: 8rem;  
  `};
`;

const Name = styled.div`
  color: white;
  text-align: center;
  margin: 20px 20px 0;
  font-weight: bold;
  font-size: 2.5rem;

  ${media.mobile`
    margin: 0.5rem 0;
    font-size: 1.5rem;
  `};
`;

const ID = styled.div`
  color: white;
`;

const MenuLink = styled(NavLink)`
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: block;
  margin: 2rem 1.5rem 1rem;
  background: white;
  text-align: center;
  color: ${props => props.theme.palette.gray};
  position: relative;
  font-size: 1.6rem;

  &.active {
    color: ${props => props.theme.palette.primary};
    font-weight: bold;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  background: #ffffff;
  box-shadow: -3px 8px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 2.5rem;
  width: 70%;
  position: relative;
`;
