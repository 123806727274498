import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import Select from 'react-select';
import { 
  getRecruitingUnassignedAgents,
  getRecruitingAssignedAgents,
  getRecruitingInRecruitingAgents,
  getLoanOfficers,
  deleteRecruitingLOAssignment,
  getPortalAdminRecruiters,
  addRecruitingRecruiterAssignment,
  deleteRecruitingRecruiterAssignment,
  getRecruitingClosedAgents,
  getRecruitingInPoolAgents
} from 'modules/api';
import { UncontrolledTooltip } from 'reactstrap'; 
import CheckBox from 'components/CheckBox';
import { orderBy }from 'lodash';
import SectionContentWrapper from 'components/VerticalWideTabWrapper';
import { STATES } from 'constants/index';
import 'react-virtualized/styles.css';
import { AutoSizer, Table, Column } from 'react-virtualized';
import withModals from 'modules/withModals';
import AssignRecruiterModal from './AssignRecruiterModal';
import ActivityModal from 'routes/Recruiting/Dashboard/LODashboard/ActivityModal';
import { formatUTCDate } from 'modules/helpers';
import DownloadCSV from 'components/DownloadCSV';

const TAB = {
  UNASSINGED: 'UNASSINGED',
  IN_RECRUITING: 'IN_RECRUITING',
  RECRUITED: 'RECRUITED',
  CLOSED: 'CLOSED',
  IN_POOL: 'IN_POOL'
}

const HorizontalTabOptions = [
  { display: 'Unassigned', value: TAB.UNASSINGED },
  { display: 'In Recruiting', value: TAB.IN_RECRUITING },
  { display: 'In Pool', value: TAB.IN_POOL },
  { display: 'Recruited', value: TAB.RECRUITED },
  { display: 'Closed', value: TAB.CLOSED },
];

const VerticalTabOptions = [
  { display: 'All Time', value: 5 },
  { display: 'Created This Week', value: 1 },
  { display: 'Created Last Week', value: 2 },
  { display: 'Created Last Month', value: 3 },
  { display: 'Custom', value: 4 },
];

const StateOptions = STATES.map(state => {
  return {
    label: state,
    value: state,
  }
});

const ActivityStatusOptions = ['Overdue', 'In Progress', 'Contacted - Followup', 'Contacted - Overdue'].map((label, value) => ({
  label,
  value
}))

class Assignments extends Component {
  state = {
    selectedLO: null,
    selectedRecruiter: null,
    allLoanOfficers: [],
    showChangeLOModal: false,
    reportPeriod: VerticalTabOptions[0].value,
    activeTab: TAB.UNASSINGED,
    selectedState: null,
    selectedActivityStatus: null,
    startDate: null,
    endDate: null,
    agents: [],
    sortCurrentColumn: 'StateCode',
    sortCurrentDirection: 'ASC',
    checkedAgents: [],
    allRecruiters: [],
   }

  _setTableRef = ref => {
    this._table = ref;
  };

  componentDidMount() {
    this.fetchData();

    getLoanOfficers().then(res => {
      const allLoanOfficers = res.filter(r => r.Status === 'Active').map(r => ({
        ...r,
        Name: `${r.FirstName} ${r.LastName}`,
        LOID: r.ID
      }))
      this.setState({ allLoanOfficers })
    })

    getPortalAdminRecruiters().then(allRecruiters => {
      this.setState({ allRecruiters })
    })
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.fetchData()
    });
  }

  fetchData = async () => {
    const { selectedLO, selectedRecruiter, activeTab, reportPeriod: period, startDate: sd, endDate: ed, selectedState, selectedActivityStatus } = this.state;
    const selectedStateCode = (selectedState || {}).value;
    const selectedLOID = (selectedLO || {}).value;
    const recruiterID = (selectedRecruiter || {}).value;
    let agents;

    let startDate = sd;
    let endDate = ed;
    let reportPeriod = period;

    if (reportPeriod === 5) { // All time
      startDate = '2020-01-01';
      endDate = moment().format('YYYY-MM-DD');
      reportPeriod = 4;
    }

    try {
      if (activeTab === TAB.RECRUITED) {
        const apiRes = await getRecruitingAssignedAgents(selectedLOID, selectedStateCode, startDate, endDate, reportPeriod);
        agents = apiRes.result;
      }
       else if (activeTab === TAB.IN_RECRUITING) {
        const apiRes = await getRecruitingInRecruitingAgents(selectedStateCode, startDate, endDate, reportPeriod, recruiterID);
        agents = selectedActivityStatus ? apiRes.result.filter(a => a.ActivityStatus === selectedActivityStatus.value) : apiRes.result;
      
      } else if (activeTab === TAB.CLOSED) {
        const apiRes = await getRecruitingClosedAgents(selectedStateCode, startDate, endDate, reportPeriod, recruiterID);
        agents = apiRes.result;
      
      } else if (activeTab === TAB.IN_POOL) {
        const apiRes = await getRecruitingInPoolAgents(selectedStateCode, startDate, endDate, reportPeriod, recruiterID, null);
        agents = apiRes.result;
      
      } else {
        const apiRes = await getRecruitingUnassignedAgents(selectedStateCode, startDate, endDate, reportPeriod);
        agents = apiRes.result;
      }
      
      this.setState({ 
        agents,
        checkedAgents: []
      }, () => {
        this.handleSort();
      })
    } catch (error) {
    }
  }

  changeHorizontalTab = activeTab => {
    this.setState({ 
      activeTab,
      agents: [],
      checkedAgents: []
    }, () => {
      this.fetchData();
    });
  };
  
  changeVerticalTab = reportPeriod => {
    this.setState({ 
      reportPeriod,
    }, () => {
      this.fetchData();
    });
  };

  handleCheckChange = (KWUID, value) => {
    const { agents, checkedAgents } = this.state;

    if (KWUID === 'all') {
      this.setState({
        checkedAgents: value ? agents.filter(b => b.MyLO_PK === null || b.MyLO_PK === undefined).map(a => a.KWUID) : []
      })
    } else {
      if (value) {
        if (!checkedAgents.includes(KWUID)) {
          this.setState({ checkedAgents: [...checkedAgents, KWUID] })
        }
      } else {
        if (checkedAgents.includes(KWUID)) {
          this.setState({ checkedAgents: checkedAgents.filter(a => a !== KWUID) })
        }
      }
    }
  }

  handleShowAssignRecruitingModal = () => {
    const { allRecruiters, checkedAgents } = this.state;

    this.props.openModal('AssignRecruiterModal', {
      allRecruiters: allRecruiters,
      onClose: () => {
        this.props.closeModal('AssignRecruiterModal')
      },
      onSelect: (recruiter) => {
        const assignments = checkedAgents.map(KWUID => ({
          KWUID,
          RecruiterID: recruiter.PK
        }))
    
        addRecruitingRecruiterAssignment(JSON.stringify(assignments)).then(res => {
          this.fetchData();
          this.props.closeModal('AssignRecruiterModal')
        })
      }
    })
  }

  handleRemoveAssignments = () => {
    const { checkedAgents, agents, activeTab } = this.state;
    const pks = [];
    checkedAgents.forEach(kwuid => {
      const agent = agents.find(a => a.KWUID === kwuid);
      if (agent && agent.AssignmentPK) {
        pks.push({ PK: agent.AssignmentPK })
      } 
    })

    if (activeTab === TAB.RECRUITED) {
      deleteRecruitingLOAssignment(JSON.stringify(pks)).then(res => {
        this.fetchData();
      })
    } else if (activeTab === TAB.IN_RECRUITING || activeTab === TAB.CLOSED) {
      deleteRecruitingRecruiterAssignment(JSON.stringify(pks)).then(res => {
        this.fetchData();
      })
    }
  }

  handleShowActivityModal = (selectedAgent) => () => {
    const { activeTab } = this.state;
    let assignmentPK
    if (activeTab === TAB.RECRUITED) {
      assignmentPK = selectedAgent.AssignmentPKRecruiter
    } else {
      assignmentPK = selectedAgent.AssignmentPK
    }

    this.props.openModal('ActivityModal', {
      assignmentPK,
    })
  }

  _sort = ({ sortBy, sortDirection }) => {
    this.setState({ 
      sortCurrentColumn: sortBy,
      sortCurrentDirection: sortDirection,
    }, () => this.handleSort())
  }

  handleSort = () => {
    const { sortCurrentColumn, agents, sortCurrentDirection } = this.state;

    let sorted = orderBy(agents, [sortCurrentColumn], [sortCurrentDirection.toLocaleLowerCase()]);
    if (sortCurrentDirection === 'DESC') {
      const nullItems = sorted.filter(i => i[sortCurrentColumn] === null)
      const unNullItems = sorted.filter(i => i[sortCurrentColumn] !== null)
      sorted = [...unNullItems, ...nullItems]
    }
    
    this.setState({
      agents: sorted,
    })
  };

  noRowsRenderer() {
    return <div></div>;
  }

  getTableHeight = () => {
    const elem = document.getElementById("table-wrapper");
    let calcHeight = 600

    if (elem) {
      const rect = elem.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      calcHeight = screenHeight - rect.top - 80
    }

    if (calcHeight < 480) {
      calcHeight = 480
    }

    return calcHeight;
  }

  getRowRenderer = props => {
    const { _key, className, columns, index, style, rowData } = props;
    const { ActivityOverdueFlag } = rowData;
    
    const isOdd = index % 2;

    return (
      <TableRow
        key={_key}
        style={{ 
          ...style, 
          backgroundColor: isOdd ? 'white' : '#eeeeee', 
          paddingTop: 7, 
        }}
        red={ActivityOverdueFlag}
      >
        <div className={className}>{columns}</div>
      </TableRow>
    );
  };

  rowGetter = ({ index }) => {
    return this.state.agents[index];
  };
 
  render() {
    const { 
      activeTab,
      reportPeriod,
      startDate,
      endDate,
      sortCurrentColumn,
      sortCurrentDirection,
      agents,
      checkedAgents,
      selectedState,
      allLoanOfficers,
      selectedLO,
      selectedActivityStatus,
      allRecruiters,
      selectedRecruiter,
    } = this.state;

    const {FirstName, LastName} = this.props.user

    let horizontalOptions = HorizontalTabOptions;
    if (this.props.user.PermissionsLevel === 4) {
      horizontalOptions = HorizontalTabOptions.filter(o => o.value === TAB.UNASSINGED) 
    }

    const LOOptions = allLoanOfficers.map(lo => ({
      label: lo.Name,
      value: lo.LOID
    }));

    const RecruiterOptions = allRecruiters.map(r => ({
      label: `${r.Firstname} ${r.Lastname}`,
      value: r.PK
    }));

    const isAssignedTab = activeTab === TAB.RECRUITED || 
                          activeTab === TAB.IN_RECRUITING || 
                          activeTab === TAB.CLOSED || 
                          activeTab === TAB.IN_POOL;
    
    return (
      <Root>
        <DownloadWrapper>
          <DownloadCSV data={agents} filename={`Assignmemt_${activeTab}`}/>
        </DownloadWrapper>
        <Name>{`${FirstName} ${LastName}`}</Name>
        <SectionContentWrapper
          title=""
          hActiveTab={activeTab}
          vActiveTab={reportPeriod}
          horizonalTabOptions={horizontalOptions}
          onHorizonalTabChange={this.changeHorizontalTab}
          verticalTabOptions={VerticalTabOptions}
          onVerticalTabChange={this.changeVerticalTab}
        >
          {reportPeriod === 4 &&
            <DateRange>
              <Row>
                <RadioLabel>Start Date</RadioLabel>
                <input 
                  placeholder="Start Date"
                  type="date" 
                  value={startDate} 
                  onChange={e => this.handleChange('startDate', e.target.value || null)}
                />
              </Row>
              <Row>
                <RadioLabel>End Date</RadioLabel>
                <input 
                  placeholder="End Date"
                  type="date" 
                  value={endDate} 
                  onChange={e => this.handleChange('endDate', e.target.value || null)}
                />
              </Row>
            </DateRange>
          }
          <Wrapper id="table-wrapper">
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  ref={this._setTableRef}
                  headerHeight={70}
                  noRowsRenderer={this.noRowsRenderer}
                  rowHeight={80}
                  rowGetter={this.rowGetter}
                  rowCount={agents.length}
                  height={this.getTableHeight()}
                  rowRenderer={this.getRowRenderer}
                  width={width}
                  sort={this._sort}
                  sortBy={sortCurrentColumn}
                  sortDirection={sortCurrentDirection}
                >
                  <Column
                    width={30}
                    disableSort
                    dataKey="Checked"
                    headerStyle={{ justifyContent: 'flex-start', minWidth: 20 }}
                    style={{ minWidth: 20 }}
                    cellRenderer={({ rowData }) => {
                      return (
                        <div id={'tooltip-' + rowData.KWUID} onClick={(e) => e.stopPropagation()}>
                          <CheckBox
                            disabled={rowData.MyLO_PK !== undefined && rowData.MyLO_PK !== null}
                            value={checkedAgents.includes(rowData.KWUID)}
                            onChange={value => this.handleCheckChange(rowData.KWUID, value)}
                          />
                          {rowData.MyLO_PK !== undefined && rowData.MyLO_PK !== null && (
                            <Tooltip placement="right" target={'tooltip-' + rowData.KWUID} delay={0}>
                              In MyLO
                            </Tooltip>
                          )}
                        </div>
                      )
                    }}
                    headerRenderer={({ rowData }) => (
                      <CheckBox
                        value={checkedAgents.length === agents.length}
                        onChange={value => this.handleCheckChange('all', value)}
                      />
                    )}
                  />
                  {activeTab === TAB.IN_RECRUITING &&
                    <Column
                      disableSort
                      dataKey="ActivityStatus"
                      width={100}
                      style={{ textAlign: 'left', minWidth: 100 }}
                      headerStyle={{ minWidth: 100 }}
                      cellRenderer={({ cellData }) => {
                        let color;
                        switch(cellData) {
                          case 0:
                            color = 'red';
                            break;
                          case 1:
                            color = 'green';
                            break;
                          case 2:
                            color = 'blue';
                            break;
                          case 3:
                            color = 'yellow';
                            break;
                        }
                        return (
                          <StatusIndicator color={color}></StatusIndicator>
                        )
                      }}
                      headerRenderer={() => (
                        <div style={{ maxWidth: '100%' }}>
                          <div style={{ textAlign: 'center' }}>Status</div>
                          <div onClick={e=> e.stopPropagation()}>
                            <StatusSelect 
                              styles={CustomDropdownStyles}
                              placeholder="All"
                              isSearchable
                              isClearable
                              options={ActivityStatusOptions} 
                              onChange={(as) => this.handleChange('selectedActivityStatus', as)} 
                              value={selectedActivityStatus}
                            />
                          </div>
                        </div>
                      )}
                    />
                  }
                  {activeTab === TAB.RECRUITED &&
                    <Column
                      disableSort
                      dataKey="FirstName"
                      width={150}
                      style={{ textAlign: 'left', minWidth: 150 }}
                      headerStyle={{ minWidth: 150 }}
                      cellRenderer={({ rowData }) => {
                        const { FirstName, LastName } = rowData;
                        return `${FirstName || ''} ${LastName || ''}`
                      }}
                      headerRenderer={() => (
                        <div style={{ maxWidth: '100%' }}>
                          <div>Assigned to</div>
                          <div onClick={e=> e.stopPropagation()}>
                            <LOSelect 
                              styles={CustomDropdownStyles}
                              isSearchable
                              isClearable
                              placeholder="All"
                              options={LOOptions} 
                              onChange={(lo) => this.handleChange('selectedLO', lo)} 
                              value={selectedLO}
                            />
                          </div>
                        </div>
                      )}
                    />
                  }
                  {(activeTab === TAB.IN_RECRUITING || activeTab === TAB.CLOSED || activeTab === TAB.IN_POOL) &&
                    <Column
                      disableSort
                      dataKey="FirstName"
                      width={150}
                      style={{ textAlign: 'left', minWidth: 150 }}
                      headerStyle={{ minWidth: 150 }}
                      cellRenderer={({ rowData }) => {
                        const { FirstName, LastName } = rowData;
                        return `${FirstName || ''} ${LastName || ''}`
                      }}
                      headerRenderer={() => (
                        <div style={{ maxWidth: '100%' }}>
                          <div>Assigned to</div>
                          <div onClick={e=> e.stopPropagation()}>
                            <LOSelect 
                              styles={CustomDropdownStyles}
                              isSearchable
                              isClearable
                              placeholder="All"
                              options={RecruiterOptions} 
                              onChange={(lo) => this.handleChange('selectedRecruiter', lo)} 
                              value={selectedRecruiter}
                            />
                          </div>
                        </div>
                      )}
                    />
                  }
                  {activeTab === TAB.RECRUITED && (
                    <Column
                      width={150}
                      label="Assigned By"
                      dataKey="AssignedBy"
                      disableSort
                      cellRenderer={({ cellData }) => cellData}
                    />
                  )}
                  <Column
                    width={90}
                    label="KWUID"
                    dataKey="KWUID"
                    disableSort
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    label="Agent Name"
                    dataKey="Firstname"
                    width={200}
                    style={{ textAlign: 'left' }}
                    headerStyle={{ justifyContent: 'flex-start' }}
                    cellRenderer={({ rowData }) => {
                      const { Firstname, Lastname } = rowData;
                      if (isAssignedTab) {
                        return <LinkButton onClick={this.handleShowActivityModal(rowData)}>{`${Firstname} ${Lastname}`}</LinkButton>;
                      } else {
                        return `${Firstname} ${Lastname}`;
                      }
                    }}
                  />
                  {activeTab === TAB.CLOSED && (
                    <Column
                      width={150}
                      label="Closed Reason"
                      dataKey="ClosedReason"
                      disableSort
                      cellRenderer={({ cellData }) => cellData}
                    />
                  )}
                  <Column
                    width={220}
                    label="Email"
                    dataKey="Email"
                    disableSort
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={150}
                    label="Mobile Phone"
                    disableSort
                    dataKey="MobilePhoneNumber"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={120}
                    dataKey="StateCode"
                    cellRenderer={({ cellData }) => cellData}
                    label={
                      <div>
                        <div>MC State</div>
                        <div onClick={e=> e.stopPropagation()}>
                          <StateSelect 
                            styles={CustomDropdownStyles}
                            isSearchable
                            isClearable
                            placeholder="All"
                            options={StateOptions} 
                            onChange={(state) => this.handleChange('selectedState', state)} 
                            value={selectedState}
                          />
                        </div>
                      </div>
                    }
                  />
                  <Column
                    label="Market Center"
                    dataKey="MarketCenterID"
                    width={200}
                    cellRenderer={({ rowData }) => {
                      const { MarketCenterID, MarketCenterName, MarketCenterTeamLeader } = rowData;
                      return `${MarketCenterName} (${MarketCenterID}) - ${MarketCenterTeamLeader}`;
                    }}
                  />
                  <Column
                    width={170}
                    label="Agent Created Date"
                    dataKey="CreatedDate"
                    cellRenderer={({ cellData }) => cellData ? formatUTCDate(cellData) : ''}
                  />
                  {isAssignedTab &&
                    <Column
                      width={170}
                      label="Assigned Date"
                      dataKey="AssignmentDate"
                      cellRenderer={({ cellData }) => cellData ? formatUTCDate(cellData) : ''}
                    />
                  }
                </Table>
              )}
            </AutoSizer>
          </Wrapper>
          {checkedAgents.length > 0 && !isAssignedTab &&
            <ActionButton onClick={this.handleShowAssignRecruitingModal}>Assign</ActionButton>
          }
          {checkedAgents.length > 0 && isAssignedTab &&
            <ActionButton onClick={this.handleRemoveAssignments}>Remove</ActionButton>
          }
        </SectionContentWrapper>
       </Root>
    )
  };
}

export default withModals({ 
    AssignRecruiterModal,
    ActivityModal,
  })(Assignments)


const Root = styled.div`
`;

const DownloadWrapper = styled.div`
  height: 0;
  position: relative;

  & > a {
    transform: translateY(0%);
    right: 0;
  }
`;

const Name = styled.div`
  position: absolute;
  font-weight: bold;
  line-height: 31px;
  font-size: 1.4rem;
`;

const ActionButton = styled.button`
  background: ${props => props.theme.palette.gray};
  color: white;
  min-width: unset;
  font-size: 1.3rem;
  position: fixed;
  left: 260px;
  bottom: 70px;
  border-radius: 6px;
  padding: 5px 10px;

  &:focus, &:hover {
    color: white;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
`;

const DateRange = styled.div`
  margin-right: 3.0rem;

  span {
    width: 8.1rem;
    margin-right: 1.0rem;
  }
`;

const RadioLabel = styled.span`
  margin-left: .4rem;
  margin-right: 2.0rem;
  font-size: 1.5rem;
`;


const TableRow = styled.div`
  ${({ red }) => red && css`
    color: red;

    button {
      color: red;
    }
  `}
`;

const StatusSelect = styled(Select)`
  text-align: left;
  width: 100%;
  font-size: 1.2rem;
  box-shadow: none;
  border-radius: 3px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 28px;
    min-width: 100px;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;


const LOSelect = styled(Select)`
  text-align: left;
  width: 100%;
  font-size: 1.2rem;
  box-shadow: none;
  border-radius: 3px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 28px;
    min-width: 150px;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const StateSelect = styled(Select)`
  text-align: left;
  width: 100%;
  font-size: 1.2rem;
  box-shadow: none;
  border-radius: 3px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 28px;
    min-width: 70px;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: () => ({
    padding: '0px 8px'
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    '& > div': {
      padding: '0 0px',
      background: 'white',
      zIndex: 10,
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 200
  }),
}

const Wrapper = styled.div`
  font-size: 1.3rem;
  height: 100%;

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Table__headerRow {
    overflow: visible !important;
  }

  .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: none;
    }

    .ReactVirtualized__Table__headerTruncatedText {
      white-space: pre-wrap;
      text-align: center;
      overflow: visible !important;
    }

    .ReactVirtualized__Table__sortableHeaderIcon {
      font-size: 22px;
    }
  }

  .ReactVirtualized__Table__rowColumn {
    text-align: center;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .ReactVirtualized__Table__row {
    height: 100%;
  }
`;

const StatusIndicator = styled.div`
  &::after {
    content: '';
    display: block;
    background: ${props => props.color};
    border-radius: 50%;
    border: 1px solid ${props => props.color};
    width: 12px;
    height: 12px;
    margin: auto;
  }
`;

const LinkButton = styled.button`
  text-decoration: underline;
  color: blue;
  text-align: left;
`;

const Tooltip = styled(UncontrolledTooltip)`
  left: -5px !important;

  .tooltip-inner {
    background: white;
    color: black;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.59);
    max-width: 100px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
  }

  .arrow::before {
    border-right-color: white;
  }
`;




