import React, { Component } from 'react';
import PropTypes from 'prop-types';

const AvatarIcon = require('assets/media/images/avatar.png');

class Image extends Component {
  static propTypes = {
    src: PropTypes.string,
    fallbackSrc: PropTypes.string,
  };

  state = {
    src: this.props.src,
  };

  componentDidMount() {
    if (this.props.src) {
      this.setState({ src: this.props.src })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.setState({ src: this.props.src })
    }
  }

  onError = () => {
    this.setState({
      src: null,
    });
  };

  render() {
    const { src } = this.state;
    const { src: propsSrc, fallbackSrc, ...props } = this.props;

    return <img src={src || fallbackSrc || AvatarIcon} onError={this.onError} {...props} />;
  }
}

export default Image;
