import styled from 'styled-components';
import { Modal as BSModal } from 'reactstrap';

const Modal = styled(BSModal)`
  max-width: ${props => (props.width ? props.width : 900)}px;

  .modal-content {
    padding: 2rem;

    .modal-header {
      border-bottom: none;

      button {
        font-size: 40px;
        opacity: 1;
        color: ${props => props.theme.palette.primary};
        position: absolute;
        right: 12px;
        top: 6px;
      }
    }

    .modal-body {
    }

    .modal-footer {
      border-top: none;
    }
  }
`;

export default Modal;
