import React, { Component } from 'react';
import styled from 'styled-components';
import ShowMoreText from 'react-show-more-text';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import moment from 'moment';
import SearchInput from 'components/SearchInput';
import { Flex } from 'styled-minimal';
import SectionContentWrapper from 'components/TabWrapper';
import Table, { ProgressRow } from 'components/Table';
import {
  getRecruitingCommunicationRecruiter,
  updateRecruitingCommunicationMessageRead,
} from 'modules/api';
import { formatUTCDateTime } from 'modules/helpers';
import update from 'immutability-helper';
import CommunicationHistoryModal from './CommunicationHistoryModal';
import withModals from 'modules/withModals';
import Icon from 'components/Icon';
import SendMessageModal from './SendMessageModal';
import { fetchUnreadMessageCount } from 'actions/index';

const TAB = {
  INBOX: 1,
  SENT: 2,
};

const HorizontalTabOptions = [
  { display: 'Inbox', value: TAB.INBOX },
  { display: 'Sent', value: TAB.SENT },
];

const PeriodOptions = [
  { label: 'Last 30 Days', value: 1 },
  { label: 'Last 60 Days', value: 2 },
  { label: 'Custom', value: 3 },
];

const TableHeaderCell = props => {
  const { children, sorted, ...rest } = props;
  return (
    <TableHeader sorted={sorted} {...rest}>
      <div>{children}</div>
    </TableHeader>
  );
};
class Communication extends Component {
  state = {
    activeTab: TAB.INBOX,
    startDate: moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    records: [],
    period: PeriodOptions[0],
    searchTerm: '',
    sortCurrentColumn: 'CreatedDate',
    sortCurrentDirection: 'desc',
    fetchInvertalId: null,
    timelapsed: 0,
  };

  componentDidMount() {
    this.fetchData();

    const fetchInvertalId = setInterval(() => {
      this.setState(({ timelapsed }) => {
        if (timelapsed === 600) {
          // every 10mins
          this.fetchData();
        } else {
          this.setState({ timelapsed: timelapsed + 1 });
        }
      });
    }, 1000);
    this.setState({ fetchInvertalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.fetchInvertalId);
  }

  fetchData = async () => {
    const { activeTab, startDate, endDate, searchTerm, period } = this.state;
    const periodValue = get(period, 'value', 1);

    try {
      const records = await getRecruitingCommunicationRecruiter(
        this.props.user.PK,
        activeTab,
        searchTerm ? searchTerm : null,
        periodValue,
        startDate,
        endDate,
      );
      this.setState({ records }, () => {
        this.handleSort();
      });
      this._fetchUnreadMessageCount();
      this.setState({ timelapsed: 0 });
    } catch (error) {}
  };

  _fetchUnreadMessageCount = () => {
    this.props.dispatch(fetchUnreadMessageCount(this.props.user.PK));
  };

  fetchBySearch = debounce(() => {
    this.fetchData();
  }, 300);

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      if (name === 'searchTerm') {
        this.fetchBySearch();
      } else {
        this.fetchData();
      }
    });
  };

  handleSort = clickedColumn => {
    const { sortCurrentColumn, records, sortCurrentDirection } = this.state;

    let updatedSortDirection = sortCurrentDirection;
    if (clickedColumn && sortCurrentColumn === clickedColumn) {
      updatedSortDirection = sortCurrentDirection === 'asc' ? 'desc' : 'asc';
    }

    let updatedSortCurrentColumn = sortCurrentColumn;
    if (clickedColumn) {
      updatedSortCurrentColumn = clickedColumn;
    }

    let sorted = sortBy(records, [updatedSortCurrentColumn]);
    if (updatedSortDirection === 'desc') {
      sorted = sorted.reverse();
    }

    this.setState({
      sortCurrentColumn: updatedSortCurrentColumn,
      records: sorted,
      sortCurrentDirection: updatedSortDirection,
    });
  };

  changeHorizontalTab = activeTab => {
    this.setState(
      {
        activeTab,
        records: [],
      },
      () => {
        this.fetchData();
      },
    );
  };

  handleChangeRead = row => async event => {
    const { PK, ThreadID } = row;
    event.stopPropagation();

    if (this.state.activeTab === TAB.SENT) {
      return;
    }
    const index = this.state.records.findIndex(c => c.PK === PK);
    const records = update(this.state.records, {
      [index]: { MessageRead: { $set: true } },
    });
    this.setState({ records });
    await updateRecruitingCommunicationMessageRead(PK, true);
    this.handleOpenCommunicationHistoryModal(ThreadID);
    this._fetchUnreadMessageCount();
  };

  handleOpenCommunicationHistoryModal = ThreadID => {
    this.props.openModal('CommunicationHistoryModal', {
      ThreadID,
      user: this.props.user,
      dispatch: this.props.dispatch,
      onUpdate: () => this.fetchData(),
    });
  };

  handleOpenSendMessageModal = isEmail => () => {
    this.props.openModal('SendMessageModal', {
      isEmail,
      user: this.props.user,
      dispatch: this.props.dispatch,
      onSend: () => {
        this.changeHorizontalTab(TAB.SENT);
      },
    });
  };

  render() {
    const {
      activeTab,
      startDate,
      endDate,
      period,
      searchTerm,
      records,
      sortCurrentColumn,
      sortCurrentDirection,
    } = this.state;

    const { FirstName, LastName } = this.props.user;

    return (
      <Container>
        <HeaderSection>
          <Name>{`${FirstName} ${LastName}`}</Name>
          <Flex justifyContent="center">
            <ComposeWrapper onClick={this.handleOpenSendMessageModal(true)}>
              <Icon name="compose-email" color="black" width={20} />
              Compose Email
            </ComposeWrapper>
            <ComposeWrapper onClick={this.handleOpenSendMessageModal(false)}>
              <Icon name="compose-sms" color="black" width={20} />
              Compose SMS
            </ComposeWrapper>
          </Flex>
        </HeaderSection>
        <SectionContentWrapper
          title=""
          hActiveTab={activeTab}
          horizonalTabOptions={HorizontalTabOptions}
          onHorizonalTabChange={this.changeHorizontalTab}
        >
          <Flex>
            <PeriodWrapper>
              <PeriodSelect
                styles={CustomDropdownStyles}
                options={PeriodOptions}
                onChange={p => this.handleChange('period', p)}
                value={period}
              />
              {period.value === 3 && (
                <DateRange>
                  <Row>
                    <RadioLabel>Start Date</RadioLabel>
                    <input
                      placeholder="Start Date"
                      type="date"
                      value={startDate}
                      onChange={e => this.handleChange('startDate', e.target.value || null)}
                    />
                  </Row>
                  <Row>
                    <RadioLabel>End Date</RadioLabel>
                    <input
                      placeholder="End Date"
                      type="date"
                      value={endDate}
                      onChange={e => this.handleChange('endDate', e.target.value || null)}
                    />
                  </Row>
                </DateRange>
              )}
            </PeriodWrapper>
            <Search
              placeholder="Search"
              value={searchTerm}
              onChange={e => this.handleChange('searchTerm', e.target.value)}
            />
          </Flex>
          <TableWrapper>
            <StyledTable borderless striped>
              <thead>
                <tr>
                  <TableHeaderCell
                    sorted={sortCurrentColumn === 'CreatedDate' ? sortCurrentDirection : null}
                    onClick={() => this.handleSort('CreatedDate')}
                  >
                    Date
                  </TableHeaderCell>
                  <TableHeaderCell>Message Type</TableHeaderCell>
                  <TableHeaderCell
                    sorted={sortCurrentColumn === 'Person' ? sortCurrentDirection : null}
                    onClick={() => this.handleSort('Person')}
                  >
                    Person
                  </TableHeaderCell>
                  <TableHeaderCell>Message</TableHeaderCell>
                </tr>
              </thead>
              <tbody>
                {records.map((row, index) => (
                  <ProgressRow
                    key={index}
                    progress={0}
                    odd={index % 2}
                    onClick={() => this.handleOpenCommunicationHistoryModal(row.ThreadID)}
                  >
                    <td>{row.CreatedDate ? formatUTCDateTime(row.CreatedDate) : ''}</td>
                    <td>{row.CommunicationType}</td>
                    <td>{row.Person}</td>
                    <td
                      className={`message-cell ${
                        row.MessageRead || activeTab === TAB.SENT ? '' : 'bold'
                      }`}
                      onClick={!row.MessageRead && this.handleChangeRead(row)}
                    >
                      <ShowMoreText lines={2} more="" less="" width={600}>
                        {row.Message}
                      </ShowMoreText>
                    </td>
                  </ProgressRow>
                ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
        </SectionContentWrapper>
      </Container>
    );
  }
}

export default withModals({
  CommunicationHistoryModal,
  SendMessageModal,
})(Communication);

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const HeaderSection = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  right: 228px;
`;

const Name = styled.div`
  font-weight: bold;
  line-height: 31px;
  font-size: 1.4rem;
  white-space: nowrap;
`;

const ComposeWrapper = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin-right: 25px;

  & > span {
    margin-right: 5px;
  }
`;

const PeriodWrapper = styled.div`
  min-width: 260px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
`;

const DateRange = styled.div`
  margin-top: 10px;

  span {
    width: 8.1rem;
    margin-right: 1rem;
  }

  input {
    font-size: 1.4rem;
  }
`;

const RadioLabel = styled.span`
  margin-left: 0.4rem;
  margin-right: 2rem;
  font-size: 1.4rem;
`;

const PeriodSelect = styled(Select)`
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  box-shadow: none;
  border-radius: 3px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 4.2rem;
    min-width: 70px;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '0px 8px',
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    '& > div': {
      padding: '0 0px',
      background: 'white',
      zIndex: 10,
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 200,
  }),
};

const Search = styled(SearchInput)`
  max-width: 400px;
  margin-left: 20px;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  min-height: 400px;
`;

const StyledTable = styled(Table)`
  &&& {
    font-size: 1.3rem;

    tr td {
      text-align: center;
    }

    .message-cell {
      width: 620px;
      min-width: 620px;
      text-align: left;
      cursor: pointer;

      &.bold {
        font-weight: bold;
      }
    }

    .person-cell {
      text-decoration: underline;
    }
  }
`;

const TableHeader = styled.th`
  cursor: pointer;
  vertical-align: middle !important;

  & > div {
    display: flex;
    align-items: center;
    justify-content: ${props => (props.leftAligned ? 'flex-start' : 'center')};
    color: ${props => props.theme.palette.gray};
    font-size: 1.2rem;
    text-align: ${props => (props.leftAligned ? 'left' : 'center')};

    &:after {
      display: ${props => (props.sorted ? 'block' : 'none')};
      content: '';
      margin-left: 0.5rem;
      width: 0; 
      height: 0; 
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-${props => (props.sorted === 'asc' ? 'bottom' : 'top')}: 0.6rem solid ${props =>
  props.theme.palette.light};
      ;
    }
  }
`;

const LinkButton = styled.button`
  text-decoration: underline;
  margin: 0 2px;
  white-space: nowrap;
`;
