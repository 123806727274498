import styled from 'styled-components';

const Button = styled.button`
  background-color: ${props => (props.outline ? 'white' : props.theme.palette.gray)};
  border-radius: 0.4rem;
  color: ${props => (props.outline ? props.theme.palette.gray : 'white')};
  text-transform: uppercase;
  min-width: 20rem;
  font-size: 1.6rem;
  padding: 0 1rem;
  height: 4.2rem;
  border: 0.1rem solid ${props => (props.outline ? props.theme.palette.gray : 'transparent')};

  &:disabled {
    background-color: ${props => (props.outline ? 'white' : props.theme.palette.light)};
    cursor: not-allowed;
  }
`;

export default Button;
