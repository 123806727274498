import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { sumBy } from 'lodash';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import { getRecruitingDashboardStatsMCs } from 'modules/api';
import { Table } from 'reactstrap';
import { formatDate } from 'modules/helpers';
import DownloadCSV from 'components/DownloadCSV';
import withModals from 'modules/withModals';
import MarketCenterDetailsModal from './MarketCenterDetailsModal';

class MarketCenterStatsModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    marketCenterSnapshot: [],
    fetching: true
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { OptoutFlag, period, startDate, endDate } = this.props;
    getRecruitingDashboardStatsMCs(null, OptoutFlag, period, startDate, endDate).then(res => {
      this.setState({ 
        marketCenterSnapshot: res.result,
        fetching: false
      });
    })
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleShowMarketCenterDetails = (marketCenter) => () => {
    const { period, startDate, endDate } = this.props
    this.props.openModal('MarketCenterDetailsModal', {
      period, startDate, endDate, marketCenter
    })
  }


  render() {
    const { isOpen, startDate, endDate } = this.props;
    const { marketCenterSnapshot, fetching } = this.state;

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={700}>
        <ModalHeader toggle={this.handleClose}>
          Market Centers
        </ModalHeader>
        <DownloadWrapper>
          <DownloadCSV data={marketCenterSnapshot} filename={`MarketCenters`}/>
        </DownloadWrapper>
        <ModalBody>
          <DateRangeView>{`${formatDate(startDate)} ~ ${formatDate(endDate)}`}</DateRangeView>
          {marketCenterSnapshot.length > 0 ? (
            <SnapshotTable striped>
              <thead>
                <tr>
                  <WhiteTh colSpan={2}></WhiteTh>
                  <WhiteTh />
                  <th colSpan={7}>Agents</th>
                  <WhiteTh />
                  <th colSpan={2}>Production</th>
                </tr>
                <tr>
                  <WhiteTh style={{ height: 2 }} colSpan={10}></WhiteTh>
                </tr>
                <tr>
                  <th></th>
                  <th>Market Center</th>
                  <WhiteTh />
                  <th>Participating</th>
                  <th>Called</th>
                  <th>Made Contact</th>
                  <th>Assigned to LO</th>
                  <th>Closed</th>
                  <th>My LO</th>
                  <th>My LO Invites</th>
                  <WhiteTh />
                  <th>Leads</th>
                  <th>Closed</th>
                </tr>
              </thead>
              <tbody>
                {marketCenterSnapshot.map((row, index) => 
                  <tr>
                    <td>{index + 1}</td>
                    <td className="name-cell">
                      <LinkButton onClick={this.handleShowMarketCenterDetails(row)}>{`${row.MarketCenterName} (${row.MarketCenterID})`}</LinkButton>
                    </td>
                    <WhiteTd/>
                    <td>{row.AgentsParticipating}</td>
                    <td>{row.AgentsCalled}</td>
                    <td>{row.AgentsMadeContact}</td>
                    <td>{row.AgentsAssignedToLO}</td>
                    <td>{row.AgentsClosed}</td>
                    <td>{row.AgentsMyLO}</td>
                    <td>{row.AgentsMyLOInvites}</td>
                    <WhiteTd/>
                    <td>{row.LeadsReceived}</td>
                    <td>{row.DealsClosed}</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2}>Total</td>
                  <WhiteTd />
                  <td>{sumBy(marketCenterSnapshot, 'AgentsParticipating')}</td>
                  <td>{sumBy(marketCenterSnapshot, 'AgentsCalled')}</td>
                  <td>{sumBy(marketCenterSnapshot, 'AgentsMadeContact')}</td>
                  <td>{sumBy(marketCenterSnapshot, 'AgentsAssignedToLO')}</td>
                  <td>{sumBy(marketCenterSnapshot, 'AgentsClosed')}</td>
                  <td>{sumBy(marketCenterSnapshot, 'AgentsMyLO')}</td>
                  <td>{sumBy(marketCenterSnapshot, 'AgentsMyLOInvites')}</td>
                  <WhiteTd />
                  <td>{sumBy(marketCenterSnapshot, 'LeadsReceived')}</td>
                  <td>{sumBy(marketCenterSnapshot, 'DealsClosed')}</td>
                </tr>
              </tfoot>
            </SnapshotTable>
          ) : (
            <Fragment>
              {!fetching && <div>There is no result.</div>}
            </Fragment>
          )
        }
        </ModalBody>
      </Modal>
    );
  }
}

export default 
  withModals({ 
    MarketCenterDetailsModal,
  })(MarketCenterStatsModal)


const Modal = styled(DigeryModal)`
  max-width: 1000px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    height: 600px;
    overflow: auto;
  }
  .modal-body {
    overflow: auto;
  }
`;

const DownloadWrapper = styled.div`
  height: 0;
  position: absolute;
  right: 64px;
  top: 30px;

  & > a {
    transform: translateY(0%);
    right: 0;
  }
`;

const SnapshotTable = styled(Table)`
  &&& {
    font-size: 1.2rem;

    tr {
      border: none;
    }

    tfoot td, th {
      text-align: center;
      height: 50px;
      vertical-align: middle;
      background: #ddd;
      border: none;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    th {
      padding: 2px;
    }

    td {
      text-align: center;
      height: 50px;
      vertical-align: middle;
      border: none;
      white-space: pre-wrap;
      word-break: break-all;
      padding: 2px;
      
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

const WhiteTh = styled.th`
  background: white !important;
  padding: 4px !important;
`;

const WhiteTd = styled.td`
  background: white !important;
  padding: 4px !important;
`;

const LinkButton = styled.button`
  text-decoration: underline;
  margin: 0 2px;
  white-space: nowrap;
`;

const DateRangeView = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 15px;
`;