/**
 * @module Sagas/App
 * @desc App
 */
import { all, put, takeLatest, call } from 'redux-saga/effects';

import { ActionTypes } from 'constants/index';
import { getRecruitingMessageCountUnread } from 'modules/api';

/**
 * My Inbox Unread Message Count
 *
 * @param {Object} action
 *
 */
export function* fetchUnreadMessageCount({ payload }) {
  try {
    const recruiterID = payload
    const response = yield call(getRecruitingMessageCountUnread, recruiterID);
    yield put({
      type: ActionTypes.SET_UNREAD_MESSAGE_COUNT,
      payload: response[0].UnreadMessageCount,
    });
  } catch (err) {
  }
}

/**
 * App Sagas
 */
export default function* root() {
  yield all([takeLatest(ActionTypes.FETCH_UNREAD_MESSAGE_COUNT, fetchUnreadMessageCount)]);
}
