import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import { pickBy, identity } from 'lodash';
import update from 'immutability-helper';
import DigeryModal from 'components/Modal';
import Input from 'components/Input';
import RadioBox from 'components/RadioBox';
import Select from 'react-select';
import Button from 'components/Button';
import { addUpdateUser, sendEmail } from 'modules/api';
import { showAlert } from 'actions';

const Modal = styled(DigeryModal)`
  max-width: 600px;

  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
`;

const Row = styled.div`
  display: flex;
  height: 40px;
  margin: auto;
  margin-bottom: 7px;
  font-size: 1.4rem;
  width: 80%;
`;

const DescriptionRow = styled.div`
  display: flex;
  width: fit-content;
  margin: auto;
  font-size: 1.2rem;
  color: ${props => props.theme.palette.gray};
  width: 80%;
`;

const Label = styled.div`
  width: 30%;
  text-align: right;
  padding-right: 20px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
`;

const RadioLabel = styled.span`
  margin-left: 4px;
  margin-right: 20px;
`;

const AddButton = styled.button`
  position: absolute;
  right: -57px;
  font-size: 13px;
  color: white;
  background: ${props => props.theme.palette.gray};
  padding: 4px 6px;
  border-radius: 5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 3.5rem;
  flex-direction: column;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  box-shadow: none;
  border-radius: 3px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 4rem;
    min-width: 100%;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '0px 8px',
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    '& > div': {
      padding: '0 0px',
      background: 'white',
    },
  }),
};

const PermissionOptions = ['Admin', 'User', 'Rep', 'Recruiter'].map((label, index) => ({
  label,
  value: index + 1,
}));

export default class LoanOfficerModal extends Component {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
  };

  state = {
    data: {},
    isUpdatePassword: false,
  };

  componentDidMount() {
    if (this.props.showModal) {
      this.initState();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.showModal && !prevProps.showModal) {
      this.initState();
    }
  }

  initState = () => {
    const { StatusDescription, PermissionsLevelDescription, ...rest } = this.props.data;
    let data = {
      Status: 1,
      PermissionsLevel: 2,
    };
    if (this.props.data.PK) {
      let PermissionsLevel;
      switch (PermissionsLevelDescription) {
        case 'Admin':
          PermissionsLevel = 1;
          break;
        case 'User':
          PermissionsLevel = 2;
          break;
        case 'Rep':
          PermissionsLevel = 3;
          break;
        default:
          PermissionsLevel = 4;
          break;
      }
      data = {
        ...rest,
        Status: StatusDescription === 'Active' ? 1 : 0,
        PermissionsLevel,
      };
    }
    this.setState({
      data,
      isUpdatePassword: false,
    });
  };

  handleClose = () => {
    this.props.onClose();
  };

  onChange = key => e => {
    const data = update(this.state.data, {
      [key]: { $set: e.target.value },
    });
    this.setState({ data });
  };

  onChangeStatus = Status => () => {
    const data = update(this.state.data, {
      Status: { $set: Status },
    });
    this.setState({ data });
  };

  onChangePermissionLevel = PermissionsLevel => {
    const data = update(this.state.data, {
      PermissionsLevel: { $set: PermissionsLevel },
    });
    this.setState({ data });
  };

  handleAddUpdateUser = () => {
    const { dispatch, onUpdate } = this.props;
    const { PK, Password, Password2, Email, Firstname, Lastname } = this.state.data;
    addUpdateUser(pickBy(this.state.data, identity))
      .then(res => {
        if (PK && Password && Password2 && Email) {
          const message = `
            Hi, ${Firstname} ${Lastname}
            This message is to confirm that your password has been reset.
            <br/>
            Keller Mortgage
            `;
          const params = {
            email: 'support@kellermortgage.com',
            subject: 'Changes to your KM Portal Admin account',
            message,
            name: 'Keller Mortgage',
            service_email: Email,
          };
          sendEmail(params)
            .then(response => {})
            .catch(error => {
              console.log(error);
            });
        }
        dispatch(showAlert('Saved successfully.', { variant: 'success', icon: 'bell' }));
        onUpdate();
        this.handleClose();
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleEnableUpdatePassword = () => {
    this.setState({ isUpdatePassword: true });
  };

  render() {
    const { showModal } = this.props;
    const { data, isUpdatePassword } = this.state;
    const enabledInputPassword = !data.PK || isUpdatePassword;

    let permissionDescription;
    switch (data.PermissionsLevel) {
      case 1:
        permissionDescription = 'Full Access Privileges.';
        break;
      case 2:
        permissionDescription = 'Access limited to Loan Officers, Reports, Agent Search.';
        break;
      case 3:
        permissionDescription = 'Access limited to Loan Officers, Reports, Agent Search.';
        break;
      default:
        permissionDescription = 'Access to all menu objects under the Recruiting area';
    }

    const buttonDisabled =
      !data.Firstname ||
      !data.Lastname ||
      !data.Email ||
      (enabledInputPassword &&
        (!data.Password || !data.Password2 || data.Password !== data.Password2));

    return (
      <Modal centered fade={false} isOpen={showModal} toggle={this.handleClose} width={700}>
        <ModalHeader toggle={this.handleClose}>{`${data.PK ? 'Update' : 'Add'} User`}</ModalHeader>
        <ModalBody>
          <Row>
            <Label>First Name</Label>
            <Value>
              <Input
                placeholder="First Name"
                value={data.Firstname}
                onChange={this.onChange('Firstname')}
              />
            </Value>
          </Row>
          <Row>
            <Label>Last Name</Label>
            <Value>
              <Input
                placeholder="Last Name"
                value={data.Lastname}
                onChange={this.onChange('Lastname')}
              />
            </Value>
          </Row>
          <Row>
            <Label>Email</Label>
            <Value>
              <Input value={data.Email || ''} onChange={this.onChange('Email')} />
            </Value>
          </Row>
          <Row>
            <Label>Phone</Label>
            <Value>
              <Input value={data.Phone || ''} onChange={this.onChange('Phone')} />
            </Value>
          </Row>
          {!enabledInputPassword && (
            <Row>
              <Label>Password</Label>
              <Value>
                <Input type="password" value="randomepassword" readOnly />
                <AddButton onClick={this.handleEnableUpdatePassword}>Reset</AddButton>
              </Value>
            </Row>
          )}
          {enabledInputPassword && (
            <Fragment>
              <Row>
                <Label>Password</Label>
                <Value>
                  <Input
                    type="password"
                    value={data.Password || ''}
                    onChange={this.onChange('Password' || '')}
                  />
                </Value>
              </Row>
              <Row>
                <Label>Confirm Password</Label>
                <Value>
                  <Input
                    type="password"
                    value={data.Password2 || ''}
                    onChange={this.onChange('Password2') || ''}
                  />
                </Value>
              </Row>
            </Fragment>
          )}
          <Row>
            <Label>Status</Label>
            <Value>
              <RadioBox value={data.Status === 1} onChange={this.onChangeStatus(1)} />
              <RadioLabel>Active</RadioLabel>
              <RadioBox value={data.Status === 0} onChange={this.onChangeStatus(0)} />
              <RadioLabel>Inactive</RadioLabel>
            </Value>
          </Row>
          <Row>
            <Label>Permission Level</Label>
            <Value>
              <StyledSelect
                styles={CustomDropdownStyles}
                options={PermissionOptions}
                onChange={option => this.onChangePermissionLevel(option.value)}
                value={PermissionOptions.find(o => o.value === data.PermissionsLevel)}
              />
              {/* <RadioBox
                value={data.PermissionsLevel === 1}
                onChange={this.onChangePermissionLevel(1)}
              />
              <RadioLabel>Admin</RadioLabel>
              <RadioBox
                value={data.PermissionsLevel === 2}
                onChange={this.onChangePermissionLevel(2)}
              />
              <RadioLabel>User</RadioLabel>
              <RadioBox
                value={data.PermissionsLevel === 3}
                onChange={this.onChangePermissionLevel(3)}
              />
              <RadioLabel>Rep</RadioLabel> */}
            </Value>
          </Row>
          <DescriptionRow>
            <Label />
            <Value>{permissionDescription}</Value>
          </DescriptionRow>
          <ButtonWrapper>
            <Button disabled={buttonDisabled} onClick={this.handleAddUpdateUser}>
              Save
            </Button>
          </ButtonWrapper>
        </ModalBody>
      </Modal>
    );
  }
}
