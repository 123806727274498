import styled from 'styled-components';
import { media } from 'modules/theme';

const HeaderTitle = styled.div`
  color: ${props => (props.primary ? props.theme.palette.primary : props.theme.palette.gray)};
  font-size: 3.6rem;
  max-width: 80rem;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;

  ${media.mobile`
    display: none;
  `};
`;

export default HeaderTitle;
