import styled from 'styled-components';
const DownloadIcon = require('assets/media/images/download.png');
import { CSVLink } from "react-csv";
import { UncontrolledTooltip } from 'reactstrap'; 
import React from 'react';
import moment from 'moment';

const Link = styled(CSVLink)`
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    font-size: 12px;
    color: black;
  }
`;

const Tooltip = styled(UncontrolledTooltip)`
  left: -5px !important;

  .tooltip-inner {
    background: white;
    color: black;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.59);
    max-width: 100px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
  }

  .arrow::before {
    border-left-color: white;
  }
`;

const downloadLink = ({ filename, title, ...props }) => {
  return (
    <Link filename={`${filename}_${moment().format()}.csv`} {...props} id='tooltip-download'>
      <img src={DownloadIcon}/>
      {title && <div>{title}</div>}
      <Tooltip placement="left" target={'tooltip-download'} delay={0}>
        Download
      </Tooltip>
    </Link>
  )
}

export default downloadLink;
