import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Flex } from 'styled-minimal';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import { login, showAlert } from 'actions/index';
import ZeroPlusLogo from 'assets/media/brand/zeroplus_logo.png';
import { setToken, setLoginFailedDate, getLoginFailedDate } from 'modules/localstorage';
import { sigin } from 'modules/api';
import Input from '../components/Input';
import Button from '../components/Button';

const SubTitle = styled.div`
  font-size: 2.8rem;
  color: #5b5b5b;
  margin-top: 100px;

`;

const StyledInput = styled(Input)`
  width: 238px;
  margin: 7px;
`;

const BoxButton = styled(Button)`
  margin: 5.5rem 0 0.5rem;
`;

const TextButton = styled(Link)`
  margin-top: 10px;
  color: ${props => props.theme.palette.primary};
  font-size: 13px;
  margin-bottom: 30px;
  &:hover,
  &:focus {
    color: ${props => props.theme.palette.primary};
  }
`;

const Error = styled.div`
  color: red;
`;

const MAX_LOGIN_COUNT = 5
class SignIn extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    password: '',
    email: '',
    failedCount: 0,
    showErrorIntervalId: null,
    disableLoginError: '',
  };

  componentDidMount() {
    this.updateErrorMessasge();
    const showErrorIntervalId = setInterval(() => {
      this.updateErrorMessasge();
    }, 1000 * 60);
    this.setState({ showErrorIntervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.showErrorIntervalId);
  }

  updateErrorMessasge = () => {
    let disableLoginError = null;
    const loginFailedDate = getLoginFailedDate();
    if (loginFailedDate > 0) {
      const timeElapsed = Math.round((Date.now() - loginFailedDate) / 1000 / 60);
      if (timeElapsed < 30) {
        disableLoginError = `You've reached the maximum number of login attempts.  Please retry in ${30 -
          timeElapsed} minutes.`;
      }
    }

    this.setState({ disableLoginError });
  };

  handleClickLogin = () => {
    const { dispatch } = this.props;
    const { password, email, failedCount } = this.state;
    if (!password) {
      dispatch(showAlert('Password is required.', { variant: 'danger', icon: 'bell' }));
      return;
    }
    if (!email) {
      dispatch(showAlert('Email is required.', { variant: 'danger', icon: 'bell' }));
      return;
    }

    sigin(email, password)
      .then(res => {
        const { token, user } = res;
        setToken(token);
        dispatch(login(user));
        setLoginFailedDate(0);
      })
      .catch(error => {
        if (error?.status === 400) {
          dispatch(
            showAlert('Please check your credential and try again.', {
              variant: 'danger',
              icon: 'bell',
              timeout: 1,
            }),
          );
          if (failedCount < MAX_LOGIN_COUNT - 1) {
            this.setState({ failedCount: failedCount + 1 });
          } else {
            setLoginFailedDate(Date.now());
            this.setState({ failedCount: 0 });
            this.updateErrorMessasge();
          }
        }
      });
  };

  handleChange = name => e => {
    this.setState({ [name]: e.target.value });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleClickLogin();
    }
  };

  render() {
    const { password, email, disableLoginError } = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>SignIn</title>
        </Helmet>
        <SubTitle>Sign In to the Admin Portal</SubTitle>
        <Flex alignItems="center" mt="30px" flexDirection="column">
          <StyledInput
            disabled={!!disableLoginError}
            placeholder="Email"
            value={email}
            onChange={this.handleChange('email')}
          />
          <StyledInput
            disabled={!!disableLoginError}
            type="password"
            placeholder="password"
            value={password}
            onChange={this.handleChange('password')}
            onKeyPress={this.handleKeyPress}
          />
        </Flex>
        <BoxButton disabled={!!disableLoginError} onClick={this.handleClickLogin}>
          Sign In
        </BoxButton>
        <TextButton to="/forgot-my-password">Forgot Password</TextButton>
        {disableLoginError && <Error>{disableLoginError}</Error>}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(SignIn);
