import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import { getSurveyMonkeyAgentResponsesACS } from 'modules/api';
import { Table } from 'reactstrap';
import { formatDate } from 'modules/helpers';
import DownloadCSV from 'components/DownloadCSV';
import { orderBy }from 'lodash';

export default class SurveyResponsesModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    data: [],
    fetching: true
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { startDate, endDate, recruiterID } = this.props
    getSurveyMonkeyAgentResponsesACS(recruiterID, startDate, endDate).then(res => {
      this.setState({ 
        data: res.result,
        fetching: false
      });
    })
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, startDate, endDate, recruiterID } = this.props;
    const { data, fetching } = this.state;
    const firstRow = data[0]
    const questions = firstRow ? Object.keys(firstRow).filter(key => key.includes('Question')) : []

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>
          {data.length > 0 ? data[0].SurveyTitle : ''}
        </ModalHeader>
        <DownloadWrapper>
          <DownloadCSV data={data} filename={`SurveyResponses`}/>
        </DownloadWrapper>
        <ModalBody>
          {/* <DateRangeView>{`${formatDate(startDate)} ~ ${formatDate(endDate)}`}</DateRangeView> */}
          {recruiterID && firstRow && <DateRangeView>{`${firstRow.RecruiterFirstName} ${firstRow.RecruiterLastName}`}</DateRangeView>}

          {data.length > 0 ? (
            <TableWrapper>
              <SnapshotTable striped>
                <thead>
                  <tr>
                    <th>Response Date</th>
                    {!recruiterID && <th>Recruiter</th>}
                    <th>KWUID</th>
                    <th>Agent</th>
                    {questions.map((_, index) => (
                      <>
                        <th className="question">Question{index + 1}</th>
                        <th>Answer{index + 1}</th>
                      </>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {orderBy(data, ['ResponseDate'], ['desc']).map((row) => 
                    <tr>
                      <td>{formatDate(row.ResponseDate)}</td>
                      {!recruiterID && <td>{row.RecruiterFirstName} {row.RecruiterLastName}</td>}
                      <td>{row.KWUID}</td>
                      <td>{row.AgentFirstName} {row.AgentLastName}</td>
                      {questions.map((_, index) => (
                        <>
                          <td className="question">{row[`Question${index + 1}`]}</td>
                          <td>{row[`Answer${index + 1}`]}</td>
                        </>
                      ))}
                    </tr>
                  )}
                </tbody>
              </SnapshotTable>
            </TableWrapper>
          ) : (
            <Fragment>
              {!fetching && <div>There is no result.</div>}
            </Fragment>
          )
        }
        </ModalBody>
      </Modal>
    );
  }
}


const Modal = styled(DigeryModal)`
  max-width: 1000px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    height: 600px;
    position: relative;
  }
`;

const DownloadWrapper = styled.div`
  height: 0;
  position: absolute;
  right: 64px;
  top: 30px;

  & > a {
    transform: translateY(0%);
    right: 0;
  }
`;

const TableWrapper = styled.div`
  overflow: auto;
  width: 100%;
  max-height: 450px;
`

const SnapshotTable = styled(Table)`
  &&& {
    font-size: 1.2rem;

    tr {
      border: none;
    }

    th {
      padding: 2px;
      min-width: 100px;
      text-align: center;
    }

    td {
      text-align: center;
      height: 50px;
      vertical-align: middle;
      border: none;
      white-space: pre-wrap;
      word-break: break-all;
      padding: 2px;
      min-width: 100px;
    }

    .question {
      min-width: 200px;
    }
  }
`;

const DateRangeView = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 15px;
`;
