import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';

import Button from 'components/Button';
import { getPortalAdminNotificationsUsers, deletePortalAdminNotificationsUsers } from 'modules/api';
import withModals from 'modules/withModals';
import AddSubscribersModal from './AddSubscribersModal';

const Modal = styled(DigeryModal)`
  max-width: 450px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-body {
    padding-top: 3rem;
    max-height: 500px;
    overflow: auto;
    text-align: center;
  }
`;

const RemoveButton = styled(Button)`
  text-transform: none;
  min-width: 12rem;
  font-size: 1.2rem;
  height: 2.5rem;
  border-radius: 999px;
`;

const AddButton = styled(Button)`
  margin-top: 2rem;
`;


const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
`;

class SubscribersModal extends Component {
  static propTypes = {
    loanOfficer: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    users: [],
    isLoading: true,
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.fetchNotificationUsers()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.fetchNotificationUsers()
    }
  }

  fetchNotificationUsers = () => {
    const { notification } = this.props;
    getPortalAdminNotificationsUsers(notification.PK)
      .then(res => {
        this.setState({ 
          isLoading: false,
          users: res
        })
      })
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleAddSubscriber = () => {
    this.props.openModal('AddSubscribersModal', {
      notification: this.props.notification,
      onUpdate: this.fetchNotificationUsers,
    })
  }

  handleRemoveUser = (PK) => () => {
    deletePortalAdminNotificationsUsers(PK)
      .then(res => {
        this.fetchNotificationUsers();
      })
  }

  render() {
    const { isOpen, notification } = this.props;
    const { users, isLoading } = this.state;

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={700}>
        <ModalHeader toggle={this.handleClose}>
          {notification.Name}
        </ModalHeader>
        <ModalBody>
          {(!isLoading && users.length === 0) ? (
            <div>No users are subscribed to this notification.</div>
          ) : (
            <Fragment>
              {
                users.map((a, index) => (
                  <Row key={index}>
                    <div>{`${index+1}. ${a.UserName}`}</div>
                    <RemoveButton onClick={this.handleRemoveUser(a.PK)}>Remove</RemoveButton>
                  </Row>
                ))
              }
            </Fragment>
          )}
          <AddButton onClick={this.handleAddSubscriber}>Add Subscriber</AddButton>
        </ModalBody>
      </Modal>
    );
  }
}

export default withModals({ AddSubscribersModal})(SubscribersModal)
