import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap'; 


const Tooltip = styled(UncontrolledTooltip)`
  left: -5px !important;

  .tooltip-inner {
    background: white;
    color: black;
    box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.59);
    max-width: 100px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
  }

  .arrow::before {
    border-left-color: white;
  }
`;

export default Tooltip;
