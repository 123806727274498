import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { sortBy } from 'lodash';
import update from 'immutability-helper';
import InputField from 'components/Input';
import TextArea from 'components/TextArea';
import Image from 'components/Image';
import CheckBox from 'components/CheckBox';
import { STATES } from 'constants/index';
import Button from 'components/Button';
import { addUpdateLoanOfficer, uploadFile, getPortalAdminLoanOfficer, getKMLoanOfficerTypes } from 'modules/api';
import { showAlert } from 'actions/index';
import Container from 'components/Container';
import Tooltip from 'components/Tooltip';
import withModals from 'modules/withModals';
import SectionContentWrapper from 'components/TabWrapper';
import Dropdown from 'components/Dropdown';
import LoanOfficerPreviewModal from '../LoanOfficers/LoanOfficerPreviewModal';
import EditLoanOfficerBioModal from '../LoanOfficers/EditLoanOfficerBioModal';

const Avatar = require('assets/media/images/avatar.png');

const StateDropdownOptions = STATES.map(state => ({
  label: state,
  value: state,
}));

const TAB = {
  PROFILE: 'My Profile',
  BIO: 'My Bio',
};

const HorizontalTabOptions = [
  { display: TAB.PROFILE, value: TAB.PROFILE },
  { display: TAB.BIO, value: TAB.BIO },
];

class MyProfile extends Component {
  state = {
    data: {},
    showLanguagesPicker: false,
    photoLoading: false,
    activeTab: TAB.PROFILE,
    isNotSubmittedSavedBio: false,
    loanOfficerTypes: [],
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleHidePicker, false);
    this.fetchLoanOfficerData();
    this.fetchLoanOfficerTypes();
  }

  fetchLoanOfficerData = () => {
    const {LOID} = this.props.user.data;
    getPortalAdminLoanOfficer(LOID).then(res => this.setState({ data: res[0] }));
  };

  fetchLoanOfficerTypes = () => {
    getKMLoanOfficerTypes().then(res => this.setState({ loanOfficerTypes: sortBy(res, 'SortOrder') }));
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleHidePicker, false);
  }

  handleHidePicker = event => {
    if (this.languagesPickerRef && !this.languagesPickerRef.contains(event.target)) {
      this.setState({ showLanguagesPicker: false });
    }
  };

  onChange = key => e => {
    let newValue;
    switch (key) {
      case 'Status':
        newValue = e ? 'Active' : 'Inactive';
        break;
      case 'Availability':
      case 'Selectability':
      case 'PhoneMobileDispalyFlag':
        newValue = !!e;
        break;
      case 'StateCode':
      case 'LoanOfficerTypeID':
        newValue = e;
        break;
      default:
        newValue = e.target.value;
    }
    const data = update(this.state.data, {
      [key]: { $set: newValue },
    });
    this.setState({ data });
  };

  onChangeLanguages = language => value => {
    const { data } = this.state;
    let languagesValue = [];
    if (data.Languages) {
      languagesValue = data.Languages.replace(/\s+/g, '').split(',');
    }

    if (value) {
      languagesValue.push(language);
    } else {
      languagesValue = languagesValue.filter(s => s !== language);
    }

    const newData = update(this.state.data, {
      Languages: { $set: languagesValue.join(', ') },
    });
    this.setState({ data: newData });
  };

  handleAddUpdateLoanOfficer = () => {
    const { dispatch } = this.props;
    addUpdateLoanOfficer(this.state.data)
      .then(() => {
        dispatch(showAlert('Saved successfully.', { variant: 'success', icon: 'bell' }));
      })
      .catch(() => {});
  };

  handleSelectFile = e => {
    const { data } = this.state;
    const { dispatch } = this.props;
    const { LoanOfficerNMLS } = data;
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    this.setState({ photoLoading: true });
    uploadFile(file, LoanOfficerNMLS)
      .then(res => {
        const { fileName, url } = res;
        const newData = update(data, {
          ProfilePhoto: { $set: fileName },
          ProfilePhotoLink: { $set: url },
        });
        this.setState({
          data: newData,
          photoLoading: false,
        });
      })
      .catch(() => {
        this.setState({ photoLoading: false });
        dispatch(
          showAlert('Failed to upload, please try again.', { variant: 'danger', icon: 'bell' }),
        );
      });
  };

  handleRemoveLink = () => {
    const { data } = this.state;
    const newData = update(data, {
      ProfilePhoto: { $set: null },
      ProfilePhotoLink: { $set: null },
    });
    this.setState({ data: newData });
  };

  handleToggleLanguagesPicker = () => {
    this.setState({ showLanguagesPicker: !this.state.showLanguagesPicker });
  };

  handleShowPreview = loanOfficer => {
    this.props.openModal('LoanOfficerPreviewModal', {
      loanOfficer,
    });
  };

  handleShowEditBioModal = () => {
    this.props.openModal('EditLoanOfficerBioModal', {
      loanOfficer: this.state.data,
      onShowPreview: this.handleShowPreview,
      onUpdate: this.fetchLoanOfficerData,
      onSubmitFlagChanged: isNotSubmittedSavedBio => {
        this.setState({ isNotSubmittedSavedBio });
      },
      isNotSubmittedSavedBio: this.state.isNotSubmittedSavedBio,
    });
  };

  render() {
    const { data, photoLoading, showLanguagesPicker, activeTab, loanOfficerTypes } = this.state;
    const loTypesOptions = loanOfficerTypes.map((el) => ({
      label: el.LoanOfficerTypeName,
      value: el.PK,
    }));

    let languagesValue = [];
    if (data.Languages) {
      languagesValue = data.Languages.replace(/\s+/g, '').split(',');
    }

    const buttonDisabled = photoLoading || !data.FirstName || !data.LastName || !data.Email;

    if (!this.props.user.data.LOID) {
      return null;
    }

    return (
      <Wrapper>
        <SectionContentWrapper
          hActiveTab={activeTab}
          horizonalTabOptions={HorizontalTabOptions}
          onHorizonalTabChange={tab => this.setState({ activeTab: tab })}
        >
          <BodyWrapper>
            {activeTab === TAB.PROFILE ? (
              <>
                <Row>
                  <Label>First Name</Label>
                  <Value>
                    <Input
                      placeholder="First Name"
                      value={data.FirstName}
                      onChange={this.onChange('FirstName')}
                    />
                  </Value>
                </Row>
                <Row>
                  <Label>Last Name</Label>
                  <Value>
                    <Input
                      placeholder="Last Name"
                      value={data.LastName}
                      onChange={this.onChange('LastName')}
                    />
                  </Value>
                </Row>
                <Row>
                  <Label>NMLS</Label>
                  <Value>
                    <Input
                      value={data.LoanOfficerNMLS || ''}
                      onChange={this.onChange('LoanOfficerNMLS')}
                    />
                  </Value>
                </Row>
                <Row>
                  <Label>Email</Label>
                  <Value>
                    <Input value={data.Email || ''} onChange={this.onChange('Email')} />
                  </Value>
                </Row>
                <Row>
                  <Label>Work Phone</Label>
                  <Value>
                    <Input value={data.PhoneWork || ''} onChange={this.onChange('PhoneWork')} />
                  </Value>
                </Row>
                <Row>
                  <Label>Mobile Phone</Label>
                  <Value>
                    <Input value={data.PhoneMobile || ''} onChange={this.onChange('PhoneMobile')} />
                  </Value>
                </Row>
                <Row>
                  <Label />
                  <Value>
                    <CheckBox value={!!data.PhoneMobileDispalyFlag} onChange={this.onChange('PhoneMobileDispalyFlag')} />
                    <span style={{marginLeft: 10}}>Hide from public display</span>
                  </Value>
                </Row>
                <Row>
                  <Label>Type</Label>
                  <Value>
                    <Dropdown
                      placeholder="- - -"
                      options={loTypesOptions}
                      onChange={this.onChange('LoanOfficerTypeID')}
                      value={data.LoanOfficerTypeID}
                    />
                  </Value>
                </Row>
                <Row>
                  <Label>Position</Label>
                  <Value>
                    <Input value={data.Position || ''} onChange={this.onChange('Position')} />
                  </Value>
                </Row>
                <Row>
                  <Label>Text Msg Only(Message to Borrow)</Label>
                  <Value>
                    <MultipleLineInput
                      value={data.MsgToBorrowerText || ''}
                      onChange={this.onChange('MsgToBorrowerText')}
                    />
                  </Value>
                </Row>
                <Row>
                  <Label>Email Only(Message to Borrow)</Label>
                  <Value>
                    <MultipleLineInput
                      value={data.MsgToBorrowerEmail || ''}
                      onChange={this.onChange('MsgToBorrowerEmail')}
                    />
                  </Value>
                </Row>
                <Row>
                  <Label>Email Signature</Label>
                  <Value>
                    <MultipleLineInput
                      value={data.MsgToBorrowerSignature || ''}
                      onChange={this.onChange('MsgToBorrowerSignature')}
                    />
                  </Value>
                </Row>
                <Row>
                  <Label>Licensed States</Label>
                  <Value>
                    <Input
                      value={data.LicensedStates || ''}
                      onChange={this.onChange('LicensedStates')}
                      readOnly
                    />
                  </Value>
                </Row>
                <Row>
                  <Label>My Residence</Label>
                  <Value>
                    <Dropdown
                      placeholder="- - -"
                      options={StateDropdownOptions}
                      onChange={this.onChange('StateCode')}
                      value={data.StateCode}
                    />
                  </Value>
                </Row>
                <Row>
                  <Label>Languages</Label>
                  <Value>
                    <Input
                      value={data.Languages || ''}
                      onChange={this.onChange('Languages')}
                      readOnly
                    />
                    <AddButton onClick={this.handleToggleLanguagesPicker}>+</AddButton>
                    {showLanguagesPicker && (
                      <StatePickerDropdown ref={node => (this.languagesPickerRef = node)}>
                        {['English', 'Spanish'].map(s => (
                          <div key={s}>
                            <CheckBox
                              value={languagesValue.includes(s)}
                              onChange={this.onChangeLanguages(s)}
                            />
                            <span>{s}</span>
                          </div>
                        ))}
                      </StatePickerDropdown>
                    )}
                  </Value>
                </Row>
                {/* <Row>
                  <Label>Years in Business</Label>
                  <Value>
                    <Input
                      value={data.YearsInBusiness || ''}
                      onChange={this.onChange('YearsInBusiness')}
                    />
                  </Value>
                </Row> */}
                <Row>
                  <Label>Industry Start Date</Label>
                  <Value>
                    <Input
                      value={
                        data.LoanOfficerIndustryStartDate &&
                        moment.utc(data.LoanOfficerIndustryStartDate).format('YYYY-MM-DD')
                      }
                      onChange={this.onChange('LoanOfficerIndustryStartDate')}
                      type="date"
                    />
                  </Value>
                </Row>
                <AutoHeightRow>
                  <Label>Profile Photo</Label>
                  <ImageValue>
                    <ProfileImageWrapper>
                      <ProfileImage src={data.ProfilePhoto ? data.ProfilePhotoLink : Avatar} />
                      {photoLoading && <Loading />}
                    </ProfileImageWrapper>
                    {false ? (
                      <UploadButton onClick={this.handleRemoveLink}>
                        <span>Delete Photo</span>
                      </UploadButton>
                    ) : (
                      <UploadButton>
                        <input
                          id="add-upload-file"
                          type="file"
                          onChange={this.handleSelectFile}
                          style={{ display: 'none' }}
                        />
                        <label htmlFor="add-upload-file">
                          <span>Upload Photo</span>
                        </label>
                      </UploadButton>
                    )}
                  </ImageValue>
                </AutoHeightRow>
                <ButtonWrapper>
                  <Button onClick={this.handleAddUpdateLoanOfficer} disabled={buttonDisabled}>
                    Save
                  </Button>
                </ButtonWrapper>
              </>
            ) : (
              <AutoHeightRow>
                <Value>
                  <BioWrapper>
                    <label>Summary:</label>
                    <MultipleLineInput readOnly disabled value={data.PersonalBio || ''} />
                    <label>Why You Should Work With Me:</label>
                    <MultipleLineInput
                      readOnly
                      disabled
                      value={data.PersonalWhyYouShouldWorkWithMe || ''}
                    />
                    <div>
                      My Bio Status: {data.EnablePersonalBioFlag ? 'Enabled' : 'Disabled'}
                      <span className="glyphicon glyphicon-info-sign" id="tooltip-status" />
                      <Tooltip placement="left" target="tooltip-status" delay={0}>
                        Status is managed by system Admins
                      </Tooltip>
                    </div>
                    {!!data.BioApprovalRequiredFlag && (
                      <BioApprovalText>Bio text submitted & awaiting approval</BioApprovalText>
                    )}
                    <div>
                      <Button onClick={this.handleShowEditBioModal}>Edit</Button>
                      <Button onClick={() => this.handleShowPreview(this.state.data)}>
                        Preview
                      </Button>
                    </div>
                  </BioWrapper>
                </Value>
              </AutoHeightRow>
            )}
          </BodyWrapper>
        </SectionContentWrapper>
      </Wrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default compose(
  connect(mapStateToProps),
  withModals({
    LoanOfficerPreviewModal,
    EditLoanOfficerBioModal,
  }),
)(MyProfile);

const Wrapper = styled(Container)`
  max-width: 900px;
  padding: 50px 0;
`;

const BodyWrapper = styled.div`
  padding: 1rem 3rem;
`;

const Row = styled.div`
  display: flex;
  width: fit-content;
  margin: auto;
  margin-bottom: 7px;
  font-size: 1.4rem;
  width: 100%;
  min-width: 250px;
  align-items: flex-start;
`;

const Label = styled.div`
  width: 30%;
  text-align: right;
  padding-right: 20px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  margin-top: 10px;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
`;

const StatePickerDropdown = styled.div`
  position: absolute;
  top: 39px;
  font-size: 15px;
  height: 200px;
  left: 0;
  right: 0;
  background: white;
  overflow-y: auto;
  border: #c4cdd5 1px solid;
  z-index: 1;

  & > div {
    display: flex;
    padding: 6px 16px;
    align-items: center;

    & > span {
      margin-left: 30px;
    }
    &:hover {
      background-color: #fafafa;
    }
  }
`;

const AddButton = styled.button`
  position: absolute;
  right: -24px;
  font-size: 28px;
  color: red;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 3.5rem;
  flex-direction: column;
  align-items: center;
`;

const AutoHeightRow = styled.div`
  display: flex;
  margin: auto;
  margin-bottom: 7px;
  font-size: 1.4rem;
  width: 100%;
  min-width: 250px;
  align-items: flex-start;
`;

const ImageValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

const UploadButton = styled.div`
  width: 20ren;
  text-align: center;

  span {
    background: ${props => props.theme.palette.gray};
    color: white;
    padding: 5px 10px;
    border-radius: 6px;
    cursor: pointer;
  }
`;

const ProfileImageWrapper = styled.div`
  position: relative;
`;

const ProfileImage = styled(Image)`
  width: 200px;
  margin-bottom: 20px;
`;

const Loading = styled.div`
  position: absolute;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid ${props => props.theme.palette.primary};
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  top: calc(50% - 15px);
  left: calc(50% - 15px);

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const MultipleLineInput = styled(TextArea)`
  height: 90px;
`;

const Input = styled(InputField)`
  background: white;
`;

const BioWrapper = styled.div`
  width: 100%;
  padding: 0.5rem;
  border-radius: 3px;
  text-align: left;

  & > label {
    font-weight: 400;
    margin-bottom: 0.2rem;
  }

  & > div {
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;

    & > span {
      margin-left: 0.2rem;
    }

    & > button {
      min-width: 10rem;
      height: 3rem;
      font-size: 1.3rem;
      margin-left: 0.5rem;
    }
  }
`;

const BioApprovalText = styled.div`
  color: red;
  font-style: italic;
  font-size: 1.3rem;
`;
