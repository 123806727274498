import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames'
import {Field, ErrorMessage} from 'formik';
import Dropdown from '../Dropdown';

const SelectField = ({ name, styles, options, label, showError = false, className, ...props }) => (
  <Root className={classnames('field-row', 'select-field', className)}>
    {label && <div className="field-label">{label}</div>}
    <div className="value-container">
      <Field name={name}>
        {({ field, form: { setFieldValue } }) => (
          <Dropdown
            styles={styles}
            options={options}
            value={field.value}
            onChange={(value) => {
              setFieldValue(name, value);
            }}
            {...props}
          />
        )}
      </Field>
      {showError && (
        <ErrorMessage
          component="div"
          style={{color: 'red', marginBottom: 0}}
          name={name}
        />
      )}
    </div>
  </Root>
);

export default SelectField;

const Root = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.darkText};

  .field-label {
    font-size: 1.4rem;
    text-align: left;
    width: 150px;
  }

  .value-container {
    flex: 1;
  }
`;

