export default class Environment {
  getENV() {
    let VIDEO_URL = '';
    let API_BASE_URL = '';
    let data = {};
    const nonce = Date.now();
    let REDIRECT_URL = '';
    let IMPLICIT_URL = '';

    const env = 'development';

    if (env === 'development') {
      API_BASE_URL = 'https://dev-kong.command-api.kw.com/kmon/';
      VIDEO_URL = 'https://s3.amazonaws.com/onboarding-dev.kellermortgage.com/videos/';
      REDIRECT_URL =
        `https://portal-dev.kellermortgage.com/user/has-authorized&response_type=token id_token&scope=openid profile email&nonce=${
          nonce}`;
      IMPLICIT_URL =
        'https://access.qa.kw.com/sso/oauth2/realms/root/realms/kwfr/authorize?client_id=cloud-dev&redirect_uri=';
    }

    if (env === 'staging') {
      API_BASE_URL = 'https://dev-kong.command-api.kw.com/kmon/';
      VIDEO_URL = 'https://s3.amazonaws.com/onboarding-dev.kellermortgage.com/videos/';
      REDIRECT_URL =
        `https://portal-dev.kellermortgage.com/user/has-authorized&response_type=token id_token&scope=openid profile email&nonce=${
          nonce}`;
      IMPLICIT_URL =
        'https://access.qa.kw.com/sso/oauth2/realms/root/realms/kwfr/authorize?client_id=cloud-dev&redirect_uri=';
    }

    if (env === 'production') {
      API_BASE_URL = 'https://kong.command-api.kw.com/kmon/';
      VIDEO_URL = 'https://s3.amazonaws.com/onboarding-dev.kellermortgage.com/videos/';
      REDIRECT_URL =
        `https://portal.kellermortgage.com/user/has-authorized&response_type=token id_token&scope=openid profile email&nonce=${
          nonce}`;
      IMPLICIT_URL =
        'https://access.kw.com/sso/oauth2/realms/root/realms/kwfr/authorize?client_id=cloud-prod&redirect_uri=';
    }

    data = {
      SEARCH_AGENT: 'https://roles.kwiq.kw.com/api/v1.1/people',
      SEARCH_MC: 'https://roles.kwiq.kw.com/api/v1.1/orgs',
      SEARCH_AGENT_API_KEY: '9acd27ea384dbed8b7b1d2b7e8ba007db1ba8481',
      QUESTION_URL: `${API_BASE_URL }agents/questions`,
      GET_AGENT: `${API_BASE_URL }agents`,
      STATUS: `${API_BASE_URL }agents/grant-access`,
      MC_LIST: `${API_BASE_URL }market_centers`,
      GET_AGENTS_LIST: `${API_BASE_URL }admin/agents/list`,
      GET_AGENTS_CITY: `${API_BASE_URL }admin/agents/agent_city`,
      LOGIN_URL: `${API_BASE_URL }login`,
      STATES: `${API_BASE_URL }states`,
      ADMIN_MC_LIST: `${API_BASE_URL }admin/market_centers`,
      ADD_UPDATE_AGENT: `${API_BASE_URL }admin/agents`,
      EXPORT_CSV: `${API_BASE_URL }admin/export_agents`,
      EXPORT_CSV_MC: `${API_BASE_URL }admin/mcs-export-list`,
      TEAMS: `${API_BASE_URL }admin/teams`,
      AGENT_LOGIN: `${API_BASE_URL }agent_login`,
      TERMS_CONDITION: `${API_BASE_URL }agents/update-terms`,
      ADMIN_LOGOUT: `${API_BASE_URL }admin/logout`,
      DELETE_AGENTS: `${API_BASE_URL }admin/agents/multi-delete`,
      UPDATE_STATUS: `${API_BASE_URL }admin/agents/multi-status`,
      AGENTS: `${API_BASE_URL }admin/agents`,
      ASSIGN_TEAM: `${API_BASE_URL }admin/agents/team`,
      ASSIGN_MC: `${API_BASE_URL }admin/agents/mc`,
      DELETE_MC: `${API_BASE_URL }admin/market_centers/destroy`,
      UPDATE_MC: `${API_BASE_URL }admin/market_centers/update`,
      MC_MULTI_DELETE: `${API_BASE_URL }admin/market_centers/multi_delete`,
      MC_MULTI_UPDATE: `${API_BASE_URL }admin/market_centers/multi_status`,
      TEAM_MULTI_UPDATE: `${API_BASE_URL }admin/teams/multi-update`,
      TEAM_MULTI_DELETE: `${API_BASE_URL }admin/teams/multi-delete`,
      PRODUCTION: `${API_BASE_URL }admin/agents/productions`,
      UPDATE_AGENT_INFO: `${API_BASE_URL }agents/update-info`,
      TEAM_DETAILS: `${API_BASE_URL }admin/teams`,
      MC_DETAILS: `${API_BASE_URL }admin/market_centers`,
      REFRESH_MC: `${API_BASE_URL }admin/market_centers/mc_refresh`,
      REFRESH_TEAM: `${API_BASE_URL }admin/teams/team_refresh`,
      SETTINGS: `${API_BASE_URL }admin/settings/add`,
      GET_SETTINGS: `${API_BASE_URL }admin/settings/list`,
      SETTINGS_RESET_THROTTEL: `${API_BASE_URL }admin/settings/reset-throttel`,
      SIGNUP_COUNTS: `${API_BASE_URL }admin/agents/signup_count`,
      IMPLICIT_LOGIN: `${API_BASE_URL }has-authorized`,
      LOGOUT: `${API_BASE_URL }agent-logout`,
      VIDEO_URL,
      REDIRECT_URL,
      IMPLICIT_URL,
    };
    return data;
  }
}
