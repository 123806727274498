import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import HeaderTitle from 'components/HeaderTitle';
import { Segment } from 'components/Segment';
import Button from 'components/Button';
import axios from 'axios';
import Select from 'react-select';
import { showAlert } from 'actions/index';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  mobile_number: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  agentfirstname: Yup.string().required('Required'),
  agentlastname: Yup.string().required('Required'),
  KWUID: Yup.string().required('Required'),
  Leademail: Yup.string().email('Invalid email').required('Required'),
});

const UrlOptions = [
  {
    label: 'https://lodashboard-dev.kellermortgage.com/api/v1/pre-app-v3',
    value: 'https://lodashboard-dev.kellermortgage.com/api/v1/pre-app-v3',
  },
  {
    label: 'https://lodashboard-staging.kellermortgage.com/api/v1/pre-app-v3',
    value: 'https://lodashboard-staging.kellermortgage.com/api/v1/pre-app-v3',
  },
  {
    label: 'https://lodashboard.kellermortgage.com/api/v1/pre-app-v3',
    value: 'https://lodashboard.kellermortgage.com/api/v1/pre-app-v3',
  },
]

class LODashForm extends React.PureComponent {
  state = {
    url: 'https://lodashboard-dev.kellermortgage.com/api/v1/pre-app-v3',
  };

  onChange = key => e => {
    const actualValue = e.value
    this.setState({ [key]: actualValue });
  };

  render() {
    const { url } = this.state
    const { dispatch } = this.props;

    return (
      <Fragment>
        <HeaderTitle primary>LO Dash Form (Testing)</HeaderTitle>
        <StyledContainer>
          <StyledSelect
            styles={CustomDropdownStyles}
            options={UrlOptions}
            onChange={this.onChange('url')}
            value={UrlOptions.filter(o => o.value === url)}
          />
          <Formik
            initialValues={{
              AgentPhone: '',
              DPPercentDisplayed: 20,
              DownPaymentDisplayed: 0,
              EstCurVal: '9999',
              FirstTimeBuyer: 'false',
              KWUID: '',
              Leademail: '',
              Lien1: null,
              Lien2: null,
              Lien3: null,
              MCID: null,
              MCname: 'Elk River',
              PropAddress: '12300 Bermuda Rd, Henderson, NV 89044, USA',
              PropCity: 'Henderson',
              PropState: 'NV',
              PropUse: 'PrimaryResidence',
              PropZip: '89044',
              RateDisplayed: 0,
              SalesPriceDisplayed: 0,
              SessionID: 263896,
              TermDisplayed: 15,
              Timeframe: 90,
              TransType: 'Purchase',
              agentfirstname: '',
              agentlastname: '',
              campaign_id: 45,
              creditscorerange: 780,
              email: '',
              first_name: '',
              is_buyer_agent: false,
              is_seller_agent: false,
              is_terms_agreed: true,
              last_name: '',
              loan_manager_id: null,
              loan_mgr_from_preapp: 1,
              mobile_number: '',
              propCounty: 'Clark County',
              propType: 'Single Family',
              state: 'NV',
              team_email: '',
              team_id: '',
              team_name: '',
              team_phone: '',
              utm_campaign: '',
              utm_content: '',
              utm_medium: 'NEW2',
              utm_source: '',
              utm_term: 'https://kellermortgage.com/',
              PartnerID: 102103,
            }}
            validationSchema={SignupSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              if (!values.state) {
                delete values.state
              }
              if (!values.PropState) {
                delete values.PropState
              }
              axios({
                method: 'post',
                url: this.state.url,
                data: values,
              })
                .then(res => {
                  if (res.data?.error) {
                    let errorMsg = 'Error';
                    const errorService = res.data?.error?.service
                    if (errorService) {
                      if (errorService === 'blend') {
                        errorMsg = `Blend : ${res.data?.error?.display}`
                      } else if (errorService === 'Roostify') {
                        errorMsg = `Roostify : ${res.data?.error?.errors[0]?.detail}`
                      }
                    } else if (res.data?.error?.error) {
                      errorMsg = res.data?.error?.error ;
                    }
                    dispatch(showAlert(errorMsg, { variant: 'danger', icon: 'bell' }));
                    return;
                  }
                  dispatch(showAlert('Succeed.', { variant: 'success', icon: 'bell' }));
                })
                .catch(error => {
                  let errorMsg = 'Failed'
                  if (error.response?.status === 412) {
                    errorMsg = error.response?.data?.errors?.state[0] || error.response?.data?.message
                  }
                  dispatch(showAlert(errorMsg, { variant: 'danger', icon: 'bell' }));
                }).finally(() => setSubmitting(false));
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Row>
                  <Label>KWUID</Label>
                  <Value>
                    <Field name="KWUID" placeholder="729732" />
                    <ErrorMessage name="KWUID" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>agentfirstname</Label>
                  <Value>
                    <Field name="agentfirstname" placeholder="John" />
                    <ErrorMessage name="agentfirstname" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>agentlastname</Label>
                  <Value>
                    <Field name="agentlastname" placeholder="Doe" />
                    <ErrorMessage name="agentlastname" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>Leademail</Label>
                  <Value>
                    <Field name="Leademail" placeholder="jvolkers@kw.com" />
                    <ErrorMessage name="Leademail" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>AgentPhone</Label>
                  <Value>
                    <Field name="AgentPhone" placeholder="320-223-3025" />
                    <ErrorMessage name="AgentPhone" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>is_buyer_agent</Label>
                  <Value>
                    <Field name="is_buyer_agent" as="select">
                      <option value={true}>TRUE</option>
                      <option value={false}>FALSE</option>
                    </Field>
                  </Value>
                </Row>
                <Row>
                  <Label>is_seller_agent</Label>
                  <Value>
                    <Field name="is_seller_agent" as="select">
                      <option value={true}>TRUE</option>
                      <option value={false}>FALSE</option>
                    </Field>
                  </Value>
                </Row>
                <Row>
                  <Label>is_terms_agreed</Label>
                  <Value>
                    <Field name="is_terms_agreed" as="select">
                      <option value={true}>TRUE</option>
                      <option value={false}>FALSE</option>
                    </Field>
                  </Value>
                </Row>
                <Row>
                  <Label>FirstTimeBuyer</Label>
                  <Value>
                    <Field name="FirstTimeBuyer" as="select">
                      <option value="true">TRUE</option>
                      <option value="false">FALSE</option>
                    </Field>
                  </Value>
                </Row>

                <Row>
                  <Label>MCname</Label>
                  <Value>
                    <Field name="MCname" />
                    <ErrorMessage name="MCname" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>MCID</Label>
                  <Value>
                    <Field name="MCID" />
                    <ErrorMessage name="MCID" component="div" />
                  </Value>
                </Row>

                <Row>
                  <Label>team_email</Label>
                  <Value>
                    <Field name="team_email" />
                    <ErrorMessage name="team_email" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>team_id</Label>
                  <Value>
                    <Field name="team_id" />
                    <ErrorMessage name="team_id" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>team_name</Label>
                  <Value>
                    <Field name="team_name" />
                    <ErrorMessage name="team_name" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>team_phone</Label>
                  <Value>
                    <Field name="team_phone" />
                    <ErrorMessage name="team_phone" component="div" />
                  </Value>
                </Row>


                <Row>
                  <Label>utm_campaign</Label>
                  <Value>
                    <Field name="utm_campaign" />
                    <ErrorMessage name="utm_campaign" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>utm_content</Label>
                  <Value>
                    <Field name="utm_content" />
                    <ErrorMessage name="utm_content" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>utm_medium</Label>
                  <Value>
                    <Field name="utm_medium" />
                    <ErrorMessage name="utm_medium" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>utm_source</Label>
                  <Value>
                    <Field name="utm_source" />
                    <ErrorMessage name="utm_source" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>utm_term</Label>
                  <Value>
                    <Field name="utm_term" />
                    <ErrorMessage name="utm_term" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>DPPercentDisplayed</Label>
                  <Value>
                    <Field name="DPPercentDisplayed" />
                    <ErrorMessage name="DPPercentDisplayed" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>DownPaymentDisplayed</Label>
                  <Value>
                    <Field name="DownPaymentDisplayed" />
                    <ErrorMessage name="DownPaymentDisplayed" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>creditscorerange</Label>
                  <Value>
                    <Field name="creditscorerange" placeholder="" />
                    <ErrorMessage name="creditscorerange" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>campaign_id</Label>
                  <Value>
                    <Field name="campaign_id" />
                    <ErrorMessage name="campaign_id" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>PartnerID</Label>
                  <Value>
                    <Field name="PartnerID" />
                    <ErrorMessage name="PartnerID" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>loan_manager_id</Label>
                  <Value>
                    <Field name="loan_manager_id" />
                    <ErrorMessage name="loan_manager_id" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>loan_mgr_from_preapp</Label>
                  <Value>
                    <Field name="loan_mgr_from_preapp" />
                    <ErrorMessage name="loan_mgr_from_preapp" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>SalesPriceDisplayed</Label>
                  <Value>
                    <Field name="SalesPriceDisplayed" />
                    <ErrorMessage name="SalesPriceDisplayed" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>RateDisplayed</Label>
                  <Value>
                    <Field name="RateDisplayed" />
                    <ErrorMessage name="RateDisplayed" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>TermDisplayed</Label>
                  <Value>
                    <Field name="TermDisplayed" />
                    <ErrorMessage name="TermDisplayed" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>Lien1</Label>
                  <Value>
                    <Field name="Lien1" />
                    <ErrorMessage name="Lien1" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>Lien2</Label>
                  <Value>
                    <Field name="Lien2" />
                    <ErrorMessage name="Lien2" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>Lien3</Label>
                  <Value>
                    <Field name="Lien3" />
                    <ErrorMessage name="Lien3" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>SessionID</Label>
                  <Value>
                    <Field name="SessionID" />
                    <ErrorMessage name="SessionID" component="div" />
                  </Value>
                </Row>

                <Row>
                  <Label>TransType</Label>
                  <Value>
                    <Field name="TransType" as="select">
                      <option value="Purchase">Purchase</option>
                      <option value="Refinance">Refinance</option>
                    </Field>
                  </Value>
                </Row>
                <Row>
                  <Label>propType</Label>
                  <Value>
                    <Field name="propType" as="select">
                      <option value="Single Family">single-family</option>
                      <option value="Two to Four Unit Property">multi-family</option>
                      <option value="Townhouse">townhome</option>
                      <option value="Condominium">condo</option>
                      <option value="other">other</option>
                    </Field>
                  </Value>
                </Row>
                <Row>
                  <Label>PropUse</Label>
                  <Value>
                    <Field name="PropUse" as="select">
                      <option value="PrimaryResidence">Primary Residence</option>
                      <option value="SecondHome">Secondary Residence</option>
                      <option value="Secondary / Vacation">Seasonal Residence</option>
                      <option value="Investment">Investment Property</option>
                    </Field>
                  </Value>
                </Row>
                <Row>
                  <Label>Timeframe</Label>
                  <Value>
                    <Field name="Timeframe" as="select">
                      <option value={90}>90</option>
                      <option value={120}>120</option>
                      <option value={365}>365</option>
                      <option value={999}>999</option>
                    </Field>
                  </Value>
                </Row>
                <Row>
                  <Label>EstCurVal</Label>
                  <Value>
                    <Field name="EstCurVal" placeholder="9999" />
                    <ErrorMessage name="EstCurVal" component="div" />
                  </Value>
                </Row>

                <Row>
                  <Label>PropAddress</Label>
                  <Value>
                    <Field name="PropAddress" placeholder="12300 Bermuda Rd, Henderson, NV 89044, USA" />
                    <ErrorMessage name="PropAddress" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>PropCity</Label>
                  <Value>
                    <Field name="PropCity" placeholder="Henderson" />
                    <ErrorMessage name="PropCity" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>PropState</Label>
                  <Value>
                    <Field name="PropState" placeholder="NV" />
                    <ErrorMessage name="PropState" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>PropZip</Label>
                  <Value>
                    <Field name="PropZip" placeholder="89044" />
                    <ErrorMessage name="PropZip" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>propCounty</Label>
                  <Value>
                    <Field name="propCounty" placeholder="Clark County" />
                    <ErrorMessage name="propCounty" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>state</Label>
                  <Value>
                    <Field name="state" placeholder="NV" />
                    <ErrorMessage name="state" component="div" />
                  </Value>
                </Row>

                <Row>
                  <Label>first_name</Label>
                  <Value>
                    <Field name="first_name" placeholder="Kevin" />
                    <ErrorMessage name="first_name" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>last_name</Label>
                  <Value>
                    <Field name="last_name" placeholder="Edmondson" />
                    <ErrorMessage name="last_name" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>email</Label>
                  <Value>
                    <Field name="email" placeholder="test@mail.com" />
                    <ErrorMessage name="email" component="div" />
                  </Value>
                </Row>
                <Row>
                  <Label>mobile_number</Label>
                  <Value>
                    <Field name="mobile_number" placeholder="(555) 555-5555" />
                    <ErrorMessage name="mobile_number" component="div" />
                  </Value>
                </Row>
                <StyledButton type="submit" disabled={isSubmitting}>Submit</StyledButton>
              </Form>
            )}
          </Formik>

        </StyledContainer>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(LODashForm);

const StyledContainer = styled(Segment)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.palette.primary};
  padding: 3.5rem;

  form {
    max-width: 600px;
    width: 100%;
    margin-top: 50px;
  }

  select {
    width: 100%;
    height: 30px;
    color: black;
  }
`;

const Row = styled.div`
  display: flex;
  width: fit-content;
  margin: auto;
  margin-top: 7px;
  font-size: 1.4rem;
  width: 100%;
  min-width: 250px;
  align-items: flex-start;
`;

const Label = styled.div`
  width: 30%;
  text-align: right;
  padding-right: 20px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  height: 30px;
`;

const Value = styled.div`
  flex: 1;
  position: relative;

  & > input {
    width: 100%;
    height: 30px;
    color: black;
  }

  & > div {
    color: red;
    text-align: left;
    font-size: 12px;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 50px;
`;

const StyledSelect = styled(Select)`
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  box-shadow: none;
  border-radius: 3px;
  max-width: 600px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 4rem;
    min-width: 100%;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: () => ({
    padding: '0px 8px'
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    '& > div': {
      padding: '0 0px',
    }
  }),
}
