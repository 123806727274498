import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import { getRecruitingDashboardStatsMessageCount, getRecruitingDashboardStatsRecruiterMessageCount } from 'modules/api';
import { Table } from 'reactstrap';
import { formatDate } from 'modules/helpers';

export default class RecruiterMessagesCountModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    counts: {},
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { startDate, endDate, period, recruiterID } = this.props
    if (recruiterID) {
      getRecruitingDashboardStatsRecruiterMessageCount(recruiterID, startDate, endDate, period).then(res => {
        this.setState({ 
          counts: res.result[0],
        });
      })
    } else {
      getRecruitingDashboardStatsMessageCount(startDate, endDate, period).then(res => {
        this.setState({ 
          counts: res.result[0],
        });
      })
    }

  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, startDate, endDate, isSent } = this.props;
    const { counts } = this.state;
    const {
      EmailsReceived,
      EmailsSent,
      SMSReceived,
      SMSSent
    } = counts || {}

    const messageDirection = isSent ? 'Sent' : 'Received'
    const emailsCount = isEmpty(counts) ? '--' : (isSent ? EmailsSent : EmailsReceived)
    const smsCounts = isEmpty(counts) ? '--' : (isSent ? SMSSent : SMSReceived)
    const totalCount = isEmpty(counts) ? '--' : (emailsCount + smsCounts)

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>
          Recruiter Messages
        </ModalHeader>
        <ModalBody>
          <DateRangeView>{`${formatDate(startDate)} ~ ${formatDate(endDate)}`}</DateRangeView>
          <StyledTable>
            <tbody>
              <tr>
                <td>Messages {messageDirection}:</td>
                <td>{totalCount}</td>
              </tr>
              <tr>
                <td>Emails {messageDirection}:</td>
                <td>{emailsCount}</td>
              </tr>
              <tr>
                <td>SMS {messageDirection}:</td>
                <td>{smsCounts}</td>
              </tr>
            </tbody>
          </StyledTable>
        </ModalBody>
      </Modal>
    );
  }
}


const Modal = styled(DigeryModal)`
  max-width: 400px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    height: 260px;
    overflow: auto;
  }
  .modal-body {
    overflow: auto;
  }
`;

const StyledTable = styled(Table)`
  &&& {
    font-size: 1.4rem;

    tr {
      border: none;

      td {
        text-align: left;
        height: 30px;
        vertical-align: middle;
        border: none;
        white-space: pre-wrap;
        word-break: break-all;
        padding: 2px 5px;
        width: 40%;
      }

      & td:nth-of-type(1) {
        text-align: right;
        width: 60%;
      }
    }

    tr:nth-of-type(1) {
      font-weight: bold;
    }
  }
`;

const DateRangeView = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 15px;
`;