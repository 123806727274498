import React, { Fragment } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import HeaderTitle from 'components/HeaderTitle';
import { Segment } from 'components/Segment';
import ButtonLink from 'components/ButtonLink';

const StyledContainer = styled(Segment)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.palette.primary};
  padding: 3.5rem;

  img {
    width: 9.6rem;
  }

  div {
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 3.6rem;
  }

  span {
    margin-bottom: 3rem;
    font-size: 1.6rem;
  }
`;

const VersionInfo = () => (
  <Fragment key="404">
    <Helmet>
      <title>Version Info</title>
    </Helmet>
    <HeaderTitle primary>Current Version: 5.1.1</HeaderTitle>
    <StyledContainer>
      <ButtonLink to="/private/home">Home</ButtonLink>
    </StyledContainer>
  </Fragment>
);

export default VersionInfo;
