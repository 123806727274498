import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody } from 'reactstrap'; 
import SearchInput from 'components/SearchInput';
import DigeryModal from 'components/Modal';
import Table, { ProgressRow } from 'components/Table';

const Modal = styled(DigeryModal)`
  max-width: 70rem;

  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 3rem;
      color: ${props => props.theme.palette.primary};
    }
  }
  .modal-body {
    text-align: left;
    margin: 10px 0 15px;
  }
`;

const SaveButton = styled.div`
  background: ${props => props.disabled ? props.theme.palette.gray : props.theme.palette.primary};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  font-weight: bold;
  font-size: 1.4rem;
  color: white;
  width: 60px;
  border-radius: 0.8rem
`;

const Label = styled.div`
  color: ${props => props.theme.palette.gray};
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2;
  text-transform: uppercase;
  min-width: 10rem;
  text-align: left;
`;


const TableWrapper = styled.div`
  width: 100%;
  max-height: 400px;
  overflow: scroll;
  margin-top: 1rem;
`;

const CenteredTD= styled.td`
  text-align: center;
`
const CenterTH = styled.th`
  text-align: center;
`;

const StatusIcon = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${props => props.active ? '#69DFBA' : '#F67178'};
  margin: auto;
`;

class ChangeLoanOfficerModal extends Component {
  static propTypes = {
    state: PropTypes.object,
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    filterKey: '',
  };

  handleSelectLoanOfficer = (loanOfficer) => () => {
    this.props.onSelect(loanOfficer);
    this.props.onClose()
  }

  onChange = (key) => (e) => {
    this.setState({ [key]: e.target.value })
  }

  render() {
    const { showModal, allLoanOfficers, onClose } = this.props;
    const { filterKey } = this.state;

    const showingNotSavedLoanOfficers = allLoanOfficers.filter(lo => 
      lo.Name && lo.Name.toLowerCase().includes(filterKey.toLowerCase())
    )

    return (
      <Modal centered fade={false} isOpen={showModal} toggle={onClose}>
        <ModalHeader toggle={onClose} />
        <ModalBody>
          <Label>Select a loan officer:</Label>
          <Fragment>
            <SearchInput
              placeholder="Search by Name"
              value={filterKey}
              onChange={this.onChange('filterKey')}
            />
            <TableWrapper>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <CenterTH>Status</CenterTH>
                    <CenterTH />
                  </tr>
                </thead>
                <tbody>
                  {
                    showingNotSavedLoanOfficers.map((o, index) => {
                      const { LOID, Name, Selectability } = o
                      return (
                        <ProgressRow key={LOID} odd={index % 2} progress={0}>
                          <td>{Name || ''}</td>
                          <CenteredTD><StatusIcon active={Selectability} /></CenteredTD>
                          <CenteredTD>
                            <SaveButton disabled={!Selectability} onClick={Selectability && this.handleSelectLoanOfficer(o)}>Select</SaveButton>
                          </CenteredTD>
                        </ProgressRow>
                      )
                    })
                  }
                </tbody>
              </Table>
            </TableWrapper>
          </Fragment>
        </ModalBody>
      </Modal>
    )
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(ChangeLoanOfficerModal);

