import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import {
  getRecruitingAssignedAgentsLO,
  sendEmail,
  addRecruitingInviteMyLOLog,
  addActivityEvents,
} from 'modules/api';
import { compose } from 'redux';
import { orderBy }from 'lodash';
import SectionContentWrapper from 'components/TabWrapper';
import { STATES } from 'constants/index';
import 'react-virtualized/styles.css';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { showAlert } from 'actions';
import withModals from 'modules/withModals';
import { formatUTCDate } from 'modules/helpers';
import DownloadCSV from 'components/DownloadCSV';
import ActivityModal from './ActivityModal';

const TAB = {
  RECRUITED: 'RECRUITED',
}

const HorizontalTabOptions = [
  { display: 'Recruited', value: TAB.RECRUITED },
];

const StateOptions = STATES.map(state => ({
  label: state,
  value: state,
}));

class LODashboard extends Component {
  state = {
    activeTab: TAB.RECRUITED,
    selectedState: null,
    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    agents: [],
    sortCurrentColumn: 'StateCode',
    sortCurrentDirection: 'ASC',
  }

  componentDidMount() {
    this.fetchData();
  }

   _setTableRef = ref => {
     this._table = ref;
   };

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.fetchData()
    });
  }

  fetchData = async () => {
    const { activeTab, startDate, endDate, selectedState } = this.state;
    const selectedStateCode = (selectedState || {}).value;
    let agents;

    try {
      if (activeTab === TAB.RECRUITED) {
        const apiRes = await getRecruitingAssignedAgentsLO(this.props.user.LOID, selectedStateCode, startDate, endDate, 4);
        agents = apiRes.result;
      }

      this.setState({
        agents,
      }, () => {
        this.handleSort();
      })
    } catch (error) {}
  }

  changeHorizontalTab = activeTab => {
    this.setState({
      activeTab,
      agents: [],
    }, () => {
      this.fetchData();
    });
  };

  handleShowActivityModal = (selectedAgent) => () => {
    this.props.openModal('ActivityModal', {
      assignmentPK: selectedAgent.AssignmentPKRecruiter,
    })
  }

  _sort = ({ sortBy, sortDirection }) => {
    this.setState({
      sortCurrentColumn: sortBy,
      sortCurrentDirection: sortDirection,
    }, () => this.handleSort())
  }

  handleSort = () => {
    const { sortCurrentColumn, agents, sortCurrentDirection } = this.state;

    let sorted = orderBy(agents, [sortCurrentColumn], [sortCurrentDirection.toLocaleLowerCase()]);
    if (sortCurrentDirection === 'DESC') {
      const nullItems = sorted.filter(i => i[sortCurrentColumn] === null)
      const unNullItems = sorted.filter(i => i[sortCurrentColumn] !== null)
      sorted = [...unNullItems, ...nullItems]
    }

    this.setState({
      agents: sorted,
    })
  };

  handleSend = async (agent) => {
    const { dispatch } = this.props;
    const {
      Email,
      LOEmail,
      MessageSubject,
      MessageTemplateEmail,
      LOFirstName,
      LOLastName,
      LOPhone,
      LoanOfficerNMLS,
      KWUID,
      ID,
      CompanyNMLS,
    } = agent;

    const message = `
      ${MessageTemplateEmail}
      <br/>
      <div>${LOFirstName} ${LOLastName}</div>
      <div>Loan Officer</div>
      <div>${LOPhone}</div>
      <div>${LOEmail}</div>
      <div>NMLS# ${LoanOfficerNMLS}</div>
      <div>NMLS# ${CompanyNMLS} company</div>
    `
    const params = {
      email: LOEmail,
      subject: MessageSubject,
      message,
      name: `${LOFirstName} ${LOLastName}`,
      service_email: Email,
      bccEmail: LOEmail,
    }
    await sendEmail(params);
    await addRecruitingInviteMyLOLog(KWUID, ID);
    await this.fetchData();
    dispatch(showAlert('Email sent successfully.', { variant: 'success', icon: 'bell' }));

    const { IPAddress, DeviceType, BrowserOS } = this.props.deviceInfo;
    addActivityEvents(78, KWUID, IPAddress, DeviceType, BrowserOS);
  };

  noRowsRenderer() {
    return <div />;
  }

  getTableHeight = () => {
    const elem = document.getElementById('table-wrapper');
    let calcHeight = 600

    if (elem) {
      const rect = elem.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      calcHeight = screenHeight - rect.top - 80
    }

    if (calcHeight < 480) {
      calcHeight = 480
    }
    return calcHeight;
  }

  getRowRenderer = props => {
    const { _key, className, columns, index, style, rowData } = props;
    const { ActivityOverdueFlag } = rowData;

    const isOdd = index % 2;

    return (
      <TableRow
        key={_key}
        style={{
          ...style,
          backgroundColor: isOdd ? 'white' : '#eeeeee',
          paddingTop: 7,
        }}
        red={ActivityOverdueFlag}
      >
        <div className={className}>{columns}</div>
      </TableRow>
    );
  };

  rowGetter = ({ index }) => {
    return this.state.agents[index];
  };

  render() {
    const {
      activeTab,
      startDate,
      endDate,
      sortCurrentColumn,
      sortCurrentDirection,
      agents,
      selectedState,
    } = this.state;

    return (
      <Fragment>
        <DateRange>
          <Row>
            <RadioLabel>Start Date</RadioLabel>
            <input
              placeholder="Start Date"
              type="date"
              value={startDate}
              onChange={e => this.handleChange('startDate', e.target.value || null)}
            />
          </Row>
          <Row>
            <RadioLabel>End Date</RadioLabel>
            <input
              placeholder="End Date"
              type="date"
              value={endDate}
              onChange={e => this.handleChange('endDate', e.target.value || null)}
            />
          </Row>
        </DateRange>
        <DownloadWrapper>
          <DownloadCSV data={agents} filename="LODashboard_Recruited" />
        </DownloadWrapper>
        <SectionContentWrapper
          title=""
          hActiveTab={activeTab}
          horizonalTabOptions={HorizontalTabOptions}
          onHorizonalTabChange={this.changeHorizontalTab}
        >
          <Wrapper id="table-wrapper">
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  ref={this._setTableRef}
                  headerHeight={70}
                  noRowsRenderer={this.noRowsRenderer}
                  rowHeight={80}
                  rowGetter={this.rowGetter}
                  rowCount={agents.length}
                  height={this.getTableHeight()}
                  rowRenderer={this.getRowRenderer}
                  width={width}
                  sort={this._sort}
                  sortBy={sortCurrentColumn}
                  sortDirection={sortCurrentDirection}
                >
                  <Column
                    width={150}
                    label="Assigned By"
                    dataKey="AssignedBy"
                    disableSort
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={90}
                    label="KWUID"
                    dataKey="KWUID"
                    disableSort
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    label="Agent Name"
                    dataKey="Firstname"
                    width={200}
                    style={{ textAlign: 'left' }}
                    headerStyle={{ justifyContent: 'flex-start' }}
                    cellRenderer={({ rowData }) => {
                      const { Firstname, Lastname } = rowData;
                      return <LinkButton onClick={this.handleShowActivityModal(rowData)}>{`${Firstname} ${Lastname}`}</LinkButton>;
                    }}
                  />
                  <Column
                    width={220}
                    label="Email"
                    dataKey="Email"
                    disableSort
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={150}
                    label="Mobile Phone"
                    disableSort
                    dataKey="MobilePhoneNumber"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={120}
                    dataKey="StateCode"
                    cellRenderer={({ cellData }) => cellData}
                    label={(
                      <div>
                        <div>State</div>
                        <div onClick={e=> e.stopPropagation()}>
                          <StateSelect
                            styles={CustomDropdownStyles}
                            isSearchable
                            isClearable
                            placeholder="All"
                            options={StateOptions}
                            onChange={(state) => this.handleChange('selectedState', state)}
                            value={selectedState}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Column
                    label="Market Center"
                    dataKey="MarketCenterID"
                    width={200}
                    cellRenderer={({ rowData }) => {
                      const { MarketCenterID, MarketCenterName, MarketCenterTeamLeader } = rowData;
                      return `${MarketCenterName} (${MarketCenterID}) - ${MarketCenterTeamLeader}`;
                    }}
                  />
                  <Column
                    width={170}
                    label="Agent Created Date"
                    dataKey="CreatedDate"
                    cellRenderer={({ cellData }) => cellData ? formatUTCDate(cellData) : ''}
                  />
                  <Column
                    width={170}
                    label="Assigned Date"
                    dataKey="AssignmentDate"
                    cellRenderer={({ cellData }) => cellData ? formatUTCDate(cellData) : ''}
                  />
                  <Column
                    label="My LO"
                    dataKey="MyLOLOName"
                    width={200}
                    cellRenderer={({ rowData }) => {
                      const { MyLOLOName, MyLOStatus } = rowData;
                      if (MyLOLOName) {
                        return MyLOLOName;
                      } else {
                        if (MyLOStatus) {
                          return <ActionButton onClick={() => this.handleSend(rowData)}>Invite again</ActionButton>
                        } else {
                          return <ActionButton green onClick={() => this.handleSend(rowData)}>Invite </ActionButton>
                        }
                      }
                    }}
                  />
                </Table>
              )}
            </AutoSizer>
          </Wrapper>
        </SectionContentWrapper>
      </Fragment>
    )
  };
}

function mapStateToProps(state) {
  return {
    deviceInfo: state.app.deviceInfo,
  };
}

export default compose(
  connect(mapStateToProps),
  withModals({
    ActivityModal,
  })
)(LODashboard)


const ActionButton = styled.button`
  background: ${({ theme, green }) => green ? theme.palette.primary : '#d5b60a' };
  padding: 10px 10px;
  color: white;
  border-radius: 3px;
  white-space: nowrap;
  font-weight: bold; 
`;


const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
`;

const DownloadWrapper = styled.div`
  height: 0;
  position: relative;

  & > a {
    transform: translateY(0%);
    right: 0;
  }
`;

const DateRange = styled.div`
  margin-right: 3.0rem;

  span {
    width: 8.1rem;
    margin-right: 1.0rem;
  }
`;

const RadioLabel = styled.span`
  margin-left: .4rem;
  margin-right: 2.0rem;
  font-size: 1.5rem;
`;


const TableRow = styled.div`
  ${({ red }) => red && css`
    color: red;

    button {
      color: red;
    }
  `}
`;

const StateSelect = styled(Select)`
  text-align: left;
  width: 100%;
  font-size: 1.2rem;
  box-shadow: none;
  border-radius: 3px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 28px;
    min-width: 70px;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: () => ({
    padding: '0px 8px'
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    '& > div': {
      padding: '0 0px',
      background: 'white',
      zIndex: 10,
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 200
  }),
}

const Wrapper = styled.div`
  font-size: 1.3rem;

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Table__headerRow {
    overflow: visible !important;
  }

  .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: none;
    }

    .ReactVirtualized__Table__headerTruncatedText {
      white-space: pre-wrap;
      text-align: center;
      overflow: visible !important;
    }

    .ReactVirtualized__Table__sortableHeaderIcon {
      font-size: 22px;
    }
  }

  .ReactVirtualized__Table__rowColumn {
    text-align: center;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .ReactVirtualized__Table__row {
    height: 100%;
  }
`;

const LinkButton = styled.button`
  text-decoration: underline;
  color: blue;
  text-align: left;
`;


