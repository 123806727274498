import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import ButtonLink from 'components/ButtonLink';
import Container from 'components/Container';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3.5rem;
`;

const HeaderTitle = styled.div`
  font-size: 3.6rem;
  text-align: center;
  margin: 60px 0 100px;
`;

const ComingSoon = () => (
  <StyledContainer>
    <Helmet>
      <title>Coming Soon</title>
    </Helmet>
    <HeaderTitle primary>Coming Soon</HeaderTitle>
    <ButtonLink to="/private/home">Home</ButtonLink>
  </StyledContainer>
);

export default ComingSoon;
