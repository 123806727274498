import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Flex } from 'styled-minimal';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import { login, showAlert } from 'actions/index';
import ZeroPlusLogo from 'assets/media/brand/zeroplus_logo.png';
import { setToken } from 'modules/localstorage';
import { forgotPassword } from 'modules/api';
import HeaderTitle from '../components/HeaderTitle';
import Input from '../components/Input';
import Button from '../components/Button';

const Logo = styled.img`
  margin-bottom: 6rem;
  margin-top: 3.5rem;
`;

const SubTitle = styled.div`
  font-size: 19px;
  color: #5b5b5b;
`;

const StyledInput = styled(Input)`
  width: 400px;
  margin: 7px;
`;

const Error = styled.div`
  width: 400px;
  color: red;
  font-size: 13px;
`;

const BoxButton = styled(Button)`
  margin: 2.5rem 0 0.5rem;
`;

const TextButton = styled(Link)`
  color: ${props => props.theme.palette.primary};
  font-size: 13px;
  margin-top: 12px;

  &:hover,
  &:focus {
    color: ${props => props.theme.palette.primary};
  }
`;

class ForgotPassword extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    email: '',
    errorMsg: '',
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { email } = this.state;
    const validEmail = this.validationEmail(email);
    if (!validEmail) {
      return;
    }

    forgotPassword(email)
      .then(res => {
        dispatch(
          showAlert('A message has been sent to your email.', { variant: 'success', icon: 'bell' }),
        );
      })
      .catch(error => {
        console.log(error);
        dispatch(showAlert(error.msg, { variant: 'danger', icon: 'bell' }));
      });
  };

  handleChange = name => e => {
    const email = e.target.value;
    this.setState({ [name]: email });
    this.validationEmail(email);
  };

  validationEmail = email => {
    if (!email) {
      this.setState({ errorMsg: 'Email address is required.' });
      return false;
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validEmail = re.test(String(email).toLowerCase());
    if (!validEmail) {
      this.setState({ errorMsg: 'Email address is badly formatted.' });
      return false;
    }
    this.setState({ errorMsg: '' });


    return true;
  };

  render() {
    const { email, errorMsg } = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        <Logo src={ZeroPlusLogo} />

        <SubTitle>
          Enter your email address to receive instructions about resetting your password.
        </SubTitle>
        <Flex alignItems="center" mt="30px" flexDirection="column">
          <StyledInput placeholder="Email" value={email} onChange={this.handleChange('email')} />
          {errorMsg && <Error>{errorMsg}</Error>}
        </Flex>
        <BoxButton onClick={this.handleSubmit}>Submit</BoxButton>
        <TextButton to="/signin">Back to sign in</TextButton>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(ForgotPassword);
