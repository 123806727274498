import { Table as BoostrapTable } from 'reactstrap';
import styled from 'styled-components';


export const ProgressRow = styled.tr`
  cursor: ${props => props.detailAccess ? 'pointer' : 'auto'};
  background: linear-gradient(to right, 
    ${props => props.odd 
      ? (props.color ==='Green' ? '#00D29E' : 'rgba(0,157,195,0.3)') 
      : (props.color ==='Green' ? '#00BB8C' : 'rgba(0,157,195,0.7)') },  
    ${props => props.progress}%, 
    ${props => props.odd ? 'white' : '#F0F2F2'} ${props => props.progress}%, 
    ${props => props.odd ? 'white' : '#F0F2F2'} 100% );
  background-repeat: no-repeat;
  background-attachment: fixed;
`;

const Table = styled(BoostrapTable)`
  &&& {
    font-size: 1.2rem;

    th {
      color: ${props => props.theme.palette.gray};
      position: relative;
  
    & td {
      color: ${props => props.theme.palette.gray};
      padding: 8px !important;
      border: none !important;
      vertical-align: middle !important;
    }
  
    .table-striped tbody tr:nth-of-type(odd) {
      background-color: red;
    }
  }

`;

export default Table;




