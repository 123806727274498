import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Flex } from 'styled-minimal';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

import { login, showAlert } from 'actions/index';
import ZeroPlusLogo from 'assets/media/brand/zeroplus_logo.png';
import { setToken } from 'modules/localstorage';
import { resetPassword, checkResetPasswordLink } from 'modules/api';
import HeaderTitle from '../components/HeaderTitle';
import Input from '../components/Input';
import Button from '../components/Button';

const Logo = styled.img`
  margin-bottom: 6rem;
  margin-top: 3.5rem;
`;

const SubTitle = styled.div`
  font-size: 19px;
  color: #5b5b5b;
`;

const StyledInput = styled(Input)`
  width: 400px;
  margin: 7px;
`;

const Error = styled.div`
  width: 400px;
  color: red;
  font-size: 13px;
`;

const BoxButton = styled(Button)`
  margin: 2.5rem 0 0.5rem;
`;

const TextButton = styled(Link)`
  color: ${props => props.theme.palette.primary};
  font-size: 13px;
  margin-top: 12px;

  &:hover,
  &:focus {
    color: ${props => props.theme.palette.primary};
  }
`;

class ResetPassword extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    Password: '',
    Password2: '',
  };

  componentDidMount() {
    const { id, email } = this.props.location.query;
    if (!id || !email || this.validationEmail(email) || isNaN(id)) {
      this.props.history.push('/signin');
    }

    checkResetPasswordLink(id, email)
      .then(res => {})
      .catch(error => {
        this.props.history.push('/signin');
      });
  }

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { Password, Password2 } = this.state;
    const { id, email } = this.props.location.query;

    if (!Password) {
      dispatch(showAlert('Password is required.', { variant: 'danger', icon: 'bell' }));
      return;
    }

    if (!Password2) {
      dispatch(showAlert('Confirm Password is required.', { variant: 'danger', icon: 'bell' }));
      return;
    }

    if (Password !== Password2) {
      dispatch(showAlert('Confirm Password does not match.', { variant: 'danger', icon: 'bell' }));
      return;
    }

    resetPassword(id, email, Password)
      .then(res => {
        this.props.history.push('/signin');
        dispatch(
          showAlert('Your password has been reset successfully.', {
            variant: 'success',
            icon: 'bell',
          }),
        );
      })
      .catch(error => {
        console.log(error);
        dispatch(showAlert(error.msg, { variant: 'danger', icon: 'bell' }));
      });
  };

  handleChange = name => e => {
    this.setState({ [name]: e.target.value });
  };

  validationEmail = email => {
    if (!email) {
      return 'Email address is required.';
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validEmail = re.test(String(email).toLowerCase());
    if (!validEmail) {
      return 'Email address is badly formatted.';
    }
    return null;
  };

  render() {
    const { Password, Password2, errorMsg } = this.state;
    const { email } = this.props.location.query;

    return (
      <Fragment>
        <Helmet>
          <title>Reset Password</title>
        </Helmet>
        <Logo src={ZeroPlusLogo} />
        <SubTitle>Reset your password.</SubTitle>

        <Flex alignItems="center" mt="30px" flexDirection="column">
          <StyledInput value={email} disabled />
          <StyledInput
            type="password"
            placeholder="Password"
            value={Password}
            onChange={this.handleChange('Password')}
          />
          <StyledInput
            type="password"
            placeholder="Confirm Password"
            value={Password2}
            onChange={this.handleChange('Password2')}
          />
        </Flex>
        <BoxButton onClick={this.handleSubmit}>Submit</BoxButton>
        <TextButton to="/signin">Sign in</TextButton>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(ResetPassword);
