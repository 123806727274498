import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import ShowMoreText from 'react-show-more-text';
import PropTypes from 'prop-types';
import { Flex } from 'styled-minimal';
import Icon from 'components/Icon';
import { ModalHeader, ModalBody } from 'reactstrap';
import { updateRecruitingCommunicationMessageRead, getRecruitingCommunication } from 'modules/api';
import DigeryModal from 'components/Modal';
import Table, { ProgressRow } from 'components/Table';
import { formatUTCDateTime } from 'modules/helpers';
import CheckBox from 'components/CheckBox';
import update from 'immutability-helper';
import { debounce, orderBy } from 'lodash';
import withModals from 'modules/withModals';
import SendMessageModal from './SendMessageModal';
class CommunicationHistoryModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    communications: [],
    isCommunicationFetching: true,
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.initSetup();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.initSetup();
    }
  }

  initSetup = () => {
    getRecruitingCommunication(this.props.ThreadID)
    .then(res => {
      this.setState({
        communications: orderBy(res, 'CreatedDate', 'desc'),
        isCommunicationFetching: false,
      });
    })
  };

  handleClose = () => {
    if (this.props.onModalClose) {
      this.props.onModalClose()
    }
    this.props.onClose();
  };

  handleChangeRead = PK => MessageReadFlag => {
    const index = this.state.communications.findIndex(c => c.PK === PK);
    const communications = update(this.state.communications, {
      [index]: { MessageRead: { $set: MessageReadFlag } },
    });
    this.setState({ communications });
    this.changeRead(PK, MessageReadFlag);
  };

  changeRead = debounce(async (PK, MessageReadFlag) => {
    await updateRecruitingCommunicationMessageRead(PK, MessageReadFlag);
    this.props.onUpdate()
  }, 300);

  handleOpenSendMessageModal = isEmail => () => {
    const firstCommunication = this.state.communications[0]
    this.props.openModal('SendMessageModal', {
      isEmail,
      user: this.props.user,
      dispatch: this.props.dispatch,
      onSend: () => {
        this.initSetup()
      },
      kwuid: firstCommunication ? firstCommunication.KWUID : null
    });
  };

  render() {
    const { isOpen } = this.props;
    const { communications } = this.state;

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>Communication History</ModalHeader>
        <ModalBody>
          <Flex justifyContent="center">
            <ComposeWrapper onClick={this.handleOpenSendMessageModal(true)}>
              <Icon name="compose-email" color="black" width={20} />
              Reply Email
            </ComposeWrapper>
            <ComposeWrapper onClick={this.handleOpenSendMessageModal(false)}>
              <Icon name="compose-sms" color="black" width={20} />
              Reply SMS
            </ComposeWrapper>
          </Flex>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Message Type</th>
                  <th>Person</th>
                  <th className="message">Message</th>
                  <th>Read</th>
                </tr>
              </thead>
              <tbody>
                {communications.map((row, index) => (
                  <ProgressRow key={row.PK} progress={0} odd={index % 2}>
                    <td>{formatUTCDateTime(row.CreatedDate)}</td>
                    <td>{row.CommunicationType}</td>
                    <td>{row.Person}</td>
                    <td className="message">
                      <ShowMoreText
                          lines={2}
                          more='Expand'
                          less='Collapse'
                          width={480}
                      >
                        {row.Message}
                      </ShowMoreText>  
                    </td>
                    <td>
                      {row.MessageReadDisplayCheckbox && (
                        <CheckBox
                          value={!!row.MessageRead}
                          onChange={this.handleChangeRead(row.PK)}
                        />
                      )}
                    </td>
                  </ProgressRow>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        </ModalBody>
      </Modal>
    );
  }
}

export default withModals({
  SendMessageModal,
})(CommunicationHistoryModal);

const Modal = styled(DigeryModal)`
  max-width: 900px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    overflow: auto;
    min-height: 347px;
  }

  .message {
    width: 500px;
  }
`;

const ComposeWrapper = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin-right: 25px;

  & > span {
    margin-right: 5px;
  }
`

const TableWrapper = styled.div`
  overflow: auto;
  max-height: 500px;
`;
