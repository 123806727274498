import styled from 'styled-components';
import { Input } from 'styled-minimal';

const SearchIcon = require('assets/media/images/search.png');

const SearchInput = styled(Input)`
  padding-left: 1rem;
  padding-right: 3rem;
  border: #c4cdd5 0.1rem solid;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  min-height: 4.2rem;
  &:focus {
    outline-width: 1px;
  }
  background: url(${SearchIcon}) no-repeat;
  background-position: right 0.5rem center;
  background-size: 2.5rem 2.4rem;
`;

export default SearchInput;
