import React, { Fragment, Component } from 'react';
import Pusher from 'pusher-js';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import update from 'immutability-helper';
import { sortBy } from 'lodash';
import 'react-virtualized/styles.css';
import { AutoSizer, Table, Column } from 'react-virtualized';

const Container = styled.div`
  padding: 2rem;
  margin: 2rem;
  background: white;
  flex: 1;
`;

const Wrapper = styled.div`
  font-size: 1.3rem;
  color: black;

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }
`;

class Responses extends Component {
  state = {
    responses: [],
  };

  _setTableRef = ref => {
    this._table = ref;
  };

  componentDidMount() {
    const pusher = new Pusher('fdeff8d5b13bfd8b0543', {
      cluster: 'mt1',
    });
    const channel = pusher.subscribe('portal');
    channel.bind('sms', data => {
      this.setState({ responses: [...this.state.responses, data] });
    });
  }

  rowGetter = ({ index }) => this.state.responses[index];

  noRowsRenderer() {
    return <div>No Data</div>;
  }

  getTableHeight = () => {
    const elem = document.getElementById('table-wrapper');
    let calcHeight = 600;

    if (elem) {
      const rect = elem.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      calcHeight = screenHeight - rect.top - 50;
    }
    return calcHeight;
  };

  getRowHeight = ({ index }) => 30;

  getRowRenderer = props => {
    const { _key, className, columns, index, style, rowData } = props;
    const isOdd = index % 2;

    return (
      <div
        key={_key}
        style={{
          ...style,
          backgroundColor: isOdd ? 'white' : '#eeeeee',
          paddingTop: 7,
          color: 'black',
        }}
      >
        <div className={className}>{columns}</div>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Response</title>
          <meta name="description" content="POC" />
        </Helmet>
        <Container>
          <Wrapper id="table-wrapper">
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  ref={this._setTableRef}
                  headerHeight={30}
                  noRowsRenderer={this.noRowsRenderer}
                  rowHeight={this.getRowHeight}
                  rowGetter={this.rowGetter}
                  rowCount={this.state.responses.length}
                  height={this.getTableHeight()}
                  rowRenderer={this.getRowRenderer}
                  width={width}
                >
                  <Column
                    width={100}
                    label="Type"
                    dataKey="type"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={150}
                    label="From"
                    dataKey="from"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={500}
                    label="Message"
                    dataKey="message"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={100}
                    label="Status"
                    dataKey="status"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={200}
                    label="DateTime"
                    dataKey="datetime"
                    cellRenderer={({ cellData }) => cellData}
                  />
                </Table>
              )}
            </AutoSizer>
          </Wrapper>
        </Container>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(Responses);
