import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';
import CheckBox from 'components/CheckBox';
import update from 'immutability-helper';
import { addUpdatePortalAdminLoanOfficerBio, getPortalAdminLoanOfficer } from 'modules/api';
import TextArea from 'components/TextArea';
import { showAlert } from 'actions/index';

class EditLoanOfficerBioModal extends Component {
  static propTypes = {
    isNotSubmittedSavedBio: PropTypes.bool,
    loanOfficer: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    loanOfficer: this.props.loanOfficer,
    isNotSubmittedSavedBio: this.props.isNotSubmittedSavedBio
  };

  fetchLoanOfficerData = () => {
    const loanOfficerID = this.props.loanOfficer.ID
    if (loanOfficerID) {
      getPortalAdminLoanOfficer(loanOfficerID)
        .then(res => this.setState({ loanOfficer: res[0] }));
    }
  }

  handleClose = () => {
    this.props.onClose();
  };

  onChange = (key, value) => {
    const loanOfficer = update(this.state.loanOfficer, {
      [key]: { $set: value },
    });
    this.setState({
      loanOfficer,
    });
  };

  handlePreview = () => {
    const { loanOfficer } = this.state;
    const previewLO = {
      ...loanOfficer,
      PersonalBio: loanOfficer.PersonalBioStaging,
      PersonalWhyYouShouldWorkWithMe: loanOfficer.PersonalWhyYouShouldWorkWithMeStaging
    }
    this.props.onShowPreview(previewLO)
  }

  handleSave = () => {
    const { ID, PersonalBioStaging, PersonalWhyYouShouldWorkWithMeStaging, EnablePersonalBioFlag, BioApprovalRequiredFlag } = this.state.loanOfficer
    const payload = {
      ID, 
      PersonalBioApprovedFlag: null, 
      EnablePersonalBioFlag, 
      PersonalBio: PersonalBioStaging, 
      PersonalWhyYouShouldWorkWithMe: PersonalWhyYouShouldWorkWithMeStaging, 
      PersonalBioSubmitForApproalFlag: BioApprovalRequiredFlag === 1 ? 1 : null, 
    }
    addUpdatePortalAdminLoanOfficerBio(payload)
      .then(() => {
        this.fetchLoanOfficerData()
        this.props.onUpdate()
        this.props.dispatch(showAlert('Saved successfully.', { variant: 'success', icon: 'bell' }));
        if (this.props.onSubmitFlagChanged) {
          this.props.onSubmitFlagChanged(true)
          this.setState({ isNotSubmittedSavedBio: true })
        }
      })
  }

  handleSubmitApproval = () => {
    const isAdminOrUser = this.props.user.data.PermissionsLevel < 3
    const { ID, PersonalBioStaging, PersonalWhyYouShouldWorkWithMeStaging, EnablePersonalBioFlag } = this.state.loanOfficer

    const payload = {
      ID, 
      PersonalBioApprovedFlag: isAdminOrUser ? 1 : null, 
      EnablePersonalBioFlag, 
      PersonalBio: PersonalBioStaging, 
      PersonalWhyYouShouldWorkWithMe: PersonalWhyYouShouldWorkWithMeStaging, 
      PersonalBioSubmitForApproalFlag: isAdminOrUser ? null : 1, 
    }
    addUpdatePortalAdminLoanOfficerBio(payload)
      .then(() => {
        this.props.onUpdate()
        this.handleClose()
        if (this.props.onSubmitFlagChanged) {
          this.props.onSubmitFlagChanged(false);
          this.setState({ isNotSubmittedSavedBio: false })
        }
      })
  }
  
  render() {
    const { isOpen, user } = this.props;
    const { loanOfficer, isNotSubmittedSavedBio } = this.state;
    const isAdminOrUser = user.data.PermissionsLevel < 3

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={700}>
        <ModalHeader toggle={this.handleClose}>
          My Bio: {`${loanOfficer.FirstName} ${loanOfficer.LastName}`}
        </ModalHeader>
        <ModalBody>
          <label>Summary:</label>
          <MultipleLineInput
            value={loanOfficer.PersonalBioStaging || ''}
            onChange={(e) => this.onChange('PersonalBioStaging', e.target.value)}
          />
          <label>Why You Should Work With Me:</label>
          <MultipleLineInput
            value={loanOfficer.PersonalWhyYouShouldWorkWithMeStaging || ''}
            onChange={(e) => this.onChange('PersonalWhyYouShouldWorkWithMeStaging', e.target.value)}
          />
          {isAdminOrUser && (
            <CheckWrapper>
              <CheckBox value={!!loanOfficer.EnablePersonalBioFlag} onChange={(v) => this.onChange('EnablePersonalBioFlag', v ? 1 : null)} />
              <label>Enable Bio</label>
            </CheckWrapper>
          )}
          <Bottom>
            <div>
              <Button onClick={this.handleSave}>Save</Button>
              <Button onClick={this.handlePreview}>Preview</Button>
            </div>
            {isAdminOrUser && loanOfficer.BioApprovalRequiredFlag && (
              <ApprovButton onClick={this.handleSubmitApproval}>Click to Approve</ApprovButton>
            )}
            {!isAdminOrUser && loanOfficer.BioApprovalRequiredFlag && (
              <>
                {isNotSubmittedSavedBio ? (
                  <ApprovButton onClick={this.handleSubmitApproval}>Submit for Approval</ApprovButton>
                ) : (
                  <Message>
                    <div>Bio text submitted & awaiting approval.</div>
                    <div>Make edits & click Save to submit new text.</div>
                  </Message>
                )}
              </>
            )}
          </Bottom>
        </ModalBody>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(EditLoanOfficerBioModal)

const Modal = styled(DigeryModal)`
  max-width: 500px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-body {
    width: 100%;
    text-align: left;
  
    & > label {
      font-weight: 400;
      margin-bottom: 0.2rem;
      margin-top: 2rem;
    }
  }
`;

const MultipleLineInput = styled(TextArea)`
  height: 90px;
`;

const CheckWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;

  & label { 
    font-weight: 400;
    margin-bottom: 0;
    margin-left: 1rem;
  }
`;

const ApprovButton = styled(Button)`
  background-color: green;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;

  & div {
    display: flex;

    & button {
      margin-right: 0.5rem;
    }
  }

  & button {
    min-width: 0;
    height: 3.6rem;
    font-size: 1.4rem;
  }

`;

const Message = styled.div`
  flex-direction: column;
  margin-left: 20px;
  
  & > div:first-of-type {
    color: red;
    font-size: 1.3rem;
  }
  & > div:last-of-type {
    font-style: italic;
    font-size: 1.2rem;
  }
`



