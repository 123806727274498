import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { 
  getRecruitingDashboardStats,
  getRecruitingDashboardStatsLOs,
  getRecruitingDashboardStatsMCs,
  getRecruitingDashboardStatsRecruiter,
  getRecruitingDashboardStatsRecruiters
} from 'modules/api';
import SectionContentWrapper from 'components/TabWrapper';
import KMDashboard from './KMDashboard';
import LODashboard from './LODashboard';
import RecruiterDashboard from './RecruiterDashboard';
import { formatDate } from 'modules/helpers';

const Tab = {
  MY_DASHBOARD: 'Recruiter Dashboard',
  LO_DASHBOARD: 'LO Dashboard',
  KM_DASHBOARD: 'KM Dashboard',
}

const AdminTabOptions = [
  { display: 'Recruiter Dashboard', value: Tab.MY_DASHBOARD },
  { display: 'LO Dashboard', value: Tab.LO_DASHBOARD },
  { display: 'KM Dashboard', value: Tab.KM_DASHBOARD },
];

const VerticalTab = {
  MTD: 1,
  LAST_MONTH: 2,
  ALL_TIME: 5,
  CUSTOM: 4,
}

const VerticalTabOptions = [
  { display: 'MTD', value: VerticalTab.MTD },
  { display: 'Last Month', value: VerticalTab.LAST_MONTH },
  { display: 'All Time', value: VerticalTab.ALL_TIME },
  { display: 'Custom', value: VerticalTab.CUSTOM },
];

class Dashboard extends Component {
  state = {
    currentTab: Tab.MY_DASHBOARD,
    kmStats: null,
    marketCenterSnapshot: [],
    loanOfficerSnapshot: [],
    recruiterSnapshot: [],
    myStats: null,
    reportPeriod: VerticalTab.MTD,
    startDate: null,
    endDate: null,
   }

  componentDidMount() {

    let currentTab;
    switch(this.props.user.PermissionsLevel) {
      case 3:
        currentTab = Tab.LO_DASHBOARD;
        break;
      case 4: 
        currentTab = Tab.MY_DASHBOARD;
        break;
      default:
        currentTab = Tab.KM_DASHBOARD;
    }
    this.setState({ currentTab }, () => {
      this.changeVerticalTab(VerticalTab.MTD)
    })
  }

  fetchDate = () => {
    const { reportPeriod: period, startDate, endDate, currentTab } = this.state;

    let reportPeriod = period;
    if (reportPeriod === VerticalTab.ALL_TIME) {
      reportPeriod = VerticalTab.CUSTOM;
    }

    if (currentTab === Tab.KM_DASHBOARD) {
      getRecruitingDashboardStats(reportPeriod, startDate, endDate).then(res => {
        this.setState({ kmStats: res.result[0] });
      })
  
      getRecruitingDashboardStatsRecruiters(null, reportPeriod, startDate, endDate).then(res => {
        this.setState({ recruiterSnapshot: res.result });
      })
  
      getRecruitingDashboardStatsLOs(null, reportPeriod, startDate, endDate).then(res => {
        this.setState({ loanOfficerSnapshot: res.result });
      })
  
      getRecruitingDashboardStatsMCs(null, null, reportPeriod, startDate, endDate).then(res => {
        this.setState({ marketCenterSnapshot: res.result });
      })

    } else if (currentTab === Tab.MY_DASHBOARD) {
      const { PK } = this.props.user
      if (PK) {
        getRecruitingDashboardStatsRecruiter(PK, reportPeriod, startDate, endDate).then(res => {
          this.setState({ myStats: res.result[0] });
        })
      }
    }
  }

  changeHorizontalTab = currentTab => {
    this.setState({ currentTab }, () => {
      this.fetchDate()
    });
  };

  changeVerticalTab = reportPeriod => {
    let startDate
    let endDate

    switch (reportPeriod) {
      case VerticalTab.LAST_MONTH:
        startDate = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD');
        endDate = moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD');
        break;
      case VerticalTab.ALL_TIME:
        startDate = '2020-01-01';
        endDate = moment().format('YYYY-MM-DD');
        break;
      default:
        startDate = moment().startOf('month').format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
        break;
    }
    this.setState({ 
      reportPeriod,
      startDate,
      endDate
    }, () => {
      this.fetchDate();
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.fetchDate()
    });
  }

  render() {
    const {
      currentTab,
      kmStats,
      loanOfficerSnapshot,
      marketCenterSnapshot,
      recruiterSnapshot,
      myStats,
      reportPeriod,
      startDate,
      endDate,
    } = this.state;

    const { user } = this.props;

    let tabOptions = AdminTabOptions;
    switch (user.PermissionsLevel) {
      case 4:
        tabOptions = AdminTabOptions.filter(o => o.value === Tab.MY_DASHBOARD);
        break;
      case 3:
        tabOptions = AdminTabOptions.filter(o => o.value === Tab.LO_DASHBOARD);
        break;
      default:
        tabOptions = AdminTabOptions.filter(o => o.value === Tab.KM_DASHBOARD);
    }

    const showDateRange = (
      currentTab === Tab.KM_DASHBOARD ||
      currentTab === Tab.MY_DASHBOARD
    )

    return (
      <Fragment>
        <DateRangeView>
          {(currentTab === Tab.MY_DASHBOARD || currentTab === Tab.LO_DASHBOARD) && `${user.FirstName} ${user.LastName}: `}
          {showDateRange && `${formatDate(startDate)} ~ ${formatDate(endDate)}`}
        </DateRangeView>

        <SectionContentWrapper
          title=""
          hActiveTab={currentTab}
          vActiveTab={reportPeriod}
          horizonalTabOptions={tabOptions}
          onHorizonalTabChange={this.changeHorizontalTab}
          verticalTabOptions={showDateRange ? VerticalTabOptions : null}
          onVerticalTabChange={this.changeVerticalTab}
        >
          {showDateRange && reportPeriod === 4 &&
            <DateRange>
              <Row>
                <RadioLabel>Start Date</RadioLabel>
                <input 
                  placeholder="Start Date"
                  type="date" 
                  value={startDate} 
                  onChange={e => this.handleChange('startDate', e.target.value || null)}
                />
              </Row>
              <Row>
                <RadioLabel>End Date</RadioLabel>
                <input 
                  placeholder="End Date"
                  type="date" 
                  value={endDate} 
                  onChange={e => this.handleChange('endDate', e.target.value || null)}
                />
              </Row>
            </DateRange>
          }
          {currentTab === Tab.MY_DASHBOARD &&
            <RecruiterDashboard 
              user={user}
              stats={myStats}
              period={reportPeriod === VerticalTab.ALL_TIME ? VerticalTab.CUSTOM : reportPeriod}
              startDate={startDate}
              endDate={endDate}
            />
          }
          {currentTab === Tab.LO_DASHBOARD &&
            <LODashboard user={user}/>
          }
          
          {currentTab === Tab.KM_DASHBOARD &&
            <KMDashboard 
              user={user}
              stats={kmStats}
              loanOfficerSnapshot={loanOfficerSnapshot}
              marketCenterSnapshot={marketCenterSnapshot}
              recruiterSnapshot={recruiterSnapshot}
              period={reportPeriod === VerticalTab.ALL_TIME ? VerticalTab.CUSTOM : reportPeriod}
              startDate={startDate}
              endDate={endDate}
            />
          }
        </SectionContentWrapper>
       </Fragment>
    )
  };
}

export default Dashboard;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
`;

const DateRange = styled.div`
  margin-right: 3.0rem;

  span {
    width: 8.1rem;
    margin-right: 1.0rem;
  }
`;

const RadioLabel = styled.span`
  margin-left: .4rem;
  margin-right: 2.0rem;
  font-size: 1.5rem;
`;

const DateRangeView = styled.div`
  margin-bottom: -30px;
  margin-top: 15px;
  font-size: 1.4rem;
  font-weight: bold;
`;

