/**
 * Set Token
 */
export const setToken = token => {
  localStorage.setItem('xfsexfeuiyewrw', token);
};

/**
 * Get Token
 */
export const getToken = () => localStorage.getItem('xfsexfeuiyewrw');

/**
 * Set Token
 */
export const setLoginFailedDate = datetime => {
  localStorage.setItem('iebaobdpbmanefsk', datetime);
};

/**
 * Get Token
 */
export const getLoginFailedDate = () => +localStorage.getItem('iebaobdpbmanefsk');
