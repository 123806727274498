import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Container from 'components/Container';
import { HashLink } from 'react-router-hash-link';
import Icon from 'components/Icon';
import Helmet from 'react-helmet';
import theme, { media } from 'modules/theme';

const PlaceholderAvatar = require('assets/media/images/sample-avatar.png');

const BodyMain = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const BodyItem = styled(HashLink)`
  width: calc(50% - 2rem);
  min-width: 277px;
  margin: 1rem;
  background: #FFFFFF;
  box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  padding: 1.5rem 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div:first-of-type {
    color: ${props => props.theme.palette.primary};
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.5;
    letter-spacing: 0.970588px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 2rem;
  }

  & > div:last-of-type {
    font-size: 1.6rem;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 1px;
    color: ${props => props.theme.palette.light};
    margin-top: 2.6rem;
  }

  ${media.mobile`
    width: 100%;
  `};
`;

const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
`;

class Home extends Component {
  state = {};

  render() {
    const { user } = this.props;
    const isAdmin = user.data.PermissionsLevel === 1;
    const isUser = user.data.PermissionsLevel < 3;
    const isNonRecruiter = user.data.PermissionsLevel < 4;

    return (
      <Fragment>
        <Helmet>
          <title>Home</title>
          <meta name="description" content="This is the Keller Mortgage Agent Portal Home page" />
        </Helmet>
        <Container>
          <BodyMain>
            <BodyItem to="/private/agent-search">
              <div>Search</div>
              <Icon name="search" color={theme.palette.gray} width={80} />
              <div>Find a KW Agent & Refer a Client</div>
            </BodyItem>
            {isNonRecruiter && (
              <BodyItem to="/private/refer-a-client">
                <div>Refer a client</div>
                <Icon name="home_refer_a_client" color={theme.palette.gray} width={105} />
                <div>Refer a Client by Agent, Team, or Loan Officer</div>
              </BodyItem>
            )}
            <BodyItem to="/private/recruiting/dashboard">
              <div>My Recruiting</div>
              <Icon name="two-men" color={theme.palette.gray} width={80} />
              <div>Recruit KW Agents</div>
            </BodyItem>
            {isNonRecruiter && (
              <BodyItem to="/private/agents/my-agents">
                <div>My Agents</div>
                <Avatar src={PlaceholderAvatar} />
                <div>View KW Agent Production Data</div>
              </BodyItem>
            )}
            {isUser && (
              <BodyItem to="/private/reports">
                <div>Reports</div>
                <Icon name="file_download" color={theme.palette.gray} width={60} />
                <div>Download Agent Portal data</div>
              </BodyItem>
            )}
            {isNonRecruiter && (
              <BodyItem to="/private/activity">
                <div>Activity</div>
                <Icon name="chart" color={theme.palette.gray} width={72} />
                <div>View Activity</div>
              </BodyItem>
            )}
            {isAdmin && (
              <BodyItem to="/private/admin/users">
                <div>Users</div>
                <Icon name="home_km_settings" color={theme.palette.gray} width={72} />
                <div>Manage Portal Admin Users</div>
              </BodyItem>
            )}
            {isUser && (
              <BodyItem to="/private/loan-officers">
                <div>Loan Officers</div>
                <Icon name="home_deals" color={theme.palette.gray} width={80} />
                <div>Manage Loan Officers</div>
              </BodyItem>
            )}
          </BodyMain>
        </Container>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(Home);
