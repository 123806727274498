import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '0px 8px',
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    '& > div': {
      padding: '0 0px',
      background: 'white',
      zIndex: 10,
    },
  }),
}

const Select = ({ styles, value, options, onChange, ...props }) => (
  <StyledSelect
    styles={{...CustomDropdownStyles, ...styles}}
    value={options.find(o => o.value === value)}
    onChange={(v) => onChange(v.value)}
    options={options}
    {...props}
  />
);

const StyledSelect = styled(ReactSelect)`
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  box-shadow: none;
  border-radius: 3px;
  color: ${props => props.theme.palette.darkText};

  & > div:first-of-type {
    box-shadow: none;
    min-height: 40px;
    border-radius: 2px;
    border: ${props => props.theme.palette.border} 1px solid;
  }

  & > div:hover {
    border: ${props => props.theme.palette.border} 1px solid;
    box-shadow: none;
  }
`;

export default Select;
