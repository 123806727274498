import publicIp from 'public-ip';
import DeviceDetector from 'device-detector-js';
import moment from 'moment';
import { isNumber } from 'lodash';

export function datasetToObject(elem: Element): Object {
  const data = {};
  [].forEach.call(elem.attributes, attr => {
    /* istanbul ignore else */
    if (/^data-/.test(attr.name)) {
      const camelCaseName = attr.name.substr(5).replace(/-(.)/g, ($0, $1) => $1.toUpperCase());
      data[camelCaseName] = attr.value;
    }
  });
  return data;
}

export function numberWithCommas(x) {
  if (x === null) {
    return null;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}

export function copyClipboard(text) {
  const input = document.createElement('textarea');
  input.innerText = text;
  document.body.appendChild(input);

  const isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
  if (isiOSDevice) {
    input.contentEditable = true;
    input.readOnly = false;
    input.style.position = 'absolute';
    input.style.left = '-9999px';
    const range = document.createRange();
    range.selectNodeContents(input);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    input.setSelectionRange(0, 999999);
  } else {
    input.select();
  }
  document.execCommand('copy');
  input.remove();
}

export function downloadCSV(filename, rows) {
  let result = '';
  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = Object.keys(rows[1]);

  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  rows.forEach(item => {
    let ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) result += columnDelimiter;

      result += `"${item[key] || ''}"`;
      ctr++;
    });
    result += lineDelimiter;
  });

  const prefix = 'data:text/csv;charset=utf-8,';
  const encodedURI = prefix + encodeURIComponent(result);

  const link = document.createElement('a');
  link.setAttribute('href', encodedURI);
  link.setAttribute('download', filename || 'export.csv');
  link.click();
}

export function downloadCSVRaw(filename, rawText) {
  const prefix = 'data:text/csv;charset=utf-8,';
  const encodedURI = prefix + encodeURIComponent(rawText);

  const link = document.createElement('a');
  link.setAttribute('href', encodedURI);
  link.setAttribute('download', filename || 'export.csv');
  link.click();
}

export function getActivitiyInfo() {
  const deviceDetector = new DeviceDetector();
  const result = deviceDetector.parse(navigator.userAgent);
  const { client, device, os } = result;

  return new Promise(resolve => {
    publicIp
      .v4()
      .then(IPAddress => {
        resolve({
          DeviceType: `${device?.type} : ${device?.brand} ${device?.model}`,
          BrowserOS: `${client?.name || ''}, ${os?.name || ''}`,
          IPAddress,
        });
      })
      .catch(() => {
        resolve({
          DeviceType: `${device?.type} : ${device?.brand} ${device?.model}`,
          BrowserOS: `${client?.name || ''}, ${os?.name || ''}`,
          IPAddress: 'unknown',
        });
      });
  });
}

export function formatUTCDate(dateStr) {
  return moment.utc(dateStr).format('MM/DD/YY');
}

export function formatUTCDateTime(dateStr) {
  return moment.utc(dateStr).format('MM/DD/YYYY h:m A');
}

export function formatDate(dateStr) {
  return moment(dateStr).format('MM/DD/YYYY');
}

export function encodeQueryData(data) {
  const ret = [];
  for (const d in data) ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(data[d])}`);
  return ret.join('&');
}

export const percentFormatter = value => {
  if (!isNumber(value)) {
    return '';
  }
  return `${(value * 100).toFixed(1)}%`;
};
