import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import HttpService from 'modules/http.service';

import {
  addUpdateKWMarketCenter,
  addUpdateKWTeam,
  updateKWAgent,
  addKWAgentTeams,
  addKWAgentMarketCenter,
  addUpdateKWRoles,
  addUpdateKWOrgRoles,
  addUpdateKWRolesTeam,
  addKWAgentOrgRole,
  addUpdateXRefMCParentOrg,
} from 'modules/api';
import Environment from '../../environments/env';

const Modal = styled(DigeryModal)`
  max-width: 70rem;

  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 3rem;
      color: ${props => props.theme.palette.primary};
    }
  }
  .modal-body {
    text-align: left;
    margin: 1.0rem 0 1.5rem;
    max-height: 70.0rem;
    overflow: auto;
    min-height: 60.0rem;
  }
`;

const Table = styled.table`
  width: 100%;
  tr {
    td {
      padding-left: 1.0rem;
      padding-right: 1.0rem;
      font-size: 1.5rem;
      vertical-align: top;
      border: .1rem solid gray;
      padding .3rem 1.0rem;
    }
    td:nth-of-type(2) {
      div {
        display: flex;
        span:first-of-type {
          display: inline-block;
          width: 8.0rem;
          padding-right: 1.0rem;
        }
      }
    }
  }
`;

class LoanOfficerDetailModal extends Component {
  _Environment = new Environment()

  _http = new HttpService()

  static propTypes = {
    kwuid: PropTypes.number,
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    agentData: {},
    teams: [],
    marketCenters: [],
  }

  componentDidMount() {
    if (this.props.kwuid) {
      this.updateLoanOfficer(this.props.kwuid);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.kwuid !== prevProps.kwuid) {
      this.updateLoanOfficer(this.props.kwuid)
    }
  }

  updateLoanOfficer = (kwuid) => {
    if (!kwuid) {
      return;
    }

    this.setState({
      agentData: {},
      teams: [],
      marketCenters: [],
    })

    if (this.props.onUpdateAgent) {
      this.props.onUpdateAgent({})
    }

    if (this.props.onUpdateTeam) {
      this.props.onUpdateTeam([])
    }

    if (this.props.onUpdateMCs) {
      this.props.onUpdateMCs([])
    }

    const headers = {
      'x-authorization': this._Environment.getENV().SEARCH_AGENT_API_KEY,
    }

    const getPersonUrl = `https://roles.kwiq.kw.com/api/v1.1/person/${kwuid}`
    this._http.getReq(getPersonUrl, headers)
      .then(({ data }) => {
        const agentData = {
          KWUID: data.kw_uid,
          FirstName: data.first_name,
          LastName: data.last_name,
          Email: data.email,
          MobilePhoneNumber: data.mobile_phone,
          Phone: data.home_phone,
          Active: data.active,
          Photo: data.photo,
          Address1: data.address_1,
          Address2: data.address_2,
          City: data.city,
          StateCode: data.state_prov_id,
          PostalCode: data.postal_cd,
          CountryCode: data.country,
        }

        this.setState({ agentData })
        if (this.props.onUpdateAgent) {
          this.props.onUpdateAgent(agentData)
        }

        updateKWAgent(agentData)
          .then(() => {
          })
          .catch(() => {});
      })
      .catch((error) => {
        console.log(error);
      });

    const getTeamUrl = `https://roles.kwiq.kw.com/api/v1.1/person/${kwuid}/teams`
    this._http.getReq(getTeamUrl, headers)
      .then(res => {
        const teamData = res.data;

        const teams = []
        const roles = []
        const teamRoles = []

        const agentTeamRoles = []

        teamData.forEach(o => {
          teams.push({
            TeamID: o.team_id,
            TeamName: o.team_name,
            TeamRefID: o.team_id,
          })
          const teamMemberInfo = o.members.find(m => m.kw_uid === kwuid)
          agentTeamRoles.push({
            KWUID: kwuid,
            TeamRefID: o.team_id,
            RoleID: teamMemberInfo ? teamMemberInfo.team_role_id : null,
          })
          if (teamMemberInfo) {
            roles.push({
              RoleID: teamMemberInfo.team_role_id,
              RoleName: teamMemberInfo.role_name,
            })
            teamRoles.push({
              TeamRoleID: teamMemberInfo.team_role_id,
              TeamRoleName: teamMemberInfo.role_name,
            })
          }
        })

        this.setState({ teams });
        this.props.onUpdateTeam(teams)

        if (teams.length > 0) {
          addUpdateKWTeam(JSON.stringify(teams))
            .then(() => {})
            .catch(() => {});

          addKWAgentTeams(JSON.stringify(agentTeamRoles))
            .then(() => {})
            .catch(() => {});
        }

        if (roles.length > 0) {
          addUpdateKWRoles(JSON.stringify(roles))
            .then(() => {})
            .catch(() => {});

          addUpdateKWRolesTeam(JSON.stringify(teamRoles))
            .then(() => {})
            .catch(() => {});
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const getMarketCenterUrl = `https://roles.kwiq.kw.com/api/v1.1/person/${kwuid}/orgs`
    this._http.getReq(getMarketCenterUrl, headers)
      .then(res => {
        const allOrgs = res.data;
        const marketCenterData = res.data.filter(m => m.org_type_id === 3)

        const marketCenters = []
        const marketCentersWithOrgRoles = []

        const agentMarketCenters = []
        const xRefMCParentOrgs = []
        const orgRoles = []
        const agentOrgRoles = []

        marketCenterData.forEach(o => {
          marketCenters.push({
            MarketCenterID: o.org_key,
            MarketCenterName: o.org_name,
            MarketCenterRefID: o.org_id,
            MarketCenterAddress: o.address,
            MarketCenterCity: o.city,
            MarketCenterEmail: o.email,
            MarketCenterPhone: o.phone,
            MarketCenterState: o.state_prov,
          })
          marketCentersWithOrgRoles.push({
            MarketCenterID: o.org_key,
            MarketCenterName: o.org_name,
            MarketCenterRefID: o.org_id,
            MarketCenterAddress: o.address,
            MarketCenterCity: o.city,
            MarketCenterEmail: o.email,
            MarketCenterPhone: o.phone,
            MarketCenterState: o.state_prov,
            MarketCenterOrgRoles: o.roles.map(r => r.role_name).join(', '),
          })
          agentMarketCenters.push({
            KWUID: kwuid,
            MarketCenterRefID: o.org_id,
          });
          xRefMCParentOrgs.push({
            MarketCenterRefID: o.org_id,
            ParentOrgID: o.parent_org_id,
          })
        })

        this.setState({ marketCenters: marketCentersWithOrgRoles })
        if (this.props.onUpdateMCs) {
          this.props.onUpdateMCs(marketCentersWithOrgRoles)
        }

        allOrgs.forEach(o => {
          const tempRoles = o.roles.map(r => ({
            OrgRoleID: r.role_id,
            OrgRoleName: r.role_name,
          }))
          orgRoles.push(...tempRoles)

          const tempAgentOrgRoles = o.roles.map(r => ({
            KWUID: kwuid,
            RoleID: r.role_id,
            MarketCenterRefID: o.org_id,
          }))
          agentOrgRoles.push(...tempAgentOrgRoles)
        })

        if (marketCenters.length > 0) {
          addUpdateKWMarketCenter(JSON.stringify(marketCenters))
            .then(() => {})
            .catch(() => {});

          addKWAgentMarketCenter(JSON.stringify(agentMarketCenters))
            .then(() => {})
            .catch(() => {});

          addUpdateXRefMCParentOrg(JSON.stringify(xRefMCParentOrgs))
            .then(() => {})
            .catch(() => {});
        }

        if (orgRoles.length > 0) {
          addUpdateKWOrgRoles(JSON.stringify(orgRoles))
            .then(() => {})
            .catch(() => {});
        }

        if (agentOrgRoles.length > 0) {
          addKWAgentOrgRole(JSON.stringify(agentOrgRoles))
            .then(() => {})
            .catch(() => {});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  render() {
    const { showModal, onClose } = this.props;
    const { agentData, teams, marketCenters } = this.state;
    const { FirstName, LastName, ...showAgentData } = agentData;

    return (
      <Modal centered fade={false} isOpen={showModal} toggle={onClose}>
        <ModalHeader toggle={onClose}>Agent Details</ModalHeader>
        <ModalBody>
          <Table>

            <tbody>
              {FirstName && (
                <tr>
                  <td>Name</td>
                  <td>{`${FirstName} ${LastName}`}</td>
                </tr>
              )}
              {
                Object.keys(showAgentData).map(key => (
                  <tr key={key}>
                    <td>{key === 'Phone' ? 'Home Phone' : key}</td>
                    <td>{showAgentData[key]}</td>
                  </tr>
                ))
              }
              {
                teams.map((t, index) => (
                  <tr key={`team_${index}`}>
                    <td>Team {index+1}</td>
                    <td>
                      {
                        Object.keys(t).map(key => (
                          <div key={`team${index}_${key}`}>
                            <span>{key.replace('Team', '')}:</span>
                            <span>{t[key]}</span>
                          </div>
                        ))
                      }
                    </td>
                  </tr>
                ))
              }
              {
                marketCenters.map((m, index) => (
                  <tr key={`mc_${index}`}>
                    <td>MarketCenter {index+1}</td>
                    <td>
                      {
                        Object.keys(m).map(key => (
                          <div key={`mc_${index}_${key}`}>
                            <span>{key.replace('MarketCenter', '')}:</span>
                            <span>{m[key]}</span>
                          </div>
                        ))
                      }
                    </td>
                  </tr>
                ))
              }
            </tbody>

          </Table>
        </ModalBody>
      </Modal>
    )
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(LoanOfficerDetailModal);

