/* eslint-disable no-lonely-if */
import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import Select from 'react-select';
import {
  getLoanOfficers,
  addRecruitingLOAssignment,
  getRecruitingInPoolAgents,
  addRecruitingActivitys,
  getRecruitingActivityTypes,
} from 'modules/api';
import CheckBox from 'components/CheckBox';
import { orderBy }from 'lodash';
import SectionContentWrapper from 'components/TabWrapper';
import { STATES } from 'constants/index';
import 'react-virtualized/styles.css';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { showAlert } from 'actions';
import ActivityModal from 'routes/Recruiting/Dashboard/LODashboard/ActivityModal';
import withModals from 'modules/withModals';
import { formatUTCDate } from 'modules/helpers';
import ChangeLoanOfficerModal from './ChangeLoanOfficerModal';

const TAB = {
  AGENTS: 'AGENTS',
}

const HorizontalTabOptions = [
  { display: 'Agents in Pool', value: TAB.AGENTS },
];

const VerticalTabOptions = [
];

const StateOptions = STATES.map(state => ({
  label: state,
  value: state,
}));

class Pool extends Component {
  state = {
    allLoanOfficers: [],
    showChangeLOModal: false,
    reportPeriod: 0,
    activeTab: TAB.AGENTS,
    selectedState: null,
    agents: [],
    sortCurrentColumn: 'StateCode',
    sortCurrentDirection: 'ASC',
    checkedAgents: [],
    assignToLOActivityTypeID: 9,
  }

   _setTableRef = ref => {
     this._table = ref;
   };

   componentDidMount() {
     this.fetchData();

     getLoanOfficers().then(res => {
       const allLoanOfficers = res.filter(r => r.Status === 'Active').map(r => ({
         ...r,
         Name: `${r.FirstName} ${r.LastName}`,
         LOID: r.ID,
       }))
       this.setState({ allLoanOfficers })
     })

     getRecruitingActivityTypes()
       .then(res => {
         const assignLOType = res.result.find(o => !!o.AssignToLOFlag);
         if (assignLOType) {
           this.setState({ assignToLOActivityTypeID: assignLOType.ActivityTypeID })
         }
       })
   }

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.fetchData()
    });
  }

  fetchData = async () => {
    const { selectedState } = this.state;
    const selectedStateCode = (selectedState || {}).value;

    const startDate = '2020-01-01';
    const endDate = moment().format('YYYY-MM-DD');
    const reportPeriod = 4;

    const { PermissionsLevel, LOID } = this.props.user;
    const LoanOfficerID = PermissionsLevel === 3 ? LOID : null;

    try {
      const apiRes = await getRecruitingInPoolAgents(selectedStateCode, startDate, endDate, reportPeriod, null, LoanOfficerID);

      this.setState({
        agents: apiRes.result,
        checkedAgents: [],
      }, () => {
        this.handleSort();
      })
    // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  changeHorizontalTab = activeTab => {
    this.setState({
      activeTab,
      agents: [],
      checkedAgents: [],
    }, () => {
      this.fetchData();
    });
  };

  changeVerticalTab = reportPeriod => {
    this.setState({
      reportPeriod,
    }, () => {
      this.fetchData();
    });
  };

  handleCheckChange = (KWUID, value) => {
    const { agents, checkedAgents } = this.state;

    if (KWUID === 'all') {
      this.setState({
        checkedAgents: value ? agents.map(a => a.KWUID) : [],
      })
    } else {
      if (value) {
        if (!checkedAgents.includes(KWUID)) {
          this.setState({ checkedAgents: [...checkedAgents, KWUID] })
        }
      } else if (checkedAgents.includes(KWUID)) {
        this.setState({ checkedAgents: checkedAgents.filter(a => a !== KWUID) })
      }
    }
  }

  handleAssignLO = async (LOID) => {
    const { checkedAgents, agents, assignToLOActivityTypeID } = this.state;
    const { dispatch } = this.props;

    const assignments = checkedAgents.map(KWUID => ({
      KWUID,
      AssignmentPK: agents.find(a => a.KWUID === KWUID).AssignmentPK,
      LOID,
    }))

    const activities = checkedAgents.map(KWUID => ({
      RecruitingRecruiterAssignmentID: agents.find(a => a.KWUID === KWUID).AssignmentPK,
      RecruitingActivityTypeID: assignToLOActivityTypeID,
    }))

    await addRecruitingLOAssignment(JSON.stringify(assignments));

    await addRecruitingActivitys(JSON.stringify(activities))

    dispatch(showAlert('Assigned successfully.', { variant: 'success', icon: 'bell' }));

    this.fetchData();
  }

  handleShowAssignRecruitingModal = () => {
    const { LOID, PermissionsLevel } = this.props.user
    if (PermissionsLevel === 3) {
      this.handleAssignLO(LOID)
    } else {
      this.setState({ showChangeLOModal: true })
    }
  }

  handleShowActivityModal = (selectedAgent) => () => {
    this.props.openModal('ActivityModal', {
      assignmentPK: selectedAgent.AssignmentPK,
    })
  }

  _sort = ({ sortBy, sortDirection }) => {
    this.setState({
      sortCurrentColumn: sortBy,
      sortCurrentDirection: sortDirection,
    }, () => this.handleSort())
  }

  handleSort = () => {
    const { sortCurrentColumn, agents, sortCurrentDirection } = this.state;

    let sorted = orderBy(agents, [sortCurrentColumn], [sortCurrentDirection.toLocaleLowerCase()]);
    if (sortCurrentDirection === 'DESC') {
      const nullItems = sorted.filter(i => i[sortCurrentColumn] === null)
      const unNullItems = sorted.filter(i => i[sortCurrentColumn] !== null)
      sorted = [...unNullItems, ...nullItems]
    }

    this.setState({
      agents: sorted,
    })
  };

  noRowsRenderer() {
    return <div />;
  }

  getTableHeight = () => {
    const elem = document.getElementById('table-wrapper');
    let calcHeight = 600

    if (elem) {
      const rect = elem.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      calcHeight = screenHeight - rect.top - 80
    }
    return calcHeight;
  }

  getRowRenderer = props => {
    const { _key, className, columns, index, style, rowData } = props;
    const { ActivityOverdueFlag } = rowData;

    const isOdd = index % 2;

    return (
      <TableRow
        key={_key}
        style={{
          ...style,
          backgroundColor: isOdd ? 'white' : '#eeeeee',
          paddingTop: 7,
        }}
        red={ActivityOverdueFlag}
      >
        <div className={className}>{columns}</div>
      </TableRow>
    );
  };

  rowGetter = ({ index }) => this.state.agents[index];

  render() {
    const {
      activeTab,
      reportPeriod,
      sortCurrentColumn,
      sortCurrentDirection,
      agents,
      checkedAgents,
      selectedState,
      showChangeLOModal,
      allLoanOfficers,
    } = this.state;

    const isRep = this.props.user.PermissionsLevel === 3

    return (
      <Fragment>
        <SectionContentWrapper
          title=""
          hActiveTab={activeTab}
          vActiveTab={reportPeriod}
          horizonalTabOptions={HorizontalTabOptions}
          onHorizonalTabChange={this.changeHorizontalTab}
          verticalTabOptions={VerticalTabOptions}
          onVerticalTabChange={this.changeVerticalTab}
        >
          <Wrapper id="table-wrapper">
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  ref={this._setTableRef}
                  headerHeight={70}
                  noRowsRenderer={this.noRowsRenderer}
                  rowHeight={80}
                  rowGetter={this.rowGetter}
                  rowCount={agents.length}
                  height={this.getTableHeight()}
                  rowRenderer={this.getRowRenderer}
                  width={width}
                  sort={this._sort}
                  sortBy={sortCurrentColumn}
                  sortDirection={sortCurrentDirection}
                >
                  <Column
                    width={30}
                    disableSort
                    dataKey="Checked"
                    headerStyle={{ justifyContent: 'flex-start', minWidth: 20 }}
                    style={{ minWidth: 20 }}
                    cellRenderer={({ rowData }) => (
                      <CheckBox
                        value={checkedAgents.includes(rowData.KWUID)}
                        onChange={value => this.handleCheckChange(rowData.KWUID, value)}
                      />
                    )}
                    headerRenderer={() => (
                      <CheckBox
                        value={checkedAgents.length === agents.length}
                        onChange={value => this.handleCheckChange('all', value)}
                      />
                    )}
                  />
                  <Column
                    width={120}
                    dataKey="StateCode"
                    cellRenderer={({ cellData }) => cellData}
                    label={(
                      <div>
                        <div>MC State</div>
                        <div onClick={e=> e.stopPropagation()}>
                          <StateSelect
                            // eslint-disable-next-line no-use-before-define
                            styles={CustomDropdownStyles}
                            isSearchable
                            isClearable
                            placeholder="All"
                            options={StateOptions}
                            onChange={(state) => this.handleChange('selectedState', state)}
                            value={selectedState}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <Column
                    label="Market Center"
                    dataKey="MarketCenterID"
                    width={200}
                    cellRenderer={({ rowData }) => {
                      const { MarketCenterID, MarketCenterName, MarketCenterTeamLeader } = rowData;
                      return `${MarketCenterName} (${MarketCenterID}) - ${MarketCenterTeamLeader}`;
                    }}
                  />
                  <Column
                    width={150}
                    label="Recruiter"
                    dataKey="FirstName"
                    disableSort
                    cellRenderer={({ rowData }) => {
                      const { FirstName, LastName } = rowData;
                      return `${FirstName || ''} ${LastName || ''}`
                    }}
                  />
                  <Column
                    width={90}
                    label="KWUID"
                    dataKey="KWUID"
                    disableSort
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    label="Agent Name"
                    dataKey="Firstname"
                    width={200}
                    style={{ textAlign: 'left' }}
                    headerStyle={{ justifyContent: 'flex-start' }}
                    cellRenderer={({ rowData }) => {
                      const { Firstname, Lastname } = rowData;
                      return <LinkButton onClick={this.handleShowActivityModal(rowData)}>{`${Firstname} ${Lastname}`}</LinkButton>;
                    }}
                  />
                  <Column
                    width={220}
                    label="Email"
                    dataKey="Email"
                    disableSort
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={150}
                    label="Mobile Phone"
                    disableSort
                    dataKey="MobilePhoneNumber"
                    cellRenderer={({ cellData }) => cellData}
                  />

                  <Column
                    width={170}
                    label="Agent Created Date"
                    dataKey="CreatedDate"
                    cellRenderer={({ cellData }) => cellData ? formatUTCDate(cellData) : ''}
                  />
                </Table>
              )}
            </AutoSizer>
          </Wrapper>
          {checkedAgents.length > 0 &&
            <ActionButton onClick={this.handleShowAssignRecruitingModal}>{isRep ? 'Assign to me' : 'Assign'}</ActionButton>
          }
        </SectionContentWrapper>
        <ChangeLoanOfficerModal
          allLoanOfficers={allLoanOfficers}
          showModal={showChangeLOModal}
          onClose={() => this.setState({ showChangeLOModal: false })}
          onSelect={this.handleAssignLO}
        />
      </Fragment>
    )
  };
}

export default
withModals({
  ActivityModal,
})(Pool)


const ActionButton = styled.button`
  background: ${props => props.theme.palette.gray};
  color: white;
  min-width: unset;
  font-size: 1.3rem;
  position: fixed;
  left: 260px;
  bottom: 70px;
  border-radius: 6px;
  padding: 5px 10px;

  &:focus, &:hover {
    color: white;
  }
`;

const TableRow = styled.div`
  ${({ red }) => red && css`
    color: red;

    button {
      color: red;
    }
  `}
`;

const StateSelect = styled(Select)`
  text-align: left;
  width: 100%;
  font-size: 1.2rem;
  box-shadow: none;
  border-radius: 3px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 28px;
    min-width: 70px;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '0px 8px',
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    '& > div': {
      padding: '0 0px',
      background: 'white',
      zIndex: 10,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 200,
  }),
}

const Wrapper = styled.div`
  font-size: 1.3rem;

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Table__headerRow {
    overflow: visible !important;
  }

  .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: none;
    }

    .ReactVirtualized__Table__headerTruncatedText {
      white-space: pre-wrap;
      text-align: center;
      overflow: visible !important;
    }

    .ReactVirtualized__Table__sortableHeaderIcon {
      font-size: 22px;
    }
  }

  .ReactVirtualized__Table__rowColumn {
    text-align: center;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .ReactVirtualized__Table__row {
    height: 100%;
  }
`;

const LinkButton = styled.button`
  text-decoration: underline;
  color: blue;
  text-align: left;
`;


