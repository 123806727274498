import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { appColor, mobileHeaderHeight, MobileWidth } from 'modules/theme';
import { NavLink } from 'react-router-dom';
import Container from 'components/Container';
import { toggleMobileSideMenu, logOut } from 'actions/index';
import { HashLink } from 'react-router-hash-link';
import MobileTitle from '../components/MobileTitle';

const Nav = styled.nav`
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  left: 0;
  bottom: 0;
  width: 100%;
  right: 0;
  top: 0;
  height: 70px;
  flex-direction: column;
  position: relative;
  background: ${appColor};

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
    height: 1px;
    background: white;
    z-index: -1;
  }
`;

const ContentContainer = styled(Container)`
  position: relative;
  width: 80%;
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const MenuLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eeeeee;
  padding: 10px 0px 25px;
  font-size: 1.6rem;
  font-weight: 600;
  position: relative;
  text-transform: uppercase;

  .arrow {
    background: white;
    display: none;
    height: 17px;
    margin-left: 0px;
    position: absolute;
    width: 20px;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);

    &:before {
      border-bottom: 10px solid white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      content: '';
      height: 0;
      left: 0;
      position: absolute;
      top: -10px;
      width: 0;
    }
  }

  span {
    white-space: nowrap;
  }

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }

  &.selected {
    text-decoration: none;
    color: white;

    .arrow {
      display: block;
    }
  }
`;

const LogoutButtonWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
`;

// ///// Mobile Menu ///////
const MobileNav = styled.nav`
  z-index: 999;
  display: ${props => (props.showOnMobile ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 0;
  top: ${mobileHeaderHeight}px;
  bottom: 0;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  overflow: auto;
  -webkit-transition: width 0.3s linear;
  transition: width 0.3s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  color: black;
  background: #ebecf1;
  padding: 14px;
`;

const MobileMenuLink = styled(HashLink)`
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  background: white;
  text-align: center;
  color: ${props => props.theme.palette.gray};
  text-transform: uppercase;
`;

class SideNavBar extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func,
    showOnMobile: PropTypes.bool,
  };

  state = {
    locked: false,
    isMobile: false,
  };

  componentDidMount() {
    this.getScreenWdith();
    window.addEventListener('resize', this.getScreenWdith);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getScreenWdith);
  }

  getScreenWdith = () => {
    let isMobile = false;
    if (window.innerWidth < MobileWidth) {
      isMobile = true;
    }
    this.setState({ isMobile });
  };

  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch(logOut());
  };

  hideNavBar = () => {
    const { dispatch } = this.props;
    dispatch(toggleMobileSideMenu());
  };

  activityMenuActive = (_, location) => location.pathname.includes('/private/activity');

  agentsMenuActive = (_, location) => location.pathname.includes('/private/agents');


  render() {
    const { locked, isMobile } = this.state;
    const { user, showOnMobile } = this.props;
    const isAdmin = user.data.PermissionsLevel === 1;
    const isUser = user.data.PermissionsLevel < 3;
    const isNonRecruiter = user.data.PermissionsLevel < 4;

    return (
      isMobile ? (
        <MobileNav showOnMobile={showOnMobile}>
          <MobileMenuLink to="/private/home" exact onClick={this.hideNavBar}>
            Home
          </MobileMenuLink>
          {isUser && (
            <Fragment>
              <MobileMenuLink to="/private/loan-officers" exact onClick={this.hideNavBar}>
                Loan Officers
              </MobileMenuLink>
              <MobileMenuLink to="/private/reports" exact onClick={this.hideNavBar}>
                Reports
              </MobileMenuLink>
            </Fragment>
          )}
          {isNonRecruiter && (
            <MobileMenuLink to="/private/agents/my-agents" exact onClick={this.hideNavBar} isActive={this.agentsMenuActive}>
              Agents
            </MobileMenuLink>
          )}
          <MobileMenuLink to="/private/agent-search" exact onClick={this.hideNavBar}>
            Search
          </MobileMenuLink>
          {isNonRecruiter && (
            <MobileMenuLink to="/private/activity" exact onClick={this.hideNavBar}>
              Activity
            </MobileMenuLink>
          )}
          <MobileMenuLink to="/private/recruiting" exact onClick={this.hideNavBar}>
            Recruiting
          </MobileMenuLink>
          {isAdmin && (
            <MobileMenuLink to="/private/admin/users" exact onClick={this.hideNavBar}>
              Admin
            </MobileMenuLink>
          )}
          <LogoutButtonWrapper>
            <MobileTitle title="Log Out" onClick={this.handleLogout} />
          </LogoutButtonWrapper>
        </MobileNav>
      )
        : (
          <Nav locked={locked}>
            <ContentContainer>
              <MenuWrapper>
                <MenuLink to="/private/home" exact activeClassName="selected">
                  <span>Home</span>
                  <div className="arrow" />
                </MenuLink>
                {isUser && (
                  <MenuLink to="/private/loan-officers" exact activeClassName="selected">
                    <span>Loan Officers</span>
                    <div className="arrow" />
                  </MenuLink>
                )}
                {isUser && (
                  <MenuLink to="/private/reports" exact activeClassName="selected">
                    <span>Reports</span>
                    <div className="arrow" />
                  </MenuLink>
                )}
                {isNonRecruiter && (
                  <MenuLink to="/private/agents/my-agents" exact activeClassName="selected" isActive={this.agentsMenuActive}>
                    <span>Agents</span>
                    <div className="arrow" />
                  </MenuLink>
                )}
                <MenuLink to="/private/agent-search" exact activeClassName="selected">
                  <span>Search</span>
                  <div className="arrow" />
                </MenuLink>
                {isNonRecruiter && (
                  <MenuLink to="/private/activity" exact activeClassName="selected" isActive={this.activityMenuActive}>
                    <span>Activity</span>
                    <div className="arrow" />
                  </MenuLink>
                )}
                <MenuLink to="/private/recruiting" activeClassName="selected">
                  <span>Recruiting</span>
                  <div className="arrow" />
                </MenuLink>
                {isAdmin && (
                  <MenuLink to="/private/admin/users" exact activeClassName="selected">
                    <span>Admin</span>
                    <div className="arrow" />
                  </MenuLink>
                )}
              </MenuWrapper>
            </ContentContainer>
          </Nav>
        )
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    showOnMobile: state.app.visibleMobileSidebar,
  };
}

export default connect(mapStateToProps)(SideNavBar);
