import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import {
  getRecruitingMarketCentersOptIn,
  getRecruitingMarketCentersOptOut,
  getRecruitingMarketCentersOptinPendingApproval,
  updateRecruitingMarketCenterReadyForRecruiting,
  sendEmail,
} from 'modules/api';
import { orderBy }from 'lodash';
import SectionContentWrapper from 'components/TabWrapper';
import 'react-virtualized/styles.css';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { showAlert } from 'actions';
import ActivityModal from 'routes/Recruiting/Dashboard/LODashboard/ActivityModal';
import withModals from 'modules/withModals';
import { formatUTCDate } from 'modules/helpers';
import DownloadCSV from 'components/DownloadCSV';

const TAB = {
  OPT_IN: 'OPT_IN',
  OPT_OUT: 'OPT_OUT',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
}

const HorizontalTabOptions = [
  { display: 'Opt-in', value: TAB.OPT_IN },
  { display: 'Opt-out', value: TAB.OPT_OUT },
  { display: 'Awaiting Approval', value: TAB.AWAITING_APPROVAL },
];

class MarketCenters extends Component {
  state = {
    reportPeriod: 0,
    activeTab: TAB.OPT_IN,
    marketCenters: [],
    sortCurrentColumn: 'MarketCenterName',
    sortCurrentDirection: 'ASC',
  }

   _setTableRef = ref => {
     this._table = ref;
   };

   componentDidMount() {
     this.fetchData();
   }

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.fetchData()
    });
  }

  fetchData = async () => {
    const {activeTab} = this.state

    let api
    switch (activeTab) {
      case TAB.OPT_IN:
        api = getRecruitingMarketCentersOptIn
        break;
      case TAB.OPT_OUT:
        api = getRecruitingMarketCentersOptOut
        break;
      default:
        api = getRecruitingMarketCentersOptinPendingApproval
        break;
    }
    try {
      const apiRes = await api();
      this.setState({
        marketCenters: apiRes,
      }, () => {
        this.handleSort();
      })
    } catch (error) {}
  }

  changeHorizontalTab = activeTab => {
    this.setState({
      activeTab,
      marketCenters: [],
    }, () => {
      this.fetchData();
    });
  };

  changeVerticalTab = reportPeriod => {
    this.setState({
      reportPeriod,
    }, () => {
      this.fetchData();
    });
  };

  _sort = ({ sortBy, sortDirection }) => {
    this.setState({
      sortCurrentColumn: sortBy,
      sortCurrentDirection: sortDirection,
    }, () => this.handleSort())
  }

  handleSort = () => {
    const { sortCurrentColumn, marketCenters, sortCurrentDirection } = this.state;

    let sorted = orderBy(marketCenters, [sortCurrentColumn], [sortCurrentDirection.toLocaleLowerCase()]);
    if (sortCurrentDirection === 'DESC') {
      const nullItems = sorted.filter(i => i[sortCurrentColumn] === null)
      const unNullItems = sorted.filter(i => i[sortCurrentColumn] !== null)
      sorted = [...unNullItems, ...nullItems]
    }

    this.setState({
      marketCenters: sorted,
    })
  };

  noRowsRenderer() {
    return <div />;
  }

  getTableHeight = () => {
    const elem = document.getElementById('table-wrapper');
    let calcHeight = 600

    if (elem) {
      const rect = elem.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      calcHeight = screenHeight - rect.top - 80
    }
    return calcHeight;
  }

  getRowRenderer = props => {
    const { _key, className, columns, index, style } = props;
    const isOdd = index % 2;

    return (
      <TableRow
        key={_key}
        style={{
          ...style,
          backgroundColor: isOdd ? 'white' : '#eeeeee',
          paddingTop: 7,
        }}
      >
        <div className={className}>{columns}</div>
      </TableRow>
    );
  };

  rowGetter = ({ index }) => this.state.marketCenters[index];

  handleApprove = async (marketCenter) => {
    const { dispatch } = this.props;
    const {
      OptInEmail,
      ApprovedEmailSubject,
      ApprovedEmailMsgBody,
      OptInPK,
    } = marketCenter;

    const message = `
      ${ApprovedEmailMsgBody}
      <br/>
    `
    const params = {
      email: 'Support@kellermortgage.com',
      subject: ApprovedEmailSubject,
      message,
      name: 'Keller Mortgage',
      service_email: OptInEmail,
    }
    await sendEmail(params);
    await updateRecruitingMarketCenterReadyForRecruiting(OptInPK);
    await this.fetchData();
    dispatch(showAlert('Email sent successfully.', { variant: 'success', icon: 'bell' }));
  };

  render() {
    const {
      activeTab,
      reportPeriod,
      sortCurrentColumn,
      sortCurrentDirection,
      marketCenters,
    } = this.state;

    return (
      <Fragment>
        <DownloadWrapper>
          <DownloadCSV data={marketCenters} filename={`MarketCenters_${activeTab}`}/>
        </DownloadWrapper>
        <SectionContentWrapper
          title=""
          hActiveTab={activeTab}
          vActiveTab={reportPeriod}
          horizonalTabOptions={HorizontalTabOptions}
          onHorizonalTabChange={this.changeHorizontalTab}
        >
          <Wrapper id="table-wrapper">
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  ref={this._setTableRef}
                  headerHeight={70}
                  noRowsRenderer={this.noRowsRenderer}
                  rowHeight={80}
                  rowGetter={this.rowGetter}
                  rowCount={marketCenters.length}
                  height={this.getTableHeight()}
                  rowRenderer={this.getRowRenderer}
                  width={width}
                  sort={this._sort}
                  sortBy={sortCurrentColumn}
                  sortDirection={sortCurrentDirection}
                >
                  <Column
                    label="Market Center(ID)"
                    dataKey="MarketCenterName"
                    width={200}
                    cellRenderer={({ rowData }) => {
                      const { MarketCenterID, MarketCenterName } = rowData;
                      return `${MarketCenterName} (${MarketCenterID})`;
                    }}
                  />
                  <Column
                    width={200}
                    label={activeTab === TAB.OPT_OUT ? 'Opt-out Person' : 'Opt-in Person'}
                    dataKey="FirstName"
                    cellRenderer={({ rowData }) => {
                      const { FirstName, LastName, OptInKWUID, OptOutKWUID } = rowData;
                      return `${FirstName || ''} ${LastName || ''} (${activeTab === TAB.OPT_OUT ? OptOutKWUID : OptInKWUID})`
                    }}
                  />
                  {(activeTab === TAB.OPT_IN || activeTab === TAB.AWAITING_APPROVAL) ? (
                    <Column
                      width={200}
                      label="Opt-in Email"
                      dataKey="OptInEmail"
                      disableSort
                      cellRenderer={({ cellData }) => cellData}
                    />
                  ) : (
                    <Column
                      width={200}
                      label="Opt-out Email"
                      dataKey="OptOutEmail"
                      disableSort
                      cellRenderer={({ cellData }) => cellData}
                    />
                  )}
                  {activeTab === TAB.OPT_IN && (
                    <Column
                      width={70}
                      label="Size"
                      dataKey="MarketCenterSize"
                      cellRenderer={({ cellData }) => cellData}
                    />
                  )}
                  {(activeTab === TAB.OPT_IN || activeTab === TAB.AWAITING_APPROVAL) ? (
                    <Column
                      width={150}
                      label="Opt-in Date"
                      dataKey="OptInDate"
                      cellRenderer={({ cellData }) => cellData ? formatUTCDate(cellData) : ''}
                    />
                  ) : (
                    <Column
                      width={150}
                      label="Opt-out Date"
                      dataKey="OptOutDate"
                      cellRenderer={({ cellData }) => cellData ? formatUTCDate(cellData) : ''}
                    />
                  )}
                  {(activeTab === TAB.AWAITING_APPROVAL || activeTab === TAB.OPT_OUT) && (
                    <Column
                      width={70}
                      label="Size"
                      dataKey="MarketCenterSize"
                      cellRenderer={({ cellData }) => cellData}
                    />
                  )}
                  {activeTab === TAB.OPT_IN && (
                    <Column
                      width={150}
                      label="Recruiting Ready Date"
                      dataKey="RecruitingReadyDate"
                      cellRenderer={({ cellData }) => cellData ? formatUTCDate(cellData) : ''}
                    />
                  )}
                  {activeTab === TAB.AWAITING_APPROVAL && (
                    <Column
                      width={200}
                      label="Approval"
                      dataKey="OptInPK"
                      disableSort
                      cellRenderer={({ rowData }) => <ActionButton onClick={() => this.handleApprove(rowData)}>Approved</ActionButton>}
                    />
                  )}
                </Table>
              )}
            </AutoSizer>
          </Wrapper>
        </SectionContentWrapper>
      </Fragment>
    )
  };
}

export default withModals({
  ActivityModal,
})(MarketCenters)


const TableRow = styled.div`
  ${({ red }) => red && css`
    color: red;

    button {
      color: red;
    }
  `}
`;

const DownloadWrapper = styled.div`
  height: 0;
  position: relative;

  & > a {
    transform: translateY(0%);
    right: 0;
  }
`;

const Wrapper = styled.div`
  font-size: 1.3rem;

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Table__headerRow {
    overflow: visible !important;
  }

  .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: none;
    }

    .ReactVirtualized__Table__headerTruncatedText {
      white-space: pre-wrap;
      text-align: center;
      overflow: visible !important;
    }

    .ReactVirtualized__Table__sortableHeaderIcon {
      font-size: 22px;
    }
  }

  .ReactVirtualized__Table__rowColumn {
    text-align: center;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .ReactVirtualized__Table__row {
    height: 100%;
  }
`;

const ActionButton = styled.button`
  background: ${({ theme }) => theme.palette.primary };
  padding: 10px 10px;
  color: white;
  border-radius: 3px;
  white-space: nowrap;
  font-weight: bold; 
`;



