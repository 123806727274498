import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getPortalAdminAgentInfoSearchTeam,
  sendPreApprovalLink,
  getPortalAdminBeginLoanProcessLinkTeam,
} from 'modules/api';
import { debounce } from 'lodash';
import RadioBox from 'components/RadioBox';
import CheckBox from 'components/CheckBox';
import Image from 'components/Image';
import NumberFormat from 'react-number-format';
import EmailIcon from 'assets/media/images/email.png';
import PhoneIcon from 'assets/media/images/phone.png';
import AccountCircle from 'assets/media/images/account_circle.png';
import { showAlert } from 'actions/index';
import HttpService from 'modules/http.service';
import withModals from 'modules/withModals';

import KWLogo from 'assets/media/brand/kw-logo.png';
import Environment from '../../environments/env';
import AssignToMyLOModal from './AssignToMyLOModal';
import InviteToMyLOModal from './InviteToMyLOModal';

import {
  StyledDropDown,
  CustomDropdownStyles,
  OptionWrapper,
  TeamDetailWrapper,
  SectionLabel,
  StyledButton,
  AgentList,
  RACWrapper,
  Label,
  RadioForm,
  StyledInput,
  ButtonWrapper,
  ClientOptionsWrapper,
  LabelWrapper,
  ValueWrapper,
  MultipleLineLabel,
  MultipleLineInput,
  TableRow,
} from './styles';

const TeamIcon = require('assets/media/images/team_avatar.png');

class TeamSearch extends Component {
  state = {
    selectedTeam: null,
    inputValue: '',
    searchResult: null,
    emailOrPhone: true,
    clientEmail: '',
    clientName: '',
    clientPhone: '',
    MsgToBorrowerEmail: '',
    MsgToBorrowerSignature: '',
    MsgToBorrowerText: '',
    supportHubLead: false,
  };

  _Environment = new Environment();

  _http = new HttpService();

  onChange = (key, value) => {
    let actualValue = value;
    if (key === 'clientPhone') {
      actualValue = value.value;
    }

    this.setState({ [key]: actualValue });
  };

  handleChangeClientOption = emailOrPhone => () => {
    this.setState({ emailOrPhone });
  };

  handleSelectTeam = selectedTeam => () => {
    const { MsgToBorrowerEmail, MsgToBorrowerText, MsgToBorrowerSignature } = selectedTeam;

    this.setState({
      selectedTeam,
      inputValue: '',
      MsgToBorrowerEmail,
      MsgToBorrowerText,
      MsgToBorrowerSignature,
    });
  };

  loadOptions = debounce((inputValue, callback) => {
    if (inputValue.length < 3) {
      callback([]);
      this.setState({
        selectedTeam: null,
      });
      return;
    }

    getPortalAdminAgentInfoSearchTeam(
      inputValue,
      null,
    )
      .then(res => {
        const searchResult = res.map(el => ({
          ...el,
          label: el.TeamName,
          value: el.TeamID,
        }));
        this.setState({
          searchResult,
          selectedTeam: null,
        });
        callback([]);
      })
      .catch(() => {});
  }, 500);

  handleInputChange = inputValue => {
    this.setState({
      inputValue,
    });
    return inputValue;
  };

  option = props => {
    const { data, label } = props;
    return (
      <OptionWrapper buttonRef={props.innerRef} {...props.innerProps}>
        <Image src={data.Photo} fallbackSrc={TeamIcon} />
        <div>
          <div>{label}</div>
          <div>{`${data.City ? data.City : ''}, ${data.StateCode ? data.StateCode : ''}`}</div>
        </div>
      </OptionWrapper>
    );
  };

  handleClearSelectedTeam = () => {
    this.setState({ selectedTeam: null });
  };

  handleSend = async () => {
    const {
      clientEmail,
      clientName,
      clientPhone,
      selectedTeam,
      emailOrPhone,
      supportHubLead,
      MsgToBorrowerSignature,
      MsgToBorrowerText,
      MsgToBorrowerEmail,
    } = this.state;

    const agentName = selectedTeam.TeamName;
    const agentEmail = 'noreply@kellermortgage.com';

    const payloadForLink = {
      CampaignID: supportHubLead ? '33' : '61',
      PartnerID: 102100,
      TeamID: selectedTeam.TeamID,
      SMSMessageTypeFlag: 1, // use sms link for now
    }

    const res = await getPortalAdminBeginLoanProcessLinkTeam(payloadForLink)
    const preApprovalLink = res.data['']

    const params = {
      clientEmail: emailOrPhone ? clientEmail : null,
      clientName,
      clientPhone: emailOrPhone ? null : clientPhone,
      preApprovalLink,
      agentName,
      agentEmail,
      signature: MsgToBorrowerSignature,
      message: MsgToBorrowerEmail,
      messageText: MsgToBorrowerText,
    };

    sendPreApprovalLink(params)
      .then(() => {
        const { dispatch } = this.props;
        dispatch(
          showAlert('Pre-approval link sent successfully.', { variant: 'success', icon: 'bell' }),
        );
        this.setState({
          clientEmail: '',
          clientName: '',
          clientPhone: '',
        });
      })
      .catch(() => {
        const { dispatch } = this.props;
        dispatch(
          showAlert('Failed to send Pre-approval link.', { variant: 'danger', icon: 'bell' }),
        );
      });
  };

  render() {
    const {
      selectedTeam,
      searchResult,
      inputValue,
      emailOrPhone,
      clientEmail,
      clientName,
      clientPhone,
      supportHubLead,
      MsgToBorrowerEmail,
      MsgToBorrowerSignature,
      MsgToBorrowerText,
    } = this.state;

    const sendDisabled =
      (!clientEmail && emailOrPhone) ||
      ((!MsgToBorrowerEmail || !MsgToBorrowerSignature) && emailOrPhone) ||
      (!emailOrPhone && !MsgToBorrowerText) ||
      (!emailOrPhone && !clientPhone) ||
      !clientName ||
      !selectedTeam;

    return (
      <>
        <StyledDropDown
          styles={CustomDropdownStyles}
          isSearchable
          placeholder=""
          loadOptions={this.loadOptions}
          onInputChange={this.handleInputChange}
          value={null}
          components={{ Option: this.option }}
          noOptionsMessage={() => null}
        />
        {inputValue && inputValue.length > 2 && searchResult && searchResult.length === 0 && (
          <div>No team were found.</div>
        )}
        {searchResult && searchResult.length > 0 && !selectedTeam && (
          <Fragment>
            <AgentList>
              {searchResult.map(data => (
                <OptionWrapper key={data.TeamID} onClick={this.handleSelectTeam(data)}>
                  <Image src={data.Photo} fallbackSrc={TeamIcon} />
                  <div>
                    <div>{data.TeamName}</div>
                    <div>
                      {`${data.TeamLeaderRainmakerFirstName || ''} ${data.TeamLeaderRainmakerLastName || ''}`}
                    </div>
                  </div>
                  <span className="org_logo">
                    <Image src={KWLogo} />
                  </span>
                </OptionWrapper>
              ))}
            </AgentList>
          </Fragment>
        )}
        {selectedTeam && (
          <Fragment>
            <StyledButton onClick={this.handleClearSelectedTeam}>Back</StyledButton>

            <TeamDetailWrapper>
              <Image src={selectedTeam.Photo} fallbackSrc={TeamIcon} />
              <div className="team-right-wrapper">
                <div className="team-name">
                  {`${selectedTeam.TeamName} (${selectedTeam.TeamID})`}
                </div>
                {selectedTeam.TeamLeaderRainmakerKWUID && (
                  <>
                    <div>Team Leader / RainMaker</div>
                    <div>
                      {`${selectedTeam.TeamLeaderRainmakerFirstName} ${selectedTeam.TeamLeaderRainmakerLastName}`}
                    </div>
                    <div>{`KWUID: ${selectedTeam.TeamLeaderRainmakerKWUID}`}</div>
                  </>
                )}
              </div>
            </TeamDetailWrapper>

            <RACWrapper>
              <div>
                <SectionLabel>Refer a client</SectionLabel>
                <TableRow>
                  <LabelWrapper>
                    <Label style={{ flex: 1 }}>Client Name:</Label>
                  </LabelWrapper>
                  <ValueWrapper>
                    <StyledInput
                      icon={AccountCircle}
                      type="text"
                      placeholder="Bob Smith"
                      value={clientName}
                      onChange={e => this.onChange('clientName', e.target.value)}
                    />
                  </ValueWrapper>
                </TableRow>
                <TableRow>
                  <LabelWrapper>
                    <Label>Client</Label>
                    <ClientOptionsWrapper>
                      <RadioForm>
                        <RadioBox
                          value={emailOrPhone}
                          onChange={this.handleChangeClientOption(true)}
                        />
                        <span>Email</span>
                      </RadioForm>
                      <RadioForm>
                        <RadioBox
                          value={!emailOrPhone}
                          onChange={this.handleChangeClientOption(false)}
                        />
                        <span>Phone</span>
                      </RadioForm>
                    </ClientOptionsWrapper>
                  </LabelWrapper>
                  <ValueWrapper>
                    {emailOrPhone ? (
                      <StyledInput
                        icon={EmailIcon}
                        iconWidth={21}
                        iconHeight={20}
                        type="email"
                        value={clientEmail}
                        placeholder="bobsmith@NeedAHouse.com"
                        onChange={e => this.onChange('clientEmail', e.target.value)}
                      />
                    ) : (
                      <NumberFormat
                        icon={PhoneIcon}
                        iconWidth={18}
                        iconHeight={18}
                        type="tel"
                        isNumericString
                        format="+1 (###) ###-####"
                        mask="_"
                        value={clientPhone}
                        placeholder="Mobile Phone#"
                        onValueChange={e => this.onChange('clientPhone', e)}
                        customInput={StyledInput}
                      />
                    )}
                  </ValueWrapper>
                </TableRow>
                <TableRow>
                  <LabelWrapper />
                  <ValueWrapper>
                    {emailOrPhone ? (
                      <Fragment>
                        <MultipleLineLabel>Email Message to your borrower:</MultipleLineLabel>
                        <MultipleLineInput
                          value={MsgToBorrowerEmail || ''}
                          onChange={e => this.onChange('MsgToBorrowerEmail', e.target.value)}
                        />
                        <MultipleLineLabel>Email Signature:</MultipleLineLabel>
                        <MultipleLineInput
                          value={MsgToBorrowerSignature || ''}
                          onChange={e => this.onChange('MsgToBorrowerSignature', e.target.value)}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <MultipleLineLabel>Text Message to your borrower:</MultipleLineLabel>
                        <MultipleLineInput
                          value={MsgToBorrowerText || ''}
                          onChange={e => this.onChange('MsgToBorrowerText', e.target.value)}
                        />
                      </Fragment>
                    )}
                  </ValueWrapper>
                </TableRow>
                <TableRow>
                  <LabelWrapper>
                    <Label style={{ flex: 1 }}>Support Hub Lead:</Label>
                  </LabelWrapper>
                  <ValueWrapper>
                    <CheckBox
                      value={supportHubLead}
                      onChange={value => this.onChange('supportHubLead', value)}
                    />
                  </ValueWrapper>
                </TableRow>
                <ButtonWrapper>
                  <StyledButton disabled={sendDisabled} onClick={this.handleSend}>
                    Send
                  </StyledButton>
                </ButtonWrapper>
              </div>
            </RACWrapper>
          </Fragment>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    deviceInfo: state.app.deviceInfo,
  };
}

export default compose(
  connect(mapStateToProps),
  withModals({
    AssignToMyLOModal,
    InviteToMyLOModal,
  })
)(TeamSearch)
