import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import NotFound from 'routes/NotFound';

import Home from 'routes/Home';
import Admin from 'routes/Admin';
import LoanOfficers from 'routes/LoanOfficers';

import VersionInfo from 'routes/VersionInfo';
import ComingSoon from 'routes/ComingSoon';
import Reports from 'routes/Reports';
import AgentInfo from 'routes/AgentInfo';
import Activity from 'routes/Activity';
import Agents from 'routes/Agents';
import Recruiting from 'routes/Recruiting';
import MyProfile from 'routes/MyProfile';
import Responses from 'routes/Responses';

import SideNavBar from 'containers/SideNavBar';
import RoutePrivate from 'components/RoutePrivate';
import LODashForm from 'routes/LODashForm';

const ContainerWrapper = styled.div`
  width: 100%;
`;

class Private extends Component {
  render() {
    return (
      <Fragment>
        <SideNavBar {...this.props} />
        <ContainerWrapper>
          <Switch>
            <RoutePrivate isAuthenticated path="/private/home" exact component={Home} />
            <RoutePrivate
              isAuthenticated
              path="/private/loan-officers"
              exact
              component={LoanOfficers}
            />
            <RoutePrivate
              isAuthenticated
              path="/private/reports"
              exact
              component={Reports}
            />
            <RoutePrivate
              isAuthenticated
              path="/private/agent-search"
              exact
              component={AgentInfo}
            />  
            <RoutePrivate
              isAuthenticated
              path="/private/activity"
              exact
              component={Activity}
            />  
            <RoutePrivate
              isAuthenticated
              path="/private/activity/:chartType"
              exact
              component={Activity}
            />
            <RoutePrivate
              isAuthenticated
              path="/private/responses"
              exact
              component={Responses}
            />  
            <RoutePrivate
              isAuthenticated
              path="/private/agents/:agentType/:submenu"
              exact
              component={Agents}
            />
            <RoutePrivate
              isAuthenticated
              path="/private/agents/:agentType"
              exact
              component={Agents}
            />
            <RoutePrivate
              isAuthenticated
              path="/private/recruiting"
              exact
              component={Recruiting}
            />
            <RoutePrivate
              isAuthenticated
              path="/private/recruiting/:subMenu"
              exact
              component={Recruiting}
            />
            <RoutePrivate isAuthenticated path="/private/admin/:tab" exact component={Admin} />
            <RoutePrivate
              isAuthenticated
              path="/private/agent-portal-admin-version"
              exact
              component={VersionInfo}
            />
            <RoutePrivate
              isAuthenticated
              path="/private/my-profile"
              exact
              component={MyProfile}
            />
            <RoutePrivate
              isAuthenticated
              path="/private/test-lo-dash"
              exact
              component={LODashForm}
            />
            <RoutePrivate
              isAuthenticated
              path="/private/coming-soon"
              exact
              component={ComingSoon}
            />
            <RoutePrivate
              isAuthenticated
              path="/private/refer-a-client"
              exact
              component={ComingSoon}
            />
            <RoutePrivate isAuthenticated path="/private" exact component={Home} />
            <Route component={NotFound} />
          </Switch>
        </ContainerWrapper>
      </Fragment>
    );
  }
}

export default Private;
