import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';
import DigeryModal from 'components/Modal';
import Image from 'components/Image';

const Modal = styled(DigeryModal)`
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 36px;
      color: ${props => props.theme.palette.primary};
      border-bottom: 3px solid;
    }
  }
`;

const Photo = styled(Image)`
  width: 100%;
`;

export default class PhotoModal extends Component {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
  };

  state = { show: false };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { showModal, data } = this.props;
    if (!data) {
      return <div />;
    }
    const { ProfilePhotoLink } = data;

    return (
      <Modal centered fade={false} isOpen={showModal} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose} />
        <ModalBody>
          <Photo src={ProfilePhotoLink} />
        </ModalBody>
      </Modal>
    );
  }
}
