import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import { range, get } from 'lodash';
import update from 'immutability-helper';
import DigeryModal from 'components/Modal';
import Input from 'components/Input';
import MultiInput from 'components/TextArea';
import RadioBox from 'components/RadioBox';
import Select from 'react-select';
import Button from 'components/Button';
import { addUpdatePortalAdminNotifications } from 'modules/api';
import { showAlert } from 'actions';

const Modal = styled(DigeryModal)`
  max-width: 600px;

  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
`;

const Row = styled.div`
  display: flex;
  margin: auto;
  margin-bottom: 7px;
  font-size: 1.4rem;
  width: 80%;
`;

const TextArea = styled(MultiInput)`
  min-height: 60px;
`;

const Label = styled.div`
  width: 30%;
  text-align: right;
  padding-right: 20px;
  align-items: center;
  justify-content: flex-end;
  display: flex;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
`;

const RadioLabel = styled.span`
  margin-left: 4px;
  margin-right: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 3.5rem;
  flex-direction: column;
  align-items: center;
`;

const StyledSelect = styled(Select)`
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  box-shadow: none;
  border-radius: 3px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 4rem;
    min-width: 100%;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '0px 8px',
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    '& > div': {
      padding: '0 0px',
    },
  }),
};

const FrequencyOptions = ['Hourly', 'Daily', 'Weekly', 'Monthly'].map((label, index) => ({
  label,
  value: index,
}));

const TimeOptions = ['8AM', '10AM', '4PM'].map(label => ({
  label,
  value: label,
}));

const DayOptions = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
].map((label, index) => ({
  label,
  value: index + 1,
}));

const DateOptions = range(1, 32).map((label, index) => ({
  label,
  value: index + 1,
}));

export default class LoanOfficerModal extends Component {
  static propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    data: {},
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.initState();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.initState();
    }
  }

  initState = () => {
    const { Status, CreatedDate, DeliveryDayofWeek, Frequency, ...rest } = this.props.data;
    let data = {
      Status: 1,
    };
    if (this.props.data.PK) {
      data = {
        ...rest,
        Status: Status === 'Active' ? 1 : 0,
        DeliveryDayOfWeek: get(DayOptions.find(o => o.label === DeliveryDayofWeek), 'value'),
        Frequency: get(
          FrequencyOptions.find(o => o.label === Frequency),
          'value',
          0,
        ),
      };
    }
    this.setState({
      data,
    });
  };

  handleClose = () => {
    this.props.onClose();
  };

  onChange = key => e => {
    const data = update(this.state.data, {
      [key]: { $set: e.target.value },
    });
    this.setState({ data });
  };

  handleChange = (key, value) => {
    const data = update(this.state.data, {
      [key]: { $set: value },
    });
    this.setState({ data });
  };

  handleAddNotification = () => {
    const { dispatch, onUpdate } = this.props;
    addUpdatePortalAdminNotifications(this.state.data)
      .then(res => {
        dispatch(showAlert('Saved successfully.', { variant: 'success', icon: 'bell' }));
        onUpdate();
        this.handleClose();
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { isOpen } = this.props;
    const { data } = this.state;
    const buttonDisabled =
      data.RecipientDeliveryType === undefined ||
      (data.Frequency === 1 && !data.DeliveryTime) ||
      (data.Frequency === 2 && (!data.DeliveryTime || !data.DeliveryDayOfWeek)) ||
      (data.Frequency === 3 && (!data.DeliveryTime || !data.DeliveryDayOfWeek || !data.DeliveryDayofMonth)) ||
      !data.DeliveryMessage ||
      !data.Description ||
      data.Frequency === undefined ||
      !data.Name;

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={700}>
        <ModalHeader toggle={this.handleClose}>
          {`${data.PK ? 'Update' : 'Add'} Notification`}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Label>Notification Name</Label>
            <Value>
              <Input
                placeholder="Notification Name"
                value={data.Name}
                onChange={this.onChange('Name')}
              />
            </Value>
          </Row>
          <Row>
            <Label>Notification Description</Label>
            <Value>
              <TextArea
                placeholder="Notification Description"
                value={data.Description}
                onChange={this.onChange('Description')}
              />
            </Value>
          </Row>
          <Row>
            <Label>Frequency</Label>
            <Value>
              <StyledSelect
                styles={CustomDropdownStyles}
                options={FrequencyOptions}
                onChange={option => this.handleChange('Frequency', option.value)}
                value={FrequencyOptions.find(o => o.value === data.Frequency)}
              />
            </Value>
          </Row>
          <Row>
            <Label>Day of Month</Label>
            <Value>
              <StyledSelect
                isDisabled={data.Frequency !== 3}
                styles={CustomDropdownStyles}
                options={DateOptions}
                onChange={option => this.handleChange('DeliveryDayofMonth', option.value)}
                value={DateOptions.find(o => o.value === data.DeliveryDayofMonth)}
              />
            </Value>
          </Row>
          <Row>
            <Label>Delivery Time</Label>
            <Value>
              <StyledSelect
                isDisabled={data.Frequency === 0}
                styles={CustomDropdownStyles}
                options={TimeOptions}
                onChange={option => this.handleChange('DeliveryTime', option.value)}
                value={TimeOptions.find(o => o.value === data.DeliveryTime)}
              />
            </Value>
          </Row>
          <Row>
            <Label>Delivery Day Of Week</Label>
            <Value>
              <StyledSelect
                isDisabled={data.Frequency !== 2}
                styles={CustomDropdownStyles}
                options={DayOptions}
                onChange={option => this.handleChange('DeliveryDayOfWeek', option.value)}
                value={DayOptions.filter(o => o.value === data.DeliveryDayOfWeek)}
              />
            </Value>
          </Row>
          <Row>
            <Label>Delivery Subject</Label>
            <Value>
              <Input
                placeholder="Delivery Subject"
                value={data.DeliverySubject}
                onChange={this.onChange('DeliverySubject')}
              />
            </Value>
          </Row>
          <Row>
            <Label>Delivery Message</Label>
            <Value>
              <TextArea
                placeholder="Delivery Message"
                value={data.DeliveryMessage}
                onChange={this.onChange('DeliveryMessage')}
              />
            </Value>
          </Row>
          <Row>
            <Label>DB Stored Procedure</Label>
            <Value>
              <Input
                placeholder="DB Stored Procedure"
                value={data.DBStoredProcedureMessageContent}
                onChange={this.onChange('DBStoredProcedureMessageContent')}
              />
            </Value>
          </Row>
          <Row>
            <Label>Recipient Delivery Type</Label>
            <Value>
              <RadioBox
                value={data.RecipientDeliveryType === 1}
                onChange={() => this.handleChange('RecipientDeliveryType', 1)}
              />
              <RadioLabel>General</RadioLabel>
              <RadioBox
                value={data.RecipientDeliveryType === 2}
                onChange={() => this.handleChange('RecipientDeliveryType', 2)}
              />
              <RadioLabel>Recipient Specific</RadioLabel>
            </Value>
          </Row>
          <Row>
            <Label>Status</Label>
            <Value>
              <RadioBox value={data.Status === 1} onChange={() => this.handleChange('Status', 1)} />
              <RadioLabel>Active</RadioLabel>
              <RadioBox value={data.Status === 0} onChange={() => this.handleChange('Status', 0)} />
              <RadioLabel>Inactive</RadioLabel>
            </Value>
          </Row>

          <ButtonWrapper>
            <Button disabled={buttonDisabled} onClick={this.handleAddNotification}>
              Save
            </Button>
          </ButtonWrapper>
        </ModalBody>
      </Modal>
    );
  }
}
