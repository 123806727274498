import styled from 'styled-components';
import { Input } from 'styled-minimal';

const TextInput = styled(Input)`
  border: #c4cdd5 1px solid;
  border-radius: 3px;
  font-size: 1.4rem;
  min-height: 4rem;
  background: ${props => (props.icon ? `url(${props.icon})` : 'none')} no-repeat;
  text-indent: ${props => (props.icon ? '26px' : '0')};
  background-position: left 5px center;
  background-size: ${props => (props.iconWidth ? props.iconWidth : 22)}px
    ${props => (props.iconHeight ? props.iconHeight : 22)}px;
  &:focus {
    outline-width: 1px;
  }
`;

export default TextInput;
