import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import Image from 'components/Image';
const LogoIcon = require('assets/media/images/tree_logo.png');
import { formatPhoneNumber } from 'modules/helpers';
import Icon from 'components/Icon';

class LoanOfficerPreviewModal extends Component {
  static propTypes = {
    loanOfficer: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.fetchLoanOfficer()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.fetchLoanOfficer()
    }
  }

  fetchLoanOfficer = () => {
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, loanOfficer } = this.props;
    const {
      ProfilePhotoLink,
      FirstName,
      LastName,
      Position,
      PhoneMobile,
      PhoneWork,
      Email,
      LoanOfficerNMLS,
      YearsInBusiness,
      Languages,
      LicensedStates,
      StateCode,
      PersonalBio,
      PersonalWhyYouShouldWorkWithMe,
      PhoneMobileDispalyFlag
    } = loanOfficer

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={700}>
        <ModalBody>
          <TopHeader>
            <p>{`${FirstName} ${LastName}`}</p>
            <TreeLogo src={LogoIcon}/>
            <CloseButton onClick={this.handleClose}><Icon name="times" color="white"/></CloseButton>
          </TopHeader>
          <Body>
            <LeftColumn>
              <Role>{Position}</Role>
              <DetailsTable>
                <tbody>
                  <tr>
                    <td>Office&nbsp;:</td>
                    <td>&nbsp;{PhoneWork ? formatPhoneNumber(PhoneWork) : ''}</td>
                  </tr>
                  {!PhoneMobileDispalyFlag && (
                    <tr>
                      <td>Cell&nbsp;:</td>
                      <td>&nbsp;{PhoneMobile ? formatPhoneNumber(PhoneMobile) : ''}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Email&nbsp;:</td>
                    <td>&nbsp;{Email}</td>
                  </tr>
                  <tr>
                    <td>NMLS#&nbsp;:</td>
                    <td>&nbsp;{LoanOfficerNMLS}</td>
                  </tr>
                </tbody>
              </DetailsTable>
              <Block>
                <LeftLabel>BIO</LeftLabel>
                <RightValues>
                  <Text>{PersonalBio || ''}</Text>
                </RightValues>
              </Block>
              <Block>
                <LeftLabel>Why you <br/>should<br/> work <br/>with me</LeftLabel>
                <RightValues>
                  <Text>{PersonalWhyYouShouldWorkWithMe || ''}</Text>
                </RightValues>
              </Block>
            </LeftColumn>
            <RightColumn>
              <Photo src={ProfilePhotoLink} />
              <ContainedLabel>Licensed states</ContainedLabel>
              <ValueText>{LicensedStates}</ValueText>
              <ContainedLabel>Language(s)</ContainedLabel>
              <ValueText>{Languages}</ValueText>
              <ContainedLabel>State of Residence</ContainedLabel>
              <ValueText>{StateCode}</ValueText>
              <ContainedLabel>Years in business</ContainedLabel>
              <ValueText>{YearsInBusiness !== null? `${YearsInBusiness} years` : 'N/A'}</ValueText>
            </RightColumn>
          </Body>
        </ModalBody>
      </Modal>
    );
  }
}

export default LoanOfficerPreviewModal


const Modal = styled(DigeryModal)`
  max-width: 800px;
  padding: 0;

  .modal-content {
    padding: 0
  }

  .modal-body {
    padding: 0;
    max-height: 800px;
    overflow: auto;
    text-align: center;
  }
`;

const TopHeader = styled.div`
  height: 80px;
  background: ${props => props.theme.palette.gray};
  border-bottom: 5px ${props => props.theme.palette.primary} solid;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  & p {
    color: white;
    margin-right: 17rem;
    text-align: right;
    text-transform: uppercase;
    font-size: 4rem;
    font-weight: bold;
    padding: 0;
    line-height: 1.2;
    margin-bottom: 0;
  }
`;

const CloseButton = styled.button`
  top: 11px;
  right: 9px;
  position: absolute;
`

const Body = styled.div`
  display: flex;
`;

const LeftColumn = styled.div`
  flex: 1;
  text-align: right;
  padding-right: 2rem;
`;

const RightColumn = styled.div`
  width: 16rem;
`;

const Photo = styled(Image)`
  width: 100%;
  margin-top: -40px;
  object-fit: cover;
  margin-bottom: 3rem;
  height: 20rem;
`;

const TreeLogo = styled(Image)`
  position: absolute;
  left: 0;
  top: 40px;
  width: 9rem;
`;

const Role = styled.div`
  font-size: 2.8rem;
  font-weight: 500;
  margin: 0.5rem 0 1rem;
`;

const DetailsTable = styled.table`
  margin-left: auto;
  margin-bottom: 2rem;

  tr {
    & td {
      color: ${props => props.theme.palette.gray};
      font-weight: 500;
      text-align: right;
      font-size: 1.4rem;
      padding-left: 1rem;
    }

    & td:nth-child(2) {
      text-align: left;
      white-space: nowrap;
    }
  }
`;

const ContainedLabel = styled.div`
  background-color: ${props => props.theme.palette.gray};
  color: white;
  padding: 5px 10px;
  font-size: 1.3rem;
  font-weight: bold;
  width: fit-content;
  margin-bottom: 0.8rem;
`;

const ValueText = styled.div`
  color: ${props => props.theme.palette.gray};
  font-weight: 500;
  padding: 0 2rem 2rem 1rem;
  font-size: 1.3rem;
  text-align: left;
  letter-spacing: 0.8px;
`;

const Block = styled.div`
  display: flex;
  border-top: 1px solid ${props => props.theme.palette.gray};
  padding: 2rem 1rem;
`;

const LeftLabel = styled.div`
  min-width: 15rem;
  color: ${props => props.theme.palette.gray};
  text-align: right;
  padding-right: 1.5rem;
  min-height: 8rem;
  max-height: 18rem;
  font-size: 2.1rem;
  font-weight: 500;
  border-right: 2px solid ${props => props.theme.palette.primary};
  line-height: 1.2;
  text-transform: uppercase;
`;

const RightValues = styled.div`
  padding-left: 1.5rem;
`
const Text = styled.p`
  font-size: 1.3rem;
  padding-bottom: 1rem;
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
`; 
