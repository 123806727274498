import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import { 
  getRecruitingInPoolAgents,
  getRecruitingInRecruitingAgents,
  getRecruitingClosedAgents,
  getRecruitingAssignedAgents,
  getRecruitingDashboardStatsMC,
  getRecruitingUnassignedAgents,
} from 'modules/api';
import { Table } from 'reactstrap';
import history from 'modules/history';
import { formatDate } from 'modules/helpers';
import DownloadCSV from 'components/DownloadCSV';
import { Flex, Box } from 'styled-minimal';
import { percentFormatter } from 'modules/helpers';
import withModals from 'modules/withModals';
import ClosedAdverseModal from '../RecruiterDashboard/ClosedAdverseModal';
import { formatUTCDate } from 'modules/helpers';

class MarketCenterDetailsModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    stats: {},
    poolAgents: [],
    recruitingAgents: [],
    closedAgents: [],
    assignedAgents: [],
    unAssignedAgents: [],
    fetching: true
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { period, startDate, endDate, marketCenter } = this.props
    const { MarketCenterRefID } = marketCenter

    getRecruitingDashboardStatsMC(null, null, period, startDate, endDate, MarketCenterRefID).then(res => {
      this.setState({ 
        stats: res.result[0],
      });
    })

    getRecruitingInPoolAgents(null, startDate, endDate, period, null, null, MarketCenterRefID).then(res => {
      this.setState({ 
        poolAgents: res.result,
      });
    })

    getRecruitingInRecruitingAgents(null, startDate, endDate, period, null, MarketCenterRefID).then(res => {
      this.setState({ 
        recruitingAgents: res.result,
      });
    })

    getRecruitingClosedAgents(null, startDate, endDate, period, null, MarketCenterRefID).then(res => {
      this.setState({ 
        closedAgents: res.result,
      });
    })

    getRecruitingAssignedAgents(null, null, startDate, endDate, period, MarketCenterRefID).then(res => {
      this.setState({ 
        assignedAgents: res.result,
      });
    })

    getRecruitingUnassignedAgents(null, startDate, endDate, period, MarketCenterRefID).then(res => {
      this.setState({ 
        unAssignedAgents: res.result,
      });
    })
   };

  handleClose = () => {
    this.props.onClose();
  };

  handleShowClosedAdverse = (recruiterID) => {
    const { period, startDate, endDate } = this.props
    this.props.openModal('ClosedAdverseModal', {
      recruiterID, period, startDate, endDate
    })
  }

  getCSVData = () => {
    const { poolAgents, recruitingAgents, closedAgents, assignedAgents, unAssignedAgents } = this.state;

    const notStarted = unAssignedAgents.map(a => ({ Stage: 'Not Started', ...a }))
    const inProgress = recruitingAgents.map(a => ({ Stage: 'In Progress', ...a }))
    const inPool = poolAgents.map(a => ({ Stage: 'In Pool', ...a }))
    const recruited = assignedAgents.map(a => ({ Stage: 'Recruited', ...a }))
    const closed = closedAgents.map(a => ({ Stage: 'Closed', ...a }))

    return [...notStarted, ...inProgress, ...inPool, ...recruited, ...closed]
  }

  render() {
    const { isOpen, marketCenter, startDate, endDate } = this.props;
    const { poolAgents, stats, recruitingAgents, closedAgents, assignedAgents, unAssignedAgents } = this.state;
    const { MarketCenterName, MarketCenterID, OptInLeader } = marketCenter || {};

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>
          {`${MarketCenterName} (${MarketCenterID}) - ${OptInLeader}`}
        </ModalHeader>
        <DownloadWrapper>
          <DownloadCSV data={this.getCSVData()} filename={`Agents`}/>
        </DownloadWrapper>
        <ModalBody>
          <DateRangeView>{`${formatDate(startDate)} ~ ${formatDate(endDate)}`}</DateRangeView>
          <Flex>
            <Box>
              <SubTitle>Agents</SubTitle>
              <Text>Participating: {stats.AgentsParticipating}</Text>
              <Text>Called: {stats.AgentsCalled} ({percentFormatter(stats.AgentsCalledPercent)})</Text>
              <Text>Made Contact: {stats.AgentsMadeContact} ({percentFormatter(stats.AgentsMadeContactPercent)})</Text>
              <Text>Assigned to LO: {stats.AgentsAssignedToLO} ({percentFormatter(stats.AgentsAssignedToLOPercent)})</Text>
              <Text>Closed: {stats.AgentsClosed} ({percentFormatter(stats.AgentsClosedPercent)})</Text>
              {/* <LinkText onClick={() => this.handleShowClosedAdverse(null)}>
                Closed: {stats.AgentsClosed} ({percentFormatter(stats.AgentsClosedPercent)})
              </LinkText> */}
              <Spacer/>
              <Text>My LO Invite: {stats.AgentsMyLOInvites} ({percentFormatter(stats.AgentsMyLOInvitesPercent)})</Text>
              <Text>My LO: {stats.AgentsMyLO} ({percentFormatter(stats.AgentsMyLOPercent)})</Text>
            </Box>
            <Box>
              <SubTitle>Production</SubTitle>
              <Text>Leads In: {stats.LeadsReceived}</Text>
              <Text>Deals Closed: {stats.DealsClosed}</Text>
            </Box>
          </Flex>
          <SnapshotTable>
              <thead>
                <tr>
                  <th>Assigned Date</th>
                  <th>KWUID</th>
                  <th>Agent Name</th>
                  <th>Email</th>
                  <th>Mobile Phone</th>
                  <th>Agent Create Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <WhiteTd colSpan={6}>Not Started</WhiteTd>
                </tr>
                {unAssignedAgents.map((row, index) => 
                  <tr>
                    <td>{row.AssignmentDate ? formatUTCDate(row.AssignmentDate) : ''}</td>
                    <td>{row.KWUID}</td>
                    <td>{`${row.Firstname} ${row.Lastname}`}</td>
                    <td>{row.Email}</td>
                    <td>{row.MobilePhoneNumber}</td>
                    <td>{row.CreatedDate ? formatUTCDate(row.CreatedDate) : ''}</td>
                  </tr>
                )}
                <tr>
                  <WhiteTd colSpan={6}>In Progress</WhiteTd>
                </tr>
                {recruitingAgents.map((row, index) => 
                  <tr>
                    <td>{row.AssignmentDate ? formatUTCDate(row.AssignmentDate) : ''}</td>
                    <td>{row.KWUID}</td>
                    <td>{`${row.Firstname} ${row.Lastname}`}</td>
                    <td>{row.Email}</td>
                    <td>{row.MobilePhoneNumber}</td>
                    <td>{row.CreatedDate ? formatUTCDate(row.CreatedDate) : ''}</td>
                  </tr>
                )}
                <tr>
                  <WhiteTd colSpan={6}>In Pool</WhiteTd>
                </tr>
                {poolAgents.map((row, index) => 
                  <tr>
                    <td>{row.AssignmentDate ? formatUTCDate(row.AssignmentDate) : ''}</td>
                    <td>{row.KWUID}</td>
                    <td>{`${row.Firstname} ${row.Lastname}`}</td>
                    <td>{row.Email}</td>
                    <td>{row.MobilePhoneNumber}</td>
                    <td>{row.CreatedDate ? formatUTCDate(row.CreatedDate) : ''}</td>
                  </tr>
                )}
                <tr>
                  <WhiteTd colSpan={6}>Recruited</WhiteTd>
                </tr>
                {assignedAgents.map((row, index) => 
                  <tr>
                    <td>{row.AssignmentDate ? formatUTCDate(row.AssignmentDate) : ''}</td>
                    <td>{row.KWUID}</td>
                    <td>{`${row.Firstname} ${row.Lastname}`}</td>
                    <td>{row.Email}</td>
                    <td>{row.MobilePhoneNumber}</td>
                    <td>{row.CreatedDate ? formatUTCDate(row.CreatedDate) : ''}</td>
                  </tr>
                )}
                <tr>
                  <WhiteTd colSpan={6}>Closed</WhiteTd>
                </tr>
                {closedAgents.map((row, index) => 
                  <tr>
                    <td>{row.AssignmentDate ? formatUTCDate(row.AssignmentDate) : ''}</td>
                    <td>{row.KWUID}</td>
                    <td>{`${row.Firstname} ${row.Lastname}`}</td>
                    <td>{row.Email}</td>
                    <td>{row.MobilePhoneNumber}</td>
                    <td>{row.CreatedDate ? formatUTCDate(row.CreatedDate) : ''}</td>
                  </tr>
                )}
              </tbody>
            </SnapshotTable>
        </ModalBody>
      </Modal>
    );
  }
}

export default 
  withModals({ 
    ClosedAdverseModal,
  })(MarketCenterDetailsModal)

const Modal = styled(DigeryModal)`
  max-width: 1000px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    height: 600px;
    overflow: auto;
    position: relative;
  }
  .modal-body {
    overflow: auto;
  }
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  padding: 15px 0 0;
`;

const Text = styled.div`
  font-size: 1.4rem;
  padding: 2px 0;
`;

const LinkText = styled.div`
  font-size: 1.4rem;
  padding: 2px 0;
  cursor: pointer;
  text-decoration: underline;
`;

const Spacer = styled.div`
  height: 1.5rem;
`

const DownloadWrapper = styled.div`
  height: 0;
  position: absolute;
  right: 64px;
  top: 30px;

  & > a {
    transform: translateY(0%);
    right: 0;
  }
`;

const SnapshotTable = styled(Table)`
  margin-top: 20px;

  &&& {
    font-size: 1.2rem;

    tr {
      border: none;
    }

    tfoot td, th {
      text-align: center;
      height: 30px;
      vertical-align: middle;
      background: #ddd;
      border: none;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    th {
      padding: 2px;
    }

    td {
      text-align: center;
      height: 30px;
      vertical-align: middle;
      border: none;
      white-space: pre-wrap;
      word-break: break-all;
      padding: 2px;

      a {
        text-decoration: underline;
        color: black;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

const WhiteTh = styled.th`
  background: white !important;
  padding: 4px !important;
`;

const WhiteTd = styled.td`
  background: white !important;
  padding-top: 15px !important;
  text-align: left !important;
  font-weight: bold;
  height: 50px !important;
`;

const LinkButton = styled.button`
  text-decoration: underline;
  margin: 0 2px;
  white-space: nowrap;
`;

const DateRangeView = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 20px;
`;
