import { css } from 'styled-components';

export const headerHeight = 70;
export const mobileHeaderHeight = 70;

export const MobileWidth = 1025;

export const appColor = '#20a85f';

export const easing = 'cubic-bezier(0.35, 0.01, 0.77, 0.34);';

export default {
  breakpoints: [400, 768, 1024, 1280, 1920],
  palette: {
    primary: appColor,
    second: '#4D4D4F',
    third: '#009DC3',
    fourth: '#5EA8D1',
    primaryLight: appColor,
    secondLight: '#D7D7D7',
    thirdLight: '#75C8DC',
    fourthLight: '#1BA9CB',
    dark: '#343434',
    light: '#4D4D4F',
    gray: '#1a2236',
    oldgray: '#637288',
    darkText: '#485A75',
    border: '#c4cdd5',
  },
  button: {
    borderRadius: {
      xs: 4,
      lg: 28,
      xl: 32,
    },
    padding: {
      lg: [12, 28],
      xl: [14, 32],
    },
  },
};

export const media = {
  handheld: (...args) => css`
    @media (max-width: 575.98px) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (max-width: 1024px) and (min-width: 576px) {
      ${css(...args)}
    }
  `,
  mobile: (...args) => css`
    @media (max-width: 1024px) {
      ${css(...args)}
    }
  `,
};
