import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import RadioBox from 'components/RadioBox';
import Chart from '../../components/Chart';
import moment from 'moment';
import { sortBy } from 'lodash';
import { 
  getActivityEventsByType,
} from 'modules/api';
import { media } from 'modules/theme';
import DownloadCSV from 'components/DownloadCSV';


const Block = styled.div`
  padding: 2rem;
  margin: 2rem;
  background: white;
  flex: 1;

  ${media.mobile`
    padding: 0;
  `};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: .3rem 0;
`;

const UsersFilterRow = styled.div`
  display: flex;
  align-items: center;
  padding: .3rem 0;
  flex-wrap: wrap;
`;

const RadioLabel = styled.span`
  margin-left: .4rem;
  margin-right: 2.0rem;
  font-size: 1.5rem;
`;

const DateRange = styled.div`
  margin-right: 3.0rem;

  span {
    width: 8.1rem;
    margin-right: 1.0rem;
  }
`;

const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 2.0rem;
  color: ${props => props.theme.palette.primary};
  font-size: 1.8rem;
  position: relative;
`;

class SOSChart extends Component {
  state = {
    activityUserLogins: [],
    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    periodType: 1,
    serverData: []
   }

  componentDidMount() {
    this.handleGetData()
  }

  handleGetData = () => {
    const { startDate, endDate, periodType } = this.state;
    
    getActivityEventsByType(startDate, endDate, periodType)
      .then(res => {
        const serverData = res.result;
        this.setState({ serverData });

        if (serverData.length === 0) {
          this.setState({ activityUserLogins: [] });
          return
        }

        if (periodType === 2) {
          const activityUserLogins = sortBy(serverData, ['Period']).map(s => ({
            date: s['Period'],
            'RAC Sent': s['RAC Sent'],
            'SOS Requested': s['SOS Requested']
          }))
          this.setState({ activityUserLogins })
          return;
        }

        const dateFormat = 'YYYY-MM-DD';

        const formattedServer = serverData.map(d => ({
          ...d,
          Period: moment(d.Period).utc().format(dateFormat)
        }))

        const data = [];
        let day;
        let lastDay;

        if (startDate) {
          day = moment(startDate)
        } else {
          day = moment(serverData[0].Period);
        }

        if (endDate) {
          lastDay = moment(endDate)
        } else {
          lastDay = moment(serverData[serverData.length - 1].Period);
        }

        let date = moment(day).utc().format(dateFormat)
      
        while (lastDay.utc().format(dateFormat) >= date) {
          const correspondDatapoint = formattedServer.find(fs => fs.Period === date) || {};
  
          data.push({
            date: moment(date).format('MMM-DD'),
            'RAC Sent': correspondDatapoint['RAC Sent'] || 0,
            'SOS Requested': correspondDatapoint['SOS Requested'] || 0,
          });
          day = day.utc().clone().add(1, 'days');
          date = moment(day).utc().format(dateFormat)
        }

        this.setState({ activityUserLogins: data })
      }).catch((error) => {
        console.log(error);
      });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.handleGetData()
    });
  }

  render() {
    const { periodType, startDate, endDate, activityUserLogins, serverData } = this.state;
    return (
      <Block>
        <SectionTitle>
          Events by Type
          <DownloadCSV data={serverData} filename='Events by Type'/>
        </SectionTitle>
        <UsersFilterRow>
            <DateRange>
              <Row>
                <RadioLabel>Start Date</RadioLabel>
                <input 
                  placeholder="Start Date"
                  type="date" 
                  value={startDate} 
                  onChange={e => this.handleChange('startDate', e.target.value || null)}
                />
              </Row>
              <Row>
                <RadioLabel>End Date</RadioLabel>
                <input 
                  placeholder="End Date"
                  type="date" 
                  value={endDate} 
                  onChange={e => this.handleChange('endDate', e.target.value || null)}
                />
              </Row>
            </DateRange>
            <div>
              <Row>
                <RadioBox
                  value={periodType === 1}
                  onChange={() => this.handleChange('periodType', 1)}
                />
                <RadioLabel>Day</RadioLabel>
              </Row>
              <Row>
                <RadioBox
                  value={periodType === 2}
                  onChange={() => this.handleChange('periodType', 2)}
                />
                <RadioLabel>Month</RadioLabel>
              </Row>
            </div>
        </UsersFilterRow>
        <Chart type='line' data={activityUserLogins} dataKeys={['RAC Sent', 'SOS Requested']} height={500} showLegend/>
      </Block>
    )
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(SOSChart);