import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  getPortalAdminLOMyAgents,
} from 'modules/api';
import CheckBox from 'components/CheckBox';
import { orderBy, isEqual } from 'lodash';
import SectionContentWrapper from 'components/TabWrapper';
import { formatUTCDate } from 'modules/helpers';
import DownloadCSV from 'components/DownloadCSV';

import 'react-virtualized/styles.css';
import { AutoSizer, Table, Column } from 'react-virtualized';
import AgentModal from './AgentModal';

const HorizontalTabOptions = [
  { display: 'Primary Partners', value: 1 },
  { display: 'Backup Partners', value: 2 },
  { display: 'Past Partners', value: 3 },
];

const VerticalTabOptions = [
  { display: 'Last Mo', value: 1 },
  { display: '3 Months', value: 3 },
  { display: 'Last Yr', value: 12 },
  { display: 'All Time', value: 13 },
  { display: 'Custom', value: '' },
];

class MyAgents extends Component {
  _setTableRef = ref => {
    this._table = ref;
  };

  state = {
    selectedLO: null,
    reportPeriod: VerticalTabOptions[1].value,
    horizontalTab: 1,
    startDate: moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    myAgents: [],
    sortCurrentColumn: 'MyLOStartDate',
    sortCurrentDirection: 'DESC',
    checkedAgents: [],
    selectedAgent: {},
    showAgentModal: false,
  };

  componentDidMount() {
    if (this.props.selectedLO) {
      this.handleChangeLO(this.props.selectedLO);
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.selectedLO, prevProps.selectedLO)) {
      this.handleChangeLO(this.props.selectedLO);
    }
  }

  handleChangeLO = selectedLO => {
    this.setState({ selectedLO }, () => {
      this.fetchData();
    });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.fetchData();
    });
  };

  fetchData = () => {
    const { selectedLO, horizontalTab, reportPeriod, startDate, endDate } = this.state;

    if (!selectedLO) {
      return;
    }

    getPortalAdminLOMyAgents(selectedLO.LOID, horizontalTab, startDate, endDate, reportPeriod)
      .then(
        res => {
          this.setState(
            {
              myAgents: res.result,
              checkedAgents: [],
            },
            () => {
              this.handleSort();
            },
          );
        },
      ).catch(() => {});
  };

  changeHorizontalTab = horizontalTab => {
    this.setState(
      {
        horizontalTab,
      },
      () => {
        this.fetchData();
      },
    );
  };

  changeVerticalTab = reportPeriod => {
    this.setState(
      {
        reportPeriod,
      },
      () => {
        this.fetchData();
      },
    );
  };

  _sort = ({ sortBy, sortDirection }) => {
    this.setState(
      {
        sortCurrentColumn: sortBy,
        sortCurrentDirection: sortDirection,
      },
      () => this.handleSort(),
    );
  };

  handleSort = () => {
    const { sortCurrentColumn, myAgents, sortCurrentDirection } = this.state;

    let sorted = orderBy(myAgents, [sortCurrentColumn], [sortCurrentDirection.toLocaleLowerCase()]);
    if (sortCurrentDirection === 'DESC') {
      const nullItems = sorted.filter(i => i[sortCurrentColumn] === null);
      const unNullItems = sorted.filter(i => i[sortCurrentColumn] !== null);
      sorted = [...unNullItems, ...nullItems];
    }

    this.setState({
      myAgents: sorted,
    });
  };

  handleCheckChange = (KWUID, value) => {
    const { myAgents, checkedAgents } = this.state;

    if (KWUID === 'all') {
      this.setState({
        checkedAgents: value ? myAgents.map(a => a.KWUID) : [],
      });
    } else if (value) {
      if (!checkedAgents.includes(KWUID)) {
        this.setState({ checkedAgents: [...checkedAgents, KWUID] });
      }
    } else if (checkedAgents.includes(KWUID)) {
      this.setState({ checkedAgents: checkedAgents.filter(a => a !== KWUID) });
    }
  };

  handleShowAgent = selectedAgent => () => {
    this.setState({
      selectedAgent,
      showAgentModal: true,
    });
  };

  noRowsRenderer() {
    return <div />;
  }

  getTableHeight = () => {
    const elem = document.getElementById('table-wrapper');
    let calcHeight = 600;

    if (elem) {
      const rect = elem.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      calcHeight = screenHeight - rect.top - 50;
    }
    return calcHeight;
  };

  getRowRenderer = props => {
    const { _key, className, columns, index, style, rowData } = props;
    const isOdd = index % 2;

    return (
      <div
        key={_key}
        style={{ ...style, backgroundColor: isOdd ? 'white' : '#eeeeee', paddingTop: 7 }}
      >
        <div className={className}>{columns}</div>
      </div>
    );
  };

  rowGetter = ({ index }) => this.state.myAgents[index];

  render() {
    const { selectedLO, allLoanOfficers } = this.props;
    const {
      horizontalTab,
      reportPeriod,
      startDate,
      endDate,
      sortCurrentColumn,
      sortCurrentDirection,
      myAgents,
      checkedAgents,
      selectedAgent,
      showAgentModal,
    } = this.state;

    const emails = [];
    checkedAgents.forEach(kwuid => {
      const agent = myAgents.find(a => a.KWUID === kwuid);
      if (agent && agent.Email) {
        emails.push(agent.Email);
      }
    });

    return (
      <>
        <DownloadWrapper>
          <DownloadCSV data={myAgents} filename={`MyAgents`}/>
        </DownloadWrapper>
        <SectionContentWrapper
          title=""
          hActiveTab={horizontalTab}
          vActiveTab={reportPeriod}
          horizonalTabOptions={HorizontalTabOptions}
          onHorizonalTabChange={this.changeHorizontalTab}
          verticalTabOptions={VerticalTabOptions}
          onVerticalTabChange={this.changeVerticalTab}
        >
          {reportPeriod === '' && (
            <DateRange>
              <Row>
                <RadioLabel>Start Date</RadioLabel>
                <input
                  placeholder="Start Date"
                  type="date"
                  value={startDate}
                  onChange={e => this.handleChange('startDate', e.target.value || null)}
                />
              </Row>
              <Row>
                <RadioLabel>End Date</RadioLabel>
                <input
                  placeholder="End Date"
                  type="date"
                  value={endDate}
                  onChange={e => this.handleChange('endDate', e.target.value || null)}
                />
              </Row>
            </DateRange>
          )}
          <Wrapper id="table-wrapper">
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  ref={this._setTableRef}
                  headerHeight={60}
                  noRowsRenderer={this.noRowsRenderer}
                  rowHeight={33}
                  rowGetter={this.rowGetter}
                  rowCount={myAgents.length}
                  height={this.getTableHeight()}
                  rowRenderer={this.getRowRenderer}
                  width={width}
                  sort={this._sort}
                  sortBy={sortCurrentColumn}
                  sortDirection={sortCurrentDirection}
                >
                  <Column
                    width={50}
                    dataKey="Checked"
                    disableSort
                    headerStyle={{ justifyContent: 'flex-start' }}
                    cellRenderer={({ rowData }) => (
                      <CheckBox
                        value={checkedAgents.includes(rowData.KWUID)}
                        onChange={value => this.handleCheckChange(rowData.KWUID, value)}
                      />
                    )}
                    headerRenderer={({ rowData }) => (
                      <CheckBox
                        value={checkedAgents.length === myAgents.length}
                        onChange={value => this.handleCheckChange('all', value)}
                      />
                    )}
                  />
                  <Column
                    label="Agent Name"
                    dataKey="AgentName"
                    width={200}
                    headerStyle={{ justifyContent: 'flex-start' }}
                    style={{ textAlign: 'left' }}
                    cellRenderer={({ rowData }) => {
                      const { AgentName } = rowData;
                      return (
                        <LinkButton onClick={this.handleShowAgent(rowData)}>
                          {AgentName}
                        </LinkButton>
                      );
                    }}
                  />
                  <Column
                    width={170}
                    label="My LO Start Date"
                    dataKey="MyLOStartDate"
                    cellRenderer={({ cellData }) => (cellData ? formatUTCDate(cellData) : '')}
                  />
                  <Column
                    width={170}
                    label="Last Lead Sent Date"
                    dataKey="LastLeadSentDate"
                    cellRenderer={({ cellData }) => (cellData ? formatUTCDate(cellData) : '')}
                  />
                  <Column
                    width={170}
                    label="Last Closing Date"
                    dataKey="LastClosingDate"
                    cellRenderer={({ cellData }) => (cellData ? formatUTCDate(cellData) : '')}
                  />
                  <Column
                    width={100}
                    label="KM Leads"
                    dataKey="KMLeads"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={120}
                    label="KM PreApps"
                    dataKey="KMPreApps"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={140}
                    label="KM Contracts"
                    dataKey="KMContracts"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={130}
                    label="KM Closings"
                    dataKey="KMClosings"
                    cellRenderer={({ cellData }) => cellData}
                  />
                </Table>
              )}
            </AutoSizer>
          </Wrapper>
          {checkedAgents.length > 0 && (
            <EmailButton href={`mailto:${emails.join(',')}`}>Email Editor</EmailButton>
          )}
        </SectionContentWrapper>
        <AgentModal
          selectedLO={selectedLO}
          allLoanOfficers={allLoanOfficers}
          selectedAgent={selectedAgent}
          showModal={showAgentModal}
          onClose={() => this.setState({ showAgentModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(MyAgents);

const EmailButton = styled.a`
  background: ${props => props.theme.palette.gray};
  color: white;
  min-width: unset;
  font-size: 1.3rem;
  position: fixed;
  left: 330px;
  bottom: 10px;
  bottom: 10px;
  border-radius: 6px;
  padding: 5px 10px;

  &:focus,
  &:hover {
    color: white;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
`;

const DownloadWrapper = styled.div`
  height: 0;
  position: relative;

  & > a {
    transform: translateY(0%);
    right: 0;
  }
`;

const DateRange = styled.div`
  margin-right: 3rem;

  span {
    width: 8.1rem;
    margin-right: 1rem;
  }
`;

const RadioLabel = styled.span`
  margin-left: 0.4rem;
  margin-right: 2rem;
  font-size: 1.5rem;
`;

const Wrapper = styled.div`
  font-size: 1.3rem;

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: none;
    }

    .ReactVirtualized__Table__headerTruncatedText {
      white-space: pre-wrap;
      text-align: center;
    }

    .ReactVirtualized__Table__sortableHeaderIcon {
      font-size: 22px;
    }
  }

  .ReactVirtualized__Table__rowColumn {
    text-align: center;
  }
`;

const LinkButton = styled.button`
  text-decoration: underline;
  color: blue;
  text-align: left;
`;
