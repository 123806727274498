import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getPortalAdminAgentInfoSearch,
  getUsers,
  sendPreApprovalLink,
  addKWAgentMarketCenter,
  getPortalAdminLoanOfficer,
  addReferAClient,
  addActivityEvents,
  sendEmail,
  getPortalAdminBeginLoanProcessLink,
} from 'modules/api';
import Icon from 'components/Icon';
import { debounce } from 'lodash';
import RadioBox from 'components/RadioBox';
import CheckBox from 'components/CheckBox';
import Image from 'components/Image';
import NumberFormat from 'react-number-format';
import EmailIcon from 'assets/media/images/email.png';
import PhoneIcon from 'assets/media/images/phone.png';
import AccountCircle from 'assets/media/images/account_circle.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showAlert } from 'actions/index';
import HttpService from 'modules/http.service';
import { getPortalUrl } from 'constants/index';
import withModals from 'modules/withModals';

import { getActivitiyInfo } from 'modules/helpers';
import KWLogo from 'assets/media/brand/kw-logo.png';
import LoanOfficerDetailModal from './LoanOfficerDetailModal';
import Environment from '../../environments/env';
import ChangeLoanOfficerModal from './ChangeLoanOfficerModal';
import AssignToMyLOModal from './AssignToMyLOModal';
import InviteToMyLOModal from './InviteToMyLOModal';

import {
  StyledDropDown,
  CustomDropdownStyles,
  OptionWrapper,
  AgentDetailWrapper,
  SectionLabel,
  StyledButton,
  InviteButton,
  AgentList,
  RACWrapper,
  Row,
  Label,
  RadioForm,
  StyledInput,
  ButtonWrapper,
  PreLink,
  ClientOptionsWrapper,
  LabelWrapper,
  ValueWrapper,
  AgentButtonWrapper,
  MultipleLineLabel,
  MultipleLineInput,
  TableRow,
  RoleText,
} from './styles';

class AgentSearch extends Component {
  state = {
    selectedAgent: null,
    inputValue: '',
    searchResult: null,
    selectedLO: null,
    emailOrPhone: true,
    clientEmail: '',
    clientName: '',
    clientPhone: '',
    MsgToBorrowerEmail: '',
    MsgToBorrowerSignature: '',
    MsgToBorrowerText: '',
    supportHubLead: false,
    showChangeLOModal: false,
    showLODetailModal: false,
    marketCenters: [],
  };

  componentDidMount() {
    getUsers().then(res => {
      const user = res.find(u => u.PK === this.props.user.data.PK);
      if (user?.LOID) {
        this.setState({ selectedLO: user });
        getPortalAdminLoanOfficer(user.LOID).then(loanOfficers => {
          const loanOfficer = loanOfficers[0];
          if (loanOfficer) {
            const selectedLO = {
              ...loanOfficer,
              Name: `${loanOfficer.FirstName} ${loanOfficer.LastName}`,
              LOID: loanOfficer.ID,
            };
            this.handleChangeLO(selectedLO);
          }
        }).catch(() => {});
      }
    }).catch(() => {});
  }

  _Environment = new Environment();

  _http = new HttpService();

  onChange = (key, value) => {
    let actualValue = value;
    if (key === 'clientPhone') {
      actualValue = value.value;
    }

    this.setState({ [key]: actualValue });
  };

  handleChangeClientOption = emailOrPhone => () => {
    this.setState({ emailOrPhone });
  };

  handleSelectAgent = selectedAgent => () => {
    this.updateMC(selectedAgent);
    this.setState({
      selectedAgent,
      inputValue: '',
    });
  };

  updateMC = selectedAgent => {
    const { MarketCenters, Kwuid } = selectedAgent;
    if (MarketCenters) {
      return;
    }
    const headers = {
      'x-authorization': this._Environment.getENV().SEARCH_AGENT_API_KEY,
    };

    const getMarketCenterUrl = `https://roles.kwiq.kw.com/api/v1.1/person/${Kwuid}/orgs`;
    this._http
      .getReq(getMarketCenterUrl, headers)
      .then(res => {
        const marketCenterData = res.data.filter(m => m.org_type_id === 3);
        const agentMarketCenters = [];

        marketCenterData.forEach(o => {
          agentMarketCenters.push({
            KWUID: Kwuid,
            MarketCenterRefID: o.org_id,
          });
        });

        if (agentMarketCenters.length > 0) {
          addKWAgentMarketCenter(JSON.stringify(agentMarketCenters))
            .then(() => {})
            .catch(() => {});

          const mcs = marketCenterData.map(m => `MC: ${m.org_id} - ${m.org_name}`);
          const mcString = mcs.join('|');

          const updatedAgent = {
            ...selectedAgent,
            MarketCenters: mcString,
          };
          this.setState({ selectedAgent: updatedAgent });
        }
      })
      .catch(() => {});
  };

  handleChangeLO = selectedLO => {
    const { MsgToBorrowerEmail, MsgToBorrowerSignature, MsgToBorrowerText } = selectedLO;
    this.setState({
      selectedLO,
      MsgToBorrowerEmail,
      MsgToBorrowerSignature,
      MsgToBorrowerText,
    });
  };

  loadOptions = debounce((inputValue, callback) => {
    if (inputValue.length < 3) {
      callback([]);
      this.setState({
        selectedAgent: null,
      });
      return;
    }

    const userData = this.props.user.data;

    getPortalAdminAgentInfoSearch(
      inputValue,
      userData.PermissionsLevel === 3 ? userData?.LOID : null,
    )
      .then(res => {
        const searchResult = res.map(a => ({
          ...a,
          label: `${a.FirstName} ${a.LastName}`,
          value: a.Kwuid,
        }));
        this.setState({
          searchResult,
          selectedAgent: null,
        });
        callback([]);
      })
      .catch(() => {});
  }, 500);

  handleInputChange = inputValue => {
    this.setState({
      inputValue,
    });
    return inputValue;
  };

  option = props => {
    const { data, label } = props;
    return (
      <OptionWrapper buttonRef={props.innerRef} {...props.innerProps}>
        <Image src={data.Photo} />
        <div>
          <div>{label}</div>
          <div>{`${data.City ? data.City : ''}, ${data.StateCode ? data.StateCode : ''}`}</div>
        </div>
      </OptionWrapper>
    );
  };

  handleClearAgents = () => {
    this.setState({ selectedAgent: null });
  };

  handleCopyLink = () => {
    const { dispatch } = this.props;
    dispatch(
      showAlert('PreApproval Link copied to your clipboard', { variant: 'success', icon: 'bell' }),
    );
  };

  handleSend = async () => {
    const {
      clientEmail,
      clientName,
      clientPhone,
      selectedLO,
      selectedAgent,
      emailOrPhone,
      supportHubLead,
      MsgToBorrowerSignature,
      MsgToBorrowerText,
      MsgToBorrowerEmail,
    } = this.state;

    const agentName = `${selectedLO.FirstName} ${selectedLO.LastName}`;
    const agentEmail = selectedLO.Email;
    const agentPhone = selectedLO.PhoneMobile;

    const payloadForLink = {
      CampaignID: supportHubLead ? '33' : '61',
      PartnerID: 102100,
      BuyersAgentFlag: null,
      SellersAgentFlag: null,
      StateCode: null,
      LOID: selectedLO ? selectedLO.LOID : '',
      KWUID: selectedAgent.Kwuid,
      SMSMessageTypeFlag: 1,
    }

    const res = await getPortalAdminBeginLoanProcessLink(payloadForLink)
    const preApprovalLink = res.data['']

    const params = {
      clientEmail: emailOrPhone ? clientEmail : null,
      clientName,
      clientPhone: emailOrPhone ? null : clientPhone,
      preApprovalLink,
      agentName,
      agentEmail,
      agentPhone,
      signature: MsgToBorrowerSignature,
      message: MsgToBorrowerEmail,
      messageText: MsgToBorrowerText,
      bccEmail: agentEmail,
    };

    sendPreApprovalLink(params)
      .then(() => {
        const { dispatch } = this.props;
        dispatch(
          showAlert('Pre-approval link sent successfully.', { variant: 'success', icon: 'bell' }),
        );
        this.setState({
          clientEmail: '',
          clientName: '',
          clientPhone: '',
        });
      })
      .catch(() => {
        const { dispatch } = this.props;
        dispatch(
          showAlert('Failed to send Pre-approval link.', { variant: 'danger', icon: 'bell' }),
        );
      });

    getActivitiyInfo().then(deviceInfo => {
      const { IPAddress, DeviceType, BrowserOS } = deviceInfo;
      addActivityEvents(28, selectedAgent.Kwuid, IPAddress, DeviceType, BrowserOS);
    });

    addReferAClient(
      selectedAgent.Kwuid,
      selectedLO?.LOID,
      'Portal Admin',
      clientName,
      emailOrPhone ? clientEmail : null,
      emailOrPhone ? null : clientPhone,
      null,
      null,
    );
  };

  handleUpdateTeams = teams => {
    if (teams && teams.length === 0) {
      return;
    }

    const { selectedAgent } = this.state;

    const teamArray = teams.map(t => `Team: ${t.TeamID} - ${t.TeamName}`);
    const teamString = teamArray.join('|');

    const updatedAgent = {
      ...selectedAgent,
      Teams: teamString,
    };
    this.setState({ selectedAgent: updatedAgent });
  };

  handleUpdateMCs = marketCenters => {
    this.setState({ marketCenters });
  };

  handleSendInvite = async () => {
    const { dispatch, user } = this.props;
    const { selectedAgent } = this.state;
    if (!selectedAgent) {
      return;
    }
    const {
      Email,
      MessageFromEmail,
      MessageSubject,
      MessageTemplateEmail,
      MessageFromLOName,
      Kwuid,
    } = selectedAgent;

    const message = `
      ${MessageTemplateEmail}
      <br/>
      <div>${MessageFromLOName}</div>
      <div>Loan Officer</div>
      <div>${MessageFromEmail}</div>
    `;
    const params = {
      email: MessageFromEmail,
      subject: MessageSubject,
      message,
      name: `${MessageFromLOName}`,
      service_email: Email,
      bccEmail: user.data.Email,
    };
    await sendEmail(params);
    dispatch(showAlert('Email sent successfully.', { variant: 'success', icon: 'bell' }));

    const { IPAddress, DeviceType, BrowserOS } = this.props.deviceInfo;
    addActivityEvents(77, Kwuid, IPAddress, DeviceType, BrowserOS);
  };

  handleInviteToMyLO = () => {
    const { selectedLO, selectedAgent } = this.state;
    this.props.openModal('InviteToMyLOModal', {
      loanOfficer: selectedLO,
      agent: selectedAgent,
      onUpdate: () => {
        this.handleSendInvite()
      },
    })
  }

  handleAssignToMyLO = () => {
    const { dispatch } = this.props;
    const { selectedAgent, searchResult } = this.state;
    this.props.openModal('AssignToMyLOModal', {
      agent: selectedAgent,
      onUpdate: (selectedLO) => {
        const newSelectedAgent = {
          ...selectedAgent,
          PrimaryLOID: selectedLO.LOID,
          PrimaryLOName: selectedLO.Name,
          MessageFromEmail: null,
          MessageFromLOName: null,
          MessageSubject: null,
          MessageTemplateEmail: null,
        }

        const newSearchResult = [...searchResult];
        const selectedItem = newSearchResult.find(agent => agent.Email === selectedAgent.Email && agent.FirstName === selectedAgent.FirstName)
        selectedItem.PrimaryLOID = selectedLO.LOID
        selectedItem.PrimaryLOName = selectedLO.Name
        selectedItem.MessageSubject = null
        selectedItem.MessageFromEmail = null
        selectedItem.MessageFromLOName = null
        selectedItem.MessageTemplateEmail = null
        this.setState({
          selectedAgent: newSelectedAgent,
          searchResult: newSearchResult,
        })
        dispatch(showAlert('Assigned successfully.', { variant: 'success', icon: 'bell' }));
      },
    })
  }

  render() {
    const { user, allLoanOfficers } = this.props;
    const isAdmin = user.data.PermissionsLevel === 1;
    const isRep = user.data.PermissionsLevel === 3;

    const {
      selectedAgent,
      searchResult,
      inputValue,
      selectedLO,
      emailOrPhone,
      clientEmail,
      clientName,
      clientPhone,
      supportHubLead,
      showChangeLOModal,
      showLODetailModal,
      MsgToBorrowerEmail,
      MsgToBorrowerSignature,
      MsgToBorrowerText,
      marketCenters,
    } = this.state;

    const {
      label,
      Photo,
      City,
      StateCode,
      AgentStatus,
      Address1,
      PostalCode,
      Kwuid,
      Email,
      MobilePhone,
      Teams,
    } = selectedAgent || {};

    const agentInactive = AgentStatus === 'Inactive';

    let teams = [];
    if (Teams) {
      teams = Teams.split('|');
    }

    const preApprovalLink = `https://preapproval.kellermortgage.com/#/?campaign_id=${
      supportHubLead ? '33' : '61'
    }&KWUID=${Kwuid || null}&loan_manager_id=${selectedLO ? selectedLO.LOID : null}`;

    const sendDisabled =
      (!clientEmail && emailOrPhone) ||
      ((!MsgToBorrowerEmail || !MsgToBorrowerSignature) && emailOrPhone) ||
      (!emailOrPhone && !MsgToBorrowerText) ||
      (!emailOrPhone && !clientPhone) ||
      !clientName ||
      !selectedAgent ||
      !selectedLO;

    const showAssignToMyLOButton = !selectedAgent?.PrimaryLOID &&
      (user.data.PermissionsLevel === 1 || user.data.PermissionsLevel === 2 || user.data.PermissionsLevel === 4); // Admin/User/Recruiter permission

    return (
      <>
        <StyledDropDown
          styles={CustomDropdownStyles}
          isSearchable
          placeholder=""
          loadOptions={this.loadOptions}
          onInputChange={this.handleInputChange}
          value={null}
          components={{ Option: this.option }}
          noOptionsMessage={() => null}
        />
        {inputValue && inputValue.length > 2 && searchResult && searchResult.length === 0 && (
          <div>No Agent were found.</div>
        )}
        {searchResult && searchResult.length > 0 && !selectedAgent && (
          <Fragment>
            <AgentList>
              {searchResult.map(data => (
                <OptionWrapper key={data.Kwuid} onClick={this.handleSelectAgent(data)}>
                  <Image src={data.Photo} />
                  <div>
                    <div>{data.label}</div>
                    <div>
                      {`${data.City ? data.City : ''}, ${data.StateCode ? data.StateCode : ''}`}
                    </div>
                  </div>
                  {data.OrganizationName === 'Keller Williams' && (
                    <span className="org_logo">
                      <Image src={KWLogo} />
                    </span>
                  )}
                </OptionWrapper>
              ))}
            </AgentList>
          </Fragment>
        )}
        {selectedAgent && (
          <Fragment>
            <StyledButton onClick={this.handleClearAgents}>Back</StyledButton>

            <AgentDetailWrapper>
              <div>
                <Image src={Photo} />
                <div>
                  <div>{label}</div>
                  <div>{`${Address1 || ''}`}</div>
                  <div>{`${City || ''}, ${StateCode || ''} ${PostalCode || ''}`}</div>
                  <div style={{ marginTop: '1.7rem' }}>{`KWUID: ${Kwuid || ''}`}</div>
                  <div>STATUS: <span className={agentInactive && 'inactive'}>{AgentStatus}</span></div>
                  {selectedAgent.PrimaryLOName && (
                    <div>{`PRIMARY LO: ${selectedAgent.PrimaryLOName}`}</div>
                  )}
                </div>
                <AgentButtonWrapper>
                  <StyledButton onClick={() => this.setState({ showLODetailModal: true })}>
                    View API Data
                  </StyledButton>
                  {isAdmin && !agentInactive && (
                    <a
                      href={`${getPortalUrl()}/spoof?KWUID=${Kwuid}&email=${Email}&accessCode=Ap$pf6@7`}
                      target="_blank"
                    >
                      <StyledButton>Spoof Agent</StyledButton>
                    </a>
                  )}
                  {selectedAgent.MessageSubject && selectedLO && (
                    <InviteButton onClick={this.handleInviteToMyLO}>Invite to my LO</InviteButton>
                  )}
                  {showAssignToMyLOButton && (
                    <InviteButton onClick={this.handleAssignToMyLO}>Assign to my LO</InviteButton>
                  )}
                </AgentButtonWrapper>
              </div>
              <div>
                {selectedAgent.OrganizationName && (
                  <div>
                    <Icon name="building" width={18} color="#999" />
                    <span>{selectedAgent.OrganizationName}</span>
                  </div>
                )}
                <div className="agent-email-wrapper">
                  <Icon name="email" width={18} color="#999" />
                  <span>{selectedAgent.EmailList.split('|').map(email => <div key={email}>{email}</div>)}</span>
                </div>
                {MobilePhone && (
                  <div>
                    <Icon name="phone" width={18} />
                    <span>(M)&nbsp;{MobilePhone}</span>
                  </div>
                )}
                {marketCenters.length > 0 &&
                  marketCenters.map(m => (
                    <>
                      <div>
                        <Icon name="home_marketing" width={18} color="#999" />
                        <span>
                          MC: {m.MarketCenterID} - {m.MarketCenterName}
                        </span>
                      </div>
                      <RoleText>{`- Role: ${m.MarketCenterOrgRoles}`}</RoleText>
                    </>
                  ))}
                {teams.length > 0 &&
                  teams.map(m => (
                    <div key={m}>
                      <Icon name="home_marketing" width={18} color="#999" />
                      <span>{m}</span>
                    </div>
                  ))}
              </div>
            </AgentDetailWrapper>

            <RACWrapper>
              <div>
                <Row>
                  <Label>Loan Officer: {selectedLO ? selectedLO.Name : 'N/A'} </Label>
                  {!isRep && (
                    <StyledButton onClick={() => this.setState({ showChangeLOModal: true })}>
                      {selectedLO ? 'Change' : 'Choose Loan Officer'}
                    </StyledButton>
                  )}
                </Row>
                {false && (
                  <Fragment>
                    <div>Pre-Approval Link:</div>
                    <Row>
                      <PreLink href={preApprovalLink}>{preApprovalLink}</PreLink>
                      <CopyToClipboard text={preApprovalLink} onCopy={this.handleCopyLink}>
                        <StyledButton>Copy</StyledButton>
                      </CopyToClipboard>
                    </Row>
                  </Fragment>
                )}
              </div>
              <div>
                <SectionLabel>Refer a client</SectionLabel>
                <TableRow>
                  <LabelWrapper>
                    <Label style={{ flex: 1 }}>Client Name:</Label>
                  </LabelWrapper>
                  <ValueWrapper>
                    <StyledInput
                      icon={AccountCircle}
                      type="text"
                      placeholder="Bob Smith"
                      value={clientName}
                      onChange={e => this.onChange('clientName', e.target.value)}
                    />
                  </ValueWrapper>
                </TableRow>
                <TableRow>
                  <LabelWrapper>
                    <Label>Client</Label>
                    <ClientOptionsWrapper>
                      <RadioForm>
                        <RadioBox
                          value={emailOrPhone}
                          onChange={this.handleChangeClientOption(true)}
                        />
                        <span>Email</span>
                      </RadioForm>
                      <RadioForm>
                        <RadioBox
                          value={!emailOrPhone}
                          onChange={this.handleChangeClientOption(false)}
                        />
                        <span>Phone</span>
                      </RadioForm>
                    </ClientOptionsWrapper>
                  </LabelWrapper>
                  <ValueWrapper>
                    {emailOrPhone ? (
                      <StyledInput
                        icon={EmailIcon}
                        iconWidth={21}
                        iconHeight={20}
                        type="email"
                        value={clientEmail}
                        placeholder="bobsmith@NeedAHouse.com"
                        onChange={e => this.onChange('clientEmail', e.target.value)}
                      />
                    ) : (
                      <NumberFormat
                        icon={PhoneIcon}
                        iconWidth={18}
                        iconHeight={18}
                        type="tel"
                        isNumericString
                        format="+1 (###) ###-####"
                        mask="_"
                        value={clientPhone}
                        placeholder="Mobile Phone#"
                        onValueChange={e => this.onChange('clientPhone', e)}
                        customInput={StyledInput}
                      />
                    )}
                  </ValueWrapper>
                </TableRow>
                <TableRow>
                  <LabelWrapper />
                  <ValueWrapper>
                    {emailOrPhone ? (
                      <Fragment>
                        <MultipleLineLabel>Email Message to your borrower:</MultipleLineLabel>
                        <MultipleLineInput
                          value={MsgToBorrowerEmail || ''}
                          onChange={e => this.onChange('MsgToBorrowerEmail', e.target.value)}
                        />
                        <MultipleLineLabel>Email Signature:</MultipleLineLabel>
                        <MultipleLineInput
                          value={MsgToBorrowerSignature || ''}
                          onChange={e => this.onChange('MsgToBorrowerSignature', e.target.value)}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <MultipleLineLabel>Text Message to your borrower:</MultipleLineLabel>
                        <MultipleLineInput
                          value={MsgToBorrowerText || ''}
                          onChange={e => this.onChange('MsgToBorrowerText', e.target.value)}
                        />
                      </Fragment>
                    )}
                  </ValueWrapper>
                </TableRow>
                <TableRow>
                  <LabelWrapper>
                    <Label style={{ flex: 1 }}>Support Hub Lead:</Label>
                  </LabelWrapper>
                  <ValueWrapper>
                    <CheckBox
                      value={supportHubLead}
                      onChange={value => this.onChange('supportHubLead', value)}
                    />
                  </ValueWrapper>
                </TableRow>
                <ButtonWrapper>
                  <StyledButton disabled={sendDisabled} onClick={this.handleSend}>
                    Send
                  </StyledButton>
                </ButtonWrapper>
              </div>
            </RACWrapper>
          </Fragment>
        )}
        <ChangeLoanOfficerModal
          allLoanOfficers={allLoanOfficers}
          showModal={showChangeLOModal}
          onClose={() => this.setState({ showChangeLOModal: false })}
          onSelect={this.handleChangeLO}
        />
        <LoanOfficerDetailModal
          kwuid={selectedAgent ? selectedAgent.Kwuid : null}
          showModal={showLODetailModal}
          onUpdateTeam={this.handleUpdateTeams}
          onUpdateMCs={this.handleUpdateMCs}
          onClose={() => this.setState({ showLODetailModal: false })}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    deviceInfo: state.app.deviceInfo,
  };
}

export default compose(
  connect(mapStateToProps),
  withModals({
    AssignToMyLOModal,
    InviteToMyLOModal,
  })
)(AgentSearch)
