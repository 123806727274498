import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Helmet from 'react-helmet';
import styled from 'styled-components';
import { sortBy } from 'lodash';
import { getUsers } from 'modules/api';
import 'react-virtualized/styles.css';
import { AutoSizer, Table, Column } from 'react-virtualized';
import UserModal from './UserModal';
import { media } from 'modules/theme';

const Container = styled.div`
  padding: 2rem;
  margin: 2rem;
  background: white;
  flex: 1;

  ${media.mobile`
    margin: 0;
  `};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: space-between;
  padding: 10px 10px 30px;
`;

const AlphabetFilter = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  button {
    padding: 0 5px;
    &:hover {
      color: ${props => props.theme.palette.primary};
    }
  }
`;

const AddLOButton = styled.button`
  text-decoration: underline;
  margin-left: 20px;
`;

const Wrapper = styled.div`
  font-size: 1.3rem;

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }
`;

const LinkButton = styled.button`
  text-decoration: underline;
`;

class LoanOfficers extends Component {
  state = {
    users: [],
    activeRow: {},
    showPhotoModal: false,
    showProfileModal: false,
    editingRow: {},
    alphabetFilterKey: '',
  };

  _setTableRef = ref => {
    this._table = ref;
  };

  componentDidMount() {
    this.refreshUsers();
  }

  refreshUsers = () => {
    getUsers()
      .then(res => {
        const users = sortBy(res, 'Name');
        this.setState({
          users,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleAlphabetFilter = alphabetFilterKey => () => {
    this.setState({ alphabetFilterKey });
  };

  rowGetter = ({ index }) => {
    const showingUsers = this.getShowingUsers();
    return showingUsers[index];
  };

  getShowingUsers = () => {
    const { users, alphabetFilterKey } = this.state;
    if (!alphabetFilterKey) {
      return users;
    }
    return users.filter(l => l.Name && l.Name.charAt(0).toLowerCase() === alphabetFilterKey);
  };

  noRowsRenderer() {
    return <div>No Data</div>;
  }

  getTableHeight = () => {
    const elem = document.getElementById("table-wrapper");
    let calcHeight = 600

    if (elem) {
      const rect = elem.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      calcHeight = screenHeight - rect.top - 50
    }
    return calcHeight;
  }

  getRowHeight = ({ index }) => this.state.activeRow[index] ? 150 : 30;

  getRowRenderer = props => {
    const { _key, className, columns, index, style, rowData } = props;
    const isOdd = index % 2;

    return (
      <div
        key={_key}
        style={{ 
          ...style, 
          backgroundColor: isOdd ? 'white' : '#eeeeee', 
          paddingTop: 7, 
        }}
      >
        <div className={className}>{columns}</div>
      </div>
    );
  };


  handleShowProfileModal = rowData => () => {
    this.setState({
      showProfileModal: true,
      editingRow: rowData,
    });
  };

  handleAddLoanOfficer = () => {
    this.setState({
      showProfileModal: true,
      editingRow: {},
    });
  };

  render() {
    const { editingRow, showProfileModal } = this.state;
    const alphabets = 'abcdefghijklmnopqrstuvwxyz'.split('');
    const { user } = this.props;
    if (user.data.PermissionsLevel !== 1) {
      return <Redirect to='/private/home'/>
    }

    return (
      <Fragment>
        <Helmet>
          <title>Users</title>
          <meta
            name="description"
            content="This is the Keller Mortgage Admin Portal Users page"
          />
        </Helmet>
        <Container>
          <Header>
            <AlphabetFilter>
              <span>Filter by: </span>
              <button onClick={this.handleAlphabetFilter('')}>All</button>
              <span>|</span>
              {alphabets.map(c => (
                <button key={c} onClick={this.handleAlphabetFilter(c)}>
                  {c.toUpperCase()}
                </button>
              ))}
            </AlphabetFilter>
            <AddLOButton onClick={this.handleAddLoanOfficer}>Add User</AddLOButton>
          </Header>

          <Wrapper id="table-wrapper">
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  ref={this._setTableRef}
                  headerHeight={30}
                  noRowsRenderer={this.noRowsRenderer}
                  rowHeight={this.getRowHeight}
                  rowGetter={this.rowGetter}
                  rowCount={this.getShowingUsers().length}
                  height={this.getTableHeight()}
                  rowRenderer={this.getRowRenderer}
                  width={width}
                >
                   <Column
                    label="Name"
                    dataKey="Name"
                    width={150}
                    cellRenderer={({ rowData }) => {
                      const { Name } = rowData;
                      return (
                        <LinkButton
                          onClick={this.handleShowProfileModal(rowData)}
                        >{Name}
                        </LinkButton>
                      );
                    }}
                  />
                  <Column
                    width={200}
                    label="Email"
                    dataKey="Email"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={150}
                    label="Phone"
                    dataKey="Phone"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={150}
                    label="Status"
                    dataKey="StatusDescription"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={150}
                    label="Permission Level"
                    dataKey="PermissionsLevelDescription"
                    cellRenderer={({ cellData }) => cellData}
                  />
                </Table>
              )}
            </AutoSizer>
          </Wrapper>
          <UserModal
            data={editingRow}
            showModal={showProfileModal}
            onUpdate={this.refreshUsers}
            onClose={() => this.setState({ showProfileModal: false })}
            dispatch={this.props.dispatch}
          />
        </Container>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(LoanOfficers);
