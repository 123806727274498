// @flow
/**
 * @module Actions/App
 * @desc App Actions
 */

import uuid from 'uuid/v4';
import { createActions } from 'redux-actions';

import { ActionTypes } from 'constants/index';

export { goBack, go, push, replace } from 'modules/history';

export const { hideAlert, showAlert, switchMenu, toggleMobileSideMenu, setDeviceInfo, fetchUnreadMessageCount, setUnreadMessageCount } = createActions({
  [ActionTypes.SWITCH_MENU]: query => ({ query }),
  [ActionTypes.HIDE_ALERT]: id => ({ id }),
  [ActionTypes.SHOW_ALERT]: (message, options) => {
    const timeout = options.variant === 'danger' ? 0 : 5;

    return {
      id: options.id || uuid(),
      icon: options.icon,
      message,
      position: options.position || 'bottom-right',
      variant: options.variant || 'dark',
      timeout: typeof options.timeout === 'number' ? options.timeout : timeout,
    };
  },
  [ActionTypes.TOGGLE_MOBILE_SIDE_MENU]: id => ({ id }),
  [ActionTypes.SET_DEVICE_INFO]: (deviceInfo) => deviceInfo,
  [ActionTypes.FETCH_UNREAD_MESSAGE_COUNT]: (id) => id,
  [ActionTypes.SET_UNREAD_MESSAGE_COUNT]: (count) => count
});
