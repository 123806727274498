import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import moment from 'moment';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import { getRecruitingDashboardStatsClosedAdverseDetail } from 'modules/api';
import { Table } from 'reactstrap';
import { formatDate } from 'modules/helpers';

export default class ContactedModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    data: [],
    fetching: true
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { recruiterID, period, startDate, endDate } = this.props

    getRecruitingDashboardStatsClosedAdverseDetail(recruiterID, period, startDate, endDate).then(res => {
      this.setState({ 
        data: res.result,
        fetching: false
      });
    })
  };

  handleClose = () => {
    this.props.onClose();
  };


  render() {
    const { isOpen, startDate, endDate } = this.props;
    const { data, fetching } = this.state;

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>
          Closed
        </ModalHeader>
        <ModalBody>
          <DateRangeView>{`${formatDate(startDate)} ~ ${formatDate(endDate)}`}</DateRangeView>
          {data.length > 0 ? (
            <StatsTable striped>
              <tbody>
                {data.map(row => 
                  <tr>
                    <th scope="row">{row.ActivityName}</th>
                    <td>
                      <div>
                        <span>{row.ActivityCount}</span>
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <th scope="row">Total</th>
                  <td>
                    <div>
                      <span>{sumBy(data, 'ActivityCount')}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </StatsTable>
          ) : (
            <Fragment>
              {!fetching && <div>There is no result.</div>}
            </Fragment>
          )
        }
        </ModalBody>
      </Modal>
    );
  }
}


const Modal = styled(DigeryModal)`
  max-width: 500px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    height: 600px;
    overflow: auto;
  }
  .modal-body {
    overflow: auto;
  }
`;

const StatsTable = styled(Table)`
  &&& {
    font-size: 1.4rem;

    th {
      text-align: center;
      height: 50px;
      vertical-align: middle;
    }

    td {
      text-align: center;
      height: 50px;
      vertical-align: middle;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    tbody tr th {
      text-align: left;
      padding-left: 10px;
    }
  }
`;

const DateRangeView = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 15px;
`;
