export default class LocalStorageService {

  /**
   * Set Token
   */
  setToken(token) {
    localStorage.setItem('xfsexfeuiyewrw', token)
  }

  /**
   * Get Token
   */
  getToken() {
    return localStorage.getItem('xfsexfeuiyewrw')
  }

  /**
   * Set Id Token
   */
  setIDToken(token_id) {
    localStorage.setItem('xysftred', token_id)
  }

  /**
   * Get Id Token
   */
  getIDToken() {
    return localStorage.getItem('xysftred')
  }

  /**
   * Set agent data to Localstorage
   */
  setAgentData(agent) {
    localStorage.setItem('agent_data', JSON.stringify(agent))
  }

  /**
   * Get User from Localstorage
   */
  getAgentData() {
    return JSON.parse(localStorage.getItem('agent_data'))
  }

  /**
   * Set agent data to Localstorage
   */
  setOneLoginData(data) {
    localStorage.setItem('user_info', JSON.stringify(data))
  }

  /**
   * Get User from Localstorage
   */
  getOneLoginData() {
    return JSON.parse(localStorage.getItem('user_info'))
  }

  /**
   * set Questions
   * @param {*} q
   */
  setQuestions(q) {
    localStorage.setItem('Questions', JSON.stringify(q))
  }

  /**
   * Get Questions
   */
  getQuestions() {
    return JSON.parse(localStorage.getItem('Questions'))
  }

  /**
   * set video
   * @param {*} q
   */
  setVideoData(q) {
    localStorage.setItem('video', JSON.stringify(q))
  }

  /**
   * Get video
   */
  getVideoData() {
    return JSON.parse(localStorage.getItem('video'))
  }

  /**
   * set video finished
   * @param {*} q
   */
  setQuestionsFinished(q) {
    localStorage.setItem('finished', JSON.stringify(q))
  }

  /**
   * Get video finished
   */
  getQuestionsFinished() {
    return JSON.parse(localStorage.getItem('finished'))
  }

  /**
   * set video finished
   * @param {*} q
   */
  setTerm(q) {
    localStorage.setItem('term', JSON.stringify(q))
  }

  /**
   * Get video finished
   */
  getTerm() {
    return JSON.parse(localStorage.getItem('term'))
  }

  /**
   * set video finished
   * @param {*} q
   */
  setReadyForVideo(q) {
    localStorage.setItem('ready', JSON.stringify(q))
  }

  /**
   * Get video finished
   */
  getReadyForVideo() {
    return JSON.parse(localStorage.getItem('ready'))
  }

  setDefaultPreApprovalSignature(q) {
    localStorage.setItem('default_preapproval_signature', q);
  }

  getDefaultPreApprovalSignature() {
    return localStorage.getItem('default_preapproval_signature');
   }

  setDefaultPreApprovalMessage(q) {
    localStorage.setItem('default_preapproval_message', q);
  }

  getDefaultPreApprovalMessage() {
    return localStorage.getItem('default_preapproval_message');
   }

  setRediretPathAfterLogin(q) {
    localStorage.setItem('redirect_path_after_login', q);
  }

  getRediretPathAfterLogin() {
    return localStorage.getItem('redirect_path_after_login');
  }

  removeRediretPathAfterLogin() {
    localStorage.removeItem('redirect_path_after_login');
  }
}
