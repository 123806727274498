import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import DigeryButton from 'components/Button';
import { getUsers, addPortalAdminNotificationsUsers } from 'modules/api';

const Modal = styled(DigeryModal)`
  max-width: 450px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    .modal-body {
      height: 500px;
      overflow: auto;
    }
  }
`;

const Button = styled(DigeryButton)`
  text-transform: none;
  min-width: 12rem;
  font-size: 1.2rem;
  height: 2.5rem;
  border-radius: 999px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
`;

class AddSubscriberModal extends Component {
  static propTypes = {
    loanOfficer: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    users: [],
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.fetchAdminUsers()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.fetchAdminUsers()
    }
  }

  fetchAdminUsers = () => {
    getUsers()
      .then(res => {
        this.setState({ 
          users: res
        })
      })
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleAdd = (UserPK) => () => {
    addPortalAdminNotificationsUsers(UserPK, this.props.notification.PK)
      .then(res => {
        this.props.onUpdate()
        this.props.onClose();
      })
  }

  render() {
    const { isOpen } = this.props;
    const { users } = this.state;

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={700}>
        <ModalHeader toggle={this.handleClose}>
          Add Subscrber
        </ModalHeader>
        <ModalBody>
          {
            users.map((a, index) => (
              <Row key={index}>
                <div>{a.Name}</div>
                <Button onClick={this.handleAdd(a.PK)}>Select</Button>
              </Row>
            ))
          }
        </ModalBody>
      </Modal>
    );
  }
}

export default AddSubscriberModal
