import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Segment = styled.div`
  border-radius: 4px;
  color: ${props => props.theme.palette.light};
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 #dfe3e8;
  border: 1px solid #dfe3e8;
  padding: 20px;
  margin-bottom: 35px;
  text-align: center;
  margin-top: 1.5rem;
`;

export const SegmentHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 10px;
`;

export const SegmentHeaderTitle = styled.div`
  color: ${props => {
    const palette = props.palette ? props.palette : 'gray';
    return props.theme.palette[palette];
  }};
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: bold;
`;

export const SegmentContent = styled.div`
  width: 100%;
  padding-bottom: 10px;
`;

export const SegmentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SegmentButton = styled.div`
  background-color: ${props => props.theme.palette.primary};
  border-radius: 4px;
  color: white;
  text-transform: uppercase;
  font-size: 1.1rem;
  padding: 5px 10px;
  margin-left: auto;
  cursor: pointer;
`;

export const SegmentLinkButton = styled(Link)`
  background-color: ${props => props.theme.palette.primary};
  border-radius: 4px;
  color: white;
  text-transform: uppercase;
  font-size: 1.1rem;
  padding: 5px 10px;
  margin-left: auto;

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }
`;
