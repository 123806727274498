import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Chart from '../../components/Chart';
import moment from 'moment';
import { 
  getNotificationLogCountPriorDayDetail,
} from 'modules/api';
import { media } from 'modules/theme';
import DownloadCSV from 'components/DownloadCSV';


const Block = styled.div`
  padding: 2rem;
  margin: 2rem;
  background: white;
  flex: 1;

  ${media.mobile`
    padding 0 0 0 3rem;
  `};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: .3rem 0;
`;

const UsersFilterRow = styled.div`
  display: flex;
  align-items: center;
  padding: .3rem 0;
  flex-wrap: wrap;
`;

const RadioLabel = styled.span`
  margin-left: .4rem;
  margin-right: 2.0rem;
  font-size: 1.5rem;
`;

const DateRange = styled.div`
  margin-right: 3.0rem;

  span {
    width: 8.1rem;
    margin-right: 1.0rem;
  }
`;

const SectionTitle = styled.div`
  text-align: center;
  margin-bottom: 2.0rem;
  color: ${props => props.theme.palette.primary};
  font-size: 1.8rem;
  position: relative;
`;

class NotificationsByType extends Component {
  state = {
    data: [],
    startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    serverData: []
   }

  componentDidMount() {
    this.handleGetData()
  }

  handleGetData = () => {
    const { startDate, endDate } = this.state;
    
    getNotificationLogCountPriorDayDetail(startDate, endDate)
      .then(res => {
        this.setState({ serverData: res.result })

        const data = res.result.map(r => ({
          ...r,
          date: r.NotificationName,
          'Notifications Sent by Type': r.NotificationsSent,
        }));
        this.setState({ data })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.handleGetData()
    });
  }

  render() {
    const { startDate, endDate, data, serverData } = this.state;

    return (
      <Block>
        <SectionTitle>
          Notifications Sent by Type
          <DownloadCSV data={serverData} filename='Notifications Sent by Type'/>
        </SectionTitle>
        <UsersFilterRow>
            <DateRange>
              <Row>
                <RadioLabel>Start Date</RadioLabel>
                <input 
                  placeholder="Start Date"
                  type="date" 
                  value={startDate} 
                  onChange={e => this.handleChange('startDate', e.target.value || null)}
                />
              </Row>
              <Row>
                <RadioLabel>End Date</RadioLabel>
                <input 
                  placeholder="End Date"
                  type="date" 
                  value={endDate} 
                  onChange={e => this.handleChange('endDate', e.target.value || null)}
                />
              </Row>
            </DateRange>
        </UsersFilterRow>
        <Chart type='bar' data={data} dataKeys={['Notifications Sent by Type']} height={500}/>

      </Block>
    )
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(NotificationsByType);