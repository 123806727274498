import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import { get } from 'lodash';
import DigeryModal from 'components/Modal';
import { getPrimaryDetail } from 'modules/api';
import Table, { ProgressRow } from 'components/Table';
import DownloadCSV from 'components/DownloadCSV';

const Modal = styled(DigeryModal)`
  max-width: 700px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    height: 600px;
    overflow: auto;
  }
`;

const LOName = styled.div`
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 2rem;
`;

const Header = styled.div`
  width: 100%;
  position: relative;
`;

export default class PrimaryAgentsModal extends Component {
  static propTypes = {
    loanOfficer: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
  };

  state = {
    agents: [],
  };

  componentDidMount() {
    if (this.props.showModal) {
      this.fetchPrimaryAgents();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.showModal && !prevProps.showModal) {
      this.fetchPrimaryAgents();
    }
  }

  fetchPrimaryAgents = () => {
    const { loanOfficer } = this.props;
    this.setState({ agents: [] });
    getPrimaryDetail(loanOfficer.ID)
      .then(res => {
        this.setState({ agents: res });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { showModal, loanOfficer } = this.props;
    const { agents } = this.state;

    return (
      <Modal centered fade={false} isOpen={showModal} toggle={this.handleClose} width={700}>
        <ModalHeader toggle={this.handleClose}>Primary Loan Officer Detail</ModalHeader>
        <ModalBody>
          <Header>
            <LOName>
              Loan Officer:{' '}
              {`${get(loanOfficer, 'FirstName', '')} ${get(loanOfficer, 'LastName', '')}`}
            </LOName>
            <DownloadCSV data={agents} filename="Primary_loan_officer_agents" />
          </Header>
          <Table>
            <thead>
              <tr>
                <th>KWUID</th>
                <th>Agent Name</th>
                <th>Email</th>
                <th>Mobile Phone</th>
                <th>State</th>
                <th>Market Center</th>
              </tr>
            </thead>
            <tbody>
              {agents.map(a => (
                <ProgressRow key={a.Email}>
                  <td>{a.KWUID}</td>
                  <td>{`${get(a, 'FirstName', '')} ${get(a, 'LastName', '')}`}</td>
                  <td>{a.Email}</td>
                  <td>{a.MobilePhoneNumber}</td>
                  <td>{a.StateCode}</td>
                  <td>{a.MarketCenters}</td>
                </ProgressRow>
              ))}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    );
  }
}
