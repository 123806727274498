import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled, { css } from 'styled-components';
import Select from 'react-select';
import {
  getAgentsRegistrationPreferredLoanOfficer,
} from 'modules/api';
import { isEqual, orderBy }from 'lodash';
import SectionContentWrapper from 'components/TabWrapper';
import { STATES } from 'constants/index';
import 'react-virtualized/styles.css';
import { AutoSizer, Table, Column } from 'react-virtualized';
import withModals from 'modules/withModals';
import { formatUTCDate } from 'modules/helpers';
import { showAlert } from 'actions/index';
import AgentRegistrationDetailModal from '../AgentRegistrationDetailModal';
import AgentInivteModal from './AgentInivteModal';
import OrganizationManagerModal from '../OrganizationManagerModal';


const TAB = {
  WaitingApproval: 0,
  Approved: 1,
  NotApproved: 2,
  Active: 3,
}

const HorizontalTabOptions = [
  { display: 'Active', value: TAB.Active },
  { display: 'Invited', value: TAB.Approved },
];

const StateOptions = STATES.map(state => ({
  label: state,
  value: state,
}));

class NKAgentsList extends Component {
  state = {
    activeTab: TAB.Active,
    selectedState: null,
    agents: [],
    sortCurrentColumn: 'RegistrationDate',
    sortCurrentDirection: 'ASC',
  }

  componentDidMount() {
    if (this.props.selectedLO) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.selectedLO, prevProps.selectedLO)) {
      this.fetchData();
    }
  }

   _setTableRef = ref => {
     this._table = ref;
   };

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.fetchData()
    });
  }

  fetchData = async () => {
    const { activeTab } = this.state;
    const { selectedLO } = this.props

    if (!selectedLO) {
      return;
    }

    try {
      const apiRes = await getAgentsRegistrationPreferredLoanOfficer(selectedLO.LOID, activeTab);

      this.setState({
        agents: apiRes,
      }, () => {
        this.handleSort();
      })
    } catch (error) {}
  }

  changeHorizontalTab = activeTab => {
    this.setState({
      activeTab,
      agents: [],
      selectedState: null,
    }, () => {
      this.fetchData();
    });
  };

  handleShowAgentDetailModal = (selectedAgent) => () => {
    this.props.openModal('AgentRegistrationDetailModal', {
      agent: selectedAgent,
      dispatch: this.props.dispatch,
      onUpdate: () => {
        this.props.dispatch(showAlert('Saved successfully.', { variant: 'success', icon: 'bell' }));
        this.fetchData()
      },
    })
  }

  handleInviteModal = () => {
    const { userLevel } = this.props;
    this.props.openModal('AgentInivteModal', {
      isAdminUserPermisison: (userLevel === 1 || userLevel === 2),
      LOID: this.props.selectedLO?.LOID,
      dispatch: this.props.dispatch,
      onUpdate: () => {
        this.fetchData()
      },
    })
  }

  _sort = ({ sortBy, sortDirection }) => {
    this.setState({
      sortCurrentColumn: sortBy,
      sortCurrentDirection: sortDirection,
    }, () => this.handleSort())
  }

  handleSort = () => {
    const { sortCurrentColumn, agents, sortCurrentDirection } = this.state;

    let sorted = orderBy(agents, [sortCurrentColumn], [sortCurrentDirection.toLocaleLowerCase()]);
    if (sortCurrentDirection === 'DESC') {
      const nullItems = sorted.filter(i => i[sortCurrentColumn] === null)
      const unNullItems = sorted.filter(i => i[sortCurrentColumn] !== null)
      sorted = [...unNullItems, ...nullItems]
    }

    this.setState({ agents: sorted })
  };

  getTableHeight = () => {
    const elem = document.getElementById('table-wrapper');
    let calcHeight = 600

    if (elem) {
      const rect = elem.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      calcHeight = screenHeight - rect.top - 80
    }
    return calcHeight;
  }

  getRowRenderer = props => {
    const { _key, className, columns, index, style, rowData } = props;
    const { ActivityOverdueFlag } = rowData;

    const isOdd = index % 2;

    return (
      <TableRow
        key={_key}
        style={{
          ...style,
          backgroundColor: isOdd ? 'white' : '#eeeeee',
          paddingTop: 7,
        }}
        red={ActivityOverdueFlag}
      >
        <div className={className}>{columns}</div>
      </TableRow>
    );
  };

  handleOrganizationManager = () => {
    this.props.openModal('OrganizationManagerModal', {
      dispatch: this.props.dispatch,
      onUpdate: () => {},
    })
  }

  render() {
    const {
      activeTab,
      sortCurrentColumn,
      sortCurrentDirection,
      agents,
      selectedState,
    } = this.state;

    const { selectedLO, userLevel } = this.props;

    let filteredAgents = agents
    if (selectedState && selectedState.value) {
      filteredAgents = agents.filter(el => el.StateCode === selectedState.value)
    }

    return (
      <Root>
        <InviteButton disabled={!selectedLO} onClick={this.handleInviteModal}>Invite Agent</InviteButton>
        <Name>Loan Officer: {selectedLO?.Name || ''}</Name>
        <SectionContentWrapper
          title=""
          hActiveTab={activeTab}
          horizonalTabOptions={HorizontalTabOptions}
          onHorizonalTabChange={this.changeHorizontalTab}
        >
          <Wrapper id="table-wrapper">
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  ref={this._setTableRef}
                  headerHeight={70}
                  noRowsRenderer={() => <div />}
                  rowHeight={80}
                  rowGetter={({ index }) => filteredAgents[index]}
                  rowCount={filteredAgents.length}
                  height={this.getTableHeight()}
                  rowRenderer={this.getRowRenderer}
                  width={width}
                  sort={this._sort}
                  sortBy={sortCurrentColumn}
                  sortDirection={sortCurrentDirection}
                >
                  <Column
                    width={170}
                    label="Invite Date"
                    dataKey="RegistrationDate"
                    cellRenderer={({ cellData }) => cellData ? formatUTCDate(cellData) : ''}
                  />
                  <Column
                    label="Agent"
                    dataKey="AgentName"
                    width={200}
                    style={{ textAlign: 'left' }}
                    headerStyle={{ justifyContent: 'flex-start' }}
                    cellRenderer={({ rowData }) => {
                      const { AgentName } = rowData;
                      return <LinkButton onClick={this.handleShowAgentDetailModal(rowData)}>{AgentName}</LinkButton>;
                    }}
                  />
                  <Column
                    width={170}
                    label="Organization"
                    dataKey="OrganizationName"
                    disableSort
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={270}
                    label="Email"
                    dataKey="Email"
                    disableSort
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={150}
                    label="Mobile Phone"
                    disableSort
                    dataKey="MobilePhoneNumber"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={120}
                    dataKey="StateCode"
                    cellRenderer={({ cellData }) => cellData}
                    label={(
                      <div>
                        <div>State</div>
                        <div onClick={e=> e.stopPropagation()}>
                          <StateSelect
                            // eslint-disable-next-line no-use-before-define
                            styles={CustomDropdownStyles}
                            isSearchable
                            isClearable
                            placeholder="All"
                            options={StateOptions}
                            onChange={(state) => this.handleChange('selectedState', state)}
                            value={selectedState}
                          />
                        </div>
                      </div>
                    )}
                  />
                  {activeTab === TAB.Active && (
                    <Column
                      width={120}
                      label="AGENT ID"
                      dataKey="NKAID"
                      disableSort
                      cellRenderer={({ cellData }) => cellData}
                    />
                  )}
                  {activeTab === TAB.Active && (
                    <Column
                      width={100}
                      label="Status"
                      dataKey="Status"
                      disableSort
                      cellRenderer={({ cellData }) => cellData}
                    />
                  )}
                </Table>
              )}
            </AutoSizer>
            {(userLevel === 1 || userLevel === 2) && (
              <OrganizationManagerButton onClick={this.handleOrganizationManager}>Organization Manager</OrganizationManagerButton>
            )}
          </Wrapper>
        </SectionContentWrapper>
      </Root>
    )
  };
}

function mapStateToProps(state) {
  return {
    userLevel: state.user?.data?.PermissionsLevel,
  };
}

export default compose(
  connect(mapStateToProps),
  withModals({
    AgentRegistrationDetailModal,
    AgentInivteModal,
    OrganizationManagerModal,
  })
)(NKAgentsList)


const Root = styled.div`
  position: relative;
`;

const Name = styled.div`
  position: absolute;
  font-weight: bold;
  line-height: 31px;
  font-size: 1.4rem;
`;

const InviteButton = styled.button`
  position: absolute;
  background: ${props => props.theme.palette.gray};
  color: white;
  min-width: unset;
  font-size: 1.3rem;
  border-radius: 6px;
  padding: 5px 7px;
  top: 6px;
  right: 0px;

  &:focus, &:hover {
    color: white;
  }
`;

const TableRow = styled.div`
  ${({ red }) => red && css`
    color: red;

    button {
      color: red;
    }
  `}
`;

const StateSelect = styled(Select)`
  text-align: left;
  width: 100%;
  font-size: 1.2rem;
  box-shadow: none;
  border-radius: 3px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 28px;
    min-width: 70px;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '0px 8px',
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    '& > div': {
      padding: '0 0px',
      background: 'white',
      zIndex: 10,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: 200,
  }),
}

const Wrapper = styled.div`
  font-size: 1.3rem;

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }

  .ReactVirtualized__Table__headerRow {
    overflow: visible !important;
  }

  .ReactVirtualized__Table__headerColumn {
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      outline: none;
    }

    .ReactVirtualized__Table__headerTruncatedText {
      white-space: pre-wrap;
      text-align: center;
      overflow: visible !important;
    }

    .ReactVirtualized__Table__sortableHeaderIcon {
      font-size: 22px;
    }
  }

  .ReactVirtualized__Table__rowColumn {
    text-align: center;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .ReactVirtualized__Table__row {
    height: 100%;
  }
`;

const LinkButton = styled.button`
  text-decoration: underline;
  color: blue;
  text-align: left;
`;

const OrganizationManagerButton = styled.button`
  text-decoration: underline;
  text-align: left;
  font-size: 1.5rem;
  margin-top: 1rem;
`;


