import React, { Component } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import {
  getRecruitingAssignedAgentsRecruiter,
  getLoanOfficers,
} from 'modules/api';
import Table, { ProgressRow } from 'components/Table';
import _ from 'lodash';
import SectionContentWrapper from 'components/TabWrapper';
import withModals from 'modules/withModals';
import { formatUTCDate } from 'modules/helpers';
import ActivityModal from './ActivityModal';
import DownloadCSV from 'components/DownloadCSV';
import SendMessageModal from '../MyInbox/SendMessageModal'

const TAB = {
  IN_PROGRESS: 1,
  NOT_STARTED: 2,
  IN_POOL: 5,
  RECRUITED: 3,
  CLOSED: 4,
};

const HorizontalTabOptions = [
  { display: 'In Progress', value: TAB.IN_PROGRESS },
  { display: 'Not Started', value: TAB.NOT_STARTED },
  { display: 'In Pool', value: TAB.IN_POOL },
  { display: 'Recruited', value: TAB.RECRUITED },
  { display: 'Closed', value: TAB.CLOSED },
];

const TableHeaderCell = props => {
  const { children, sorted, ...rest } = props;
  return (
    <TableHeader sorted={sorted} {...rest}>
      <div>{children}</div>
    </TableHeader>
  );
};

class MyRecruiting extends Component {
  state = {
    selectedLO: null,
    allLoanOfficers: [],
    activityGroup: TAB.IN_PROGRESS,
    selectedState: null,
    agents: [],
    sortCurrentColumn: 'AssignmentDate',
    sortCurrentDirection: 'asc',
    selectedAgent: {},
    showAgentModal: false,
    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };

  componentDidMount() {
    getLoanOfficers().then(res => {
      const allLoanOfficers = res.filter(r => r.Status === 'Active').map(r => ({
        ...r,
        Name: `${r.FirstName} ${r.LastName}`,
        LOID: r.ID
      }))
      this.setState({ allLoanOfficers });
    })

    this.fetchData();
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.fetchData();
    });
  };

  fetchData = () => {
    const { activityGroup } = this.state;
    const recruiterID = this.props.user.PK;

    const isShowDateRange = activityGroup === TAB.RECRUITED || activityGroup === TAB.CLOSED
    const period = isShowDateRange ? 4 : null
    const startDate = isShowDateRange ? this.state.startDate : null
    const endDate = isShowDateRange ? this.state.endDate : null
    
    getRecruitingAssignedAgentsRecruiter(recruiterID, activityGroup, period, startDate, endDate).then(res => {
      this.setState(
        {
          agents: res.result,
        },
        () => {
          this.handleSort();
        },
      );
    });
  };

  changeHorizontalTab = activityGroup => {
    this.setState(
      {
        activityGroup,
        agents: [],
      },
      () => {
        this.fetchData();
      },
    );
  };

  handleSort = clickedColumn => {
    const { sortCurrentColumn, agents, sortCurrentDirection } = this.state;

    let updatedSortDirection = sortCurrentDirection;
    if (clickedColumn && sortCurrentColumn === clickedColumn) {
      updatedSortDirection = sortCurrentDirection === 'asc' ? 'desc' : 'asc';
    }

    let updatedSortCurrentColumn = sortCurrentColumn;
    if (clickedColumn) {
      updatedSortCurrentColumn = clickedColumn;
    }

    let sorted = _.sortBy(agents, [updatedSortCurrentColumn]);
    if (updatedSortDirection === 'desc') {
      sorted = sorted.reverse();
    }

    this.setState({
      sortCurrentColumn: updatedSortCurrentColumn,
      agents: sorted,
      sortCurrentDirection: updatedSortDirection,
    });
  };

  handleShowActivityModal = selectedAgent => () => {
    this.props.openModal('ActivityModal', {
      user: this.props.user,
      dispatch: this.props.dispatch,
      selectedAgent,
      assignmentPK: selectedAgent.AssignmentPK,
      lastActivity: selectedAgent.LastActivity,
      onSubmit: () => {
        this.fetchData();
        this.props.closeModal('ActivityModal');
      },
    });
  };

  handleOpenSendMessageModal = (agent, isEmail) => () => {
    this.props.openModal('SendMessageModal', {
      isEmail,
      user: this.props.user,
      dispatch: this.props.dispatch,
      onSend: () => {},
      kwuid: agent.KWUID
    });
  };

  render() {
    const {
      activityGroup,
      sortCurrentColumn,
      sortCurrentDirection,
      agents,
      startDate,
      endDate,
    } = this.state;

    const {FirstName, LastName} = this.props.user

    return (
      <Root>
        <DownloadWrapper>
          <DownloadCSV data={agents} filename={`MyRecruiting`}/>
        </DownloadWrapper>
        <Name>{`${FirstName} ${LastName}`}</Name>
        <SectionContentWrapper
          title=""
          hActiveTab={activityGroup}
          horizonalTabOptions={HorizontalTabOptions}
          onHorizonalTabChange={this.changeHorizontalTab}
        >
          {(activityGroup === TAB.RECRUITED || activityGroup === TAB.CLOSED) &&
            <DateRange>
              <Row>
                <RadioLabel>Start Date</RadioLabel>
                <input 
                  placeholder="Start Date"
                  type="date" 
                  value={startDate} 
                  onChange={e => this.handleChange('startDate', e.target.value || null)}
                />
              </Row>
              <Row>
                <RadioLabel>End Date</RadioLabel>
                <input 
                  placeholder="End Date"
                  type="date" 
                  value={endDate} 
                  onChange={e => this.handleChange('endDate', e.target.value || null)}
                />
              </Row>
            </DateRange>
          }
          
          <Table borderless striped>
            <thead>
              <tr>
                <TableHeaderCell />
                <TableHeaderCell
                  sorted={sortCurrentColumn === 'AssignmentDate' ? sortCurrentDirection : null}
                  onClick={() => this.handleSort('AssignmentDate')}
                >
                  Assigned Date
                </TableHeaderCell>
                <TableHeaderCell>KWUID</TableHeaderCell>
                <TableHeaderCell
                  sorted={sortCurrentColumn === 'AgentFirstName' ? sortCurrentDirection : null}
                  onClick={() => this.handleSort('AgentFirstName')}
                >
                  Agent Name
                </TableHeaderCell>
                {/* <TableHeaderCell
                  sorted={sortCurrentColumn === 'LastActivity' ? sortCurrentDirection : null}
                  onClick={() => this.handleSort('LastActivity')}
                >
                  Last Activity
                </TableHeaderCell>  */}
                {activityGroup === TAB.RECRUITED && <TableHeaderCell>LO Assigned</TableHeaderCell>}
                {/* <TableHeaderCell>
                  Activity
                </TableHeaderCell>  */}
                <TableHeaderCell>Email</TableHeaderCell>
                <TableHeaderCell>Mobile Phone</TableHeaderCell>
                <TableHeaderCell>MC State</TableHeaderCell>
                <TableHeaderCell>Market Center</TableHeaderCell>
                <TableHeaderCell
                  sorted={sortCurrentColumn === 'AgentCreatedDate' ? sortCurrentDirection : null}
                  onClick={() => this.handleSort('AgentCreatedDate')}
                >
                  Agent Created Date
                </TableHeaderCell>
              </tr>
            </thead>
            <tbody>
              {agents.map((agent, index) => (
                <TableRow key={index} progress={0} odd={index % 2} red={agent.ActivityOverdueFlag}>
                  <td>
                    <LogActivityButton onClick={this.handleShowActivityModal(agent)}>
                      Log Activity
                    </LogActivityButton>
                  </td>
                  <td>{agent.AssignmentDate ? formatUTCDate(agent.AssignmentDate) : ''}</td>
                  <td>{agent.KWUID}</td>
                  <td>{`${agent.AgentFirstName} ${agent.AgentLastName}`}</td>
                  {activityGroup === TAB.RECRUITED && (
                    <td>{`${agent.LOFirstName} ${agent.LOLastName}`}</td>
                  )}
                  <td>
                    <LinkButton onClick={this.handleOpenSendMessageModal(agent, true)}>{agent.AgentEmail}</LinkButton>
                  </td>
                  <td>
                    <LinkButton onClick={this.handleOpenSendMessageModal(agent, false)}>{agent.AgentMobilePhone}</LinkButton>
                  </td>
                  <td>{agent.AgentStateCode}</td>
                  <td>{`${agent.MarketCenterName} (${agent.MarketCenterID}) - ${agent.MarketCenterTeamLeader}`}</td>
                  <td>{agent.AgentCreatedDate ? formatUTCDate(agent.AgentCreatedDate) : ''}</td>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </SectionContentWrapper>
      </Root>
    );
  }
}

export default withModals({
    ActivityModal,
    SendMessageModal,
  })(MyRecruiting);

const Root = styled.div`
  position: relative;
`;

const DownloadWrapper = styled.div`
  height: 0;
  position: relative;

  & > a {
    transform: translateY(0%);
    right: 0;
  }
`;

const Name = styled.div`
  position: absolute;
  font-weight: bold;
  line-height: 31px;
  font-size: 1.4rem;
`;

const TableHeader = styled.th`
  cursor: pointer;
  vertical-align: middle !important;

  & > div {
    display: flex;
    align-items: center;
    justify-content: ${props => (props.leftAligned ? 'flex-start' : 'center')};
    color: ${props => props.theme.palette.gray};
    font-size: 1.2rem;
    text-align: ${props => (props.leftAligned ? 'left' : 'center')};

    &:after {
      display: ${props => (props.sorted ? 'block' : 'none')};
      content: '';
      margin-left: 0.5rem;
      width: 0; 
      height: 0; 
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-${props => (props.sorted === 'asc' ? 'bottom' : 'top')}: 0.6rem solid ${props =>
  props.theme.palette.light};
      ;
    }
  }
`;

const TableRow = styled(ProgressRow)`
  & td {
    width: 15%;
    vertical-align: middle !important;
    font-size: 1.1rem;
    text-align: center;

    ${({ red }) =>
    red &&
      css`
        color: red;
      `}
  }

  & td:nth-of-type(1) {
    width: 40px;
  }
`;

const LinkButton = styled.button`
  text-decoration: underline;
  margin: 0 2px;
  white-space: nowrap;
`;

const LinkButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EmailButton = styled.a`
  color: inherit;
  text-decoration: underline;
`;

const LogActivityButton = styled.button`
  background: ${props => props.theme.palette.primary};
  padding: 10px 10px;
  color: white;
  border-radius: 3px;
  white-space: nowrap;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
`;

const DateRange = styled.div`
  margin-right: 3.0rem;

  span {
    width: 8.1rem;
    margin-right: 1.0rem;
  }
`;

const RadioLabel = styled.span`
  margin-left: .4rem;
  margin-right: 2.0rem;
  font-size: 1.5rem;
`;
