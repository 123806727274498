import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Container from 'components/Container';
import Helmet from 'react-helmet';
import { media, MobileWidth } from 'modules/theme';
import { NavLink } from 'react-router-dom';
import Users from './Users';
import Notifications from './Notifications';

const Body = styled(Container)`
  display: flex;
  padding-top: 0;

  ${media.mobile`
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
  `};

  ${media.handheld`
    padding-left: 10px;
    padding-right: 10px;
  `};
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  min-width: 200px;
`;

const MenuLink = styled(NavLink)`
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  background: white;
  text-align: center;
  color: ${props => props.theme.palette.gray};

  &.active {
    color: ${props => props.theme.palette.primary};
  }
`;

const Menus = {
  USERS: 'users',
  NOTIFICATIONS: 'notifications',
}

class Admin extends Component {
  state = {
    isMobile: false,
    showMobileModal: false
  }

  getScreenWdith = () => {
    let isMobile = false;
    if (window.innerWidth < MobileWidth) {
      isMobile = true;
    }
     this.setState({ isMobile });
  }

  componentDidMount() {
    this.getScreenWdith();
    window.addEventListener("resize", this.getScreenWdith);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.getScreenWdith);
  }

  handleModal = (showMobileModal) => () => {
    this.setState({ showMobileModal })
  }

  render() {
    const { user } = this.props;
    const isAdmin = user.data.PermissionsLevel === 1;
    const tab = this.props.match.params.tab;

    if (!isAdmin) {
      return <Redirect to={`/private/admin/home`} />
    }

    return (
      <Fragment>
        <Helmet>
          <title>Admin</title>
          <meta name="description" content="This is the Keller Mortgage Admin page" />
        </Helmet>
        <Body>
          <NavBar>
            <MenuLink to={`/private/admin/${Menus.USERS}`} exact activeClassName="active" onClick={this.handleModal(true)}>
              Users
            </MenuLink>
            <MenuLink to={`/private/admin/${Menus.NOTIFICATIONS}`} exact activeClassName="active" onClick={this.handleModal(true)}>
              Notifications <br/> (Portal Admin)
            </MenuLink>
          </NavBar>
          {tab === Menus.USERS && <Users/>}
          {tab === Menus.NOTIFICATIONS && <Notifications />}
        </Body>
      </Fragment>
    )
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(Admin);

