/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import {
  getAgentOrganizations,
  getUSAStates,
  addAgentsRegistrationInvite,
  getAgentValidateEmail,
} from 'modules/api';
import Button from 'components/Button';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import { showAlert } from 'actions/index';
import withModals from 'modules/withModals';
import OrganizationManagerModal from '../OrganizationManagerModal';

class AgentInivteModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    data: {},
    states: [],
    organizations: [],
    isSubmitting: false,
    csvArray: [],
    isCsvComplete: false,
    invalidCSVError: '',
    isSelectedCSV: false,
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.initFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.initFetch();
    }
  }

  initFetch = () => {
    getUSAStates()
      .then(res => {
        this.setState({ states: res })
      })
      .catch(() => {});

    this.fetchOrganizations()
  }

  fetchOrganizations = () => {
    getAgentOrganizations()
      .then(res => {
        this.setState({ organizations: res })
      })
      .catch(() => {});
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleChange = (key, newValue) => {
    const data = { ...this.state.data, [key]: newValue};
    this.setState({ data });
  };

  getExistingEmails = async (emails) => {
    const payload = emails.map(email => ({ email }));
    try {
      const res = await getAgentValidateEmail(JSON.stringify(payload));
      return res.map(el => el.email);
    } catch (error) {
      return false;
    }
  }

  handleSave = async () => {
    this.setState({ isSubmitting: true });

    const exisitngEmails = await this.getExistingEmails([this.state.data.Email]);
    if (!exisitngEmails) {
      this.setState({ isSubmitting: false });
      return;
    }

    if (exisitngEmails.length > 0) {
      this.setState({ isSubmitting: false });
      this.props.dispatch(showAlert('Email already exists.', { variant: 'danger', icon: 'bell' }));
      return;
    }

    try {
      const payload = {
        ID: '',
        PreferredLoanOfficerID: this.props.LOID,
        OrganizationName: '',
        ...this.state.data,
      }
      await addAgentsRegistrationInvite(JSON.stringify(payload));
      this.props.onClose();
      this.props.onUpdate();
      this.props.dispatch(showAlert('Invited successfully.', { variant: 'success', icon: 'bell' }));
      this.setState({ isSubmitting: false });
    } catch (error) {
      this.setState({ isSubmitting: false });
    }
  }

  handleSelectFile = e => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    this.setState({
      csvArray: [],
      isCsvComplete: false,
      invalidCSVError: '',
      isSelectedCSV: false,
    })

    const reader = new FileReader();

    reader.onload = (event) => {
      const text = event.target.result;
      this.processCSV(text);
    }

    reader.readAsText(file);
  };

  processCSV = async (str, delim=',') => {
    const firstLine = str.indexOf('\n');
    const headers = str.slice(0, firstLine).split(delim);
    let rows = []
    if (firstLine !== -1) {
      rows = str.slice(firstLine + 1).split('\n');
    }
    const csvArray = rows.map(row => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {})
      return eachObject;
    })

    this.setState({
      csvArray,
      isSelectedCSV: true,
    });

    const validArray = csvArray.filter(el => el.FirstName && el.LastName && el.Email);
    if (validArray.length !== csvArray.length || validArray.length === 0) {
      const invalidCSVError = (
        <>
          <span>Error: Missing Required Fields.</span>
          <br />
          <span>Correct the error and try again</span>
        </>
      );
      this.setState({ invalidCSVError });
      return;
    }

    // Make Importing Data transparent
    this.setState({ invalidCSVError: ' ' });

    const emails = validArray.map(el => el.Email);
    const exisitngEmails = await this.getExistingEmails(emails);

    if (exisitngEmails.length > 0) {
      const invalidCSVError = `Error: The following email(s) already exists: ${exisitngEmails.join(', ')}`;
      this.setState({ invalidCSVError });
      return;
    }

    this.setState({ invalidCSVError: '' });
    const payload = validArray.map(el => ({ ...el, PreferredLoanOfficerID: this.props.LOID }))
    addAgentsRegistrationInvite(JSON.stringify(payload))
      .then(() => {
        this.setState({ isCsvComplete: true });
        this.props.onUpdate();
      })
      .catch(() => {
      });
  }

  handleOrganizationManager = () => {
    this.props.openModal('OrganizationManagerModal', {
      dispatch: this.props.dispatch,
      onUpdate: () => {
        this.fetchOrganizations()
      },
    })
  }

  render() {
    const { isOpen, isAdminUserPermisison } = this.props;
    const { data, states, organizations, isSubmitting, csvArray, isCsvComplete, invalidCSVError, isSelectedCSV } = this.state;
    const nullOption = { label: '- - -', value: null };

    const stateOptions = [
      nullOption,
      ...states.map(el => ({
        label: el.StateName,
        value: el.StateCode,
      })
      )];

    const organizationsOptions = [
      nullOption,
      ...organizations.map(el => ({
        label: el.OrganizationName,
        value: el.ID,
      })
      )];

    const DropdownCss = `
      font-size: 1.4rem;
      & > div:first-of-type {
        border-radius: 2px;
        min-height: 40px;
      }
    `

    const isButtonDisabled = !data.FirstName || !data.LastName || !data.Email || !data.StateCode || isSubmitting

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>
          Agent Invite Manager
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Wrapper style={{flex: 1}}>
                <Subtitle>Import & Invite Agents</Subtitle>
                <UploadWrapper>
                  <UploadButton>
                    <input
                      id="add-agent-csv-file"
                      type="file"
                      accept=".csv"
                      onChange={this.handleSelectFile}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="add-agent-csv-file">
                      <span>Choose Import File</span>
                    </label>
                  </UploadButton>
                  {isSelectedCSV ? (
                    <>
                      <div>{`${csvArray.length} Records Found`}</div>
                      <div>Validating Data</div>
                      {!invalidCSVError && <div>Importing Data</div>}
                      {isCsvComplete && <div>Importing Complete</div>}
                      {isCsvComplete && <div>{`${csvArray.length} Invitations Added to the Email Queue`}</div>}
                      {invalidCSVError && <div className="error">{invalidCSVError}</div>}
                    </>
                  ) : (
                    <Instruction>
                      <div className="step-row">
                        <label>Step (1)</label>
                        <div>
                          <a
                            target="_blank"
                            href="https://s3.amazonaws.com/portal.kellermortgage.com/agent-import/agent-import-template.csv"
                          >Download Import Template
                          </a>
                        </div>
                      </div>
                      <div className="step-row">
                        <label>Step (2)</label>
                        <div>
                          <div>Complete the template</div>
                          <div>Required Fields:</div>
                          <ul>
                            <li>First Name</li>
                            <li>Last Name</li>
                            <li>Email</li>
                          </ul>
                        </div>
                      </div>
                      <div className="step-row">
                        <label>Step (3)</label>
                        <div>Import the File</div>
                      </div>
                    </Instruction>
                  )}
                </UploadWrapper>
              </Wrapper>
            </Col>
            <Col>
              <Wrapper>
                <Subtitle>Invite Agent</Subtitle>
                <RowItem>
                  <Label>Agent First Name&#42;</Label>
                  <Value>
                    <Input
                      value={data.FirstName || ''}
                      onChange={(e) => this.handleChange('FirstName', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Agent Last Name&#42;</Label>
                  <Value>
                    <Input
                      value={data.LastName || ''}
                      onChange={(e) => this.handleChange('LastName', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Agent Email&#42;</Label>
                  <Value>
                    <Input
                      value={data.Email || ''}
                      onChange={(e) => this.handleChange('Email', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Mobile Phone Number</Label>
                  <Value>
                    <Input
                      value={data.MobilePhoneNumber || ''}
                      onChange={(e) => this.handleChange('MobilePhoneNumber', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Phone</Label>
                  <Value>
                    <Input
                      value={data.Phone || ''}
                      onChange={(e) => this.handleChange('Phone', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Address 1</Label>
                  <Value>
                    <Input
                      value={data.Address1 || ''}
                      onChange={(e) => this.handleChange('Address1', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Address 2</Label>
                  <Value>
                    <Input
                      value={data.Address2 || ''}
                      onChange={(e) => this.handleChange('Address2', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>City</Label>
                  <Value>
                    <Input
                      value={data.City || ''}
                      onChange={(e) => this.handleChange('City', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>State&#42;</Label>
                  <Value>
                    <Dropdown
                      placeholder="- - -"
                      options={stateOptions}
                      onChange={(value) => this.handleChange('StateCode', value)}
                      value={data.StateCode}
                      css={DropdownCss}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Postal Code</Label>
                  <Value>
                    <Input
                      value={data.PostalCode || ''}
                      onChange={(e) => this.handleChange('PostalCode', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label
                    style={{ textDecoration: isAdminUserPermisison ? 'underline' : 'none', cursor: isAdminUserPermisison ? 'pointer' : 'auto' }}
                    onClick={isAdminUserPermisison && this.handleOrganizationManager}
                  >
                    Organization
                  </Label>
                  <Value>
                    <Dropdown
                      placeholder="- - -"
                      options={organizationsOptions}
                      onChange={(value) => this.handleChange('OrganizationID', value)}
                      value={data.OrganizationID}
                      css={DropdownCss}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label />
                  <Value>
                    <StyledButton disabled={isButtonDisabled} onClick={this.handleSave}>SEND INVITE</StyledButton>
                  </Value>
                </RowItem>
              </Wrapper>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default withModals({
  OrganizationManagerModal,
})(AgentInivteModal)


const Modal = styled(DigeryModal)`
  max-width: 1000px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    overflow: auto;
    min-height: 600px;
  }
`;

const Wrapper = styled.div`
  border: 1px solid black;
  padding: 2rem;
  margin: 0.5rem 0;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
`;

const Col = styled.div`
  flex: 1;
  margin: 0 0.5rem;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
`;

const RowItem = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  align-items: center;

  &.multiline-container {
    height: auto;
    align-items: flex-start;

    & > div:nth-child(1) {
      margin-top: 0.5rem;
    }
  }
`;

const Label = styled.div`
  width: 40%;
  text-align: left;
  padding-right: 20px;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  color: white;
  font-size: 1.4rem;
  height: 30px;
`;

const Subtitle = styled.div`
  font-weight: 500;
  margin-bottom: 1rem;
`;

const UploadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    margin-bottom: 8px;
    font-size: 1.4rem;
  }

  .error {
    color: red;
    text-align: center;
  }
`

const UploadButton = styled.div`
  width: 20rem;
  text-align: center;
  margin-top: 20px;

  span {
    background: ${props => props.theme.palette.gray};
    color: white;
    padding: 5px 10px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.4rem;
  }
`;

const Instruction = styled.div`
  .step-row {
    display: flex;

    label {
      width: 100px;
      text-align: left;
    }

    div {
      margin-bottom: 4px;
    }

    ul {
      margin-bottom: 0;
    }
    
  }
`