import React from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';

const Select = ({ styles, ...props }) => {

  return (
    <StyledSelect 
      styles={{...CustomDropdownStyles, ...styles}}
      {...props}
    />
  )
};

const StyledSelect = styled(ReactSelect)`
  text-align: left;
  width: 100%;
  font-size: 1.2rem;
  box-shadow: none;
  border-radius: 3px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 28px;
    min-width: 70px;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: () => ({
    padding: '0px 8px'
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    '& > div': {
      padding: '0 0px',
      background: 'white',
      zIndex: 10,
    }
  }),
}

export default Select;
