import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DigeryModal from 'components/Modal';
import Input from 'components/Input';
import Button from 'components/Button';
import { addRecruitingWebsiteLinks } from 'modules/api';

export default class AddWebsiteLinkModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    link: '',
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleAdd = () => {
    addRecruitingWebsiteLinks(this.state.link)
      .then(res => {
        this.handleClose()
        this.props.onAdd()
      })
  }

  render() {
    const { isOpen } = this.props;
    const { link } = this.state;

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>Add Website Link</ModalHeader>
        <ModalBody>
          <Input placeholder="Website Link" value={link} onChange={(e) => this.setState({ link: e.target.value })}/>
        </ModalBody>
        <ModalFooter>
          <StyledButton disabled={!link} onClick={this.handleAdd}>Add Link</StyledButton>
        </ModalFooter>
      </Modal>
    );
  }
}

const Modal = styled(DigeryModal)`
  max-width: 700px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    overflow: auto;
  }
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  color: white;
  min-width: unset;
  font-size: 1.4rem;
  height: 30px;
`;

