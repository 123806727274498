import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import theme, { appColor, headerHeight, media, MobileWidth } from 'modules/theme';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import Image from 'components/Image';
import { push } from 'modules/history';

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { logOut, toggleMobileSideMenu } from 'actions/index';

import Environment from '../environments/env';

const Logo = require('assets/media/brand/logo.png');
const MobileLogo = require('assets/media/brand/logo_white.png');
const PlaceholderAvatar = require('assets/media/images/sample-avatar.png');

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 40px 0 40px;
  height: ${headerHeight}px;
  z-index: 999;
  border-bottom: solid 0.1rem ${props => rgba(props.theme.palette.light, 0.5)};

  ${media.mobile`
    padding-left: 15px;
  `};
`;

const MobileMenuButton = styled.button`
  margin-right: 15px;
  align-self: center;
`;

const HeaderLogo = styled.div`
  align-items: center;
  display: inline-flex;
  font-size: 0;
  height: 100%;
  height: 35px;
  align-self: center;
  padding-right: 15px;

  img:first-of-type {
    height: auto;
    width: 202px;
  }

  img:last-of-type {
    display: none;
  }
`;

const HeaderLeftWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const AgentLogo = styled.div`
  color: ${props => props.theme.palette.primary};
  font-size: 2rem;
  padding: 1px 15px;
  align-self: center;
  border-left: 2px solid ${props => props.theme.palette.primary};
`;

const MenuDropDown = styled(UncontrolledDropdown)`
  min-width: 200px;
  color: ${props => props.theme.palette.light};
  font-size: 13px;
  margin-bottom: -1px;
  text-transform: uppercase;
  .dropdown-toggle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    padding: 0px 34px 0px 13px;
    border: solid 0.1rem ${props => rgba(props.theme.palette.light, 0.5)};
    border-radius: 4px 4px 0 0;
    background-color: white;
  }
  .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    content: '';
    border-top: 0.6em solid;
    border-right: 0.6em solid transparent;
    border-bottom: 0;
    border-left: 0.6em solid transparent;
    position: absolute;
    right: 11px;
    top: 22px;
  }
  .dropdown-menu {
    min-width: 200px;
  }
  .dropdown-item {
    color: ${props => props.theme.palette.light};
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-transform: uppercase;
    &:hover,
    &:focus,
    &.selected {
      color: ${appColor};
    }
    & > div:last-child {
      display: none;
    }
  }
`;

const Avatar = styled(Image)`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin-right: 10px;
`;

const RightButtons = styled.div`
  display: flex;
  margin-left: auto;
`;

class PrivateHeader extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  _Environment = new Environment();

  state = {
    isMobile: false,
  };

  getScreenWdith = () => {
    document.body.height = window.innerHeight;

    let isMobile = false;
    if (window.innerWidth < MobileWidth) {
      isMobile = true;
    }
    this.setState({ isMobile });
  };

  componentDidMount() {
    this.getScreenWdith();
    window.addEventListener('resize', this.getScreenWdith);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getScreenWdith);
  }

  handleClickLogout = () => {
    const { dispatch } = this.props;
    dispatch(logOut());
  };

  handleToggleMobileSideMenu = () => {
    const { dispatch } = this.props;
    dispatch(toggleMobileSideMenu());
  };

  handleShowProfile = () => {
    push('/private/my-profile');
  };

  render() {
    const { isMobile } = this.state;
    const { user } = this.props;
    const isRep = user.data.PermissionsLevel === 3;

    return (
      <HeaderWrapper>
        {isMobile && (
          <MobileMenuButton onClick={this.handleToggleMobileSideMenu}>
            <Icon name="hamburger" width={30} color={theme.palette.gray} />
          </MobileMenuButton>
        )}
        <HeaderLeftWrapper>
          <HeaderLogo>
            <Link to="/private/home">
              <img src={Logo} alt="" />
              <img src={MobileLogo} alt="" />
            </Link>
          </HeaderLogo>
          <AgentLogo>Admin</AgentLogo>
        </HeaderLeftWrapper>
        <RightButtons>
          {user.isAuthenticated && !isMobile && (
            <MenuDropDown setActiveFromChild>
              <DropdownToggle tag="div" caret>
                <Avatar src={user.data && user.data.photo ? user.data.photo : PlaceholderAvatar} />
                <span>
                  {user.data.FirstName ? `${user.data.FirstName} ${user.data.LastName}` : 'Admin'}
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem style={{display: isRep ? 'flex' : 'none'}} onClick={this.handleShowProfile}>My Profile</DropdownItem>
                <DropdownItem onClick={this.handleClickLogout}>Sign out</DropdownItem>
              </DropdownMenu>
            </MenuDropDown>
          )}
        </RightButtons>
      </HeaderWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    showMobileSideBar: state.showMobileSideBar,
  };
}

export default connect(mapStateToProps)(PrivateHeader);
