// Polyfills
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { store, persistor } from 'store/index';
import { showAlert } from 'actions/index';

import 'bootstrap/dist/css/bootstrap.min.css';

import App from 'containers/App';
import Loader from 'components/Loader';
import Reload from 'components/Reload';

export const app = {
  cssRetries: 0,
  fetchRetries: 0,

  run() {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: process.env.REACT_APP_ENV,
      ignoreErrors:[
        'Non-Error exception captured',
        'Non-Error promise rejection captured',
        'The operation is insecure', 'ServiceWorker', 'service worker',
      ],
    });

    this.render(App);

    /* istanbul ignore else */
    if (process.env.NODE_ENV === 'production') {
      this.initOfflinePlugin();
    }
  },
  initOfflinePlugin() {
    const OfflinePlugin = require('offline-plugin/runtime');

    /* istanbul ignore next */
    OfflinePlugin.install({
      onUpdateReady: () => {
        OfflinePlugin.applyUpdate();
      },
      onUpdated: () => {
        store.dispatch(showAlert(<Reload />, { id: 'sw-update', icon: 'bolt', timeout: 0 }));
      },
    });
  },
  render(Component) {
    const root = document.getElementById('react');

    /* istanbul ignore next */
    if (root) {
      ReactDOM.render(
        <BrowserRouter>
          <AppContainer>
            <Provider store={store}>
              <PersistGate loading={<Loader />} persistor={persistor}>
                <Component />
              </PersistGate>
            </Provider>
          </AppContainer>
        </BrowserRouter>,
        root,
      );
    }
  },
};

app.run();

/* istanbul ignore next  */
if (module.hot) {
  module.hot.accept('containers/App', () => app.render(App));
}
