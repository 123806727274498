import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { utils } from 'styled-minimal';

const IconWrapper = styled(SVG)`
  display: inline-block;
  line-height: 0;

  svg {
    height: auto;
    max-height: 100%;
    width: ${({ width }) => utils.px(width)};

    path {
      fill: ${({ color }) => color};
    }
  }
`;

const Icon = ({ name, ...rest }) => (
  <IconWrapper src={`${process.env.PUBLIC_URL}/media/icons/${name}.svg`} {...rest} />
);

Icon.propTypes = {
  color: PropTypes.string,
  name: PropTypes.oneOf([
    'bell-o',
    'bell',
    'bolt',
    'check-circle-o',
    'check-circle',
    'check',
    'dot-circle-o',
    'exclamation-circle',
    'question-circle-o',
    'question-circle',
    'sign-in',
    'sign-out',
    'times-circle-o',
    'times-circle',
    'times',
    'setting',
    'email',
    'account-balance',
    'more-vertical',
    'home',
    'application',
    'book',
    'comment',
    'integrations',
    'marketing',
    'phone',
    'question',
    'statistics',
    'attatch',
    'cloud_download',
    'create_new_folder',
    'crop_original',
    'file_download',
    'laptop_mac',
    'picture_as_pdf',
    'arrow_up',
    'arrow_down',
    'box',
    'undo',
    'play-circle',
    'sample-avatar',
    'document',
    'vimeo',
    'flyer',
    'logo',
    'exclamation-rectangle',
    'redo',
    'folder',
    'setting-menu',
    'send',
    'help',
    'facebook',
    'chat',
    'instagram',
    'twitter',
    'home_marketing',
    'home_refer_a_client',
    'home_deals',
    'home_km_settings',
    'home_5_star',
    'home_education',
    'home_faq',
    'home_testimonial',
    'home_scripts',
    'home_e_store',
    'sos',
    'burger',
    'phone2',
    'exclamation-circle-1',
    'hamburger',
    'two-men',
    'compose-email',
    'compose-sms',
    'list',
    'building',
  ]).isRequired,
  width: PropTypes.number,
};

Icon.defaultProps = {
  width: 20,
};

export default Icon;
