import styled from 'styled-components';

const Loading = styled.div`
  position: absolute;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid ${props => props.theme.palette.primary};
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  top: calc(50% - 15px);
  left: calc(50% - 15px);

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loading;
