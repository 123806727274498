import React, { Component } from 'react';
import styled from 'styled-components';
import withModals from 'modules/withModals';
import { Flex, Box } from 'styled-minimal';
import ClosedAdverseModal from './ClosedAdverseModal';
import { percentFormatter } from 'modules/helpers';
import RecruiterMessagesCountModal from '../KMDashboard/RecruiterMessagesCountModal';
import SurveyResponsesModal from '../KMDashboard/SurveyResponsesModal';
class RecruiterDashboard extends Component {
  handleShowClosedAdverse = () => {
    const { period, startDate, endDate, user } = this.props
    const recruiterID = user.PK
    this.props.openModal('ClosedAdverseModal', {
      recruiterID, period, startDate, endDate
    })
  }

  handleShowMessagesCount = (isSent) => () => {
    const { period, startDate, endDate, user } = this.props
    const recruiterID = user.PK

    this.props.openModal('RecruiterMessagesCountModal', {
      period, startDate, endDate, isSent, recruiterID
    })
  }

  handleShowSurveys = () => {
    const { period, startDate, endDate, user } = this.props
    const recruiterID = user.PK

    this.props.openModal('SurveyResponsesModal', {
      period, startDate, endDate, recruiterID,
    })
  }

  render() {
    const stats = this.props.stats || {}

    return (
      <Container>
        <Flex>
          <Box>
            <SubTitle>Agents</SubTitle>
            <Text>Assigned: {stats.AgentsAssigned}</Text>
            <Text>Called: {stats.AgentsCalled} ({percentFormatter(stats.AgentCalledPercent)})</Text>
            <Text>Made Contact: {stats.AgentsMadeContact} ({percentFormatter(stats.AgentsMadeContactPercent)})</Text>
            <Text>Assigned to LO: {stats.AgentsAssignedToLO} ({percentFormatter(stats.AgentsAssignedToLOPercent)})</Text>
            <LinkText onClick={() => this.handleShowClosedAdverse()}>
              Closed: {stats.AgentsClosed} ({percentFormatter(stats.AgentsClosedPercent)})
            </LinkText>
          </Box>
          <Box>
          <SubTitle>Recruiter Messages</SubTitle>
          <LinkText onClick={this.handleShowMessagesCount(true)}>Messages Sent: {stats.MessagesSent}</LinkText>
          <LinkText onClick={this.handleShowMessagesCount(false)}>Messages Received: {stats.MessagesReceived}</LinkText>
          <SubTitle>Surveys</SubTitle>
          <Text>Sent: {stats.SurveysSent}</Text>
          <LinkText onClick={this.handleShowSurveys}>
            Responses: {stats.SurveysReceived}({percentFormatter(stats.SurveysReceivedPercent)})
          </LinkText>
          </Box>
        </Flex>
      </Container>
    )
  };
}

export default 
  withModals({ 
    ClosedAdverseModal,
    RecruiterMessagesCountModal,
    SurveyResponsesModal,
  })(RecruiterDashboard)

const Container = styled.div`
  width: 100%;
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  padding: 15px 0 3px;
`;

const Text = styled.div`
  font-size: 1.4rem;
  padding: 2px 0;
`;

const LinkText = styled.div`
  font-size: 1.4rem;
  padding: 2px 0;
  cursor: pointer;
  text-decoration: underline;
`;
