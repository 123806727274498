import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';

const ButtonLink = styled(HashLink)`
  background-color: ${props => (props.outline ? 'white' : props.theme.palette.gray)};
  border-radius: 0.4rem;
  color: ${props => (props.outline ? props.theme.palette.gray : 'white')};
  text-transform: uppercase;
  min-width: 20rem;
  font-size: 1.6rem;
  padding: 0 1rem;
  height: 4.2rem;
  border: 1px solid ${props => (props.outline ? props.theme.palette.gray : 'transparent')};
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;

  &:hover,
  &:focus {
    color: ${props => (!props.outline ? 'white' : props.theme.palette.gray)};
    text-decoration: none;
  }
`;

export default ButtonLink;
