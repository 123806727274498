import React, { Component } from 'react';
import styled from 'styled-components';
import history from 'modules/history';
import { Table } from 'reactstrap';
import { sumBy } from 'lodash';
import { Flex, Box } from 'styled-minimal';
import withModals from 'modules/withModals';
import MarketCenterStatsModal from './MarketCenterStatsModal';
import LoanOfficerStatsModal from './LoanOfficerStatsModal';
import RecruiterStatsModal from './RecruiterStatsModal';
import MarketCenterDetailsModal from './MarketCenterDetailsModal';
import RecruiterMessagesCountModal from './RecruiterMessagesCountModal';
import ClosedAdverseModal from '../RecruiterDashboard/ClosedAdverseModal';
import SurveyResponsesModal from './SurveyResponsesModal';

import { percentFormatter } from 'modules/helpers';
class KMDashboard extends Component {
  state = {
    recruiterMessagesCounts: {},
  }

  handleShowAllMCs = (OptoutFlag) => () => {
    const { period, startDate, endDate } = this.props
    this.props.openModal('MarketCenterStatsModal', {
      period, startDate, endDate, OptoutFlag
    })
  }

  handleShowAllLOs = () => {
    const { period, startDate, endDate } = this.props
    this.props.openModal('LoanOfficerStatsModal',
    { period, startDate, endDate })
  }

  handleShowAllRecruiters = () => {
    const { period, startDate, endDate } = this.props
    this.props.openModal('RecruiterStatsModal',
    { period, startDate, endDate })
  }

  handleShowClosedAdverse = (recruiterID) => {
    const { period, startDate, endDate } = this.props
    this.props.openModal('ClosedAdverseModal', {
      recruiterID, period, startDate, endDate
    })
  }

  handleShowMarketCenterDetails = (marketCenter) => () => {
    const { period, startDate, endDate } = this.props
    this.props.openModal('MarketCenterDetailsModal', {
      period, startDate, endDate, marketCenter
    })
  }

  handleMoveLoanOfficerMyRecruiting = (LOID) => () => {
    if (!LOID) {
      return
    }
    history.push(`/private/recruiting/my_recruiting?LOID=${LOID}`)
  }

  handleShowMessagesCount = (isSent) => () => {
    const { period, startDate, endDate } = this.props
    this.props.openModal('RecruiterMessagesCountModal', {
      period, startDate, endDate, isSent
    })
  }

  handleShowSurveys = () => {
    const { period, startDate, endDate } = this.props
    this.props.openModal('SurveyResponsesModal', {
      period, startDate, endDate
    })
  }

  render() {
    const { recruiterSnapshot, marketCenterSnapshot, loanOfficerSnapshot } = this.props
    const stats = this.props.stats || {}
    
    return (
      <Container>
        <Flex>
          <Box>
            <SubTitle>Agents</SubTitle>
            <Text>Participating: {stats.AgentsParticipating}</Text>
            <Text>Called: {stats.AgentsCalled} ({percentFormatter(stats.AgentsCalledPercent)})</Text>
            <Text>Made Contact: {stats.AgentsMadeContact} ({percentFormatter(stats.AgentsMadeContactPercent)})</Text>
            <Text>Assigned to LO: {stats.AgentsAssignedToLO} ({percentFormatter(stats.AgentsAssignedToLOPercent)})</Text>
            <LinkText onClick={() => this.handleShowClosedAdverse(null)}>
              Closed: {stats.AgentsClosed} ({percentFormatter(stats.AgentsClosedPercent)})
            </LinkText>
            <Spacer/>
            <Text>My LO Invite: {stats.AgentsMyLOInvites} ({percentFormatter(stats.AgentsMyLOInvitesPercent)})</Text>
            <Text>My LO: {stats.AgentsMyLO} ({percentFormatter(stats.AgentsMyLOPercent)})</Text>
          </Box>
          <Box>
            <SubTitle>Market Centers</SubTitle>
            <LinkText onClick={this.handleShowAllMCs(null)}>
              Opted-in: {stats.MarketCentersOptin}
            </LinkText>
            <LinkText>
              Opted-out: {stats.MarketCentersOptout}
            </LinkText>
            <SubTitle>Production</SubTitle>
            <Text>Leads In: {stats.LeadsReceived}</Text>
            <Text>Deals Closed: {stats.DealsClosed}</Text>
            <SubTitle>Recruiter Messages</SubTitle>
            <LinkText onClick={this.handleShowMessagesCount(true)}>Messages Sent: {stats.MessagesSent}</LinkText>
            <LinkText onClick={this.handleShowMessagesCount(false)}>Messages Received: {stats.MessagesReceived}</LinkText>
          </Box>
          <Box>
            <SubTitle>Loan officers</SubTitle>
            <LinkText onClick={this.handleShowAllLOs}>
              Active: {stats.LoanOfficersActive}
            </LinkText>
            <SubTitle>Recruiters</SubTitle>
            <LinkText onClick={this.handleShowAllRecruiters}>
              Active: {stats.RecruitersActive}
            </LinkText>
            <SubTitle>Surveys</SubTitle>
            <Text>Sent: {stats.SurveysSent}</Text>
            <LinkText onClick={this.handleShowSurveys}>
              Responses: {stats.SurveysReceived}({percentFormatter(stats.SurveysReceivedPercent)})
            </LinkText>
          </Box>
        </Flex>

        <Title>Market Center Snapshot:</Title>
        <SnapshotTable striped>
          <thead>
            <tr>
              <WhiteTh colSpan={2}></WhiteTh>
              <WhiteTh />
              <th colSpan={7}>Agents</th>
              <WhiteTh />
              <th colSpan={2}>Production</th>
            </tr>
            <tr>
              <WhiteTh style={{ height: 8 }} colSpan={10}></WhiteTh>
            </tr>
            <tr>
              <th className="number-cell"></th>
              <th className="name-cell">Market Center</th>
              <WhiteTh />
              <th>Participating</th>
              <th>Called</th>
              <th>Made Contact</th>
              <th>Assigned to LO</th>
              <th>Closed</th>
              <th>My LO</th>
              <th>My LO Invites</th>
              <WhiteTh />
              <th>Leads</th>
              <th>Closed</th>
            </tr>
          </thead>
          <tbody>
            {marketCenterSnapshot.slice(0,5).map((row, index) => 
              <tr>
                <td className="number-cell">{index + 1}</td>
                <td className="name-cell">
                  <LinkButton onClick={this.handleShowMarketCenterDetails(row)}>{`${row.MarketCenterName} (${row.MarketCenterID})`}</LinkButton>
                </td>
                <WhiteTd/>
                <td>{row.AgentsParticipating}</td>
                <td>{row.AgentsCalled}</td>
                <td>{row.AgentsMadeContact}</td>
                <td>{row.AgentsAssignedToLO}</td>
                <td>{row.AgentsClosed}</td>
                <td>{row.AgentsMyLO}</td>
                <td>{row.AgentsMyLOInvites}</td>
                <WhiteTd/>
                <td>{row.LeadsReceived}</td>
                <td>{row.DealsClosed}</td>
              </tr>
            )}
            {marketCenterSnapshot.length > 0 && (
              <tr>
                <td></td>
                <td><LinkButton onClick={this.handleShowAllMCs(null)}>View All</LinkButton></td>
                <td colSpan={11}></td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>Total</td>
              <WhiteTd />
              <td>{sumBy(marketCenterSnapshot, 'AgentsParticipating')}</td>
              <td>{sumBy(marketCenterSnapshot, 'AgentsCalled')}</td>
              <td>{sumBy(marketCenterSnapshot, 'AgentsMadeContact')}</td>
              <td>{sumBy(marketCenterSnapshot, 'AgentsAssignedToLO')}</td>
              <td>{sumBy(marketCenterSnapshot, 'AgentsClosed')}</td>
              <td>{sumBy(marketCenterSnapshot, 'AgentsMyLO')}</td>
              <td>{sumBy(marketCenterSnapshot, 'AgentsMyLOInvites')}</td>
              <WhiteTd />
              <td>{sumBy(marketCenterSnapshot, 'LeadsReceived')}</td>
              <td>{sumBy(marketCenterSnapshot, 'DealsClosed')}</td>
            </tr>
          </tfoot>
        </SnapshotTable>

        <Title>Recruiter Snapshot:</Title>
        <SnapshotTable striped>
          <thead>
            <tr>
              <WhiteTh colSpan={2}></WhiteTh>
              <WhiteTh />
              <th colSpan={5}>Agents</th>
            </tr>
            <tr>
              <WhiteTh style={{ height: 8 }} colSpan={8}></WhiteTh>
            </tr>
            <tr>
              <th className="number-cell"></th>
              <th className="name-cell">Recruiter</th>
              <WhiteTh />
              <th>Assigned</th>
              <th>Called</th>
              <th>Made Contact</th>
              <th>Assigned to LO</th>
              <th>Closed</th>
            </tr>
          </thead>
          <tbody>
            {recruiterSnapshot.slice(0,5).map((row, index) => 
              <tr>
                <td className="number-cell">{index + 1}</td>
                <td className="name-cell"><a href={`${window.location.href}?RecruiterID=${row.ID}&FirstName=${row.FirstName}&LastName=${row.LastName}`} target="_blank">{`${row.FirstName} ${row.LastName}`}</a></td>
                <WhiteTd/>
                <td>{row.AgentsAssigned}</td>
                <td>{row.AgentsCalled}</td>
                <td>{row.AgentsMadeContact}</td>
                <td>{row.AgentsAssignedToLO}</td>
                <td>
                  <LinkText onClick={() => this.handleShowClosedAdverse(row.ID)}>{row.AgentsClosed}</LinkText>
                </td>
              </tr>
            )}
            {recruiterSnapshot.length > 0 && (
              <tr>
                <td></td>
                <td><LinkButton onClick={this.handleShowAllRecruiters}>View All</LinkButton></td>
                <td colSpan={6}></td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>Total</td>
              <WhiteTd />
              <td>{sumBy(recruiterSnapshot, 'AgentsAssigned')}</td>
              <td>{sumBy(recruiterSnapshot, 'AgentsCalled')}</td>
              <td>{sumBy(recruiterSnapshot, 'AgentsMadeContact')}</td>
              <td>{sumBy(recruiterSnapshot, 'AgentsAssignedToLO')}</td>
              <td>{sumBy(recruiterSnapshot, 'AgentsClosed')}</td>
            </tr>
          </tfoot>
        </SnapshotTable>

        <Title>Loan Officer Snapshot:</Title>
        <SnapshotTable striped>
          <thead>
            <tr>
              <WhiteTh colSpan={2}></WhiteTh>
              <WhiteTh />
              <th colSpan={3}>Agents</th>
              <WhiteTh />
              <th colSpan={2}>Production</th>
            </tr>
            <tr>
              <WhiteTh style={{ height: 8 }} colSpan={9}></WhiteTh>
            </tr>
            <tr>
              <th className="number-cell"></th>
              <th className="name-cell">Loan Officer</th>
              <WhiteTh />
              <th>Assigned to LO</th>
              <th>My LO</th>
              <th>My LO Invites</th>
              <WhiteTh />
              <th>Leads</th>
              <th>Closed</th>
            </tr>
          </thead>
          <tbody>
            {loanOfficerSnapshot.slice(0,5).map((row, index) => 
              <tr>
                <td className="number-cell">{index + 1}</td>
                <td className="name-cell">
                  <a href={`${window.location.href}?LOID=${row.LOID}&FirstName=${row.FirstName}&LastName=${row.LastName}`} target="_blank">{`${row.FirstName} ${row.LastName}`}</a>
                </td>
                <WhiteTd/>
                <td>{row.AgentsAssignedToLO}</td>
                <td>{row.AgentsMyLO}</td>
                <td>{row.AgentsMyLOInvites}</td>
                <WhiteTd/>
                <td>{row.LeadsReceived}</td>
                <td>{row.DealsClosed}</td>
              </tr>
            )}
            {loanOfficerSnapshot.length > 0 && (
              <tr>
                <td></td>
                <td><LinkButton onClick={this.handleShowAllLOs}>View All</LinkButton></td>
                <td colSpan={7}></td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>Total</td>
              <WhiteTd />
              <td>{sumBy(loanOfficerSnapshot, 'AgentsAssignedToLO')}</td>
              <td>{sumBy(loanOfficerSnapshot, 'AgentsMyLO')}</td>
              <td>{sumBy(loanOfficerSnapshot, 'AgentsMyLOInvites')}</td>
              <WhiteTd/>
              <td>{sumBy(loanOfficerSnapshot, 'LeadsReceived')}</td>
              <td>{sumBy(loanOfficerSnapshot, 'DealsClosed')}</td>
            </tr>
          </tfoot>
        </SnapshotTable>
      </Container>
    )
  };
}

export default 
  withModals({ 
    MarketCenterStatsModal,
    LoanOfficerStatsModal,
    ClosedAdverseModal,
    RecruiterStatsModal,
    MarketCenterDetailsModal,
    RecruiterMessagesCountModal,
    SurveyResponsesModal,
  })(KMDashboard)

const Container = styled.div`
  width: 100%;
`;

const Title = styled.div`
  margin: 40px 0 20px;
  font-size: 1.6rem;
  font-weight: bold;
`;

const UnderlineTitle = styled.div`
  width: 100%;
  border-bottom: 1px solid black;
  font-weight: 700;
  font-size: 1.6rem;
  padding: 10px 0 2px;
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  padding: 15px 0 0;
`;

const Text = styled.div`
  font-size: 1.4rem;
  padding: 2px 0;
`;

const LinkText = styled.div`
  font-size: 1.4rem;
  padding: 2px 0;
  cursor: pointer;
  text-decoration: underline;
`;

const SnapshotTable = styled(Table)`
  &&& {
    font-size: 1.2rem;

    tr {
      border: none;
    }

    tfoot td, th {
      text-align: center;
      height: 50px;
      vertical-align: middle;
      background: #ddd;
      border: none;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    th {
      padding: 2px;
      white-space: pre-wrap;
      word-break: break-all;
    }

    td {
      text-align: center;
      height: 50px;
      vertical-align: middle;
      border: none;
      white-space: pre-wrap;
      word-break: break-all;
      padding: 2px;

      a {
        text-decoration: underline;
        color: black;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .name-cell {
      width: 160px;
    }

    .number-cell {
      width: 22px;
    }
  }
`;

const WhiteTh = styled.th`
  background: white !important;
  width: 8px !important;
`;

const WhiteTd = styled.td`
  background: white !important;
  width: 8px !important;
`;

const LinkButton = styled.button`
  text-decoration: underline;
  margin: 0 2px;
  white-space: nowrap;
`;

const Spacer = styled.div`
  height: 1.5rem;
`


