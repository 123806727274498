import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import { getAgentOrganizations } from 'modules/api';
import Table, { ProgressRow } from 'components/Table';
import withModals from 'modules/withModals';

import AddEditOrganizationModal from './AddEditOrganizationModal';

class OrganizationManagerModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    organizations: [],
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.fetchOrganization();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.fetchOrganization();
    }
  }

  fetchOrganization = () => {
    getAgentOrganizations()
      .then(res => {
        this.setState({ organizations: res });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleClose = () => {
    this.props.onClose();
    this.props.onUpdate();
  };

  handleOpenDetailsModal = (organization) => () => {
    this.props.openModal('AddEditOrganizationModal', {
      organization,
      dispatch: this.props.dispatch,
      onUpdate: () => {
        this.fetchOrganization()
      },
    })
  };

  render() {
    const { isOpen } = this.props;
    const { organizations } = this.state;

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>Organization Manager</ModalHeader>
        <ModalBody>
          <Header>
            <LinkLargeButton onClick={this.handleOpenDetailsModal(null)}>Add Organization</LinkLargeButton>
          </Header>
          <TableWrapper>
            <Table>
              <thead>
                <tr>
                  <th>Organization</th>
                  <th>Address 1</th>
                  <th>Address 2</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Postal Code</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody>
                {organizations.map(el => (
                  <ProgressRow key={el.ID}>
                    <td>
                      <LinkButton onClick={this.handleOpenDetailsModal(el)}>{el.OrganizationName}</LinkButton>
                    </td>
                    <td>{el.Address1}</td>
                    <td>{el.Address2}</td>
                    <td>{el.City}</td>
                    <td>{el.StateCode}</td>
                    <td>{el.PostalCode}</td>
                    <td>{el.CountryCode}</td>
                  </ProgressRow>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        </ModalBody>
      </Modal>
    );
  }
}

export default withModals({
  AddEditOrganizationModal,
})(OrganizationManagerModal)

const Modal = styled(DigeryModal)`
  max-width: 900px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
  }
`;

const LinkButton = styled.button`
  font-size: 1.2rem;
  text-decoration: underline;
`;

const LinkLargeButton = styled.button`
  font-size: 1.4rem;
  text-decoration: underline;
`;

const Header = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 2rem;
`;

const TableWrapper = styled.div`
  height: 400px;
  overflow: auto;
`;