import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  padding-top: 2px;
  display: flex;
  flex-direction: row;
  margin-bottom: -1px;
  height: ${props => props.vertical ? '68px' : '38px'};
  transform: ${props => props.vertical ? 'translateX(-50%) rotate(90deg) translateY(-33px)' : 'none'} ;
`;

const Tab = styled.div`
  font-size: 13px;
  text-align: center;
  padding: 7px 15px;
  border: solid ${props => props.theme.palette.light} 1px;
  border-bottom: solid  1px  ${props => props.active ? 'white' : props.theme.palette.light};
  color: ${props => props.theme.palette.gray};
  z-index: ${props => props.active ? 90 : 90 - props.index};
  border-radius: 4px 4px 0 0;
  margin-left: ${props => props.index === 0 ? '0px' : '-3px'};
  background-color: white;
  cursor: pointer;
  font-weight: ${props => props.active ? 'bold' : '300'};
  line-height: 1.3;
  display: flex;
  align-items: center;
`;

export default class SectionTabs extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
  }

  toggle = (tabIndex) => {
    const activeTabValue = this.props.options[tabIndex].value
    if (this.props.value !== activeTabValue) {
      this.props.onChange(activeTabValue);
    }
  }

  render() {
    const { options, vertical, value } = this.props;
    const activeTabIndex = options.findIndex(o => o.value === value)

    return (
      <Container vertical={vertical}>
        {options.map((option, index) => 
          <Tab key={index} index={index} active={activeTabIndex === index} onClick={() => this.toggle(index)}>
            {option.display}
          </Tab>
        )}
      </Container>
    );
  }
}
