import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import Container from 'components/Container';
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import Button from 'components/Button';


export const Body = styled(Container)`
  max-width: 600px;
  font-size: 1.4rem;
  margin-bottom: 3rem;
`;

export const StyledDropDown = styled(AsyncSelect)`
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  box-shadow: none;
  border-radius: 3px;
  max-width: 600px;
  margin: auto;
  margin-bottom: 1rem;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

export const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '7px 8px',
  }),
  menuList: () => ({
    maxHeight: '600px',
    overflow: 'auto',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
};

export const OptionWrapper = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.08);
  margin: 1rem;
  cursor: pointer;

  &:hover {
    background: #f7f7f7;
  }

  & > img {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    margin-right 2rem;
    object-fit: cover;
    object-position: top;
  }

  & > div {
    flex: 1;
    
    div: first-of-type {
      font-weight: bold;
    }
  }

  .org_logo {
    margin-right: auto;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      height: 2.5rem;
      width: 2.5rem;
      object-fit: contain;
    }
  }
`;

export const AgentDetailWrapper = styled.div`
  margin-top: 1rem;
  padding: 2rem;
  font-weight: 400;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  border-radius: 10px;
  background: white;
  width: 100%;

  & > div:nth-of-type(1) {
    display: flex;
    padding-bottom: 2rem;
    border-bottom: 1px solid #999;

    img {
      height: 10rem;
      width: 10rem;
      border-radius: 50%;
      margin-right 2rem;
      object-fit: cover;
      object-position: top;
    }

    & > div {
      flex: 1;
      font-size: 1.4rem;
      text-transform: uppercase;
      
      & > div {
        color: #999;
        line-height: 1.5;

        .inactive {
          color: red;
        }
      }

      & > div:first-of-type {
        font-weight: bold;
        color: black;
      }
    }

    & > button {
      margin-right: auto;
    }
  }

  & > div:nth-of-type(2) {
  
    & > div {
      display: flex;
      margin-bottom: 10px 0;
      align-items: center;
      color: #999;
      font-size: 1.4rem;
      margin-top: 1rem;

      span {
        margin-left: 10px;
      }
    }

    .agent-email-wrapper {
      align-items: flex-start;

      .isvg {
        margin-top: 4px;
      }
    }
  }
`;

export const TeamDetailWrapper = styled.div`
  margin-top: 1rem;
  padding: 2rem;
  font-weight: 400;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  border-radius: 10px;
  background: white;
  width: 100%;
  display: flex;
  padding-bottom: 2rem;

  img {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    margin-right 2rem;
    object-fit: cover;
    object-position: top;
  }

  .team-right-wrapper {
    flex: 1;
    font-size: 1.4rem;
    text-transform: uppercase;

    & > div {
      color: #999;
      line-height: 1.5;
    }

    .team-name {
      font-weight: bold;
      color: black;
      margin-bottom: 1rem;
    }
  }
`;

export const SectionLabel = styled.div`
  margin-bottom: 1rem;
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 2rem;
`;

export const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  height: 30px;
  color: white;
  min-width: unset;
  font-size: 1.3rem;
  height: 27px;
`;

export const InviteButton = styled(StyledButton)`
  background: ${props => props.theme.palette.primary};
`;

export const AgentList = styled.div`
  max-height: 600px;
  overflow: auto;
`;

export const RACWrapper = styled.div`
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: white;
  width: 100%;
  padding: 2.5rem;
  margin-top: 2rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  margin-right: 1rem;
  white-space: nowrap;
`;

export const RadioForm = styled.div`
  display: flex;
  margin-left: 10px;
  & > span {
    margin-left: 5px;
    margin-right: 10px;
  }
`;

export const StyledInput = styled(Input)`
  font-size: 1.4rem;
  color: ${props => props.theme.palette.gray};
  border-top: none;
  border-left: none;
  border-right: none;
  min-height: 3.6rem;
  flex: 1;

  &:focus {
    outline: 0;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 3rem;
  & > button {
    width: 130px;
    font-size: 1.6rem;
    height: 40px;
  }
`;

export const PreLink = styled.a`
  word-break: break-all;
  margin-right: 1rem;
`;

export const ClientOptionsWrapper = styled.div`
  flex: 1;
  margin-right: 10px;

  & > div:first-of-type {
    margin-bottom: 5px;
  }
`;

export const LabelWrapper = styled.div`
  min-height: 45px;
  display: flex;
  align-items: center;
  width: 150px;
`;

export const ValueWrapper = styled.div`
  flex: 1;
`;

export const AgentButtonWrapper = styled.span`
  display: flex;
  flex-direction: column;

  & button {
    width: 100%;
    margin-bottom: 5px;
  }
`;

export const MultipleLineLabel = styled.div`
  margin: 5px 0 2px;
`;

export const MultipleLineInput = styled(TextArea)`
  min-height: 100px;
  color: ${props => props.theme.palette.gray};
  padding: 0.8rem;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const RoleText = styled.div`
  margin-left: 38px;
  margin-top: 0px !important;
`;
