import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { media } from 'modules/theme';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { compose } from 'redux';
import { HashLink } from 'react-router-hash-link';
import update from 'immutability-helper';
import { sortBy } from 'lodash';
import { getPortalAdminNotifications, deletePortalAdminNotifications } from 'modules/api';
import 'react-virtualized/styles.css';
import { AutoSizer, Table, Column } from 'react-virtualized';
import withModals from 'modules/withModals';
import NotificationModal from './NotificationModal';
import SubscribersModal from './SubscribersModal';

const Container = styled.div`
  padding: 2rem;
  margin: 2rem;
  background: white;
  flex: 1;

  ${media.mobile`
    margin: 0;
  `};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: flex-end;
  padding: 10px 10px 30px;
`;

const AddLOButton = styled.button`
  text-decoration: underline;
  margin-left: 20px;
`;

const Wrapper = styled.div`
  font-size: 1.3rem;

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }
`;

const LinkButton = styled.button`
  text-decoration: underline;
`;

class Notifications extends Component {
  state = {
    notifications: [],
    activeRow: {},
    showProfileModal: false,
    editingRow: {},
  };

  _setTableRef = ref => {
    this._table = ref;
  };

  componentDidMount() {
    this.refreshData();
  }

  refreshData = () => {
    getPortalAdminNotifications()
      .then(res => {
        const notifications = sortBy(res, 'Name');
        this.setState({
          notifications,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  rowGetter = ({ index }) => {
    const showingUsers = this.state.notifications;
    return showingUsers[index];
  };

  noRowsRenderer() {
    return <div>No Data</div>;
  }

  getTableHeight = () => {
    const elem = document.getElementById("table-wrapper");
    let calcHeight = 600

    if (elem) {
      const rect = elem.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      calcHeight = screenHeight - rect.top - 50
    }
    return calcHeight;
  }

  getRowHeight = ({ index }) => this.state.activeRow[index] ? 150 : 30;

  getRowRenderer = props => {
    const { _key, className, columns, index, style, rowData } = props;
    const isOdd = index % 2;

    return (
      <div
        key={_key}
        style={{ 
          ...style, 
          backgroundColor: isOdd ? 'white' : '#eeeeee', 
          paddingTop: 7, 
        }}
      >
        <div className={className}>{columns}</div>
      </div>
    );
  };

  handleAddNotification = () => {
    this.props.openModal('NotificationModal', {
      data: {},
      onUpdate: this.refreshData,
      dispatch: this.props.dispatch
    })
  };

  handleEditNotification = rowData => () => {
    this.props.openModal('NotificationModal', {
      data: rowData,
      onUpdate: this.refreshData,
      dispatch: this.props.dispatch
    })
  };

  handleSubscribers = notification => () => {
    this.props.openModal('SubscribersModal', {
      notification,
    })
  };

  handleDelete = rowData => () => {
    deletePortalAdminNotifications(rowData.PK)
      .then(res => {
        this.refreshData();
      })
  };

  render() {
    const { editingRow, showProfileModal, notifications } = this.state;
    const { user } = this.props;
    if (user.data.PermissionsLevel !== 1) {
      return <Redirect to='/private/home'/>
    }

    return (
      <Fragment>
        <Helmet>
          <title>Users</title>
          <meta
            name="description"
            content="This is the Keller Mortgage Admin Portal Users page"
          />
        </Helmet>
        <Container>
          <Header>
            <AddLOButton onClick={this.handleAddNotification}>Add Notification</AddLOButton>
          </Header>

          <Wrapper id="table-wrapper">
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  ref={this._setTableRef}
                  headerHeight={30}
                  noRowsRenderer={this.noRowsRenderer}
                  rowHeight={this.getRowHeight}
                  rowGetter={this.rowGetter}
                  rowCount={notifications.length}
                  height={this.getTableHeight()}
                  rowRenderer={this.getRowRenderer}
                  width={width}
                >
                   <Column
                    label="Notification Name"
                    dataKey="Name"
                    width={300}
                    cellRenderer={({ rowData }) => {
                      const { Name } = rowData;
                      return (
                        <LinkButton
                          onClick={this.handleEditNotification(rowData)}
                        >{Name}
                        </LinkButton>
                      );
                    }}
                  />
                  <Column
                    width={100}
                    label="Frequency"
                    dataKey="Frequency"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={100}
                    label="Delivery Day"
                    dataKey="DeliveryDayofWeek"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={100}
                    label="Delivery Time"
                    dataKey="DeliveryTime"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={70}
                    label="Status"
                    dataKey="Status"
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={100}
                    label="Subscribers"
                    dataKey="Subscribers"
                    cellRenderer={({ rowData }) => {
                      return (
                        <LinkButton onClick={this.handleSubscribers(rowData)}>
                          Subscribers
                        </LinkButton>
                      );
                    }}
                  />
                  <Column
                    width={100}
                    label="Delete"
                    dataKey="Delete"
                    cellRenderer={({ rowData }) => {
                      return (
                        <LinkButton onClick={this.handleDelete(rowData)}>
                          Delete
                        </LinkButton>
                      );
                    }}
                  />
                </Table>
              )}
            </AutoSizer>
          </Wrapper>

        </Container>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default compose(
  connect(mapStateToProps),
  withModals({ 
    NotificationModal,
    SubscribersModal,
  })
)(Notifications)
