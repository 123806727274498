import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ModalHeader } from 'reactstrap';
import DigeryModal from 'components/Modal';
import { sendPreApprovalLink, addReferAClient, addActivityEvents } from 'modules/api';
import Icon from 'components/Icon';
import RadioBox from 'components/RadioBox';
import CheckBox from 'components/CheckBox';
import NumberFormat from 'react-number-format';
import EmailIcon from 'assets/media/images/email.png';
import PhoneIcon from 'assets/media/images/phone.png';
import Input from 'components/Input';
import AccountCircle from 'assets/media/images/account_circle.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showAlert } from 'actions/index';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import { getActivitiyInfo } from 'modules/helpers';
import Image from 'components/Image';
import LoanOfficerDetailModal from '../../AgentInfo/LoanOfficerDetailModal';
import ChangeLoanOfficerModal from './ChangeLoanOfficerModal';

const PlaceholderAvatar = require('assets/media/images/sample-avatar.png');

class AgentModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (this.props.showModal) {
      this.handleChangeLO(this.props.selectedLO);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.showModal && !prevProps.showModal) {
      this.handleChangeLO(this.props.selectedLO);
    }
  }

  state = {
    selectedLO: null,
    emailOrPhone: true,
    clientEmail: '',
    clientName: '',
    clientPhone: '',
    supportHubLead: false,
    showChangeLOModal: false,
    showLODetailModal: false,
    teams: [],
    marketCenters: [],
    agentData: {},
    MsgToBorrowerEmail: '',
    MsgToBorrowerSignature: '',
    MsgToBorrowerText: '',
  };

  handleChangeClientOption = emailOrPhone => () => {
    this.setState({ emailOrPhone });
  };

  handleChangeLO = selectedLO => {
    const { MsgToBorrowerEmail, MsgToBorrowerSignature, MsgToBorrowerText } = selectedLO || {};
    this.setState({
      selectedLO,
      MsgToBorrowerEmail,
      MsgToBorrowerSignature,
      MsgToBorrowerText,
    });
  };

  handleUpdateTeams = teams => {
    this.setState({ teams });
  };

  handleUpdateMCs = marketCenters => {
    this.setState({ marketCenters });
  };

  handleAgentData = agentData => {
    this.setState({ agentData });
  };

  onChange = (key, value) => {
    let actualValue = value;
    if (key === 'clientPhone') {
      actualValue = value.value;
    }

    this.setState({ [key]: actualValue });
  };

  handleCopyLink = e => {
    const { dispatch } = this.props;
    dispatch(
      showAlert('PreApproval Link copied to your clipboard', { variant: 'success', icon: 'bell' }),
    );
  };

  handleSend = () => {
    const { dispatch } = this.props;
    const {
      clientEmail,
      clientName,
      clientPhone,
      selectedLO,
      agentData,
      emailOrPhone,
      supportHubLead,
      MsgToBorrowerSignature,
      MsgToBorrowerText,
    } = this.state;

    const agentName = `${selectedLO.FirstName} ${selectedLO.LastName}`;
    const agentEmail = selectedLO.Email;
    const agentPhone = selectedLO.PhoneMobile;
       const preApprovalLink = `https://preapproval.kellermortgage.com/#/?campaign_id=${
      supportHubLead ? '33' : '61'
    }&KWUID=${agentData.KWUID}&loan_manager_id=${selectedLO.LOID}&partnerid=102100`;

    const params = {
      clientEmail: emailOrPhone ? clientEmail : null,
      clientName,
      clientPhone: emailOrPhone ? null : clientPhone,
      preApprovalLink,
      agentName,
      agentEmail,
      agentPhone,
      signature: MsgToBorrowerSignature,
      messageText: MsgToBorrowerText,
      bccEmail: agentEmail,
      CampaignID: supportHubLead ? '33' : '61',
      PartnerID: 102100,
      LOID: selectedLO.LOID,
      KWUID: agentData.KWUID,
    };

    sendPreApprovalLink(params)
      .then(res => {
        dispatch(
          showAlert('Pre-approval link sent successfully.', { variant: 'success', icon: 'bell' }),
        );
        this.setState({
          clientEmail: '',
          clientName: '',
          clientPhone: '',
        });
      })
      .catch(error => {
        console.log(error);
        dispatch(
          showAlert('Failed to send Pre-approval link.', { variant: 'danger', icon: 'bell' }),
        );
      });

    getActivitiyInfo().then(deviceInfo => {
      const { IPAddress, DeviceType, BrowserOS } = deviceInfo;
      addActivityEvents(28, agentData.KWUID, IPAddress, DeviceType, BrowserOS);
    });

    addReferAClient(
      agentData.KWUID,
      selectedLO.LOID,
      'Portal Admin',
      clientName,
      emailOrPhone ? clientEmail : null,
      emailOrPhone ? null : clientPhone,
      null,
      null,
    );
  };

  render() {
    const { showModal, onClose, selectedAgent, allLoanOfficers, user } = this.props;

    const {
      selectedLO,
      emailOrPhone,
      clientEmail,
      clientName,
      clientPhone,
      supportHubLead,
      showChangeLOModal,
      showLODetailModal,
      agentData,
      teams,
      marketCenters,
      MsgToBorrowerEmail,
      MsgToBorrowerSignature,
      MsgToBorrowerText,
    } = this.state;

    const { Photo, City, StateCode, Address1, PostalCode, KWUID, Email, Phone, MobilePhoneNumber } =
      agentData || {};

    const preApprovalLink = `https://preapproval.kellermortgage.com/#/?campaign_id=${
      supportHubLead ? '33' : '61'
    }&KWUID=${KWUID || null}&loan_manager_id=${selectedLO ? selectedLO.LOID : null}`;

    const sendDisabled =
      (!clientEmail && emailOrPhone) ||
      ((!MsgToBorrowerEmail || !MsgToBorrowerSignature) && emailOrPhone) ||
      (!emailOrPhone && !MsgToBorrowerText) ||
      (!emailOrPhone && !clientPhone) ||
      !clientName ||
      !selectedAgent ||
      !selectedLO;

    return (
      <Modal centered fade={false} isOpen={showModal} toggle={onClose}>
        <ModalHeader toggle={onClose} />

        <AgentDetailWrapper>
          <div>
            <Image src={Photo} fallbackSrc={PlaceholderAvatar} />
            <div>
              <div>{selectedAgent.AgentName}</div>
              <div>{`${Address1 || ''}`}</div>
              <div>{`${City || ''}, ${StateCode || ''} ${PostalCode || ''}`}</div>
              <div>{`KWUID: ${KWUID || ''}`}</div>
            </div>
            <StyledButton onClick={() => this.setState({ showLODetailModal: true })}>
              View API Data
            </StyledButton>
          </div>
          <div>
            <div className="agent-email-wrapper">
              <Icon name="email" width={18} color="#999" />
              {Email && <span>{Email.split('|').map(email => <div>{email}</div>)}</span>}
            </div>
            {MobilePhoneNumber && (
              <div>
                <Icon name="phone" width={18} />
                <span>(M)&nbsp;{MobilePhoneNumber}</span>
              </div>
            )}
            {marketCenters.length > 0 &&
              marketCenters.map(m => (
                <>
                  <div>
                    <Icon name="home_marketing" width={18} color="#999" />
                    <span>
                      MC: {m.MarketCenterID} - {m.MarketCenterName}
                    </span>
                  </div>
                  <RoleText>{`- Role: ${m.MarketCenterOrgRoles}`}</RoleText>
                </>
              ))}
            {teams.length > 0 &&
              teams.map(m => (
                <div>
                  <Icon name="home_marketing" width={18} color="#999" />
                  <span>
                    Team: {m.TeamID} - {m.TeamName}
                  </span>
                </div>
              ))}
          </div>
        </AgentDetailWrapper>

        <RACWrapper>
          <div>
            <Row>
              <Label>Loan Officer: {selectedLO ? selectedLO.Name : 'N/A'} </Label>
              {user.data.PermissionsLevel !== 3 && (
                <StyledButton onClick={() => this.setState({ showChangeLOModal: true })}>
                  {selectedLO ? 'Change' : 'Choose Loan Officer'}
                </StyledButton>
              )}
            </Row>
            {false && (
              <Fragment>
                <div>Pre-Approval Link:</div>
                <Row>
                  <PreLink href={preApprovalLink}>{preApprovalLink}</PreLink>
                  <CopyToClipboard text={preApprovalLink} onCopy={this.handleCopyLink}>
                    <StyledButton>Copy</StyledButton>
                  </CopyToClipboard>
                </Row>
              </Fragment>
            )}
          </div>
          <div>
            <SectionLabel>Refer a client</SectionLabel>
            <TableRow>
              <LabelWrapper>
                <Label style={{ flex: 1 }}>Client Name:</Label>
              </LabelWrapper>
              <ValueWrapper>
                <StyledInput
                  icon={AccountCircle}
                  type="text"
                  placeholder="Bob Smith"
                  value={clientName}
                  onChange={e => this.onChange('clientName', e.target.value)}
                />
              </ValueWrapper>
            </TableRow>
            <TableRow>
              <LabelWrapper>
                <Label>Client</Label>
                <ClientOptionsWrapper>
                  <RadioForm>
                    <RadioBox value={emailOrPhone} onChange={this.handleChangeClientOption(true)} />
                    <span>Email</span>
                  </RadioForm>
                  <RadioForm>
                    <RadioBox
                      value={!emailOrPhone}
                      onChange={this.handleChangeClientOption(false)}
                    />
                    <span>Phone</span>
                  </RadioForm>
                </ClientOptionsWrapper>
              </LabelWrapper>
              <ValueWrapper>
                {emailOrPhone ? (
                  <StyledInput
                    icon={EmailIcon}
                    iconWidth={21}
                    iconHeight={20}
                    type="email"
                    value={clientEmail}
                    placeholder="bobsmith@NeedAHouse.com"
                    onChange={e => this.onChange('clientEmail', e.target.value)}
                  />
                ) : (
                  <NumberFormat
                    icon={PhoneIcon}
                    iconWidth={18}
                    iconHeight={18}
                    type="tel"
                    isNumericString
                    format="+1 (###) ###-####"
                    mask="_"
                    value={clientPhone}
                    placeholder="Mobile Phone#"
                    onValueChange={e => this.onChange('clientPhone', e)}
                    customInput={StyledInput}
                  />
                )}
              </ValueWrapper>
            </TableRow>
            <TableRow>
              <LabelWrapper />
              <ValueWrapper>
                {emailOrPhone ? (
                  <Fragment>
                    <MultipleLineLabel>Email Message to your borrower:</MultipleLineLabel>
                    <MultipleLineInput
                      value={MsgToBorrowerEmail || ''}
                      onChange={e => this.onChange('MsgToBorrowerEmail', e.target.value)}
                    />
                    <MultipleLineLabel>Email Signature:</MultipleLineLabel>
                    <MultipleLineInput
                      value={MsgToBorrowerSignature || ''}
                      onChange={e => this.onChange('MsgToBorrowerSignature', e.target.value)}
                    />
                  </Fragment>
                ) : (
                  <Fragment>
                    <MultipleLineLabel>Text Message to your borrower:</MultipleLineLabel>
                    <MultipleLineInput
                      value={MsgToBorrowerText || ''}
                      onChange={e => this.onChange('MsgToBorrowerText', e.target.value)}
                    />
                  </Fragment>
                )}
              </ValueWrapper>
            </TableRow>
            <TableRow>
              <LabelWrapper>
                <Label style={{ flex: 1 }}>Support Hub Lead:</Label>
              </LabelWrapper>
              <ValueWrapper>
                <CheckBox
                  value={supportHubLead}
                  onChange={value => this.onChange('supportHubLead', value)}
                />
              </ValueWrapper>
            </TableRow>
            <ButtonWrapper>
              <StyledButton disabled={sendDisabled} onClick={this.handleSend}>
                Send
              </StyledButton>
            </ButtonWrapper>
          </div>
        </RACWrapper>
        <LoanOfficerDetailModal
          kwuid={selectedAgent ? selectedAgent.KWUID : null}
          showModal={showLODetailModal}
          onUpdateTeam={this.handleUpdateTeams}
          onUpdateMCs={this.handleUpdateMCs}
          onUpdateAgent={this.handleAgentData}
          onClose={() => this.setState({ showLODetailModal: false })}
        />
        <ChangeLoanOfficerModal
          allLoanOfficers={allLoanOfficers}
          showModal={showChangeLOModal}
          onClose={() => this.setState({ showChangeLOModal: false })}
          onSelect={this.handleChangeLO}
        />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(AgentModal);

const Modal = styled(DigeryModal)`
  max-width: 70rem;

  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 3rem;
      color: ${props => props.theme.palette.primary};
    }
  }
  .modal-body {
    text-align: left;
    margin: 10px 0 15px;
  }
`;

const AgentDetailWrapper = styled.div`
  margin-top: 1rem;
  padding: 2rem;
  font-weight: 400;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  border-radius: 10px;
  background: white;
  width: 100%;

  & > div:nth-of-type(1) {
    display: flex;
    padding-bottom: 2rem;
    border-bottom: 1px solid #999;

    img {
      height: 10rem;
      width: 10rem;
      border-radius: 50%;
      margin-right 2rem;
      object-fit: cover;
      object-position: top;
    }

    & > div {
      flex: 1;
      font-size: 1.4rem;
      text-transform: uppercase;
      
      & > div {
        color: #999;
        line-height: 1.5;
      }

      & > div:first-of-type {
        font-weight: bold;
        color: black;
      }

      & > div:last-of-type {
        margin-top: 1.7rem;
      }
    }

    & > button {
      margin-right: auto;
    }
  }

  & > div:nth-of-type(2) {

    & > div {
      display: flex;
      margin-bottom: 10px 0;
      align-items: center;
      color: #999;
      font-size: 1.4rem;
      margin-top: 1rem;

      span {
        margin-left: 10px;
      }
    }

    .agent-email-wrapper {
      align-items: flex-start;

      .isvg {
        margin-top: 4px;
      }
    }
  }
`;

const SectionLabel = styled.div`
  margin-bottom: 1rem;
  font-size: 1.7rem;
  font-weight: bold;
  margin-top: 2rem;
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  height: 30px;
  color: white;
  min-width: unset;
  font-size: 1.3rem;
  height: 27px;
`;

const RACWrapper = styled.div`
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: white;
  width: 100%;
  padding: 2.5rem;
  margin-top: 2rem;
  font-size: 1.4rem;
  color: black;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  margin-right: 1rem;
  white-space: nowrap;
`;

const RadioForm = styled.div`
  display: flex;
  margin-left: 10px;
  & > span {
    margin-left: 5px;
    margin-right: 10px;
  }
`;

const StyledInput = styled(Input)`
  font-size: 1.4rem;
  color: ${props => props.theme.palette.gray};
  border-top: none;
  border-left: none;
  border-right: none;
  min-height: 3.6rem;
  flex: 1;

  &:focus {
    outline: 0;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 3rem;
  & > button {
    width: 130px;
    font-size: 1.6rem;
    height: 40px;
  }
`;

const PreLink = styled.a`
  word-break: break-all;
  margin-right: 1rem;
`;

const ClientOptionsWrapper = styled.div`
  flex: 1;
  margin-right: 10px;

  & > div:first-of-type {
    margin-bottom: 5px;
  }
`;

const LabelWrapper = styled.div`
  min-height: 45px;
  display: flex;
  align-items: center;
  width: 150px;
`;

const ValueWrapper = styled.div`
  flex: 1;
`;

const MultipleLineLabel = styled.div`
  margin: 5px 0 2px;
`;

const MultipleLineInput = styled(TextArea)`
  min-height: 100px;
  color: ${props => props.theme.palette.gray};
  padding: 0.8rem;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const RoleText = styled.div`
  margin-left: 38px;
  margin-top: 0px !important;
`;
