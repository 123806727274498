import axios from 'axios';
import { getToken } from 'modules/localstorage';

const KM_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const KM_API_TOKEN = process.env.REACT_APP_KM_API_TOKEN;

const KMServerAPI = axios.create({
  baseURL: `${KM_API_BASE_URL}/private/portal`,
  timeout: 80000,
  headers: {
    'content-type': 'application/json',
    Authorization: `Basic ${KM_API_TOKEN}`,
  },
});

const publicApiRequest = (method, url, payload) => {
  const requestParams = {
    method,
    url: `${KM_API_BASE_URL}/private/portal${url}`,
    timeout: 80000,
    headers: {
      'content-type': 'application/json',
      Authorization: `Basic ${KM_API_TOKEN}`,
    },
  };
  if (method === 'get' || method === 'delete') {
    requestParams.params = payload;
  } else if (method === 'post' || method === 'put') {
    requestParams.data = payload;
  }

  return new Promise((resolve, reject) => {
    axios(requestParams)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        console.log(error.response);
        reject(error.response);
      });
  });
};

const privateApiRequest = (method, url, payload) => {
  const requestParams = {
    method,
    url: `${KM_API_BASE_URL}/private${url}`,
    timeout: 80000,
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  };
  if (method === 'get' || method === 'delete') {
    requestParams.params = payload;
  } else if (method === 'post' || method === 'put') {
    requestParams.data = payload;
  }

  return new Promise((resolve, reject) => {
    axios(requestParams)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

const privateBasicApiRequest = (method, url, payload) => {
  const requestParams = {
    method,
    url: `${KM_API_BASE_URL}/private${url}`,
    timeout: 80000,
    headers: {
      'content-type': 'application/json',
      Authorization: `Basic ${KM_API_TOKEN}`,
    },
  };
  if (method === 'get' || method === 'delete') {
    requestParams.params = payload;
  } else if (method === 'post' || method === 'put') {
    requestParams.data = payload;
  }

  return new Promise((resolve, reject) => {
    axios(requestParams)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

// Admin APIS
export const signup = payload => publicApiRequest('post', '/admin/register', payload);
// return KMServerAPI.post('/admin/register', payload);

export const sigin = (Email, Password) =>
  publicApiRequest('post', '/admin/login', { Email, Password });
// return KMServerAPI.post('/admin/login', { Email, Password });

export const forgotPassword = Email =>
  publicApiRequest('post', '/admin/forgot-password', { Email });

export const resetPassword = (ID, Email, Password) =>
  publicApiRequest('post', '/admin/reset-password', { ID, Email, Password });

export const checkResetPasswordLink = (ID, Email) =>
  publicApiRequest('post', '/admin/check-reset-password', { ID, Email });

export const getLoanOfficers = () => privateApiRequest('get', '/admin/loan-officers');

export const addUpdateLoanOfficer = payload =>
  privateApiRequest('post', '/admin/loan-officers', payload);

export const getUsers = () => privateApiRequest('get', '/admin/users');

export const addUpdateUser = payload => privateApiRequest('post', '/admin/users', payload);

export const getPocketAgents = ID => privateApiRequest('get', '/admin/pocket-agents', { ID });

export const getPocketAgentSearch = (ID, SearchTerm) =>
  privateApiRequest('get', '/admin/pocket-agents-search', { ID, SearchTerm });

export const addPortalAdminKWPocketAgent = (KWUID, LoanOfficerID) =>
  privateApiRequest('post', '/admin/pocket-agents', { KWUID, LoanOfficerID });

export const deletePocketAgent = PK => privateApiRequest('delete', '/admin/pocket-agents', { PK });

export const getPrimaryDetail = ID => privateApiRequest('get', '/admin/primary-detail', { ID });

export const getBackupLODetail = ID => privateApiRequest('get', '/admin/backup-detail', { ID });

export const getReportAgentPrimaryBackup = () =>
  privateApiRequest('get', '/admin/report-agent-primary-backup');

export const getReportAgentsCreatedBackupLOs = () =>
  privateApiRequest('get', '/admin/report-agents-created');

export const uploadFile = (file, LoanOfficerNMLS) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('LoanOfficerNMLS', LoanOfficerNMLS);
  const requestParams = {
    method: 'post',
    url: `${KM_API_BASE_URL}/private/admin/uploadfile`,
    timeout: 300000,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  requestParams.data = formData;

  return new Promise((resolve, reject) => {
    axios(requestParams)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const getPortalAdminAgentInfoSearch = (SearchString, LOID) =>
  privateApiRequest('get', '/admin/agent-info-search', { SearchString, LOID });

export const getPortalAdminAgentInfoSearchTeam = (SearchString, LOID) =>
  privateApiRequest('get', '/admin/agent-info-search-team', { SearchString, LOID });

export const getNotificationLogCount = (StartDate, EndDate, PeriodType) =>
  privateApiRequest('get', '/admin/notificationLogCount', { StartDate, EndDate, PeriodType });

export const getNotificationLogCountPriorDayDetail = (StartDate, EndDate) =>
  privateApiRequest('get', '/admin/notificationLogCount-prior-day', { StartDate, EndDate });

export const getActivityUserLogins = (StartDate, EndDate, PeriodType) =>
  privateApiRequest('get', '/admin/activity-user-logins', { StartDate, EndDate, PeriodType });

export const getActivityPageViewsByPeriod = (StartDate, EndDate, PeriodType) =>
  privateApiRequest('get', '/admin/activity-page-by-period', { StartDate, EndDate, PeriodType });

export const getActivityEventsByTypeDayRAC = (StartDate, EndDate, PeriodType) =>
  privateApiRequest('get', '/admin/activity-events-rac', { StartDate, EndDate, PeriodType });

export const getActivityEventsByTypeDayRACPortalAdmin = (StartDate, EndDate, PeriodType, LOID) =>
  privateApiRequest('get', '/admin/activity-events-rac-admin', {
    StartDate,
    EndDate,
    PeriodType,
    LOID,
  });

export const getActivityRACDetail = (StartDate, EndDate, LOID) =>
  privateApiRequest('get', '/admin/activity-rac-detail', { StartDate, EndDate, LOID });

export const getActivityEventsByTypeDaySOS = (StartDate, EndDate, PeriodType) =>
  privateApiRequest('get', '/admin/activity-events-sos', { StartDate, EndDate, PeriodType });

export const getActivityEventsByType = (StartDate, EndDate, PeriodType) =>
  privateApiRequest('get', '/admin/activity-events-by-type', { StartDate, EndDate, PeriodType });

export const getActivityPageViewsByDevice = (StartDate, EndDate) =>
  privateApiRequest('get', '/admin/activity-page-by-device', { StartDate, EndDate });

export const getActivityPageViewsByDeviceGroup = (StartDate, EndDate, PeriodType) =>
  privateApiRequest('get', '/admin/activity-page-by-device-group', {
    StartDate,
    EndDate,
    PeriodType,
  });

export const getActivityPageViewsByPage = (StartDate, EndDate) =>
  privateApiRequest('get', '/admin/activity-page-by-page', { StartDate, EndDate });

export const getActivityNotificationResponse = (StartDate, EndDate, PeriodType) =>
  privateApiRequest('get', '/admin/activity-notification-response', {
    StartDate,
    EndDate,
    PeriodType,
  });

export const getPortalAdminLOMyAgents = (LOID, PartnerType, StartDate, EndDate, PeriodType) =>
  privateApiRequest('get', '/admin/loanofficer-my-agents', {
    LOID,
    PartnerType,
    StartDate,
    EndDate,
    PeriodType,
  });

export const getLeadsPreappsClosingContractsClosingByRegion = () =>
  privateApiRequest('get', '/admin/lpccc-by-region');

export const getRecruitingAssignedAgents = (LOID, StateCode, StartDate, EndDate, Period, MarketCenterRefID) =>
  privateApiRequest('get', '/admin/recruiting-assigned-agents', {
    LOID,
    StateCode,
    StartDate,
    EndDate,
    Period,
    MarketCenterRefID,
  });

export const getRecruitingUnassignedAgents = (StateCode, StartDate, EndDate, Period, MarketCenterRefID) =>
  privateApiRequest('get', '/admin/recruiting-unassigned-agents', {
    StateCode,
    StartDate,
    EndDate,
    Period,
    MarketCenterRefID,
  });

export const addRecruitingLOAssignment = RecruitingLOJSON =>
  privateApiRequest('post', '/admin/recruiting-lo-assignment', { RecruitingLOJSON });

export const deleteRecruitingLOAssignment = RecruitingLOAssignmentJSON =>
  privateApiRequest('delete', '/admin/recruiting-lo-assignment', { RecruitingLOAssignmentJSON });

export const getRecruitingAssignedAgentsLO = (LOID, StateCode, StartDate, EndDate, Period) =>
  privateApiRequest('get', '/admin/recruiting-assigned-agents-lo', {
    LOID,
    StateCode,
    StartDate,
    EndDate,
    Period,
  });

export const getRecruitingHistory = PKXRefRecruitingRecruiterAssignment =>
  privateApiRequest('get', '/admin/recruiting-history', { PKXRefRecruitingRecruiterAssignment });

export const getRecruitingNotes = PKXRefRecruitingRecruiterAssignment =>
  privateApiRequest('get', '/admin/recruiting-notes', { PKXRefRecruitingRecruiterAssignment });

export const addRecruitingNote = (LOID, RecruitingRecruiterAssignmentID, Note) =>
  privateApiRequest('post', '/admin/recruiting-notes', {
    LOID,
    RecruitingRecruiterAssignmentID,
    Note,
  });

export const getRecruitingActivityTypes = () =>
  privateApiRequest('get', '/admin/recruiting-activity-types');

export const addRecruitingActivity = (RecruitingActivityTypeID, RecruitingRecruiterAssignmentID) =>
  privateApiRequest('post', '/admin/recruiting-activity-types', {
    RecruitingActivityTypeID,
    RecruitingRecruiterAssignmentID,
  });

export const getPortalAdminLoanOfficer = ID =>
  privateApiRequest('get', '/admin/loan-officer', { ID });

export const getRecruitingDashboardStats = (Period, StartDate, EndDate) =>
  privateApiRequest('get', '/admin/recruiting-dashboard-stats', { Period, StartDate, EndDate });

export const getRecruitingDashboardStatsClosedAdverseDetail = (ID, Period, StartDate, EndDate) =>
  privateApiRequest('get', '/admin/recruiting-dashboard-stats-closed-adversed-detail', {
    ID,
    Period,
    StartDate,
    EndDate,
  });

export const getRecruitingDashboardStatsContacted = () =>
  privateApiRequest('get', '/admin/recruiting-dashboard-stats-contacted');

export const getRecruitingDashboardStatsLOs = (Snapshotflag, Period, StartDate, EndDate) =>
  privateApiRequest('get', '/admin/recruiting-dashboard-stats-los', {
    Snapshotflag,
    StartDate,
    EndDate,
    Period,
  });

export const getRecruitingDashboardStatsRecruiters = (Snapshotflag, Period, StartDate, EndDate) =>
  privateApiRequest('get', '/admin/recruiting-dashboard-stats-recruiters', {
    Snapshotflag,
    StartDate,
    EndDate,
    Period,
  });

export const getRecruitingDashboardStatsRecruiter = (RecruiterID, Period, StartDate, EndDate) =>
  privateApiRequest('get', '/admin/recruiting-dashboard-stats-recruiter', {
    RecruiterID,
    StartDate,
    EndDate,
    Period,
  });

export const getRecruitingDashboardStatsMCs = (
  Snapshotflag,
  OptoutFlag,
  Period,
  StartDate,
  EndDate,
) =>
  privateApiRequest('get', '/admin/recruiting-dashboard-stats-mcs', {
    Snapshotflag,
    OptoutFlag,
    StartDate,
    EndDate,
    Period,
  });

export const getRecruitingDashboardStatsMC = (
  Snapshotflag,
  OptoutFlag,
  Period,
  StartDate,
  EndDate,
  MarketCenterRefID,
) =>
  privateApiRequest('get', '/admin/recruiting-dashboard-stats-mc', {
    Snapshotflag,
    OptoutFlag,
    StartDate,
    EndDate,
    Period,
    MarketCenterRefID,
  });

export const getRecruitingDashboardStatsLO = ID =>
  privateApiRequest('get', '/admin/recruiting-dashboard-stats-lo', { ID });

export const getPortalAdminNotifications = () => privateApiRequest('get', '/admin/notifications');

export const addUpdatePortalAdminNotifications = payload =>
  privateApiRequest('post', '/admin/notifications', payload);

export const deletePortalAdminNotifications = PK =>
  privateApiRequest('delete', '/admin/notifications', { PK });

export const getPortalAdminNotificationsUsers = PK =>
  privateApiRequest('get', '/admin/notifications-users', { PK });

export const addPortalAdminNotificationsUsers = (UserPK, NotificationPK) =>
  privateApiRequest('post', '/admin/notifications-users', { UserPK, NotificationPK });

export const deletePortalAdminNotificationsUsers = PK =>
  privateApiRequest('delete', '/admin/notifications-users', { PK });

export const addRecruitingRecruiterAssignment = RecruitingRecruiterJSON =>
  privateApiRequest('post', '/admin/recruiting-recruiter-assignment', { RecruitingRecruiterJSON });

export const getPortalAdminRecruiters = () => privateApiRequest('get', '/admin/recruiters');

export const getRecruitingInRecruitingAgents = (
  StateCode,
  StartDate,
  EndDate,
  Period,
  RecruiterID,
  MarketCenterRefID
) =>
  privateApiRequest('get', '/admin/recruiting-in-recruiting-agents', {
    StateCode,
    StartDate,
    EndDate,
    Period,
    RecruiterID,
    MarketCenterRefID,
  });

export const getRecruitingAssignedAgentsRecruiter = (RecruiterID, ActivityGroup, Period, StartDate, EndDate) =>
  privateApiRequest('get', '/admin/recruiting-assigned-agents-recruiter', {
    RecruiterID,
    ActivityGroup,
    Period,
    StartDate,
    EndDate
  });

export const deleteRecruitingRecruiterAssignment = RecruitingRecruiterAssignmentJSON =>
  privateApiRequest('delete', '/admin/recruiting-recruiter-assignment', {
    RecruitingRecruiterAssignmentJSON,
  });

export const getRecruitingClosedAgents = (StateCode, StartDate, EndDate, Period, RecruiterID, MarketCenterRefID) =>
  privateApiRequest('get', '/admin/recruiting-closed-agents', {
    StateCode,
    StartDate,
    EndDate,
    Period,
    RecruiterID,
    MarketCenterRefID,
  });

export const getRecruitingInPoolAgents = (
  StateCode,
  StartDate,
  EndDate,
  Period,
  RecruiterID,
  LOID,
  MarketCenterRefID,
) =>
  privateApiRequest('get', '/admin/recruiting-in-pool-agents', {
    StateCode,
    StartDate,
    EndDate,
    Period,
    RecruiterID,
    LOID,
    MarketCenterRefID,
  });

export const addRecruitingActivitys = RecruitingActivitysJSON =>
  privateApiRequest('post', '/admin/recruiting-activitys', { RecruitingActivitysJSON });

export const getRptRegionalActivitySummary2020 = () =>
  privateApiRequest('get', '/admin/rpt-regional-activity-summary');

export const getRecruitingLoanOfficersSelectable = StateCode =>
  privateApiRequest('get', '/admin/recruiting-loan-officers-selectable', { StateCode });

export const addRecruitingInviteMyLOLog = (KWUID, LOID) =>
  privateApiRequest('post', '/admin/recruiting-invite-my-lo-log', { KWUID, LOID });

export const addUpdatePortalAdminLoanOfficerBio = payload =>
  privateApiRequest('post', '/admin/loan-officer-bio', payload);

export const getRecruitingMarketCentersOptIn = () =>
  privateApiRequest('get', '/admin/recruiting-marketCenters-optIn');

export const getRecruitingMarketCentersOptOut = () =>
  privateApiRequest('get', '/admin/recruiting-marketCenters-optOut');

export const getRecruitingMarketCentersOptinPendingApproval = () =>
  privateApiRequest('get', '/admin/recruiting-marketCenters-optIn-pendingApproval');

export const updateRecruitingMarketCenterReadyForRecruiting = OptInPK =>
  privateApiRequest('post', '/admin/recruiting-marketCenters-ready-for-recruiting', { OptInPK });

export const getRecruitingCommunication = PKXRefRecruitingRecruiterAssignment =>
  privateApiRequest('get', '/admin/recruiting-communication', {
    PKXRefRecruitingRecruiterAssignment,
  });

export const addRecruitingCommunication = payload =>
  privateApiRequest('post', '/admin/recruiting-communication', payload);

export const updateRecruitingCommunicationMessageRead = (PK, MessageReadFlag) =>
  privateApiRequest('post', '/admin/recruiting-communication-message-read', {
    PK,
    MessageReadFlag,
  });

export const getRecruitingCommunicationRecruiter = (RecruiterID, MessageType, SearchTerm, Period, StartDate, EndDate) =>
  privateApiRequest('get', '/admin/recruiting-communication-recruiter', {
    RecruiterID,
    MessageType,
    SearchTerm,
    Period,
    StartDate,
    EndDate,
  });

// /////// Public APIS /////////////

export const sendEmail = payload => KMServerAPI.post('/send-contact-us-email', payload);

export const sendSMS = payload => KMServerAPI.post('/send-sms', payload);

export const sendPreApprovalLink = payload =>
  KMServerAPI.post('/send-pre-approval-link-email', payload);

export const getPortalAdminBeginLoanProcessLink = (params) =>
  KMServerAPI.get('/begin-loan-process-link', { params });

export const getPortalAdminBeginLoanProcessLinkTeam = (params) =>
  KMServerAPI.get('/begin-loan-process-link-team', { params });

// Get Credit Card list
export const getAllCurrentDeals = () => KMServerAPI.get('/current-applications');

export const getAllArchivedDeals = () => KMServerAPI.get('/archived-applications');

export const archiveDeal = EncompassID => KMServerAPI.post('/archived-deal', { EncompassID });

export const unArchiveDeal = EncompassID =>
  KMServerAPI.delete('/archived-deal', { params: { EncompassID } });

export const getCurrendDealByAgent = KWUID =>
  KMServerAPI.get('/current-deals-by-agent', { params: { KWUID } });

export const getArchivedDealByAgent = KWUID =>
  KMServerAPI.get('/archived-deals-by-agent', { params: { KWUID } });

export const getSpoofedAgent = KWUID => KMServerAPI.get('/spoofed-agent', { params: { KWUID } });

export const getKWAgent = KWUID => KMServerAPI.get('/kw-agent', { params: { KWUID } });

export const updateKWAgent = payload =>
  /*
  payload = {
    KWUID,
    FirstName,
    LastName,
    email,
    MobilePhoneNumber,
    Active,
    Photo,
    Address1,
    Address2,
    City,
    StateCode,
    PostalCode,
    CountryCode,
    MarketCenterID,
    RoleID,
    TeamID
  }
  */
  KMServerAPI.put('/kw-agent', payload);

export const getKWMarketCenter = MarketCenterID =>
  KMServerAPI.get('/kw-market-center', { params: { MarketCenterID } });

export const addUpdateKWMarketCenter = MarketCenterJSON =>
  KMServerAPI.post('/kw-market-center', { MarketCenterJSON });

export const getKWTeam = TeamID => KMServerAPI.get('/kw-team', { params: { TeamID } });

export const addUpdateKWTeam = TeamJSON => KMServerAPI.post('/kw-team', { TeamJSON });

export const updateAgentNotificationPreferences = (
  KWUID,
  DoNotSendNotificationsText,
  DoNotSendNotificationsEmail,
) =>
  KMServerAPI.put('/agent-notification-preferences', {
    KWUID,
    DoNotSendNotificationsText,
    DoNotSendNotificationsEmail,
  });

export const getKWAgentSavedLoanOfficers = (KWUID, StateCode) =>
  KMServerAPI.get('/agent-saved-loan-officers', { params: { KWUID, StateCode } });

export const getKWAgentTeams = KWUID => KMServerAPI.get('/agent-teams', { params: { KWUID } });

export const addKWAgentOrgRole = OrgRoleJSON => KMServerAPI.put('/kw-agent-org-role', { OrgRoleJSON });

export const getKWAgentOrgRoles = Kwuid => KMServerAPI.get('/kw-agent-org-role', { params: { Kwuid } });

export const addUpdateKWOrgRoles = OrgRoleJSON => KMServerAPI.put('/kw-org-roles', { OrgRoleJSON });

export const addUpdateKWRolesTeam = RoleJSON => KMServerAPI.put('/kw-roles-team', { RoleJSON });

export const addUpdateKWRoles = RoleJSON => KMServerAPI.post('/kw-roles', { RoleJSON });

export const addUpdateXRefMCParentOrg = XRefMCParentOrgJSON => KMServerAPI.put('/xref-mc-parent-org', { XRefMCParentOrgJSON });

export const addKWAgentTeams = TeamRoleJSON => KMServerAPI.post('/agent-teams', { TeamRoleJSON });

export const addKWAgentMarketCenter = MarketCenterJSON =>
  KMServerAPI.post('/agent-market-centers', { MarketCenterJSON });

export const updateKWAgentMsgToBorrowerPreferences = payload =>
  /*
      payload = {
        MsgToBorrowerPreferenceFlag,
        KWUID,
        RoleID,
        TeamRefID,
        MsgToBorrowerText,
        MsgToBorrowerEmail,
        MsgToBorrowerSignature
      }
    */
  KMServerAPI.put('/agent-msg-borrower-preferences', payload);

export const getKWAgentSavedLoanOfficersAll = KWUID =>
  KMServerAPI.get('/agent-saved-loan-officers-all', { params: { KWUID } });

export const getPreferredLoanOfficer = KWUID =>
  KMServerAPI.get('/preferred-loan-officer', { params: { KWUID } });

export const updatePreferredLoanOfficer = (KWUID, PK) =>
  KMServerAPI.put('/preferred-loan-officer', { KWUID, PK });

export const addKWAgentSavedLoanOfficer = (KWUID, LoanOfficerID) =>
  KMServerAPI.post('/agent-saved-loan-officers', { KWUID, LoanOfficerID });

export const getKWAgentNotSavedLoanOfficer = KWUID =>
  KMServerAPI.get('/agent-not-saved-loan-officers', { params: { KWUID } });

export const deleteKWAgentSavedLoanOfficer = PK =>
  KMServerAPI.delete('/agent-saved-loan-officers', { params: { PK } });

export const getPreferredLoanOfficerByState = (KWUID, StateCode) =>
  KMServerAPI.get('/preferred-loan-officer-by-state', { params: { KWUID, StateCode } });

export const getKWTeamMembers = TeamRefID =>
  KMServerAPI.get('/kw-team-members', { params: { TeamRefID } });

export const updateKWTeamKMNameEmailPhone = (TeamRefID, KMTeamName, KMTeamEmail, KMTeamPhone) =>
  KMServerAPI.put('/agent-team-name-email-phone', {
    TeamRefID,
    KMTeamName,
    KMTeamEmail,
    KMTeamPhone,
  });

export const updateKWTeamAdminFlag = (PK, TeamAdminFlag) =>
  KMServerAPI.put('/agent-team-admin-flag', { PK, TeamAdminFlag });

export const updateKWTeamProducerFlag = (PK, ProducerFlag) =>
  KMServerAPI.put('/agent-team-producer-flag', { PK, ProducerFlag });

export const deleteKWAgentTeamRole = PK =>
  KMServerAPI.delete('/kw-team-members', { params: { PK } });

export const addReferAClient = (
  KWUID,
  LOID,
  SentFrom,
  CustomerName,
  CustomerEmail,
  CustomerPhone,
  AgentRepresents,
  StateCode,
) =>
  KMServerAPI.post('/refer-a-client', {
    KWUID,
    LOID,
    SentFrom,
    CustomerName,
    CustomerEmail,
    CustomerPhone,
    AgentRepresents,
    StateCode,
  });

export const addActivityEvents = (EventTypePK, KWUID, IPAddress, DeviceType, BrowserOS) => KMServerAPI.put('/activity-events', {
  EventTypePK,
  KWUID,
  IPAddress,
  DeviceType,
  BrowserOS,
});

export const getRecruitingAgentInfoSearch = (SearchString, LOID, RecruiterID) =>
  privateApiRequest('get', '/admin/recruiting-agent-info-search', { SearchString, LOID, RecruiterID });

export const uploadRecruiterFile = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const requestParams = {
    method: 'post',
    url: `${KM_API_BASE_URL}/private/admin/uploadRecruiterFile`,
    timeout: 300000,
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  requestParams.data = formData;

  return new Promise((resolve, reject) => {
    axios(requestParams)
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const getRecruiterFiles = () => privateApiRequest('get', '/admin/s3-recruiter-files');

export const deleteRecruiterFiles = FilesJSON =>
  privateApiRequest('delete', '/admin/s3-recruiter-files', { FilesJSON });


export const getRecruitingWebsiteLinks = () => privateApiRequest('get', '/admin/recruiting-website-links');

export const addRecruitingWebsiteLinks = (WebsiteURL) => privateApiRequest('post', '/admin/recruiting-website-links', { WebsiteURL });

export const deleteRecruitingWebsiteLinks = RecruitingWebsiteLinksJSON => privateApiRequest('delete', '/admin/recruiting-website-links', { RecruitingWebsiteLinksJSON });

export const getEmailTemplates = (ID) => privateApiRequest('get', '/admin/email-templates', { ID });

export const addUpdateEmailTemplates = (PK, PKPortalAdminUsers, TemplateName, TemplateContent, TemplateSubject) => privateApiRequest('put', '/admin/email-templates', { PK, PKPortalAdminUsers, TemplateName, TemplateContent, TemplateSubject });

export const deleteEmailTemplates = EmailTemplatesJSON => privateApiRequest('delete', '/admin/email-templates', { EmailTemplatesJSON });

export const getRecruitingMessageCountUnread = (ID) => privateApiRequest('get', '/admin/recruiting-message-count-unread', { ID });

export const getRecruitingDashboardStatsMessageCount = (StartDate, EndDate, Period) => privateApiRequest('get', '/admin/recruiting-dashboard-stats-message-count', { StartDate, EndDate, Period });

export const getRecruitingDashboardStatsRecruiterMessageCount = (RecruiterID, StartDate, EndDate, Period) => privateApiRequest('get', '/admin/recruiting-dashboard-stats-recruiter-message-count', { RecruiterID, StartDate, EndDate, Period });

export const getSurveyMonkeyAgentResponsesACS = (RecruiterID, StartDate, EndDate) => privateApiRequest('get', '/admin/survey-agent-responses-acs', { RecruiterID, StartDate, EndDate });

export const getSurveyMonkeySurveysNames = () => privateApiRequest('get', '/admin/survey-names');

export const getPortalAdminMarketingEvents = () => privateApiRequest('get', '/admin/marketing-events');

export const addPortalAdminPreferredLoanOfficer = (KWUID, LOID, PKMarketingEvents, Notes) => privateApiRequest('post', '/admin/preferred-loan-officer', { KWUID, LOID, PKMarketingEvents, Notes });
export const addPortalAdminAgentMarketingEventNotes = (KWUID, LOID, PKMarketingEvents, Notes) => privateApiRequest('post', '/admin/agent-marketing-event-notes', { KWUID, LOID, PKMarketingEvents, Notes });

export const getAgentsRegistrationPool = (StateCode) => privateApiRequest('get', '/admin/agents-registration-pool', { StateCode });

export const getAgentRegistrationDetail = (RegistrationID) => privateApiRequest('get', '/admin/agent-registration-detail', { RegistrationID });
export const getAgentRegistrationLoanOfficers = () => privateApiRequest('get', '/admin/agent-registration-los');
export const getAgentRegistrationApprovedByNotApprovedBy = () => privateApiRequest('get', '/admin/agent-registration-approved-not-by');

export const getAgentOrganizations = () => privateBasicApiRequest('get', '/services/agent-organizations');
export const getUSAStates = () => privateBasicApiRequest('get', '/services/usa-states');
export const addUpdateAgentsRegistration = (KWAgentsRegistrationJSON) => privateBasicApiRequest('post', '/services/agents-registration',  { KWAgentsRegistrationJSON });
export const getAgentsRegistrationPreferredLoanOfficer = (PreferredLoanOfficerID, ApprovedFlag) => privateApiRequest('get', '/admin/agent-registration-preferred-lo', { PreferredLoanOfficerID, ApprovedFlag });
export const addAgentsRegistrationInvite = (KWAgentsRegistrationInviteJSON) => privateBasicApiRequest('post', '/services/agents-registration-invite', { KWAgentsRegistrationInviteJSON });
export const getAgentValidateEmail = (Email) => privateBasicApiRequest('get', '/services/agent-validate-email', { Email });

export const getAgentOrganizationsDetail = (OrganizationID) => privateApiRequest('get', '/admin/agent-organization', { OrganizationID });
export const addUpdateAgentOrganization = (payload) => privateApiRequest('post', '/admin/agent-organization', payload);
export const getKMLoanOfficerTypes = () => privateApiRequest('get', '/admin/km-loan-officer-types');
export const getPortalAdminLoanOfficersToAgentAssignment = (KWUID) => privateApiRequest('get', '/admin/loan-officers-to-agent-assignment', { KWUID });
