import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import ReactGA from 'react-ga';

import history from 'modules/history';
import theme, { headerHeight, mobileHeaderHeight, media } from 'modules/theme';

import config from 'config';
import { setDeviceInfo } from 'actions/index';

import SignUp from 'routes/SignUp';
import SignIn from 'routes/SignIn';
import ForgotPassword from 'routes/ForgotPassword';
import ResetPassword from 'routes/ResetPassword';
import NotFound from 'routes/NotFound';

import PrivateHeader from 'containers/PrivateHeader';
import SystemAlerts from 'containers/SystemAlerts';
import Private from 'containers/Private';

import GlobalStyles from 'components/GlobalStyles';
import RoutePublic from 'components/RoutePublic';
import RoutePrivate from 'components/RoutePrivate';

import { getActivitiyInfo } from 'modules/helpers';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
`;

const Main = styled.main`
  color: #fff;
  overflow: auto;
  position: relative;
  flex: 1;
  -webkit-overflow-scrolling: touch;
  min-height: calc(100vh - ${headerHeight}px);

  ${media.mobile`
    margin-left: 0; 
    min-height: calc(100vh - ${mobileHeaderHeight}px);
  `};
`;

const BodyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background: rgba(220, 222, 229, 0.24);
  min-height: 100%;
  ${media.mobile`
    background: #EBECF1;
  `};
`;

export class App extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  componentDidMount() {
    getActivitiyInfo().then(deviceInfo => {
      this.props.dispatch(setDeviceInfo(deviceInfo));
    });
    ReactGA.initialize('UA-134441130-2', {
      titleCase: false,
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
    history.listen(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.scrollTop();
    });
  }

  scrollTop() {
    const container = document.getElementById('body-container');
    if (container) {
      container.scrollTop = 0;
    }
  }

  render() {
    const { dispatch, user } = this.props;

    return (
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <AppWrapper logged={user.isAuthenticated}>
            <Helmet
              defer={false}
              htmlAttributes={{ lang: 'pt-br' }}
              encodeSpecialCharacters={true}
              defaultTitle={config.title}
              titleTemplate={`${config.title} - %s`}
              titleAttributes={{ itemprop: 'name', lang: 'pt-br' }}
            />
            <PrivateHeader dispatch={dispatch} user={user} />
            <Main isAuthenticated={user.isAuthenticated} id="body-container">
              <BodyContainer>
                <Switch>
                  <RoutePublic
                    isAuthenticated={user.isAuthenticated}
                    path="/signup"
                    exact
                    component={SignUp}
                  />
                  <RoutePublic
                    isAuthenticated={user.isAuthenticated}
                    path="/signin"
                    exact
                    component={SignIn}
                  />
                  <RoutePublic
                    isAuthenticated={user.isAuthenticated}
                    path="/forgot-my-password"
                    exact
                    component={ForgotPassword}
                  />
                  <RoutePublic
                    isAuthenticated={user.isAuthenticated}
                    path="/password-reset"
                    exact
                    component={ResetPassword}
                  />
                  <RoutePrivate
                    isAuthenticated={user.isAuthenticated}
                    path="/private"
                    component={Private}
                  />
                  <RoutePublic
                    isAuthenticated={user.isAuthenticated}
                    path="/"
                    exact
                    component={SignIn}
                  />
                  <Route component={NotFound} />
                </Switch>
              </BodyContainer>
            </Main>
            <SystemAlerts />
            <GlobalStyles />
          </AppWrapper>
        </ThemeProvider>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(App);
