import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap'; 
import DigeryModal from 'components/Modal';

const Modal = styled(DigeryModal)`
  padding: 0.3rem;

  .modal-content {
    padding: 2rem 0.2rem;
  }

  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 3rem;
      color: ${props => props.theme.palette.primary};
    }
  }
  .modal-body {
    text-align: left;
  }
`;

export default class MobileContentModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  };

  handleClose = () => {
    this.props.onClose();
  }

  render() {
    const { children, showModal } = this.props

    return (
      <Modal centered fade={false} isOpen={showModal} toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>
        </ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
      </Modal>
    )
  };
}

