import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  getEmailTemplates,
  deleteEmailTemplates,
} from 'modules/api';
import DigeryModal from 'components/Modal';
import CheckBox from 'components/RadioBox';
import Button from 'components/Button';
import Loading from 'components/Loading';
import withModals from 'modules/withModals';
import AddUpdateTemplateModal from './AddUpdateTemplateModal';

class TemplateModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    templates: [],
    isLoading: true,
    templatesChecked: [],
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.initialFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.initialFetch();
    }
  }

  initialFetch = async () => {
    this.setState({ isLoading: true })
    await this.fetchTemplates()
    this.setState({ isLoading: false })
  }

  fetchTemplates = () => {
    getEmailTemplates(this.props.user.PK)
    .then(res => {
      this.setState({
        templates: res,
        templatesChecked: []
      });
    })
  };

  handleClose = () => {
    this.props.onClose();
    this.props.onUpdate()
  };

  handleCheckTemplateChange = (PK, value) => {
    this.setState({ templatesChecked: [PK] })
  }

  handleAttach = () => {
    const { templatesChecked } = this.state;
    this.props.onAttach(templatesChecked)
    this.handleClose()
  }

  handleDelete = () => {
    const { templatesChecked, templates } = this.state;

    this.setState({ isLoading: true })
    const pks = templatesChecked.map(PK => ({ PK }))
    templatesChecked.forEach(wl=> {
      const websiteObj = templates.find(wo => wo.WebsiteURL === wl)
      if (websiteObj) {
        pks.push({ PK: websiteObj.PK })
      }
    })

    deleteEmailTemplates(JSON.stringify(pks))
      .then(res => {
        this.fetchTemplates()
        this.setState({ isLoading: false })
      })

  }

  handleAddUpdateTemplate = (isCreated) => () => {
    const { templatesChecked, templates } = this.state;
    const template = templates.find(el => el.PK === templatesChecked[0])

    this.props.openModal('AddUpdateTemplateModal', {
      dispatch: this.props.dispatch,
      template: isCreated ? {} : template,
      user: this.props.user,
      onSave: () => {
        this.fetchTemplates()
      }
    });
  }

  render() {
    const { isOpen } = this.props;
    const { isLoading, templates, templatesChecked } = this.state;
    const editButtonDisabled = templatesChecked.length !== 1 || isLoading
    const deleteButtonDisabled = templatesChecked.length === 0 || isLoading

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>Communication Templates</ModalHeader>
        <ModalBody>
          {isLoading && <Loading />}
          <div className="body-container">
            {templates.map(template => (
              <Row key={template.PK}>
                <CheckBox
                  value={templatesChecked.includes(template.PK)}
                  onChange={value => this.handleCheckTemplateChange(template.PK, value)}
                  />
                <Label>{template.TemplateName}</Label>
              </Row>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <StyledButton onClick={this.handleAddUpdateTemplate(true)}>Add Template</StyledButton>
            <StyledButton disabled={editButtonDisabled} onClick={this.handleAddUpdateTemplate(false)}>Edit Template</StyledButton>
            <StyledButton disabled={deleteButtonDisabled} onClick={this.handleDelete}>Delete Template</StyledButton>
          </ButtonWrapper>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withModals({
  AddUpdateTemplateModal,
})(TemplateModal);

const Modal = styled(DigeryModal)`
  max-width: 500px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    overflow: auto;
    min-height: 460px;
  }

  .body-container {
    width: 100%;
    min-height: 250px;
  }

  .message {
    width: 500px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const Label = styled.span`
  margin-left: 10px;
  font-size: 1.3rem;
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  color: white;
  min-width: unset;
  font-size: 1.4rem;
  height: 30px;

  label {
    margin: 0;

    span {
      font-weight: normal;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
