import React from 'react';
import { map } from 'lodash';

export default (Modals) => (WrappedComponent) => (
  class extends React.Component {
    state = {};    

    handleOpenModal = (modalId, props = {}) => {
      this.setState({ [modalId]: props });
    }    

    handleCloseModal = (modalId) => {
      if (modalId) {
        this.setState({ [modalId]: false });
      }
    }    

    isModalVisible(modalId) {
      return !!this.state[modalId];
    }    
    render() {
      return [
        <WrappedComponent
          key='WrappedComponent'
          {...this.props}
          openModal={this.handleOpenModal}
          closeModal={this.handleCloseModal}
        />,
        ...map(Modals, (Modal, modalId) => {
          if (!this.isModalVisible(modalId)) {
            return null;
          }
          return (
            <Modal
              key={`modal-${modalId}`}
              {...this.state[modalId]}
              isOpen
              onClose={() => this.handleCloseModal(modalId)}
            />
          );
        })
      ];
    }
  }
);