import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'redux';
import Helmet from 'react-helmet';
import Container from 'components/Container';
import styled from 'styled-components';
import update from 'immutability-helper';
import { sortBy } from 'lodash';
import { getLoanOfficers } from 'modules/api';
import 'react-virtualized/styles.css';
import { AutoSizer, Table, Column } from 'react-virtualized';
import Tooltip from 'components/Tooltip';
import { media } from 'modules/theme';
import RadioBox from 'components/RadioBox';
import withModals from 'modules/withModals';
import DownloadCSV from 'components/DownloadCSV';
import LoanOfficerModal from './LoanOfficerModal';
import PocketAgentsModal from './PocketAgentsModal';
import PrimaryAgentsModal from './PrimaryAgentsModal';
import BackupAgentsModal from './BackupAgentsModal';

import PhotoModal from './PhotoModal';

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: flex-end;
  padding: 1rem 3rem 3rem;
  flex-wrap: wrap;

  ${media.mobile`
    padding: 1rem 0 3rem;
  `};
`;

const AlphabetFilter = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  flex-wrap: wrap;

  button {
    padding: 0 5px;
    &:hover {
      color: ${props => props.theme.palette.primary};
    }
  }
`;

const ActiveOptions = styled.div`
  display: flex;
  padding 15px 0;
`;

const AddLOButton = styled.button`
  text-decoration: underline;
  margin-left: 20px;
`;

const Wrapper = styled.div`
  font-size: 1.3rem;

  .ReactVirtualized__Table__Grid {
    &:focus {
      outline: none;
    }
  }
`;

const DetailContainer = styled.div`
  padding-top: 5px;
  margin-left: 30px;
  & > div {
    line-height: 1.7;
  }
`;

const LinkButton = styled.button`
  text-decoration: underline;
  color: blue;
`;

const BoolSpan = styled.div`
  &::after {
    content: '';
    display: block;
    background: ${props => props.value ? 'green': 'red'};
    border-radius: 50%;
    border: 1px solid ${props => props.value ? 'green': 'red'};
    width: 12px;
    height: 12px;
    margin: auto;
  }
`;

const BioSpan = styled.div`
  width: fit-content;
  margin: auto;
  &::after {
    content: '';
    display: block;
    background: ${props => props.color};
    border-radius: 50%;
    border: 1px solid ${props => props.color};
    width: 12px;
    height: 12px;
    margin: auto;
  }
`;

const RadioForm = styled.div`
  display: flex;
  margin-left: 10px;
  & > span {
    margin-left: 5px;
    margin-right: 10px;
  }
`;

const DownloadWrapper = styled.div`
  height: 0;
  position: relative;
  z-index: 100;
  
  & > a {
    transform: translateY(0%);
    right: 0;
  }
`;

class LoanOfficers extends Component {
  state = {
    loanOfficers: [],
    activeRow: {},
    showPhotoModal: false,
    showProfileModal: false,
    showPocketAgentModal: false,
    showPrimaryAgentModal: false,
    showBackupAgentModal: false,
    editingRow: {},
    alphabetFilterKey: '',
    statusFilter: 'Active',
  };

  _setTableRef = ref => {
    this._table = ref;
  };

  componentDidMount() {
    this.refreshLoanOfficers();
  }

  refreshLoanOfficers = () => {
    getLoanOfficers()
      .then(res => {
        const loanOfficers = sortBy(res, 'FirstName');
        this.setState({
          loanOfficers,
        });
      })
      .catch(() => {});
  };

  handleAlphabetFilter = alphabetFilterKey => () => {
    this.setState({ alphabetFilterKey });
  };

  rowGetter = ({ index }) => {
    const showingLoanOfficers = this.getShowingLoanOfficers();
    return showingLoanOfficers[index];
  };

  getShowingLoanOfficers = () => {
    const { loanOfficers, alphabetFilterKey, statusFilter } = this.state;
    let filteredLOs = loanOfficers
    if (statusFilter === 'Active' || statusFilter === 'Inactive') {
      filteredLOs = loanOfficers.filter(l => l.Status === statusFilter)
    }

    if (!alphabetFilterKey) {
      return filteredLOs;
    }
    return filteredLOs.filter(l => l.FirstName.charAt(0).toLowerCase() === alphabetFilterKey);
  };

  noRowsRenderer() {
    return <div>No Data</div>;
  }

  getRowHeight = ({ index }) => this.state.activeRow[index] ? 235 : 30;

  getTableHeight = () => {
    const elem = document.getElementById('table-wrapper');
    let calcHeight = 600

    if (elem) {
      const rect = elem.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      calcHeight = screenHeight - rect.top - 10
    }
    return calcHeight;
  }


  getRowRenderer = props => {
    const { _key, className, columns, index, style, rowData } = props;
    const { activeRow } = this.state;
    const isExtended = activeRow[index];
    const { ID, LoanOfficerRole, LicensedStates, Email, PhoneWork, PhoneMobile, Position, LoanOfficerNMLS } = rowData;
    const isOdd = index % 2;

    return (
      <div
        key={_key}
        style={{ ...style, backgroundColor: isOdd ? 'white' : '#eeeeee', paddingTop: 7 }}
      >
        <div className={className}>{columns}</div>
        {isExtended && (
          <DetailContainer>
            <div>Velocify ID: {ID || '--'}</div>
            <div>NMLS: {LoanOfficerNMLS || '--'}</div>
            <div>Email: {Email || '--'}</div>
            <div>Work Phone: {PhoneWork || '--'}</div>
            <div>Mobile Phone: {PhoneMobile || '--'}</div>
            <div>Position: {Position || '--'}</div>
            <div>Loan Officer Role: {LoanOfficerRole || '--'}</div>
            <div>Licensed States: {LicensedStates || '--'}</div>
            <LinkButton onClick={this.handleShowPocketAgentsModal(rowData)}> Pocket Agents </LinkButton>
          </DetailContainer>
        )}
      </div>
    );
  };

  toggleRow = index => () => {
    const { activeRow } = this.state;
    const oldState = activeRow[index];
    const newActiveRow = update(activeRow, { [index]: { $set: !oldState } });
    this.setState({ activeRow: newActiveRow }, () => {
      if (this._table) {
        this._table.recomputeRowHeights();
      }
    });
  };

  handleShowPhotoModal = rowData => () => {
    this.setState({
      showPhotoModal: true,
      editingRow: rowData,
    });
  };

  handleShowProfileModal = rowData => () => {
    this.setState({
      showProfileModal: true,
      editingRow: rowData,
    });
  };

  handleAddLoanOfficer = () => {
    this.setState({
      showProfileModal: true,
      editingRow: {},
    });
  };

  handleShowPocketAgentsModal = rowData => () => {
    this.setState({
      showPocketAgentModal: true,
      editingRow: rowData,
    });
  };

  handleShowPrimaryAgentsModal = rowData => () => {
    this.setState({
      showPrimaryAgentModal: true,
      editingRow: rowData,
    });
  };

  handleShowBackupAgentsModal = rowData => () => {
    this.setState({
      showBackupAgentModal: true,
      editingRow: rowData,
    });
  };

  handleChangeStatus = (statusFilter) => () => {
    this.setState({ statusFilter })
  }

  getDownloadData = () => {
    const { loanOfficers } = this.state;
    return loanOfficers.map(lo => ({
      FirstName: lo.FirstName,
      LastName: lo.LastName,
      Status: lo.Status,
      Availability: lo.Availability,
      Selectable: lo.Selectability,
      'Selectable (Recruiting)': lo.SelectabilityRecruiting,
      'Primary #': lo.LOIsPrimaryCount,
      'Recruited #': lo.Recruited,
      'Backup #': lo.LOIsBackupCount,
      'Velocify ID': lo.ID,
      NMLS: lo.LoanOfficerNMLS,
      Email: lo.Email,
      'Work Phone': lo.PhoneWork,
      'Mobile Phone': lo.PhoneMobile,
      Position: lo.Position,
      'Loan Officer Role': lo.LoanOfficerRole,
      'Licensed States': lo.LicensedStates,
      CreatedDate: '2019-07-30T16:03:06.593Z',
    }))
  }


  render() {
    const {
      activeRow,
      editingRow,
      showPhotoModal,
      showProfileModal,
      showPocketAgentModal,
      showPrimaryAgentModal,
      showBackupAgentModal,
      statusFilter,
    } = this.state;
    const alphabets = 'abcdefghijklmnopqrstuvwxyz'.split('');

    const { user } = this.props;
    if (user.data.PermissionsLevel === 3) {
      return <Redirect to="/private/home" />
    }

    const downloadData = this.getDownloadData();

    return (
      <Fragment>
        <Helmet>
          <title>LoanOfficers</title>
          <meta
            name="description"
            content="This is the Keller Mortgage Agent Portal Loan Officers page"
          />
        </Helmet>
        <Container>
          <Header>
            <AlphabetFilter>
              <span>Filter by: </span>
              <button onClick={this.handleAlphabetFilter('')}>All</button>
              <span>|</span>
              {alphabets.map(c => (
                <button key={c} onClick={this.handleAlphabetFilter(c)}>
                  {c.toUpperCase()}
                </button>
              ))}
            </AlphabetFilter>

            <ActiveOptions>
              <span>Status:</span>
              <RadioForm>
                <RadioBox
                  value={statusFilter === 'All'}
                  onChange={this.handleChangeStatus('All')}
                />
                <span>All</span>
              </RadioForm>
              <RadioForm>
                <RadioBox
                  value={statusFilter === 'Active'}
                  onChange={this.handleChangeStatus('Active')}
                />
                <span>Active</span>
              </RadioForm>
              <RadioForm>
                <RadioBox
                  value={statusFilter === 'Inactive'}
                  onChange={this.handleChangeStatus('Inactive')}
                />
                <span>Inactive</span>
              </RadioForm>
            </ActiveOptions>

            <AddLOButton onClick={this.handleAddLoanOfficer}>Add Loan Officer</AddLOButton>
          </Header>
          <DownloadWrapper>
            <DownloadCSV data={downloadData} filename="LoanOfficers" />
          </DownloadWrapper>
          <Wrapper id="table-wrapper">
            <AutoSizer disableHeight>
              {({ width }) => (
                <Table
                  ref={this._setTableRef}
                  headerHeight={50}
                  noRowsRenderer={this.noRowsRenderer}
                  rowHeight={this.getRowHeight}
                  rowGetter={this.rowGetter}
                  rowCount={this.getShowingLoanOfficers().length}
                  height={this.getTableHeight()}
                  rowRenderer={this.getRowRenderer}
                  width={width}
                >
                  <Column
                    dataKey="Show"
                    width={10}
                    cellRenderer={({ rowIndex }) => (
                      <button onClick={this.toggleRow(rowIndex)}>
                        {activeRow[rowIndex] ? '-' : '+'}
                      </button>
                    )}
                  />
                  <Column
                    label="Name"
                    dataKey="Name"
                    width={150}
                    cellRenderer={({ rowData }) => {
                      const { FirstName, LastName } = rowData;
                      return (
                        <LinkButton
                          onClick={this.handleShowProfileModal(rowData)}
                        >{`${FirstName} ${LastName}`}
                        </LinkButton>
                      );
                    }}
                  />
                  <Column
                    width={80}
                    label="Status"
                    dataKey="Status"
                    headerStyle={{ textAlign: 'center' }}
                    cellRenderer={({ cellData }) => <BoolSpan value={cellData === 'Active'} />}
                  />
                  <Column
                    width={80}
                    label="Available"
                    dataKey="Availability"
                    headerStyle={{ textAlign: 'center' }}
                    cellRenderer={({ cellData }) => <BoolSpan value={cellData} />}
                  />
                  <Column
                    width={80}
                    label="Selectable"
                    dataKey="Selectability"
                    headerStyle={{ textAlign: 'center' }}
                    cellRenderer={({ cellData }) => <BoolSpan value={cellData} />}
                  />
                  <Column
                    width={100}
                    label={<div><div>Selectable</div><div>(Recruiting)</div></div>}
                    dataKey="SelectabilityRecruiting"
                    headerStyle={{ textAlign: 'center' }}
                    cellRenderer={({ cellData }) => <BoolSpan value={cellData} />}
                  />
                  <Column
                    width={80}
                    label="Bio"
                    dataKey="PersonalBioStatus"
                    headerStyle={{ textAlign: 'center' }}
                    cellRenderer={({ cellData, rowData }) => {
                      let color;
                      let tooltip;
                      switch(cellData) {
                        case 1:
                          color = 'green';
                          tooltip = 'Enabled';
                          break;
                        case 2:
                          color = 'orange';
                          tooltip = 'Enabled / New Approval Requested';
                          break;
                        case 3:
                          color = 'yellow';
                          tooltip = 'Disabled / New Approval Requested';
                          break;
                        default:
                          color = 'red';
                          tooltip = 'Disabled';
                      }

                      return (
                        <BioSpan color={color} id={`tooltip-bio-${rowData.ID}`}>
                          <Tooltip placement="left" target={`tooltip-bio-${rowData.ID}`} delay={0}>
                            {tooltip}
                          </Tooltip>
                        </BioSpan>
                      )
                    }}
                  />
                  <Column
                    width={100}
                    label="Primary #"
                    dataKey="LOIsPrimaryCount"
                    headerStyle={{ textAlign: 'center' }}
                    style={{ textAlign: 'center' }}
                    cellRenderer={({ rowData }) => {
                      const { LOIsPrimaryCount } = rowData;
                      if (!LOIsPrimaryCount) {
                        return null
                      }
                      return (
                        <LinkButton onClick={this.handleShowPrimaryAgentsModal(rowData)}>
                          {LOIsPrimaryCount}
                        </LinkButton>
                      );
                    }}
                  />
                  <Column
                    width={100}
                    label="Backup #"
                    dataKey="LOIsBackupCount"
                    headerStyle={{ textAlign: 'center' }}
                    style={{ textAlign: 'center' }}
                    cellRenderer={({ rowData }) => {
                      const { LOIsBackupCount } = rowData;
                      if (!LOIsBackupCount) {
                        return null
                      }
                      return (
                        <LinkButton onClick={this.handleShowBackupAgentsModal(rowData)}>
                          {LOIsBackupCount}
                        </LinkButton>
                      );
                    }}
                  />
                  <Column
                    width={100}
                    label="Recruited #"
                    dataKey="Recruited"
                    headerStyle={{ textAlign: 'center' }}
                    style={{ textAlign: 'center' }}
                    cellRenderer={({ cellData }) => cellData}
                  />
                  <Column
                    width={120}
                    label="Profile Photo"
                    dataKey="ProfilePhoto"
                    cellRenderer={({ rowData }) => {
                      const { ProfilePhoto } = rowData;
                      return (
                        <LinkButton onClick={this.handleShowPhotoModal(rowData)}>
                          {ProfilePhoto}
                        </LinkButton>
                      );
                    }}
                  />
                </Table>
              )}
            </AutoSizer>
          </Wrapper>
          <PhotoModal
            data={editingRow}
            showModal={showPhotoModal}
            onClose={() => this.setState({ showPhotoModal: false })}
          />
          <LoanOfficerModal
            data={editingRow}
            showModal={showProfileModal}
            onUpdate={this.refreshLoanOfficers}
            onClose={() => {
              this.refreshLoanOfficers()
              this.setState({ showProfileModal: false })
            }}
            dispatch={this.props.dispatch}
          />
          <PocketAgentsModal
            loanOfficer={editingRow}
            showModal={showPocketAgentModal}
            onUpdate={this.refreshLoanOfficers}
            onClose={() => this.setState({ showPocketAgentModal: false })}
            dispatch={this.props.dispatch}
          />
          <PrimaryAgentsModal
            loanOfficer={editingRow}
            showModal={showPrimaryAgentModal}
            onUpdate={this.refreshLoanOfficers}
            onClose={() => this.setState({ showPrimaryAgentModal: false })}
            dispatch={this.props.dispatch}
          />
          <BackupAgentsModal
            loanOfficer={editingRow}
            showModal={showBackupAgentModal}
            onUpdate={this.refreshLoanOfficers}
            onClose={() => this.setState({ showBackupAgentModal: false })}
            dispatch={this.props.dispatch}
          />
        </Container>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default compose(
  connect(mapStateToProps),
  withModals({
    LoanOfficerModal,
  })
)(LoanOfficers)
