import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/lib/constants';

import { ActionTypes } from 'constants/index';

export const appState = {
  alerts: [],
  visibleMobileSidebar: false,
  deviceInfo: {},
  unreadMessageCount: 0,
};

export default {
  app: handleActions(
    {
      [REHYDRATE]: state =>
        immutable(state, {
          alerts: { $set: [] },
        }),
      [ActionTypes.HIDE_ALERT]: (state, { payload: { id } }) => {
        const alerts = state.alerts.filter(d => d.id !== id);

        return immutable(state, {
          alerts: { $set: alerts },
        });
      },
      [ActionTypes.SHOW_ALERT]: (state, { payload }) =>
        immutable(state, {
          alerts: { $push: [payload] },
        }),
      [ActionTypes.TOGGLE_MOBILE_SIDE_MENU]: state =>
        immutable(state, {
          visibleMobileSidebar: { $set: !state.visibleMobileSidebar },
        }),
      [ActionTypes.SET_DEVICE_INFO]: (state, { payload }) =>
        immutable(state, {
          deviceInfo: { $set: payload },
        }),
      [ActionTypes.SET_UNREAD_MESSAGE_COUNT]: (state, { payload }) =>
        immutable(state, {
          unreadMessageCount: { $set: payload },
        }),
    },
    appState,
  ),
};
