import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Flex } from 'styled-minimal';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { setToken } from 'modules/localstorage';

import HeaderTitle from 'components/HeaderTitle';
import Input from 'components/Input';
import Button from 'components/Button';

import { login } from 'actions/index';
import ZeroPlusLogo from 'assets/media/brand/zeroplus_logo.png';
import { signup } from 'modules/api';

const Logo = styled.img`
  margin-bottom: 25px;
`;

const SubTitle = styled.div`
  font-size: 28px;
  color: #5b5b5b;
`;

const StyledInput = styled(Input)`
  width: 238px;
  margin: 7px;
`;

const JoinButton = styled(Button)`
  margin: 45px 0 5px;
`;

const SignInButton = styled(Link)`
  color: ${props => props.theme.palette.primary};
  font-size: 13px;
`;

class SignUp extends React.PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    Password: '',
    Password2: '',
    FirstName: '',
    LastName: '',
    Phone: '',
    Email: '',
    Status: 1,
  };

  onChange = key => e => {
    this.setState({
      [key]: e.target.value,
    });
  };

  handleClickSignUp = () => {
    const { Email, Password } = this.state;
    if (!Password) {
      dispatch(showAlert('Password is required.', { variant: 'danger', icon: 'bell' }));
      return;
    }
    if (!Email) {
      dispatch(showAlert('Email is required.', { variant: 'danger', icon: 'bell' }));
      return;
    }
    signup(this.state)
      .then(res => {
        const { token } = res;
        setToken(token);
        const { dispatch } = this.props;
        dispatch(login(this.state));
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { FirstName, LastName, Email, Phone, Password, Password2 } = this.state;
    return (
      <Fragment>
        <Helmet>
          <title>Sign up</title>
        </Helmet>
        <HeaderTitle>Save Your Clients Thousands of Dollars</HeaderTitle>
        <Logo src={ZeroPlusLogo} />
        <SubTitle>Sign Up to Join the Lab</SubTitle>
        <Flex justifyContent="center" mt="30px">
          <StyledInput
            type="text"
            placeholder="First Name"
            value={FirstName}
            onChange={this.onChange('FirstName')}
          />
          <StyledInput
            type="text"
            placeholder="Last Name"
            value={LastName}
            onChange={this.onChange('LastName')}
          />
        </Flex>
        <Flex justifyContent="center">
          <StyledInput
            type="email"
            placeholder="Email"
            value={Email}
            onChange={this.onChange('Email')}
          />
          <StyledInput
            type="text"
            placeholder="Phone"
            value={Phone}
            onChange={this.onChange('Phone')}
          />
        </Flex>
        <Flex justifyContent="center">
          <StyledInput
            type="password"
            placeholder="Password"
            value={Password}
            onChange={this.onChange('Password')}
          />
          <StyledInput
            type="password"
            placeholder="Password2"
            value={Password2}
            onChange={this.onChange('Password2')}
          />
        </Flex>
        <JoinButton onClick={this.handleClickSignUp}>JOIN</JoinButton>
        <SignInButton to="/signin">or Sign In</SignInButton>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(SignUp);
