import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Container from 'components/Container';
import Helmet from 'react-helmet';
import { media, MobileWidth } from 'modules/theme';
import { NavLink } from 'react-router-dom';


import PortalUsersChart from './PortalUsersChart'
import PageViewsChart from './PageViewsChart';
import RACChart from './RACChart';
import RACChartPortalAdmin from './RACChartPortalAdmin';
import SOSChart from './SOSChart';
import NotificationsByDay from './NotificationsByDay';
import NotificationsByType from './NotificationsByType';
import EventsByType from './EventsByType';
import PageViewsByDevice from './PageViewsByDevice';
import PageViewsByDeviceGroup from './PageViewsByDeviceGroup';
import PageViewsByPage from './PageViewsByPage';
import MobileContentModal from './MobileContentModal'
import NotificationResponses from './NotificationResponses';


const Body = styled(Container)`
  display: flex;

  ${media.mobile`
    padding-left: 10px;
    padding-right: 10px;
  `};

  ${media.handheld`
    padding-left: 10px;
    padding-right: 10px;
  `};
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  
  ${media.mobile`
    width: 100%;
  `};
`;

const MenuLink = styled(NavLink)`
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  background: white;
  text-align: center;
  color: ${props => props.theme.palette.gray};

  &.active {
    color: ${props => props.theme.palette.primary};
  }
`;

const Menus = {
  NOTIFICATIONS_BY_DATE: 'notifications_by_day',
  NOTIFICATIONS_BY_TYPE: 'notifications_by_type',
  NOTIFICATION_RESPONSES: 'notification_responses',
  PORTAL_USERS: 'portal_users',
  EVENTS_BY_TYPE: 'events_by_type',
  RAC_SUBMISSIONS: 'rac_submissions',
  RAC_SUBMISSIONS_LO: 'rac_submissions_lo',
  SOS_SUBMISSIONS: 'sos_submissions',
  PAGE_VIEWS: 'page_views',
  PAGE_VIEWS_BY_PAGE: 'page_views_by_page',
  PAGE_VIEWS_BY_DEVICE_GROUP: 'page_views_by_device_group',
  PAGE_VIEWS_BY_DEVICE: 'page_views_by_device',
}

class Activity extends Component {

  state = {
    isMobile: false,
    showMobileModal: false
  }

  getScreenWdith = () => {
    let isMobile = false;
    if (window.innerWidth < MobileWidth) {
      isMobile = true;
    }
     this.setState({ isMobile });
  }

  componentDidMount() {
    this.getScreenWdith();
    window.addEventListener("resize", this.getScreenWdith);
  }
  
  componentWillUnmount() {
    window.removeEventListener("resize", this.getScreenWdith);
  }

  handleModal = (showMobileModal) => () => {
    this.setState({ showMobileModal })
  }

  render() {
    const { showMobileModal, isMobile } = this.state
    const { user } = this.props;
    const isRep = user.data.PermissionsLevel === 3;
    const chartType = this.props.match.params.chartType;

    if (isRep && chartType !== Menus.RAC_SUBMISSIONS_LO) {
      return <Redirect to={`/private/activity/${Menus.RAC_SUBMISSIONS_LO}`} />
    }

    let chart = <NotificationsByDay/>

    switch(chartType) {
      case Menus.NOTIFICATIONS_BY_DATE:
        chart = <NotificationsByDay />
        break
      case Menus.NOTIFICATIONS_BY_TYPE:
        chart = <NotificationsByType />
        break
      case Menus.NOTIFICATION_RESPONSES:
        chart = <NotificationResponses />
        break
      case Menus.PORTAL_USERS:
        chart = <PortalUsersChart />
        break
      case Menus.EVENTS_BY_TYPE:
        chart = <EventsByType />
        break
      case Menus.RAC_SUBMISSIONS:
        chart = <RACChart />
        break
      case Menus.RAC_SUBMISSIONS_LO:
        chart = <RACChartPortalAdmin />
        break
      case Menus.SOS_SUBMISSIONS:
        chart = <SOSChart />
        break
      case Menus.PAGE_VIEWS:
        chart = <PageViewsChart />
        break
      case Menus.PAGE_VIEWS_BY_PAGE:
        chart = <PageViewsByPage />
        break
      case Menus.PAGE_VIEWS_BY_DEVICE_GROUP:
        chart = <PageViewsByDeviceGroup />
        break
      case Menus.PAGE_VIEWS_BY_DEVICE:
        chart = <PageViewsByDevice />
        break
    }

    return (
      <Fragment>
        <Helmet>
          <title>Activity</title>
          <meta name="description" content="This is the Keller Mortgage Admin Portal Activity page" />
        </Helmet>
        <Body>
          <NavBar>
            {!isRep && 
              <>
                <MenuLink to={`/private/activity/${Menus.NOTIFICATIONS_BY_DATE}`} exact activeClassName="active" onClick={this.handleModal(true)}>
                  Notifications Sent by Day
                </MenuLink>
                <MenuLink to={`/private/activity/${Menus.NOTIFICATIONS_BY_TYPE}`} exact activeClassName="active" onClick={this.handleModal(true)}>
                  Notifications Sent by Type
                </MenuLink>
                <MenuLink to={`/private/activity/${Menus.NOTIFICATION_RESPONSES}`} exact activeClassName="active" onClick={this.handleModal(true)}>
                  Notification Responses
                </MenuLink>
                <MenuLink to={`/private/activity/${Menus.PORTAL_USERS}`} exact activeClassName="active" onClick={this.handleModal(true)}>
                  Portal Users
                </MenuLink>
                <MenuLink to={`/private/activity/${Menus.EVENTS_BY_TYPE}`} exact activeClassName="active" onClick={this.handleModal(true)}>
                  Events by Type
                </MenuLink>
                <MenuLink to={`/private/activity/${Menus.RAC_SUBMISSIONS}`} exact activeClassName="active" onClick={this.handleModal(true)}>
                  Refer a Client Submissions <br/> (Agents)
                </MenuLink>
              </>
            }
            <MenuLink to={`/private/activity/${Menus.RAC_SUBMISSIONS_LO}`} exact activeClassName="active" onClick={this.handleModal(true)}>
              Refer a Client Submissions <br/> (Loan Officers)
            </MenuLink>
            {!isRep && 
              <>
                <MenuLink to={`/private/activity/${Menus.SOS_SUBMISSIONS}`} exact activeClassName="active" onClick={this.handleModal(true)}>
                  SOS Submissions
                </MenuLink>
                <MenuLink to={`/private/activity/${Menus.PAGE_VIEWS}`} exact activeClassName="active" onClick={this.handleModal(true)}>
                  Page Views
                </MenuLink>
                <MenuLink to={`/private/activity/${Menus.PAGE_VIEWS_BY_PAGE}`} exact activeClassName="active" onClick={this.handleModal(true)}>
                  Page Views By Page
                </MenuLink>
                <MenuLink to={`/private/activity/${Menus.PAGE_VIEWS_BY_DEVICE_GROUP}`} exact activeClassName="active" onClick={this.handleModal(true)}>
                  Page Views By Device Group
                </MenuLink>
                <MenuLink to={`/private/activity/${Menus.PAGE_VIEWS_BY_DEVICE}`} exact activeClassName="active" onClick={this.handleModal(true)}>
                  Page Views By Device
                </MenuLink>
              </>
            }
          </NavBar>
          {isMobile ? (
            <MobileContentModal showModal={showMobileModal} onClose={this.handleModal(false)}>
              {chart}
            </MobileContentModal>
          ) : (
            chart
          )}
        </Body>
      </Fragment>
    )
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(Activity);

