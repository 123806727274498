import styled from 'styled-components';

const TextArea = styled.textarea`
  width: 100%;
  border: #c4cdd5 0.1rem solid;
  border-radius: 0.3rem;
  font-size: 1.4rem;
  padding: 0.5rem;
  &:focus {
    outline-width: 0.1rem;
  }
`;

export default TextArea;
