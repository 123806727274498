/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik'
import DigeryModal from 'components/Modal';
import { ModalHeader, ModalBody } from 'reactstrap';
import {
  getUSAStates,
  addUpdateAgentOrganization,
} from 'modules/api';
import { showAlert } from 'actions/index';
import Button from 'components/Button';
import InputField from 'components/Form/InputField';
import SelectField from 'components/Form/SelectField';
import * as Yup from 'yup';
import { media } from 'modules/theme';

const Schema = Yup.object().shape({
  OrganizationName: Yup.string().required('Organization Name is required'),
  Address1: Yup.string().required('Address 1 is required'),
  City: Yup.string().required('City is required'),
  StateCode: Yup.string().required('State is required'),
  PostalCode: Yup.string().required('Postal Code is required'),
  CountryCode: Yup.string().required('Country Code is required'),
});

class AddEditOrganizationModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    initialOrganization: { CountryCode: 'US' },
    states: [],
  };

  componentDidMount() {
    const { organization } = this.props;
    if (organization) {
      this.setState({ initialOrganization: organization });
    }

    this.initFetch();
  }

  initFetch = () => {
    getUSAStates()
      .then(res => {
        this.setState({
          states: res.map(el => ({
            label: el.StateName,
            value: el.StateCode,
          })),
        })
      })
      .catch(() => {});
  }

  handleClose = () => {
    this.props.onClose();
  };

  handleSubmit = async (values) => {
    const { dispatch, onUpdate, onClose } = this.props;

    try {
      await addUpdateAgentOrganization(values);
      dispatch(showAlert('Saved successfully.', { variant: 'success', icon: 'bell' }));
      onUpdate();
      onClose();
    } catch (error) {
      dispatch(showAlert('Error', { variant: 'danger', icon: 'bell' }));
    }
  };

  render() {
    const { isOpen } = this.props;
    const { initialOrganization, states } = this.state;
    const fieldNames = Schema._nodes

    return (
      <Modal fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>Organization Detail</ModalHeader>
        <ModalBody>
          <ProfileContent>
            <Formik
              enableReinitialize={true}
              initialValues={initialOrganization}
              validationSchema={Schema}
            >
              {({ validateForm, values }) => (
                <StyledForm>
                  <InputField name="OrganizationName" label="Organization*" />
                  <InputField
                    name="Address1"
                    label="Address 1*"
                  />
                  <InputField
                    name="Address2"
                    label="Address 2"
                  />
                  <InputField
                    name="City"
                    label="City*"
                  />
                  <SelectField
                    options={states}
                    name="StateCode"
                    label="State*"
                  />
                  <InputField
                    name="PostalCode"
                    label="Postal Code*"
                  />
                  <InputField
                    name="CountryCode"
                    label="Country Code*"
                  />
                  <Bottom>
                    <div>(*) Required</div>
                    <Button
                      type="button"
                      onClick={() => {
                        validateForm()
                          .then(res => {
                            let errorMsg
                            for(let i = fieldNames.length - 1; i > -1; i--) {
                              const field = fieldNames[i];
                              errorMsg = res[field];
                              if (errorMsg) {
                                break;
                              }
                            }
                            if (errorMsg) {
                              this.props.dispatch(showAlert(errorMsg, { variant: 'danger', icon: 'bell', timeout: 3 }));
                            } else {
                              this.handleSubmit(values);
                            }
                          })
                      }}
                    >Save
                    </Button>
                  </Bottom>
                </StyledForm>
              )}
            </Formik>
          </ProfileContent>
        </ModalBody>
      </Modal>
    );
  }
}

export default AddEditOrganizationModal;

const Modal = styled(DigeryModal)`
  max-width: 500px;
  padding: 0;

  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }

  .modal-body {
    overflow: auto;
    text-align: center;
    padding: 1rem;
    display: flex;

    ${media.handheld`
      flex-direction: column;
    `};
  }
`;

const ProfileContent = styled.div`
  flex: 1;
  padding: 0 2rem;

  .field-row {
    margin-bottom: 1rem;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
  text-align: center;
`;

const Bottom = styled.div`
  margin: 2.5rem 0 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;

  & div {
    color: ${props => props.theme.palette.darkText};
    font-size: 1.3rem;
    width: 150px;
  }
`;