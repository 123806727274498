import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import {
  getRecruitingActivityTypes,
  addRecruitingActivity,
  getRecruitingHistory,
  getRecruitingNotes,
  addRecruitingNote,
  addRecruitingLOAssignment,
  getRecruitingLoanOfficersSelectable,
} from 'modules/api';
import Button from 'components/Button';
import Table, { ProgressRow } from 'components/Table';
import TextArea from 'components/TextArea';
import { Flex } from 'styled-minimal';
import withModals from 'modules/withModals';
import SendMessageModal from '../MyInbox/SendMessageModal';
import CommunicationHistoryModal from '../MyInbox/CommunicationHistoryModal';
import Icon from 'components/Icon';
class ActivityModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    isNoteFetching: true,
    notes: [],
    newNote: '',
    options: [],
    histories: [],
    isHistoryFetching: true,
    selectedActivity: null,
    selectedLoanOfficer: null,
    allLoanOfficers: [],
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.initFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.initFetch();
    }
  }

  initFetch = () => {
    this.fetchOptions();
    this.fetchNotes();
    this.fetchHistory();

    const { AgentStateCode } = this.props.selectedAgent;
    getRecruitingLoanOfficersSelectable(AgentStateCode).then(res => {
      const allLoanOfficers = res
        .filter(r => r.Availability && r.Selectability)
        .map(r => ({
          ...r,
          Name: `${r.FirstName} ${r.LastName}`,
          LOID: r.ID,
        }));
      this.setState({ allLoanOfficers });
    });
  };

  fetchOptions = () => {
    getRecruitingActivityTypes()
      .then(res => {
        const options = res.result;
        this.setState({ options });
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchHistory = () => {
    const { assignmentPK } = this.props;
    this.setState({
      isHistoryFetching: true,
    });

    getRecruitingHistory(assignmentPK)
      .then(res => {
        this.setState({
          histories: res.result,
          isHistoryFetching: false,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchNotes = () => {
    const { assignmentPK } = this.props;
    this.setState({
      notes: [],
      newNote: '',
      isNoteFetching: true,
    });

    getRecruitingNotes(assignmentPK)
      .then(res => {
        this.setState({
          notes: res.result,
          isNoteFetching: false,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleSave = async () => {
    const { newNote, selectedActivity, selectedLoanOfficer } = this.state;
    const { assignmentPK, selectedAgent } = this.props;

    if (newNote) {
      await addRecruitingNote(null, assignmentPK, newNote);
      await this.fetchNotes();
      this.setState({ newNote: '' });
    }

    if (selectedActivity) {
      if (selectedActivity.label === 'Assign to Loan Officer' && selectedLoanOfficer) {
        const assignments = [
          {
            KWUID: selectedAgent.KWUID,
            LOID: selectedLoanOfficer.value,
            AssignmentPK: assignmentPK,
          },
        ];
        await addRecruitingLOAssignment(JSON.stringify(assignments));
      }
      await addRecruitingActivity(selectedActivity.value, assignmentPK);
    }

    this.props.onSubmit();
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleOpenSendMessageModal = isEmail => () => {
    const agent = this.props.selectedAgent;
    this.props.openModal('SendMessageModal', {
      isEmail,
      user: this.props.user,
      dispatch: this.props.dispatch,
      kwuid: agent.KWUID,
      onSend: () => this.fetchHistory()
    });
  };

  handleOpenCommunicationHistoryModal = () => {
    const { assignmentPK } = this.props;

    this.props.openModal('CommunicationHistoryModal', {
      ThreadID: assignmentPK,
      user: this.props.user,
      dispatch: this.props.dispatch,
      onUpdate: () => {},
    });
  };

  render() {
    const { isOpen, selectedAgent } = this.props;
    const {
      options,
      histories,
      isNoteFetching,
      isHistoryFetching,
      notes,
      newNote,
      selectedActivity,
      selectedLoanOfficer,
      allLoanOfficers,
    } = this.state;

    const activityTypeOptions = options.map(o => ({
      label: o.ActivityName,
      value: o.ActivityTypeID,
    }));

    const loanOfficersOptions = allLoanOfficers.map(l => ({
      label: l.Name,
      value: l.LOID,
    }));

    let activityOption = {};
    if (selectedActivity) {
      activityOption = options.find(o => o.ActivityTypeID === selectedActivity.value);
    }

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>
          Activity
          <div className="title-agent-name">{`${selectedAgent.AgentFirstName} ${selectedAgent.AgentLastName}`}</div>
        </ModalHeader>
        <ModalBody>
          <Flex justifyContent="center">
            <ComposeWrapper onClick={this.handleOpenSendMessageModal(true)}>
              <Icon name="compose-email" color="black" width={20} />
              Compose Email
            </ComposeWrapper>
            <ComposeWrapper onClick={this.handleOpenSendMessageModal(false)}>
              <Icon name="compose-sms" color="black" width={20} />
              Compose SMS
            </ComposeWrapper>
            <ComposeWrapper onClick={this.handleOpenCommunicationHistoryModal}>
              <Icon name="list" color="black" width={18} />
              View History
            </ComposeWrapper>
          </Flex>
          <Label>Select an Activity:</Label>
          <StyledSelect
            styles={CustomDropdownStyles}
            placeholder="- Choose One -"
            options={activityTypeOptions}
            onChange={e => this.handleChange('selectedActivity', e)}
            value={selectedActivity}
          />
          {activityOption && activityOption.AssignToLOFlag && (
            <>
              <Label>Select a loan officer:</Label>
              <StyledSelect
                styles={CustomDropdownStyles}
                placeholder="- Choose One -"
                options={loanOfficersOptions}
                onChange={e => this.handleChange('selectedLoanOfficer', e)}
                value={selectedLoanOfficer}
              />
            </>
          )}
          <div>
            <Label>Add a Note</Label>
            <TextArea
              id="note-label"
              placeholder="Write Your Note Here"
              rows={8}
              onChange={e => this.handleChange('newNote', e.target.value)}
              value={newNote}
            />
            <Flex justifyContent="center">
              <StyledButton onClick={this.handleSave}>Save</StyledButton>
            </Flex>

            <Label>Notes:</Label>
            {notes.length > 0 ? (
              <Table>
                <tbody>
                  {notes.map((row, index) => (
                    <ProgressRow key={row.PK} progress={0} odd={index % 2}>
                      <td>
                        <Card>
                          <div>
                            <strong>{row.Note}</strong>
                          </div>
                          <div>
                            {`Entered by ${row.Firstname} ${row.LastName}`} &nbsp; &nbsp;{' '}
                            {`${moment(row.CreatedDate).format('MM/DD/YYYY')}`}
                          </div>
                        </Card>
                      </td>
                    </ProgressRow>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Fragment>{!isNoteFetching && <div>There is no note.</div>}</Fragment>
            )}
          </div>
          <Label>History:</Label>
          {histories.length > 0 ? (
            <TableWrapper>
              <Table>
                <tbody>
                  {histories.map((row, index) => (
                    <ProgressRow key={row.PK} progress={0} odd={index % 2}>
                      <td>{row.ActivityName}</td>
                      <td>{moment(row.CreatedDate).format('MM/DD/YYYY')}</td>
                    </ProgressRow>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
          ) : (
            <Fragment>{!isHistoryFetching && <div>There is no activity.</div>}</Fragment>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default withModals({
  SendMessageModal,
  CommunicationHistoryModal,
})(ActivityModal);


const Modal = styled(DigeryModal)`
  max-width: 500px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
      text-align: center;
    }
    .title-agent-name {
      font-size: 14px;
    }
  }
  .modal-content {
    overflow: auto;
    min-height: 347px;
    font-size: 1.2rem;
  }
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  color: white;
  min-width: unset;
  font-size: 1.4rem;
  height: 30px;
`;

const Row = styled.div`
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  color: ${props => props.theme.palette.primary};
  font-size: 1.4rem;
  margin: 14px 0 0.3rem;
`;

const Card = styled.div``;

const StyledSelect = styled(Select)`
  text-align: left;
  width: 300px;
  font-size: 1.2rem;
  box-shadow: none;
  border-radius: 3px;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
    min-height: 40px;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '0px 8px',
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    '& > div': {
      padding: '0 0px',
      background: 'white',
      zIndex: 10,
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 200,
  }),
};

const ComposeWrapper = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  margin-right: 25px;

  & > span {
    margin-right: 5px;
  }
`

const TableWrapper = styled.div`
  max-height: 200px;
  overflow: auto;
`
