import React from 'react';
import styled from 'styled-components';
import { media } from 'modules/theme';
import Icon from 'components/Icon';

const Title = styled.div`
  display: none;

  ${media.mobile`
    width: 100%;
    text-align: center;
    display: block;
    background: white;
    color: ${props => props.theme.palette.darkText};
    padding: 1rem;
    font-size: 2rem;
    position: relative;
  `};
`;

const PhoneLink = styled.a`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const MobileTitle = ({ title, hasPhone, onClick }) => (
  <Title onClick={onClick}>
    {hasPhone && (
      <PhoneLink href="tel:1-888-766-2678">
        <Icon name="phone2" width={30} />
      </PhoneLink>
    )}
    {title}
  </Title>
);

export default MobileTitle;
