import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import { get } from 'lodash';
import update from 'immutability-helper';
import DigeryModal from 'components/Modal';
import Input from 'components/Input';
import CheckBox from 'components/CheckBox';
import Select from 'react-select';
import { STATES } from 'constants/index';
import Button from 'components/Button';
import {
  getPocketAgents,
  getPocketAgentSearch,
  addPortalAdminKWPocketAgent,
  deletePocketAgent,
} from 'modules/api';
import { showAlert } from 'actions/index';
import Table, { ProgressRow } from 'components/Table';
import AsyncSelect from 'react-select/async';
import Icon from 'components/Icon';

const Modal = styled(DigeryModal)`
  max-width: 500px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
`;

const Label = styled.div`
  text-align: left;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
`;

const LOName = styled.div`
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 2rem;
`;

const StyledDropDown = styled(AsyncSelect)`
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  box-shadow: none;
  border-radius: 3px;
  margin-bottom: 2rem;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CenteredTD = styled.td`
  text-align: center;
`;

const CenterTH = styled.th`
  text-align: center;
`;

const DeleteIcon = styled(Icon)`
  svg {
    width: 17px;
    path {
      fill: ${props => props.theme.palette.gray};
    }
  }
`;

const SaveButton = styled.div`
  color: black;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-weight: bold;
  font-size: 3rem;
  line-height: 18px;
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '7px 8px',
  }),
};

export default class PocketAgentsModal extends Component {
  static propTypes = {
    loanOfficer: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
  };

  state = {
    agents: [],
  };

  componentDidMount() {
    if (this.props.showModal) {
      this.fetchPocketAgents();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.showModal && !prevProps.showModal) {
      this.fetchPocketAgents();
    }
  }

  fetchPocketAgents = () => {
    const { loanOfficer } = this.props;
    getPocketAgents(loanOfficer.ID)
      .then(res => {
        this.setState({ agents: res });
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleClose = () => {
    this.props.onClose();
  };

  loadOptions = (inputValue, callback) => {
    const { loanOfficer } = this.props;
    if (inputValue.length < 3) {
      callback([]);
      return;
    }
    getPocketAgentSearch(loanOfficer.ID, inputValue)
      .then(res => {
        const options = res.map(r => ({
          label: `${r.AgentName} (${r.KWUID})`,
          value: r.KWUID,
        }));
        callback(options);
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleSelect = selectedAgent => {
    const { loanOfficer } = this.props;

    addPortalAdminKWPocketAgent(selectedAgent.value, loanOfficer.ID)
      .then(res => {
        this.fetchPocketAgents();
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleDeleteAgent = PK => () => {
    deletePocketAgent(PK)
      .then(res => {
        this.fetchPocketAgents();
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { showModal, loanOfficer } = this.props;
    const { agents } = this.state;

    return (
      <Modal centered fade={false} isOpen={showModal} toggle={this.handleClose} width={700}>
        <ModalHeader toggle={this.handleClose}>Pocket Agents</ModalHeader>
        <ModalBody>
          <LOName>
            Loan Officer:{' '}
            {`${get(loanOfficer, 'FirstName', '')} ${get(loanOfficer, 'LastName', '')}`}
          </LOName>
          <Label>Add Pocket Agent</Label>
          <StyledDropDown
            value={null}
            cacheOptions
            loadOptions={this.loadOptions}
            onChange={this.handleSelect}
            styles={CustomDropdownStyles}
            placeholder="Search Pocket Agent to Add"
          />
          <Table>
            <thead>
              <tr>
                <th>KWUID</th>
                <th>Pocket Agent Name</th>
                <CenterTH>Remove</CenterTH>
              </tr>
            </thead>
            <tbody>
              {agents.map(a => (
                <ProgressRow>
                  <td>{a.KWUID}</td>
                  <td>{a.AgentName}</td>
                  <CenteredTD>
                    <SaveButton onClick={this.handleDeleteAgent(a.PK)}>
                      <DeleteIcon name="times" width={14} />
                    </SaveButton>
                  </CenteredTD>
                </ProgressRow>
              ))}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    );
  }
}
