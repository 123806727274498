import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import TextArea from 'components/TextArea';
import {
  getAgentRegistrationDetail,
  getAgentRegistrationLoanOfficers,
  getAgentOrganizations,
  getUSAStates,
  addUpdateAgentsRegistration,
} from 'modules/api';
import Button from 'components/Button';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import { formatDate } from 'modules/helpers';
import withModals from 'modules/withModals';
import OrganizationManagerModal from './OrganizationManagerModal';

class AgentRegistrationDetailModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    data: {},
    loanOfficers: [],
    states: [],
    organizations: [],
    isSubmitting: false,
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.initFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.initFetch();
    }
  }

  initFetch = () => {
    this.fetchAgent();

    getAgentRegistrationLoanOfficers()
      .then(res => {
        this.setState({ loanOfficers: res })
      })
      .catch(() => {});

    getUSAStates()
      .then(res => {
        this.setState({ states: res })
      })
      .catch(() => {});

    this.fetchOrganizations()
  }

  fetchAgent = () => {
    const { agent } = this.props;
    this.setState({ data: {} });

    getAgentRegistrationDetail(agent?.ID[0])
      .then(res => {
        this.setState({ data: res[0] })
      })
      .catch(() => {});
  };

  fetchOrganizations = () => {
    getAgentOrganizations()
      .then(res => {
        this.setState({ organizations: res })
      })
      .catch(() => {});
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleChange = (key, newValue) => {
    const data = {...this.state.data, [key]: newValue};
    this.setState({
      data,
    });
  };

  handleSave = () => {
    this.setState({ isSubmitting: true });
    const { AgentFirstName, AgentLastName, ...rest } = this.state.data;
    const payload = {
      ...rest,
      FirstName: AgentFirstName,
      LastName: AgentLastName,
      CountryCode: 'US',
      OrganizationNameOtherCountryCode: 'US'
    }
    addUpdateAgentsRegistration(JSON.stringify(payload))
      .then(() => {
        this.props.onClose();
        this.props.onUpdate && this.props.onUpdate();
        this.setState({ isSubmitting: false });
      })
      .catch(() => {
        this.setState({ isSubmitting: false });
      });
  };

  handleOrganizationManager = () => {
    this.props.openModal('OrganizationManagerModal', {
      dispatch: this.props.dispatch,
      onUpdate: () => {
        this.fetchOrganizations()
      },
    })
  }

  render() {
    const { isOpen, userLevel } = this.props;
    const { data, loanOfficers, states, organizations, isSubmitting } = this.state;
    const nullOption = { label: '- - -', value: null };
    const isAdminOrUserPermission = userLevel === 1 || userLevel === 2;

    const loanOfficersOptions = [
      nullOption,
      ...loanOfficers.map(lo => ({
        label: lo.PreferredLoanOfficer,
        value: lo.PreferredLoanOfficerID,
      })
      )];

    const stateOptions = [
      nullOption,
      ...states.map(el => ({
        label: el.StateName,
        value: el.StateCode,
      })
      )];

    const organizationsOptions = [
      nullOption,
      ...organizations.map(el => ({
        label: el.OrganizationName,
        value: el.ID,
      })
      )];

    const isButtonDisabled = !data.AgentFirstName || !data.AgentLastName || !data.Email || !data.StateCode || isSubmitting

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>
          Agent Detail
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Wrapper>
                <Subtitle>Loan Officer</Subtitle>
                <RowItem>
                  <Label>Preferred Loan Officer</Label>
                  <Value>
                    <Dropdown
                      placeholder="- - -"
                      options={loanOfficersOptions}
                      onChange={(value) => this.handleChange('PreferredLoanOfficerID', value)}
                      value={data.PreferredLoanOfficerID}
                    />
                  </Value>
                </RowItem>
              </Wrapper>
              <Wrapper style={{flex: 1, position: 'relative', paddingBottom: '4rem'}}>
                <Subtitle>Organization Detail</Subtitle>
                <RowItem>
                  <Label>Organization</Label>
                  <Value>
                    <Dropdown
                      placeholder="- - -"
                      options={organizationsOptions}
                      onChange={(value) => this.handleChange('OrganizationID', value)}
                      value={data.OrganizationID}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Organization Name Other</Label>
                  <Value>
                    <Input
                      value={data.OrganizationNameOther || ''}
                      onChange={(e) => this.handleChange('OrganizationNameOther', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Address 1</Label>
                  <Value>
                    <Input
                      value={data.OrganizationNameOtherStreet1 || ''}
                      onChange={(e) => this.handleChange('OrganizationNameOtherStreet1', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Address 2</Label>
                  <Value>
                    <Input
                      value={data.OrganizationNameOtherStreet2 || ''}
                      onChange={(e) => this.handleChange('OrganizationNameOtherStreet2', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>City</Label>
                  <Value>
                    <Input
                      value={data.OrganizationNameOtherCity || ''}
                      onChange={(e) => this.handleChange('OrganizationNameOtherCity', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>State</Label>
                  <Value>
                    <Dropdown
                      placeholder="- - -"
                      options={stateOptions}
                      onChange={(value) => this.handleChange('OrganizationNameOtherStateCode', value)}
                      value={data.OrganizationNameOtherStateCode}
                    />
                  </Value>
                </RowItem>
                {isAdminOrUserPermission && (
                  <LinkButton onClick={this.handleOrganizationManager}>Organization Manager</LinkButton>
                )}
              </Wrapper>
            </Col>
            <Col>
              <Wrapper>
                <Subtitle>Agent Detail</Subtitle>
                <RowItem>
                  <Label>Invite ID</Label>
                  <Value>
                    <Input
                      value={data.ID || ''}
                      disabled
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Invite Date</Label>
                  <Value>
                    <Input
                      value={data.RegistrationDate ? formatDate(data.RegistrationDate) : ''}
                      disabled
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Agent First Name&#42;</Label>
                  <Value>
                    <Input
                      value={data.AgentFirstName || ''}
                      onChange={(e) => this.handleChange('AgentFirstName', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Agent Last Name&#42;</Label>
                  <Value>
                    <Input
                      value={data.AgentLastName || ''}
                      onChange={(e) => this.handleChange('AgentLastName', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Agent Email&#42;</Label>
                  <Value>
                    <Input
                      value={data.Email || ''}
                      onChange={(e) => this.handleChange('Email', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Mobile Phone Number</Label>
                  <Value>
                    <Input
                      value={data.MobilePhoneNumber || ''}
                      onChange={(e) => this.handleChange('MobilePhoneNumber', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Phone</Label>
                  <Value>
                    <Input
                      value={data.Phone || ''}
                      onChange={(e) => this.handleChange('Phone', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Address 1</Label>
                  <Value>
                    <Input
                      value={data.Address1 || ''}
                      onChange={(e) => this.handleChange('Address1', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Address 2</Label>
                  <Value>
                    <Input
                      value={data.Address2 || ''}
                      onChange={(e) => this.handleChange('Address2', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>City</Label>
                  <Value>
                    <Input
                      value={data.City || ''}
                      onChange={(e) => this.handleChange('City', e.target.value)}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>State&#42;</Label>
                  <Value>
                    <Dropdown
                      placeholder="- - -"
                      options={stateOptions}
                      onChange={(value) => this.handleChange('StateCode', value)}
                      value={data.StateCode}
                    />
                  </Value>
                </RowItem>
                <RowItem>
                  <Label>Postal Code</Label>
                  <Value>
                    <Input
                      value={data.PostalCode || ''}
                      onChange={(e) => this.handleChange('PostalCode', e.target.value)}
                    />
                  </Value>
                </RowItem>
              </Wrapper>
            </Col>
          </Row>
          <Footer>
            <StyledButton disabled={isButtonDisabled} onClick={this.handleSave}>SAVE</StyledButton>
          </Footer>
        </ModalBody>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    userLevel: state.user?.data?.PermissionsLevel,
  };
}

export default compose(
  connect(mapStateToProps),
  withModals({
    OrganizationManagerModal,
  })
)(AgentRegistrationDetailModal)

const Modal = styled(DigeryModal)`
  max-width: 1000px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    overflow: auto;
    min-height: 600px;
  }
`;

const Wrapper = styled.div`
  border: 1px solid black;
  padding: 2rem;
  margin: 0.5rem 0;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
`;

const Col = styled.div`
  flex: 1;
  margin: 0 0.5rem;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
`;

const RowItem = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  align-items: center;

  &.multiline-container {
    height: auto;
    align-items: flex-start;

    & > div:nth-child(1) {
      margin-top: 0.5rem;
    }
  }
`;

const Label = styled.div`
  width: 40%;
  text-align: left;
  padding-right: 20px;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  color: white;
  font-size: 1.4rem;
  height: 30px;
`;

const Subtitle = styled.div`
  font-weight: 500;
  margin-bottom: 1rem;
`;

const LinkButton = styled.button`
  text-decoration: underline;
  position: absolute;
  bottom: 1rem;
  left: 2rem;
  font-size: 1.5rem;
`;