import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Container from 'components/Container';
import Helmet from 'react-helmet';
import { compose } from 'redux';
import { getLoanOfficers } from 'modules/api';
import RadioBox from 'components/RadioBox';

import AgentSearch from './AgentSearch';
import TeamSearch from './TeamSearch';

import {
  RadioForm,
} from './styles';

class Search extends Component {
  state = {
    allLoanOfficers: [],
    isTeamSearch: false,
  };

  componentDidMount() {
    getLoanOfficers().then(res => {
      const allLoanOfficers = res
        .filter(r => r.Status === 'Active')
        .map(r => ({
          ...r,
          Name: `${r.FirstName} ${r.LastName}`,
          LOID: r.ID,
        }));
      this.setState({ allLoanOfficers });
    }).catch(() => {});
  }

  render() {
    const { allLoanOfficers, isTeamSearch } = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>Search</title>
          <meta
            name="description"
            content="This is the Keller Mortgage Admin Portal Agent-Search page"
          />
        </Helmet>
        <Body>
          <Header>
            <div className="search-label">Search</div>
            <div className="type-select-wrapper">
              <div className="by">By</div>
              <RadioForm>
                <RadioBox
                  value={!isTeamSearch}
                  onChange={() => this.setState({ isTeamSearch: false })}
                />
                <span>Agent</span>
              </RadioForm>
              <RadioForm>
                <RadioBox
                  value={isTeamSearch}
                  onChange={() => this.setState({ isTeamSearch: true })}
                />
                <span>Team</span>
              </RadioForm>
            </div>
          </Header>
          {isTeamSearch ? (
            <TeamSearch allLoanOfficers={allLoanOfficers} />
          ) : (
            <AgentSearch allLoanOfficers={allLoanOfficers} />
          )}
        </Body>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default compose(
  connect(mapStateToProps),
)(Search)

const Body = styled(Container)`
  max-width: 600px;
  font-size: 1.4rem;
  margin-bottom: 3rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  justify-content: space-between;

  .search-label {
    font-size: 1.7rem;
    font-weight: bold;
  }

  .type-select-wrapper {
    display: flex;
  }
`;

