import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { orderBy }from 'lodash';
import styled from 'styled-components';
import Container from 'components/Container';
import RadioBox from 'components/RadioBox';
import Button from 'components/Button';
import Select from 'components/Select';
import Helmet from 'react-helmet';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import {
  getReportAgentPrimaryBackup,
  getReportAgentsCreatedBackupLOs,
  getLeadsPreappsClosingContractsClosingByRegion,
  getRptRegionalActivitySummary2020,
  getSurveyMonkeySurveysNames,
} from 'modules/api';
import { downloadCSV, encodeQueryData, downloadCSVRaw } from 'modules/helpers';
import { media } from 'modules/theme';

const ReportNames = {
  AGENT_CREATED: 'Agents Created',
  AGENT_PRIMARY: 'Agent Primary / Backup LO',

  LEADS_DETAIL: 'Leads Detail',
  CLOSING_DETAIL: 'Closings Detail',
  BUYER_SELLER_REFERRING_AGENTS: 'Buyer Seller Referring Agents',
  COUNT_AGENTS_LEADS: 'Count of Agents with Leads - Closings by Region',
  REGIONAL_ACTIVITY_SUMMARY: 'Regional Activity Summary - 2020',

  KW_ORG_ROLES: 'KW Org Roles',
  LOAN_OFFICERS: 'Loan Officers',

};
const ReportData = [
  { title: ReportNames.AGENT_CREATED, type: 'api', isDateRange: false },
  {
    title: 'Agents Market Center / Region',
    type: 'ssrs',
    isDateRange: false,
    ssrsReportName: '/KM+Reports/Agents+Created+Market+Center+Region',
    params: { 'rs:Format': 'csv' },
  },
  { title: ReportNames.AGENT_PRIMARY, type: 'api', isDateRange: false },
  {
    title: 'Agent Production',
    type: 'ssrs',
    isDateRange: true,
    ssrsReportName: '/KM+Reports/KWAgentProduction',
    params: { 'rs:Format': 'csv' },
  },
  {
    title: 'Agents Team',
    type: 'ssrs',
    isDateRange: false,
    ssrsReportName: '/KM+Reports/Agents+Created+by+Team',
    params: { 'rs:Format': 'csv' },
  },
  {
    title: ReportNames.LEADS_DETAIL,
    type: 'ssrs',
    isDateRange: true,
    ssrsReportName: '/KM+Reports/Leads+Detail',
    params: { 'rs:Format': 'csv' },
  },
  {
    title: ReportNames.CLOSING_DETAIL,
    type: 'ssrs',
    isDateRange: true,
    ssrsReportName: '/KM+Reports/Closings+Detail',
    params: { 'rs:Format': 'csv' },
  },
  {
    title: ReportNames.BUYER_SELLER_REFERRING_AGENTS,
    type: 'ssrs',
    isDateRange: true,
    ssrsReportName: '/KM+Reports/Buyer-Seller-Referring-Agents',
    params: { 'rs:Format': 'csv' },
  },
  { title: ReportNames.COUNT_AGENTS_LEADS, type: 'api', isDateRange: false },
  { title: ReportNames.REGIONAL_ACTIVITY_SUMMARY, type: 'api', isDateRange: false },
  {
    title: 'Survey Responses (ACS)',
    type: 'ssrs',
    isDateRange: true,
    ssrsReportName: '/KM+Reports/Survey+Monkey+ACS+Response',
    params: { 'rs:Format': 'csv' },
  },
  {
    title: 'Survey Responses',
    type: 'ssrs',
    isDateRange: true,
    isSurveyResponse: true,
    ssrsReportName: '/KM+Reports/Survey+Monkey+Responses',
    params: { 'rs:Format': 'csv' },
  },
  {
    title: ReportNames.LOAN_OFFICERS,
    type: 'ssrs',
    isDateRange: false,
    ssrsReportName: '/KM+Reports/Loan+Officers',
    params: { 'rs:Format': 'csv' },
  },
  {
    title: 'KW Recruiters',
    type: 'ssrs',
    isDateRange: false,
    ssrsReportName: '/KM+Reports/Recruiters',
    params: { 'rs:Format': 'csv' },
  },
  {
    title: 'KW Regions',
    type: 'ssrs',
    isDateRange: false,
    ssrsReportName: '/KM+Reports/KW+Regions',
    params: { 'rs:Format': 'csv' },
  },
  {
    title: 'KW Market Centers',
    type: 'ssrs',
    isDateRange: false,
    ssrsReportName: '/KM+Reports/KW+Market+Centers',
    params: { 'rs:Format': 'csv' },
  },
  {
    title: 'KW Teams',
    type: 'ssrs',
    isDateRange: false,
    ssrsReportName: '/KM+Reports/KW+Teams',
    params: { 'rs:Format': 'csv' },
  },
  {
    title: ReportNames.KW_ORG_ROLES,
    type: 'ssrs',
    isDateRange: false,
    ssrsReportName: '/KM+Reports/KW+Org+Roles',
    params: { 'rs:Format': 'csv' },
  },
];
class Reports extends Component {
  state = {
    isDownloading: false,
    activeReport: ReportNames.AGENT_PRIMARY,
    startDate: moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    survey: null,
    surveys: [],
  };

  componentDidMount() {
    this.fetchSurveyData();
  }

  fetchSurveyData = () => {
    getSurveyMonkeySurveysNames()
      .then(res => {
        const surveys = orderBy(res.result, ['CreatedDate'], 'asc')
        this.setState({
          surveys: surveys.map(survey =>({
            label: survey.SurveyName,
            value: survey.SurveyID,
          })),
        });
      })
  }

  fetchReportPrimaryAgentBackupLOs = () => {
    this.setState({ isDownloading: true });
    getReportAgentPrimaryBackup()
      .then(res => {
        this.setState({
          isDownloading: false,
        });
        downloadCSV(`AgentPrimaryBackupLO_${moment().format('YYYY-MM-DD:')}.csv`, res);
      })
      .catch(error => {
        this.setState({ isDownloading: false });
        console.log(error);
      });
  };

    fetchReportPrimaryAgentBackupLOs = () => {
    this.setState({ isDownloading: true });
    getReportAgentPrimaryBackup()
      .then(res => {
        this.setState({
          isDownloading: false,
        });
        downloadCSV(`AgentPrimaryBackupLO_${moment().format('YYYY-MM-DD:')}.csv`, res);
      })
      .catch(error => {
        this.setState({ isDownloading: false });
        console.log(error);
      });
  };

  fetchReportCreatedAgents = () => {
    // const test = [{"KWUID":107539,"Firstname":"James","Lastname":"Bishop","Email":"#2bishoju@realtracs.com","Phone":null,"City":null,"State":null,"PortalAgentDisclaimerFlag":null,"KWStatus":"Active","AgentDBCreateDate":"2020-04-17T00:00:00.000Z","FirstLoginDate":null,"LastLoginDate":"2020-05-10T00:06:40.430Z","AgentAddedSource":"Lead Submission","LeadSubmissionDate":"2020-10-07T12:00:03.907Z"}, {"KWUID":107539,"Firstname":"James","Lastname":"Bishop","Email":"2bishoju@realtracs.com","Phone":null,"City":null,"State":null,"PortalAgentDisclaimerFlag":null,"KWStatus":"Active","AgentDBCreateDate":"2020-04-17T00:00:00.000Z","FirstLoginDate":null,"LastLoginDate":"2020-05-10T00:06:40.430Z","AgentAddedSource":"Lead Submission","LeadSubmissionDate":"2020-10-07T12:00:03.907Z"}]
    // downloadCSV(`AgentsCreated_${moment().format()}.csv`, test)
    // return

    this.setState({ isDownloading: true });
    getReportAgentsCreatedBackupLOs()
      .then(res => {
        this.setState({ isDownloading: false });
        downloadCSV(`AgentsCreated_${moment().format()}.csv`, res);
      })
      .catch(error => {
        this.setState({ isDownloading: false });
        console.log(error);
      });
  };

  fetchLPCCCBR = () => {
    this.setState({ isDownloading: true });
    getLeadsPreappsClosingContractsClosingByRegion()
      .then(res => {
        this.setState({ isDownloading: false });
        downloadCSV(`CountOfAgentsWithLeads_ClosingsByRegion_${moment().format()}.csv`, res);
      })
      .catch(error => {
        console.log(error);
        this.setState({ isDownloading: false });
      });
  };

  fetchActivitySummary2020 = () => {
    this.setState({ isDownloading: true });
    getRptRegionalActivitySummary2020()
      .then(res => {
        this.setState({ isDownloading: false });
        downloadCSV(`ActivitySummary2020_${moment().format()}.csv`, res);
      })
      .catch(error => {
        this.setState({ isDownloading: false });
        console.log(error);
      });
  };

  fetchSSRSData = report => {
    const { startDate, endDate, survey } = this.state;
    const { ssrsReportName, isDateRange, title, isSurveyResponse } = report;
    const params = report.params || {};
    if (isDateRange) {
      params.StartDate = moment(startDate).format('M/D/YYYY');
      params.EndDate = moment(endDate).format('M/D/YYYY');
    }
    if (isSurveyResponse) {
      params.SurveyID = survey.value;
    }
    const searchParams = encodeQueryData(params);
    const url = `https://reports.kellermortgage.com:443/ReportServer/Pages/ReportViewer.aspx?${ssrsReportName}&${searchParams}`;

    const config = {
      method: 'get',
      url,
      headers: {
        Authorization: 'Basic c3NyczpTM2xzV2ttNXhUR0A5ZUBhRWhjdw==',
      },
      timeout: 50000,
    };

    this.setState({ isDownloading: true });
    axios(config)
      .then(response => {
        downloadCSVRaw(`${title}_${moment().format('YYYY-MM-DD:')}.csv`, response.data);
        this.setState({ isDownloading: false });
      })
      .catch(error => {
        this.setState({ isDownloading: false });
      });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { activeReport } = this.state;
    const report = ReportData.find(r => r.title === activeReport);

    if (report.type === 'ssrs') {
      this.fetchSSRSData(report);
    } else {
      switch (activeReport) {
        case ReportNames.AGENT_PRIMARY:
          this.fetchReportPrimaryAgentBackupLOs();
          break;
        case ReportNames.AGENT_CREATED:
          this.fetchReportCreatedAgents();
          break;
        case ReportNames.COUNT_AGENTS_LEADS:
          this.fetchLPCCCBR();
          break;
        case ReportNames.REGIONAL_ACTIVITY_SUMMARY:
          this.fetchActivitySummary2020();
          break;
      }
    }
  };

  renderItem = (report) => {
    const { activeReport, startDate, endDate, surveys, survey } = this.state;
    return (
      <div key={report.title}>
        <Row>
          <RadioBox
            value={activeReport === report.title}
            onChange={() => this.handleChange('activeReport', report.title)}
          />
          <RadioLabel>{report.title}</RadioLabel>
        </Row>
        {activeReport === report.title && (
          <DateRange>
            {report.isSurveyResponse && (
              <Row>
                <DateRangeLabel>Name</DateRangeLabel>
                <Select 
                  placeholder="Select"
                  options={surveys} 
                  onChange={(s) => this.handleChange('survey', s)} 
                  value={survey}
                  css={`
                    font-size: 1.4rem;
                    & > div:first-of-type {
                      border-color: black;
                      border-radius: 2px;
                    }
                  `}
                />
              </Row>
            )}
            {report.isDateRange && (
              <>
                <Row>
                  <DateRangeLabel>Start Date</DateRangeLabel>
                  <DateInput
                    placeholder="Start Date"
                    type="date"
                    value={startDate}
                    onChange={e => this.handleChange('startDate', e.target.value || null)}
                  />
                </Row>
                <Row>
                  <DateRangeLabel>End Date</DateRangeLabel>
                  <DateInput
                    placeholder="End Date"
                    type="date"
                    value={endDate}
                    onChange={e => this.handleChange('endDate', e.target.value || null)}
                  />
                </Row>
              </>
            )}
          </DateRange>
        )}
      </div>
    )
  }

  render() {
    const { isDownloading, activeReport, startDate, endDate, surveys, survey } = this.state;
    const { user } = this.props;
    if (user.data.PermissionsLevel === 3) {
      return <Redirect to="/private/home" />;
    }
    const activeReportData = ReportData.find(r => r.title === activeReport);

    const isButtonDisabled =
      isDownloading || (activeReportData.isDateRange && (!startDate || !endDate)) || (activeReportData.isSurveyResponse && !survey);

    return (
      <Fragment>
        <Helmet>
          <title>Reports</title>
          <meta
            name="description"
            content="This is the Keller Mortgage Agent Portal Reports page"
          />
        </Helmet>
        <Container>
          <Wrapper>
            <Column>
              {ReportData.slice(0, 5).map(report => (
                this.renderItem(report)
              ))}
            </Column>
            <Column>
              {ReportData.slice(5, 12).map(report => (
                this.renderItem(report)
              ))}
            </Column>
            <Column>
              {ReportData.slice(12, 18).map(report => (
                this.renderItem(report)
              ))}
            </Column>
          </Wrapper>
          <SubmitButton disabled={isButtonDisabled} onClick={this.handleSubmit}>
            {isDownloading ? <Loading /> : 'Submit'}
          </SubmitButton>
        </Container>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(Reports);

const Loading = styled.div`
  border: 5px solid white;
  border-radius: 50%;
  border-top: 5px solid ${props => props.theme.palette.primary};
  width: 20px;
  height: 20px;
  margin-right: 1rem;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Row = styled.div`
  display: flex;
  margin: 1rem;

  & > div {
    margin-top: 2px;
  }
`;

const RadioLabel = styled.span`
  margin-left: 1.5rem;
  margin-right: 2rem;
  font-size: 1.6rem;
`;

const DateRange = styled.div`
  margin-left: 2rem;
  max-width: 400px;
`;

const DateRangeLabel = styled.span`
  margin-left: 1.5rem;
  min-width: 8.1rem;
  margin-right: 1rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
`;

const DateInput = styled.input`
  width: 100%;
  font-size: 1.4rem;
`;

const SubmitButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${media.mobile`
    flex-direction: column;
  `};
`

const Column = styled.div`
  flex: 1;
`