import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames'
import {Field, ErrorMessage} from 'formik'

const InputField = ({ name, label, showError = false, className, ...props }) => (
  <Root className={classnames('field-row', 'input-field', className)}>
    {label && <div className="field-label">{label}</div>}
    <div className="value-container">
      <Field
        name={name}
        {...props}
      />
      {showError && (
        <ErrorMessage
          component="div"
          style={{color: 'red', marginBottom: 0}}
          name={name}
        />
      )}
    </div>
  </Root>
);

export default InputField;

const Root = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.darkText};

  .field-label {
    font-size: 1.4rem;
    text-align: left;
    width: 150px;
  }

  .value-container {
    flex: 1;
  }

  input {
    border: ${props => props.theme.palette.border} 1px solid;
    border-radius: 3px;
    font-size: 1.4rem;
    min-height: 4.6rem;
    width: 100%;
    color: ${props => props.theme.palette.darkText};
    padding: 0 10px;

    &:focus {
      outline-width: 1px;
    }
  }
`;

