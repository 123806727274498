import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import HeaderTitle from 'components/HeaderTitle';
import {
  Segment,
  SegmentHeader,
  SegmentHeaderTitle,
  SegmentContent,
  SegmentFooter,
  SegmentLinkButton,
} from 'components/Segment';
import ButtonLink from 'components/ButtonLink';

const KeyNotFound = require('assets/media/images/key-not-found.png');

const StyledContainer = styled(Segment)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.palette.primary};
  padding: 3.5rem;

  img {
    width: 9.6rem;
  }

  div {
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 3.6rem;
  }

  span {
    margin-bottom: 3rem;
    font-size: 1.6rem;
  }
`;

const NotFound = () => (
  <Fragment key="404">
    <Helmet>
      <title>Not Found</title>
    </Helmet>
    <HeaderTitle primary>404 this page has not been found</HeaderTitle>
    <StyledContainer>
      <img src={KeyNotFound} />
      <div>Well, this is embarrasing!</div>
      <span>
        It could be because it doent exist, <br />
        or maybe it lost it’s keys and left to search for them.{' '}
      </span>
      <span>Either way, we’re sure you can find what you need on our Home page.</span>
      <ButtonLink to="/">Home</ButtonLink>
    </StyledContainer>
  </Fragment>
);

export default NotFound;
