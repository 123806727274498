import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { media } from 'modules/theme';
import { NavLink , Redirect } from 'react-router-dom';
import { fetchUnreadMessageCount } from 'actions/index';
import Tooltip from 'components/Tooltip';
import Assignments from './Assignments';
import MyRecruiting from './MyRecruiting';
import Dashboard from './Dashboard';
import Pool from './Pool';
import MyInbox from './MyInbox';
import MarketCenters from './MarketCenters';

const Menus = {
  MY_RECRUITING: 'my_recruiting',
  ASSIGNMENTS: 'assignments',
  POOL: 'pool',
  DASHBOARD: 'dashboard',
  MARKET_CENTERS: 'market_centers',
  MyInbox: 'communication',
};

class Activity extends Component {
  state = {
    user: null,
    fetchUnreadMessageIntervalId: null,
  };

  componentDidMount() {
    const { LOID, RecruiterID, FirstName, LastName } = this.props.location.query;
    if (RecruiterID) {
      this.setState({
        user: {
          PK: RecruiterID,
          FirstName,
          LastName,
          PermissionsLevel: 4,
        },
      });
      this._fetchUnreadMessageCount(RecruiterID);
    } else if (LOID) {
      this.setState({
        user: {
          LOID,
          FirstName,
          LastName,
          PermissionsLevel: 3,
        },
      });
    } else {
      const { user } = this.props;
      this.setState({ user });
      if (user.PermissionsLevel === 3 || user.PermissionsLevel === 4) {
        this._fetchUnreadMessageCount(user.PK);
      }
    }
  }

  _fetchUnreadMessageCount = userPK => {
    this.props.dispatch(fetchUnreadMessageCount(userPK));

    const fetchUnreadMessageIntervalId = setInterval(() => {
      if (this.props.match.params.subMenu !== Menus.MyInbox) {
        this.props.dispatch(fetchUnreadMessageCount(userPK));
      }
    }, 600000); // refresh every 10mins
    this.setState({ fetchUnreadMessageIntervalId });
  };

  componentWillUnmount() {
    clearInterval(this.state.fetchUnreadMessageIntervalId);
  }

  render() {
    const { user } = this.state;
    if (!user) {
      return null;
    }

    const {subMenu} = this.props.match.params;

    if (!subMenu) {
      return <Redirect to={`/private/recruiting/${Menus.DASHBOARD}`} />;
    }

    if (user.PermissionsLevel === 3 && subMenu === Menus.ASSIGNMENTS) {
      return <Redirect to={`/private/recruiting/${Menus.DASHBOARD}`} />;
    }

    if (user.PermissionsLevel === 3 && subMenu === Menus.MY_RECRUITING) {
      return <Redirect to={`/private/recruiting/${Menus.DASHBOARD}`} />;
    }

    if (user.PermissionsLevel === 4 && subMenu === Menus.POOL) {
      return <Redirect to={`/private/recruiting/${Menus.DASHBOARD}`} />;
    }

    if (user.PermissionsLevel > 2 && subMenu === Menus.MARKET_CENTERS) {
      return <Redirect to={`/private/recruiting/${Menus.DASHBOARD}`} />;
    }

    if (user.PermissionsLevel < 3 && subMenu === Menus.MyInbox) {
      return <Redirect to={`/private/recruiting/${Menus.DASHBOARD}`} />;
    }

    return (
      <Fragment>
        <Helmet>
          <title>Recruiting</title>
          <meta
            name="description"
            content="This is the Keller Mortgage Admin Portal Recruiting page"
          />
        </Helmet>
        <Body>
          <NavBar>
            {true && (
              <MenuLink
                to={`/private/recruiting/${Menus.DASHBOARD}`}
                exact
                activeClassName="active"
              >
                Dashboard
              </MenuLink>
            )}
            {(user.PermissionsLevel === 3 || user.PermissionsLevel === 4) && (
              <MenuLink to={`/private/recruiting/${Menus.MyInbox}`} exact activeClassName="active">
                My Inbox
                {!!this.props.unreadMessageCount && (
                  <>
                    <Badge id="tooltip-unread-count">{this.props.unreadMessageCount}</Badge>
                    <Tooltip placement="right" target="tooltip-unread-count" delay={0}>
                      {`${this.props.unreadMessageCount} Unread Messages`}
                    </Tooltip>
                  </>
                )}
              </MenuLink>
            )}
            {(user.PermissionsLevel === 1 || user.PermissionsLevel === 2) && (
              <MenuLink
                to={`/private/recruiting/${Menus.MARKET_CENTERS}`}
                exact
                activeClassName="active"
              >
                Market Centers
              </MenuLink>
            )}
            {user.PermissionsLevel !== 3 && (
              <MenuLink
                to={`/private/recruiting/${Menus.MY_RECRUITING}`}
                exact
                activeClassName="active"
              >
                My Recruiting
              </MenuLink>
            )}
            {user.PermissionsLevel !== 4 && (
              <MenuLink to={`/private/recruiting/${Menus.POOL}`} exact activeClassName="active">
                Pool
              </MenuLink>
            )}
            {user.PermissionsLevel !== 3 && (
              <MenuLink
                to={`/private/recruiting/${Menus.ASSIGNMENTS}`}
                exact
                activeClassName="active"
              >
                Assignments
              </MenuLink>
            )}
          </NavBar>
          <Block>
            {subMenu === Menus.DASHBOARD && <Dashboard user={user} />}
            {subMenu === Menus.ASSIGNMENTS && <Assignments user={user} />}
            {subMenu === Menus.MY_RECRUITING && (
              <MyRecruiting user={user} dispatch={this.props.dispatch} />
            )}
            {subMenu === Menus.POOL && <Pool user={user} dispatch={this.props.dispatch} />}
            {subMenu === Menus.MARKET_CENTERS && <MarketCenters user={user} dispatch={this.props.dispatch} />}
            {subMenu === Menus.MyInbox && <MyInbox user={user} dispatch={this.props.dispatch} />}
          </Block>
        </Body>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    unreadMessageCount: state.app.unreadMessageCount,
  };
}

export default connect(mapStateToProps)(Activity);

const Body = styled.div`
  display: flex;
  padding: 20px;
  color: black;
  height: calc(100vh - 140px);
  flex-wrap: wrap;

  ${media.mobile`
    padding-left: 10px;
    padding-right: 10px;
  `};

  ${media.handheld`
    padding-left: 10px;
    padding-right: 10px;
  `};
`;

const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  min-width: 20rem;

  ${media.mobile`
    width: 100%;
  `};
`;

const MenuLink = styled(NavLink)`
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  background: white;
  text-align: center;
  color: ${props => props.theme.palette.gray};
  position: relative;

  &.active {
    color: ${props => props.theme.palette.primary};
  }
`;

const Block = styled.div`
  padding: 2rem;
  margin: 2rem;
  background: white;
  flex: 1;
  box-shadow: -3px 8px 8px rgba(0, 0, 0, 0.2);
  overflow: auto;

  ${media.mobile`
    margin: 0;
  `};
`;

const Badge = styled.div`
  height: 20px;
  color: white;
  font-size: 11px;
  padding: 0px 8px;
  background-color: ${props => props.theme.palette.primary};
  display: flex;
  align-items: center;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(40px, -50%);
`;
