import React, { Component } from 'react';
import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import { 
  addUpdateEmailTemplates,
} from 'modules/api';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import { Flex } from 'styled-minimal';
import Input from 'components/Input';
import { showAlert } from 'actions';

class AddUpdateTemplateModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    templateContent: '',
    templateName: '',
    templateSubject: '',
  };

  componentDidMount() {
    if (this.props.isOpen) {
      const { TemplateName, TemplateContent, TemplateSubject } = this.props.template
      this.setState({
        templateName: TemplateName,
        templateContent: TemplateContent,
        templateSubject: TemplateSubject,
      })
    }
  }


  handleSend = async () => {
    const { template, dispatch, user } = this.props
    const { templateContent, templateName, agent, templateSubject } = this.state

    try {
      await addUpdateEmailTemplates(template.PK, user.PK, templateName, templateContent, templateSubject)
      dispatch(showAlert('Saved successfully.', { variant: 'success', icon: 'bell' }));
      this.handleClose()
      this.props.onSave()
    } catch (error) {
      dispatch(showAlert('Failed', { variant: 'danger', icon: 'bell' }));
    }
  }
  
  handleClose = () => {
    this.props.onClose();
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  }


  render() {
    const { isOpen, template } = this.props;
    const { templateContent, templateName, templateSubject } = this.state;

    const isSendButtonDisabled = !templateContent || !templateName || !templateSubject

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>
          {template.PK ? 'Edit Template' : 'Add Template'}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Label>Name</Label>
            <Input
              value={templateName}
              onChange={(e) => this.handleChange('templateName', e.target.value)}
            />
          </Row>
          <Row>
            <Label>Subject</Label>
            <Input
              value={templateSubject}
              onChange={(e) => this.handleChange('templateSubject', e.target.value)}
            />
          </Row>
          <LabelMessage>Message Body</LabelMessage>
          <TextArea 
            rows={8}
            onChange={(e) => this.handleChange('templateContent', e.target.value)} 
            value={templateContent}
          />
          <Flex justifyContent="space-between">
            <StyledButton disabled={isSendButtonDisabled} onClick={this.handleSend}>Save</StyledButton>
          </Flex>
        </ModalBody>
      </Modal>
    );
  }
}

export default AddUpdateTemplateModal;

const Modal = styled(DigeryModal)`
  max-width: 500px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    overflow: auto;
    min-height: 347px;
  }
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  color: white;
  min-width: unset;
  font-size: 1.4rem;
  height: 30px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-size: 1.4rem;
  margin: 1rem 1rem 1rem 0;
  min-width: 60px;
`;


const LabelMessage = styled.div`
  font-size: 1.4rem;
  margin: 1rem 0.5rem 0.3rem 0;
`;

const AgentSearch = styled(AsyncSelect)`
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  box-shadow: none;
  border-radius: 3px;
  max-width: 600px;
  margin: auto;
  margin-bottom: 1rem;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '7px 8px',
  }),
  menuList: () => ({
    maxHeight: '600px',
    overflow: 'auto',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
};
