import React, { Component } from 'react';
import styled from 'styled-components';
import AsyncSelect from 'react-select/async';
import { get } from 'lodash'
import Select from 'react-select';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import debounce from 'lodash/debounce';
import { 
  sendSMS, 
  sendEmail,
  addRecruitingCommunication,
  getRecruitingAgentInfoSearch,
  getEmailTemplates,
} from 'modules/api';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import { Flex } from 'styled-minimal';
import Input from 'components/Input';
import { showAlert } from 'actions';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'constants/index';
import withModals from 'modules/withModals';
import AttachFilesModal from './AttatchFilesModal';
import TemplateModal from './TemplateModal';
class SendMessageModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    messageBody: '',
    emailSubject: '',
    ccEmails: '',
    bccEmails: '',
    agent: null,
    templates: [],
    selectedTemplate: null,
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.fetchAgentWithKWUID();
      this.fetchTemplates();
    }
  }

  fetchTemplates = () => {
    getEmailTemplates(this.props.user.PK)
    .then(res => {
      this.setState({
        templates: res.map(t => ({
          label: t.TemplateName,
          value: t.PK,
          data: t,
        })),
      });
    })
  };

  fetchAgentWithKWUID = async () => {
    const {kwuid, user, isEmail} = this.props;
    if (!kwuid) {
      return
    }

    getRecruitingAgentInfoSearch(
      kwuid,
      null,
      user.PK,
    )
      .then(res => {
        const searchResult = res.map(a => ({
          ...a,
          label: `${a.AgentFirstName} ${a.AgentLastName} (${isEmail ? a.AgentEmail : a.AgentMobilePhone})`,
        }));
        this.setState({ agent: searchResult[0] })
      })
  }

  handleSend = async () => {
    const { isEmail, dispatch } = this.props
    const { messageBody, emailSubject, agent, ccEmails, bccEmails } = this.state

    if (!agent) {
      return
    }

    try {
      if (isEmail) {
        const params = {
          email: SUPPORT_EMAIL,
          subject: emailSubject,
          message: messageBody,
          name: 'Keller Mortgage',
          service_email: agent.AgentEmail
        }
        if (ccEmails) {
          params.ccEmails = ccEmails.split(',')
        }
        if (bccEmails) {
          params.bccEmails = bccEmails.split(',')
        }
        await sendEmail(params)
        dispatch(showAlert('Sent Email successfully.', { variant: 'success', icon: 'bell' }));
        await this.addRecruiter();
        this.handleClose()
        this.props.onSend()
      } else {
        const response = await sendSMS({
          phone: agent.AgentMobilePhone,
          message: messageBody,
          statusCallback: `${process.env.REACT_APP_API_BASE_URL}/public/sms-status`,
        })
        dispatch(showAlert('Sent SMS successfully.', { variant: 'success', icon: 'bell' }));
        const {sid} = response.data
        await this.addRecruiter(sid);
        this.handleClose()
        this.props.onSend()
      }
    } catch (error) {
      dispatch(showAlert('Failed', { variant: 'danger', icon: 'bell' }));
    }
  }

  addRecruiter = async (TwilioSID) => {
    const { isEmail } = this.props
    const { messageBody, agent } = this.state

    const payload = {
      PKRecruitingCommunicationType: isEmail ? 3 : 1,
      Message: messageBody,
      SenderID: isEmail ? SUPPORT_EMAIL : SUPPORT_PHONE,
      RecipientID: isEmail ? agent.AgentEmail : agent.AgentMobilePhone,
      PKRecruitingRecruiterAssignment: agent.PKRecruitingRecruiterAssignment,
      TwilioSID: isEmail ? null : TwilioSID
    };
    try {
      await addRecruitingCommunication(payload);
    } catch (error) {
      console.log(error);
    }
  }
  
  handleClose = () => {
    this.props.onClose();
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
    if (key === 'selectedTemplate') {
      const messageBody = get(value, 'data.TemplateContent', '')
      const emailSubject = get(value, 'data.TemplateSubject', '')
      this.setState({ messageBody, emailSubject })
    }
  }

  loadOptions = debounce((inputValue, callback) => {
    if (inputValue.length < 3) {
      callback([]);
      return;
    }

    const {user, isEmail} = this.props;
  
    getRecruitingAgentInfoSearch(
      inputValue,
      null,
      user.PK,
    )
      .then(res => {
        const searchResult = res.map(a => ({
          ...a,
          label: `${a.AgentFirstName} ${a.AgentLastName} (${isEmail ? a.AgentEmail : a.AgentMobilePhone})`,
        }));
        callback(searchResult);
      })
      .catch(error => {
        callback([]);
        console.log(error);
      });
  }, 500);

  onSelectAgent = (agent) => {
    this.setState({ agent })
  }

  handleOpenAttachFilesModal = () => {
    this.props.openModal('AttachFilesModal', {
      dispatch: this.props.dispatch,
      onAttach: (filePaths) => {
        const {messageBody} = this.state
        const newMessagBody = messageBody + '\n' + filePaths.join('\n')
        this.setState({ messageBody: newMessagBody })
      }
    });
  };

  handleTemplatesModal = () => {
    this.props.openModal('TemplateModal', {
      dispatch: this.props.dispatch,
      user: this.props.user,
      onUpdate: () => this.fetchTemplates(),
    });
  };


  render() {
    const { isOpen, isEmail, kwuid } = this.props;
    const { messageBody, emailSubject, templates, selectedTemplate, ccEmails, bccEmails } = this.state;
    const agent = this.state.agent || {}
    const isSendButtonDisabled = !messageBody || (isEmail && (!emailSubject || !agent.AgentEmail)) || (!isEmail && !agent.AgentMobilePhone)

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>
          {isEmail ? 'Compose Email' : 'Compose SMS'}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Label>To</Label>
            <AgentSearch
              disabled={kwuid}
              styles={CustomDropdownStyles}
              isSearchable={!kwuid}
              isClearable={!kwuid}
              placeholder={`Search by Name, KWUID, or ${isEmail ? 'Email' : 'Mobile Phone Number'}`}
              loadOptions={this.loadOptions}
              onChange={this.onSelectAgent}
              value={this.state.agent}
              noOptionsMessage={() => kwuid ? null : 'No agents'}
            />
          </Row>
          {isEmail && (
            <>
              <Row>
                <Label>Subject</Label>
                <Input
                  value={emailSubject}
                  onChange={(e) => this.handleChange('emailSubject', e.target.value)}
                />
              </Row>
              <Row>
                <Label>CC</Label>
                <Input
                  value={ccEmails}
                  placeholder="Separate multiple addresses with semi-colon"
                  onChange={(e) => this.handleChange('ccEmails', e.target.value)}
                />
              </Row>
              <Row>
                <Label>BCC</Label>
                <Input
                  value={bccEmails}
                  placeholder="Separate multiple addresses with semi-colon"
                  onChange={(e) => this.handleChange('bccEmails', e.target.value)}
                />
              </Row>
            </>
          )}
          <Row>
            <Label>Template</Label>
            <TemplateSelect 
              styles={CustomDropdownStyles}
              placeholder="Select Template"
              isSearchable
              isClearable
              options={templates} 
              onChange={(as) => this.handleChange('selectedTemplate', as)} 
              value={selectedTemplate}
            />
          </Row>
          <LabelMessage>{isEmail ? 'Email Message' : 'Text Message'}</LabelMessage>
          <TextArea 
            rows={8}
            onChange={(e) => this.handleChange('messageBody', e.target.value)} 
            value={messageBody}
          />
          <Flex justifyContent="space-between">
            <Flex>
              <StyledButton onClick={this.handleOpenAttachFilesModal}>Attach Link</StyledButton>
              <StyledButton style={{ marginLeft: '10px' }} onClick={this.handleTemplatesModal}>Templates</StyledButton>
            </Flex>
            <StyledButton disabled={isSendButtonDisabled} onClick={this.handleSend}>Send</StyledButton>
          </Flex>
        </ModalBody>
      </Modal>
    );
  }
}

export default withModals({
  AttachFilesModal,
  TemplateModal,
})(SendMessageModal);

const Modal = styled(DigeryModal)`
  max-width: 500px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    overflow: auto;
    min-height: 347px;
  }
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  color: white;
  min-width: unset;
  font-size: 1.4rem;
  height: 30px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

const Label = styled.div`
  font-size: 1.4rem;
  margin: 1rem 1rem 1rem 0;
  min-width: 65px;
`;


const LabelMessage = styled.div`
  font-size: 1.4rem;
  margin: 1rem 0.5rem 0.3rem 0;
`;

const AgentSearch = styled(AsyncSelect)`
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  box-shadow: none;
  border-radius: 3px;
  max-width: 600px;
  margin: auto;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;

const CustomDropdownStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: () => ({
    padding: '7px 8px',
  }),
  menuList: () => ({
    maxHeight: '600px',
    overflow: 'auto',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
};

const TemplateSelect = styled(Select)`
  text-align: left;
  width: 100%;
  font-size: 1.4rem;
  box-shadow: none;
  border-radius: 3px;
  max-width: 600px;
  margin: auto;

  & > div:first-of-type {
    box-shadow: none;
    border: #c4cdd5 1px solid;
  }

  & > div:hover {
    border: #c4cdd5 1px solid;
    box-shadow: none;
  }
`;
