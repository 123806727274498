import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CheckIcon = require('assets/media/images/checked.png');
const UncheckIcon = require('assets/media/images/unchecked.png');

const Button = styled.div`
  background: white;
  width: ${props => (props.size ? props.size : 2)}rem;
  height: ${props => (props.size ? props.size : 2)}rem;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.palette.gray};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: none;
`;

const Fill = styled.div`
  width: ${props => (props.size ? props.size * 0.8 : 1.4)}rem;
  height: ${props => (props.size ? props.size * 0.8 : 1.4)}rem;
  background-color: ${props => props.theme.palette.gray};
  border-radius: 50%;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`;

class CheckBox extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    size: PropTypes.number,
    value: PropTypes.bool,
  };

  onChange = () => {
    const { value, onChange, disabled } = this.props;
    if (!disabled && onChange) {
      onChange(!value);
    }
  };

  render() {
    const { value, size, disabled } = this.props;
    return (
      <Button onClick={this.onChange} size={size} disabled={disabled}>
        {!!value && <Fill disabled={disabled} />}
      </Button>
    );
  }
}

export default CheckBox;
