import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import { 
  getRecruitingHistory,
  getRecruitingNotes, 
} from 'modules/api';
import Button from 'components/Button';
import Table, { ProgressRow } from 'components/Table';

export default class ActivityModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    isNoteFetching: true,
    notes: [],
    histories: [],
    isHistoryFetching: true,
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.initFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.initFetch();
    }
  }

  initFetch = () => {
    this.fetchNotes();
    this.fetchHistory();
  }

  fetchHistory = () => {
    const { assignmentPK } = this.props;
    this.setState({ 
      histories: [],
      isHistoryFetching: true
    });

    getRecruitingHistory(assignmentPK)
      .then(res => {
        this.setState({ 
          histories: res.result,
          isHistoryFetching: false
        })
      })
      .catch(error => {
        console.log(error);
      });
  };

  fetchNotes = () => {
    const { assignmentPK } = this.props;
    this.setState({ 
      notes: [],
      newNote: '',
      isNoteFetching: true
    });
    
    getRecruitingNotes(assignmentPK)
      .then(res => {
        this.setState({ 
          notes: res.result,
          isNoteFetching: false
        })
      })
      .catch(error => {
        console.log(error);
      });
  };
  
  handleClose = () => {
    this.props.onClose();
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  }

  render() {
    const { isOpen } = this.props;
    const { histories, isNoteFetching, isHistoryFetching, notes } = this.state;

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>
          Activity
        </ModalHeader>
        <ModalBody>
          <div>
            <Label>Notes:</Label>
            {notes.length > 0 ? (
              <Table>
                <tbody>
                  {
                    notes.map((row, index) => (
                      <ProgressRow
                        key={row.PK}
                        progress={0} 
                        odd={index % 2}
                      >
                        <td>
                          <Card>
                            <div><strong>{row.Note}</strong></div>
                            <div>{`Entered by ${row.Firstname} ${row.LastName}`} &nbsp; &nbsp; {`${moment(row.CreatedDate).format('MM/DD/YYYY')}`}</div>
                          </Card>
                        </td>
                      </ProgressRow>
                    ))
                  }
                </tbody>
              </Table>
            ) : (
              <Fragment>
                {!isNoteFetching && <div>There is no note.</div>}
              </Fragment>
            )}
          </div>
          <Label>History:</Label>
          {histories.length > 0 ? (
            <Table>
              <tbody>
                {
                  histories.map((row, index) => (
                    <ProgressRow
                      key={row.PK}
                      progress={0} 
                      odd={index % 2}
                    >
                      <td>{row.ActivityName}</td>
                      <td>{moment(row.CreatedDate).format('MM/DD/YYYY')}</td>
                    </ProgressRow>
                  ))
                }
              </tbody>
            </Table>
          ) : (
            <Fragment>
              {!isHistoryFetching && <div>There is no activity.</div>}
            </Fragment>
          )
        }
        </ModalBody>
      </Modal>
    );
  }
}

const Modal = styled(DigeryModal)`
  max-width: 500px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    overflow: auto;
    min-height: 347px;
  }
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  color: white;
  min-width: unset;
  font-size: 1.4rem;
  height: 30px;
`;

const Row = styled.div`
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  color: ${props => props.theme.palette.primary};
  font-size: 1.4rem;
  margin: 14px 0 0.3rem;
`;

const Card = styled.div`
`;

