import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  uploadRecruiterFile,
  getRecruiterFiles,
  getRecruitingWebsiteLinks,
  addRecruitingWebsiteLinks,
  deleteRecruitingWebsiteLinks,
  deleteRecruiterFiles,
} from 'modules/api';
import DigeryModal from 'components/Modal';
import CheckBox from 'components/CheckBox';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { showAlert } from 'actions';
import SectionContentWrapper from 'components/TabWrapper';
import withModals from 'modules/withModals';
import AddWebsiteLinkModal from './AddWebsiteLinkModal';

const TAB = {
  WEBSITES: 'Websites',
  FILES: 'Files'
}

const HorizontalTabOptions = [
  { display: TAB.WEBSITES, value: TAB.WEBSITES },
  { display: TAB.FILES, value: TAB.FILES },
];

class AttachFilesModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    activeTab: TAB.WEBSITES,
    files: [],
    websites: [],
    isLoading: true,
    fileChecked: [],
    websitesChecked: [],
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.initialFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.initialFetch();
    }
  }

  initialFetch = async () => {
    this.setState({ isLoading: true })
    await this.fetchFiles()
    await this.fetchWebsiteUrls()
    this.setState({ isLoading: false })
  }

  fetchFiles = () => {
    getRecruiterFiles()
    .then(res => {
      this.setState({
        files: res,
        fileChecked: []
      });
    })
  };

  fetchWebsiteUrls = () => {
    getRecruitingWebsiteLinks()
    .then(res => {
      this.setState({
        websites: res,
        websitesChecked: []
      });
    })
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleCheckFileChange = (fileName, value) => {
    const { fileChecked } = this.state;

    if (value) {
      if (!fileChecked.includes(fileName)) {
        this.setState({ fileChecked: [...fileChecked, fileName] })
      }
    } else {
      if (fileChecked.includes(fileName)) {
        this.setState({ fileChecked: fileChecked.filter(a => a !== fileName) })
      }
    }
  }

  handleCheckSiteChange = (url, value) => {
    const { websitesChecked } = this.state;

    if (value) {
      if (!websitesChecked.includes(url)) {
        this.setState({ websitesChecked: [...websitesChecked, url] })
      }
    } else {
      if (websitesChecked.includes(url)) {
        this.setState({ websitesChecked: websitesChecked.filter(a => a !== url) })
      }
    }
  }

  handleSelectFile = async e => {
    const { dispatch } = this.props;
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    const { files } = this.state

    if (files.map(f => f.fileName).includes(file.name)) {
      dispatch(
        showAlert('The file you selected already exists.  Contact a system Admin to remove the existing file then upload your file.', { variant: 'danger', icon: 'bell' }),
      );
      return
    }

    this.setState({ isLoading: true });
    uploadRecruiterFile(file)
      .then(async () => {
        await this.fetchFiles()
        this.setState({ isLoading: false });
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
        dispatch(
          showAlert('Failed to upload, please try again.', { variant: 'danger', icon: 'bell' }),
        );
      });
  };

  handleAttach = () => {
    const { activeTab, fileChecked, websitesChecked } = this.state;
    const links = activeTab === TAB.WEBSITES ? websitesChecked : fileChecked
    this.props.onAttach(links)
    this.handleClose()
  }

  handleDelete = () => {
    const { activeTab, fileChecked, websitesChecked, files, websites } = this.state;

    this.setState({ isLoading: true })
    if (activeTab === TAB.WEBSITES) {
      const pks = []
      websitesChecked.forEach(wl=> {
        const websiteObj = websites.find(wo => wo.WebsiteURL === wl)
        if (websiteObj) {
          pks.push({ PK: websiteObj.PK })
        }
      })
      console.log('pks', JSON.stringify(pks))
      deleteRecruitingWebsiteLinks(JSON.stringify(pks))
        .then(res => {
          this.fetchWebsiteUrls()
          this.setState({ isLoading: false })
        })
    } else {
      const fileNames = []
      fileChecked.forEach(fp=> {
        const fileObj = files.find(wo => wo.filePath === fp)
        if (fileObj) {
          fileNames.push(fileObj.fileName)
        }
      })

      deleteRecruiterFiles(JSON.stringify(fileNames))
        .then(res => {
          this.setState({ isLoading: false })
          this.fetchFiles()
        })
    }
  }

  handleShowAddWebsite = () => {
    this.props.openModal('AddWebsiteLinkModal', {
      dispatch: this.props.dispatch,
      onAdd: () => {
        this.fetchWebsiteUrls()
      }
    });
  }

  changeHorizontalTab = activeTab => {
    this.setState({ 
      activeTab,
    });
  };

  render() {
    const { isOpen } = this.props;
    const { files, fileChecked, isLoading, activeTab, websites, websitesChecked } = this.state;

    const attachButtonDisabled = 
      (activeTab === TAB.FILES && fileChecked.length === 0) ||
      (activeTab === TAB.WEBSITES && websitesChecked.length === 0) ||
      isLoading

    console.log('thisstat', this.state)

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>Recruiting Links</ModalHeader>
        <ModalBody>
          {isLoading && <Loading />}
          <SectionContentWrapper
            title=""
            hActiveTab={activeTab}
            horizonalTabOptions={HorizontalTabOptions}
            onHorizonalTabChange={this.changeHorizontalTab}
          >
            <div className="body-container">
              {activeTab === TAB.WEBSITES ? (
                <>
                  {websites.map(site => (
                    <Row key={site.PK}>
                      <CheckBox
                        value={websitesChecked.includes(site.WebsiteURL)}
                        onChange={value => this.handleCheckSiteChange(site.WebsiteURL, value)}
                        />
                      <Label>{site.WebsiteURL}</Label>
                    </Row>
                  ))}
                </>
              ) : (
                <>
                  {files.map(file => (
                    <Row key={file.fileName}>
                      <CheckBox
                        value={fileChecked.includes(file.filePath)}
                        onChange={value => this.handleCheckFileChange(file.filePath, value)}
                        />
                      <Label>{file.fileName}</Label>
                    </Row>
                  ))}
                </>
              )}
            </div>
          </SectionContentWrapper>
        </ModalBody>
        <ModalFooter>
          <ButtonWrapper>
            <StyledButton disabled={attachButtonDisabled} onClick={this.handleAttach}>Attach Link</StyledButton>
            <StyledButton disabled={attachButtonDisabled} onClick={this.handleDelete}>{`Delete ${activeTab === TAB.FILES ? 'Link' : 'Link'}`}</StyledButton>
            {activeTab === TAB.WEBSITES ? (
              <StyledButton disabled={isLoading} onClick={this.handleShowAddWebsite}>Add Website</StyledButton>
            ) : (
              <StyledButton disabled={isLoading}>
                <input
                  id="add-upload-recruiter-file"
                  type="file"
                  onChange={this.handleSelectFile}
                  style={{ display: 'none' }}
                  disabled={isLoading}
                />
                <label htmlFor="add-upload-recruiter-file">
                  <span>Upload File</span>
                </label>
              </StyledButton>
            )}

          </ButtonWrapper>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withModals({
  AddWebsiteLinkModal,
})(AttachFilesModal);

const Modal = styled(DigeryModal)`
  max-width: 700px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    overflow: auto;
    min-height: 460px;
  }

  .body-container {
    width: 100%;
    min-height: 250px;
  }

  .message {
    width: 500px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const Label = styled.span`
  margin-left: 10px;
  font-size: 1.3rem;
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  color: white;
  min-width: unset;
  font-size: 1.4rem;
  height: 30px;

  label {
    margin: 0;

    span {
      font-weight: normal;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
