import React, { Component } from 'react';
import { withSize } from 'react-sizeme';
import PropTypes from 'prop-types';
import _ from 'lodash'
import styled from 'styled-components';
import { 
  XAxis, 
  YAxis, 
  CartesianGrid,
  Tooltip, 
  Legend, 
  LineChart, 
  Line,
  Surface,
  Symbols,
  BarChart,
  Bar
} from 'recharts';
import { media, MobileWidth } from 'modules/theme';

const Container = styled.div`
  width: 100%;
  padding-bottom: ${props => props.showLegend ? '40px' : '0px'};

  ${media.mobile`
    padding-bottom: 9rem;

    text {
      font-size: 1.4rem;
    }
  `};

  .recharts-wrapper {
    margin-left: -20px;
    
    ${media.mobile`
      margin-left: -42px;
    `};
  }

`;

const CustomRegend = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 4.0rem;

  ${media.mobile`
    margin-left: 38px;
  `};

  .legend-item {
    display: flex;
    align-items: center;
    margin: 0 1.0rem;
    cursor: pointer;

    & > span {
      font-size: 1.6rem;

      ${media.mobile`
        font-size: 1.4rem;
      `};
    }
  }
`;

const colors = ['#B20710', '#ffcccb', '#FF9914', '#C33C54'];

class Chart extends Component {
  state = {
    disabled: [],
    isMobile: false
  }

  getScreenWdith = () => {
    let isMobile = false;
    if (window.innerWidth < MobileWidth) {
      isMobile = true;
    }
     this.setState({ isMobile });
  }
  
  componentDidMount() {
    this.getScreenWdith();
    window.addEventListener("resize", this.getScreenWdith);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getScreenWdith);
  }

  handleClick = dataKey => {
    if (_.includes(this.state.disabled, dataKey)) {
      this.setState({
        disabled: this.state.disabled.filter(obj => obj !== dataKey)
      });
    } else {
      this.setState({ disabled: this.state.disabled.concat(dataKey) });
    }
  };

  renderCusomizedLegend = ({ payload }) => {
    return (
      <CustomRegend>
        {payload.map(entry => {
          const { dataKey, color } = entry;
          const active = _.includes(this.state.disabled, dataKey);
          const style = {
            color: active ? "#AAA" : "#000"
          };

          return (
            <span
              className="legend-item"
              onClick={() => this.handleClick(dataKey)}
              style={style}
            >
              <Surface width={20} height={20} viewBox="0 0 20 20">
                <Symbols cx={10} cy={10} type="circle" size={100} fill={color} />
                {active && (
                  <Symbols
                    cx={10}
                    cy={10}
                    type="circle"
                    size={50}
                    fill={"#FFF"}
                  />
                )}
              </Surface>
              <span>{dataKey}</span>
            </span>
          );
        })}
      </CustomRegend>
    );
  };

  renderCustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-45)">{payload.value}</text>
      </g>
    );
  };

  xAxisTickFormat = (label) => {
    if (label.length > 15) {
      return label.slice(0, 15) + '...'
    } 
    return label
  }


  render() {
    const { size, data, dataKeys, height, type, showLegend } = this.props;
    const { disabled, isMobile } = this.state;

    const chartColors = dataKeys.map((d, index) => ({
      dataKey: d,
      color: colors[index]
    }))

    const width = size.width + (isMobile ? 40 : 15)
  
    return (
      <Container showLegend={showLegend}>
        {type === 'bar' 
        ?
          <BarChart width={width} height={height | 300} data={data} margin={{top: 5, right: 0, left: 0, bottom: 5}}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="date" angle={-45} textAnchor="end" interval={0} height={100} tick={{ fontSize: 12 }} tickFormatter={this.xAxisTickFormat}/>
            <YAxis allowDecimals={false}/>
            <Tooltip/>
            {showLegend &&
              <Legend
                verticalAlign="bottom"
                height={36}
                align="left"
                payload={chartColors}
                content={this.renderCusomizedLegend}
              />
            }
            {dataKeys.filter(d => !disabled.includes(d)).map((dataKey) => {
              const color = chartColors.find(c => c.dataKey === dataKey).color
              return (
                <Bar dataKey={dataKey} stroke={color} fill={color}/>
              )
            }
            )}
          </BarChart>
        :
          <LineChart width={width} height={height | 300} data={data} margin={{top: 5, right: 0, left: 0, bottom: 5}}>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="date" minTickGap={0} height={30} tick={{ fontSize: 12 }}/>
            <YAxis allowDecimals={false}/>
            <Tooltip/>
            {showLegend &&
              <Legend
                verticalAlign="bottom"
                height={36}
                align="left"
                payload={chartColors}
                content={this.renderCusomizedLegend}
              />
            }
            {dataKeys.filter(d => !disabled.includes(d)).map((dataKey) => {
              const color = chartColors.find(c => c.dataKey === dataKey).color
              return (
                <Line type='monotone' dataKey={dataKey} stroke={color} fill={color} strokeWidth={2} dot={false}/>
              )
            })}
          </LineChart>
        }
 
      </Container>
    ) 
  }
}

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  dataKeys: PropTypes.array.isRequired,
  height: PropTypes.number
};

export default withSize({ monitorWidth: true })(Chart)
