import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import {
  getPortalAdminMarketingEvents,
  addPortalAdminPreferredLoanOfficer,
  getPortalAdminLoanOfficersToAgentAssignment,
} from 'modules/api';
import Button from 'components/Button';
import Image from 'components/Image';
import MultiInput from 'components/TextArea';
import ChangeLoanOfficerModal from 'routes/Agents/MyAgents/ChangeLoanOfficerModal';

const PlaceholderAvatar = require('assets/media/images/sample-avatar.png');

export default class AssignToMyLOModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    marketingEvents: [],
    notes: '',
    selectedEvent: { label: '- - -', value: null },
    allLoanOfficers: [],
    loanOfficer: null,
    showChangeLOModal: false,
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.initFetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.initFetch();
    }
  }

  initFetch = () => {
    this.fetchMarketingEvents();
    this.fetchLoanOfficers();
  }

  fetchMarketingEvents = () => {
    getPortalAdminMarketingEvents()
      .then(res => {
        this.setState({
          marketingEvents: res,
        })
      })
      .catch(() => {});
  };

  fetchLoanOfficers = () => {
    this.setState({ allLoanOfficers: [] });
    const { agent } = this.props;

    if (agent?.Kwuid) {
      getPortalAdminLoanOfficersToAgentAssignment(agent?.Kwuid).then(res => {
        const allLoanOfficers = res
          .filter(r => r.Status === 'Active')
          .map(r => ({
            ...r,
            Name: `${r.FirstName} ${r.LastName}`,
            LOID: r.ID,
          }));
        this.setState({ allLoanOfficers });
      }).catch(() => {});
    }
  }

  handleClose = () => {
    this.props.onClose();
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  }

  handleAssign = () => {
    const { agent } = this.props;
    const { notes, selectedEvent, loanOfficer } = this.state;

    addPortalAdminPreferredLoanOfficer(agent.Kwuid, loanOfficer.LOID, selectedEvent?.value, notes)
      .then(() => {
        this.props.onClose();
        this.props.onUpdate(loanOfficer)
      })
      .catch(() => {});
  }

  onChangeLO = loanOfficer => {
    this.setState({ loanOfficer });
  };

  handleShowChangeLO = () => {
    this.setState({ showChangeLOModal: true });
  };

  render() {
    const { isOpen, agent } = this.props;
    const { marketingEvents, notes, selectedEvent, loanOfficer, allLoanOfficers, showChangeLOModal } = this.state;
    const eventOptions = [
      { label: '- - -', value: null },
      ...marketingEvents.map(e => ({ label: e.EventName, value: e.PK })),
    ]

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose} width={500}>
        <ModalHeader toggle={this.handleClose}>
          ASSIGN TO MY LO
        </ModalHeader>
        <ModalBody>
          <Header>
            <div className="user-info">
              <Image src={agent?.Photo} />
              <div className="user-name">{`${agent?.label}`}</div>
              <div className="user-agent">{agent?.Kwuid}</div>
            </div>
            <div className="arrow-wrapper">
              <i className="arrow-long-right" />
              <div className="my-loan-officer">My Loan Officer</div>
            </div>
            <div className="user-info">
              <Image src={loanOfficer?.ProfilePhotoLink} fallbackSrc={PlaceholderAvatar} />
              <div className="user-name">{loanOfficer ? `${loanOfficer?.Name || ''}` : 'N/A'}</div>
              <LinkButton onClick={this.handleShowChangeLO}>{loanOfficer ? 'Change LO' : 'Choose LO'}</LinkButton>
            </div>
          </Header>
          <Body>
            <Label>Agent Attribution Source:</Label>
            <Select
              placeholder="- - -"
              options={eventOptions}
              onChange={(s) => this.handleChange('selectedEvent', s)}
              value={selectedEvent}
              css={`
                font-size: 1.4rem;
                & > div:first-of-type {
                  border-radius: 2px;
                }
              `}
            />
            <Label>Notes:</Label>
            <TextArea
              placeholder="Notes"
              value={notes}
              onChange={(e) => this.handleChange('notes', e.target.value)}
            />
            <StyledButton disabled={!loanOfficer} onClick={this.handleAssign}>Assign to my LO</StyledButton>
          </Body>
          <ChangeLoanOfficerModal
            allLoanOfficers={allLoanOfficers}
            showModal={showChangeLOModal}
            onClose={() => this.setState({ showChangeLOModal: false })}
            onSelect={this.onChangeLO}
          />
        </ModalBody>
      </Modal>
    );
  }
}

const Modal = styled(DigeryModal)`
  max-width: 500px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    overflow: auto;
    min-height: 500px;
  }
`;

const StyledButton = styled(Button)`
  background: ${props => props.theme.palette.gray};
  border-radius: 0.5rem;
  color: white;
  min-width: unset;
  font-size: 1.4rem;
  height: 30px;
  margin-top: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      object-fit: cover;
    }

    .user-name {
      font-size: 1.4rem;
      margin-top: 5px;
    }

    .user-agent {
      font-size: 1.2rem;
      margin-top: 4px;
      line-height: 1.2rem;
    }
  }

  .arrow-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .arrow-long-right {
      box-sizing: border-box;
      position: relative;
      display: block;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      box-shadow: inset 0 0 0 3px;
      width: 150px;
      height: 8px
    }
  
    .arrow-long-right::after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 8px;
      height: 8px;
      border-top: 3px solid;
      border-right: 3px solid;
      transform: rotate(45deg);
      right: 0;
      bottom: -3px
    }

    .my-loan-officer {
      font-size: 13px;
      margin-top: 3px;
    }
  }
}`;

const Body = styled.div`
  margin: auto;
  max-width: 300px;
  text-align: center;
`;

const TextArea = styled(MultiInput)`
  min-height: 100px;
`;

const Label = styled.div`
  font-size: 1.4rem;
  margin: 30px 0 0.5rem;
  text-align: left;
`;

const LinkButton = styled.button`
  text-decoration: underline;
  font-size: 1.3rem;
  margin-top: 0.3rem;
  color: ${props => props.theme.palette.primary};
`;
