import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';
import { ModalHeader, ModalBody } from 'reactstrap';
import DigeryModal from 'components/Modal';
import { getRecruitingDashboardStatsRecruiters } from 'modules/api';
import { Table } from 'reactstrap';
import { formatDate } from 'modules/helpers';
import DownloadCSV from 'components/DownloadCSV';

export default class MarketCenterStatsModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  state = {
    recruiterStats: [],
    fetching: true
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { period, startDate, endDate } = this.props
    getRecruitingDashboardStatsRecruiters(null, period, startDate, endDate).then(res => {
      this.setState({ 
        recruiterStats: res.result,
        fetching: false
      });
    })
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { isOpen, startDate, endDate } = this.props;
    const { recruiterStats, fetching } = this.state;

    return (
      <Modal centered fade={false} isOpen={isOpen} toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>
          Recruiters
        </ModalHeader>
        <DownloadWrapper>
          <DownloadCSV data={recruiterStats} filename={`Recruiters`}/>
        </DownloadWrapper>
        <ModalBody>
          <DateRangeView>{`${formatDate(startDate)} ~ ${formatDate(endDate)}`}</DateRangeView>
          {recruiterStats.length > 0 ? (
            <SnapshotTable striped>
              <thead>
                <tr>
                  <WhiteTh colSpan={2}></WhiteTh>
                  <WhiteTh />
                  <th colSpan={5}>Agents</th>
                </tr>
                <tr>
                  <WhiteTh style={{ height: 2 }} colSpan={8}></WhiteTh>
                </tr>
                <tr>
                  <th></th>
                  <th>Recruiter</th>
                  <WhiteTh />
                  <th>Assigned</th>
                  <th>Called</th>
                  <th>Made Contact</th>
                  <th>Assigned to LO</th>
                  <th>Closed</th>
                </tr>
              </thead>
              <tbody>
                {recruiterStats.map((row, index) => 
                  <tr>
                    <td>{index + 1}</td>
                    <td><a href={`${window.location.href}?RecruiterID=${row.ID}&FirstName=${row.FirstName}&LastName=${row.LastName}`} target="_blank">{`${row.FirstName} ${row.LastName}`}</a></td>
                    <WhiteTd/>
                    <td>{row.AgentsAssigned}</td>
                    <td>{row.AgentsCalled}</td>
                    <td>{row.AgentsMadeContact}</td>
                    <td>{row.AgentsAssignedToLO}</td>
                    <td>{row.AgentsClosed}</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2}>Total</td>
                  <WhiteTd />
                  <td>{sumBy(recruiterStats, 'AgentsAssigned')}</td>
                  <td>{sumBy(recruiterStats, 'AgentsCalled')}</td>
                  <td>{sumBy(recruiterStats, 'AgentsMadeContact')}</td>
                  <td>{sumBy(recruiterStats, 'AgentsAssignedToLO')}</td>
                  <td>{sumBy(recruiterStats, 'AgentsClosed')}</td>
                </tr>
              </tfoot>
            </SnapshotTable>
          ) : (
            <Fragment>
              {!fetching && <div>There is no result.</div>}
            </Fragment>
          )
        }
        </ModalBody>
      </Modal>
    );
  }
}


const Modal = styled(DigeryModal)`
  max-width: 1000px;
  .modal-header {
    justify-content: flex-start;
    h5 {
      margin: 0 auto;
      font-size: 20px;
      color: ${props => props.theme.palette.gray};
    }
  }
  .modal-content {
    height: 600px;
    overflow: auto;
  }
  .modal-body {
    overflow: auto;
  }
`;

const DownloadWrapper = styled.div`
  height: 0;
  position: absolute;
  right: 64px;
  top: 30px;

  & > a {
    transform: translateY(0%);
    right: 0;
  }
`;

const SnapshotTable = styled(Table)`
  &&& {
    font-size: 1.2rem;

    tr {
      border: none;
    }

    tfoot td, th {
      text-align: center;
      height: 50px;
      vertical-align: middle;
      background: #ddd;
      border: none;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    th {
      padding: 2px;
    }

    td {
      text-align: center;
      height: 50px;
      vertical-align: middle;
      border: none;
      white-space: pre-wrap;
      word-break: break-all;
      padding: 2px;

      a {
        text-decoration: underline;
        color: black;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;

const WhiteTh = styled.th`
  background: white !important;
  padding: 4px !important;
`;

const WhiteTd = styled.td`
  background: white !important;
  padding: 4px !important;
`;

const LinkButton = styled.button`
  text-decoration: underline;
  margin: 0 2px;
  white-space: nowrap;
`;

const DateRangeView = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 15px;
`;