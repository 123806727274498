/**
 * Import Section
 */
import axios from 'axios'
import LocalStorageService from './localstorage1'
import Environment from './../environments/env'


/**
 * Cancel Token
 */
const CancelToken = axios.CancelToken

/**
 * Use to Cancel Http Requests
 */
let _cancel
let _type
export default class HttpService {
  _local = new LocalStorageService()
  _Environment = new Environment()
  _http = null

  errMsg = 'Session Expired'
  serverError = 'We are currently experiencing issues, but are working hard to resolve them. Please try back again later.'

  /* Use to cancel Http Requests */

  /**
   * GET Request
   *
   * @param url
   */
  get(url) {
    return axios
      .get(url, {
        headers: this.agentHeaders(),
        cancelToken: new CancelToken(function executor(c) {
          _cancel = c
        })
      })
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err))
  }

  /**
   * Agent Headers
   */
  agentHeaders() {
    _type = 'user'
    var headers = {}
    if (process.env.NODE_ENV === 'development') {
      headers = {
        'x-userinfo':
          '{"given_name":"kelle1","updated_at":"2018-01-23T07:19:36.950Z","family_name":"kelle1","preferred_username":"kelle1","custom_fields":{"KW_UID":"556396"},"email":"kelle1@kw.com","name":"kelle1 kelle1","sub":"37087183"}',
        'x-consumer-username': 'onelogin',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-user-group': 'agent',
        Authorization:
          'Bearer ' + this._local.getToken()
      }
    } else {
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-user-group': 'agent',
        Authorization:
          'Bearer ' + this._local.getToken()
      }
    }
    return headers
  }

  /**
   * Admin Headers
   */
  adminHeaders() {
    _type = 'admin'
    var headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-user-group': 'admin',
      Authorization: 'Bearer ' + this._local.getToken()
    }
    return headers
  }

  /**
   * GET Request
   *
   * @param url
   */
  getWhenAdmin(url) {
    return axios
      .get(url, {
        headers: this.adminHeaders(),
        cancelToken: new CancelToken(function executor(c) {
          _cancel = c
        })
      })
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err))
  }

  /**
   * GET Request
   *
   * @param url with header
   */
  getReq(url, header) {
    return axios
      .get(url, {
        headers: header,
        cancelToken: new CancelToken(function executor(c) {
          _cancel = c
        })
      })
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err))
  }

  /**
   * GET Request
   *
   * @param url with params
   */
  getReqParams(url, params) {
    return axios
      .get(url, {
        params: params ? params : null,
        headers: this.adminHeaders(),
        cancelToken: new CancelToken(function executor(c) {
          _cancel = c
        })
      })
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err))
  }

  cancel() {
    this._http.abort()
  }

  /**
   * POST Request
   *
   * @param url
   * @param params
   */
  post(url, params) {
    var headers = {
      'x-user-group': 'admin'
    }
    return (this._http = axios
      .post(url, params, { headers: headers })
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err)))
  }

  /**
   * POST Request
   *
   * @param url
   * @param params
   */
  postWithToken(url, params) {
    return (this._http = axios
      .post(url, params, { headers: this.adminHeaders() })
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err)))
  }

  postFormData(url, params) {
    return (this._http = axios
      .post(url, params, {
        headers: {
          Authorization: 'Bearer ' + this._local.getToken()
        }
      })
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err)))
  }

  /**
   * PUT Request
   *
   * @param url
   * @param params
   */
  put(url, params) {
    return axios
      .put(url, params, {
        headers: this.agentHeaders()
      })
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err))
  }

  /**
   * PUT Request with form data
   *
   * @param url
   * @param params
   */
  putFormData(url, params) {
    return (this._http = axios
      .put(url, params, {
        headers: this.adminHeaders()
      })
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err)))
  }

  /**
   * DELETE Request
   *
   * @param url
   */
  delete(url) {
    return axios
      .delete(url, {
        headers: this.adminHeaders()
      })
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err))
  }

  /**
   * DELETE Request
   *
   * @param url
   */
  deleteMulti(url, params) {
    return (this._http = axios
      .post(url, params, {
        headers: this.adminHeaders()
      })
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err)))
  }

  /**
   * PATCH Request
   *
   * @param url
   * @param params
   */
  patch(url, params) {
    return axios
      .patch(url, params)
      .then(res => this.handleResponse(res))
      .catch(err => this.handleError(err))
  }

  /**
   * Handle Success Response
   *
   * @param res
   */
  handleResponse(res) {
    let r = res.data
    return Promise.resolve(r)
  }

  handleSessionErrors() {
    if (_type === 'user') {
      localStorage.clear()
      var e = new Event('goToLogin', {})
      document.dispatchEvent(e)
    }
    if (_type === 'admin') {
      localStorage.clear()
      var event = new Event('goToAdmin', {})
      document.dispatchEvent(event)
    }
  }

  /**
   * Handle API Error Reponse
   *
   * @param {Object|Null} error
   *
   * @return {Object|String|Null}
   */
  handleError(error) {
  /* error = { error, config, code, request, response } */
  try {
    if (!error) return Promise.reject({})
    /* Handle Cancel Request */
    _cancel = []
    if (!error.request) return Promise.reject('cancelled')
    var xhr = error.request
    if (!xhr.response) {
      this.handleSessionErrors();
      this._notify.error(this.errMsg);
      return Promise.reject({})
    }
    var err = this.extractJSON(xhr.response)
    if (xhr && xhr.status && err) {
      switch (xhr.status) {
        case 400:
          var msg = err.message ? err.message : err.error
          this._notify.error(msg)
          break
        case 401:
          this.handleSessionErrors();
          this._notify.error(this.errMsg);
        break
        case 404:
          this._notify.error(err.message)
          break
        case 422:
          if (err.message !== 'Invalid Credentials.') {
            this._notify.error(err.message)
          }
          break
        case 500:
          this._notify.error(err.message)
          break
        case 408:
          this._notify.error(err.message)
          break
        case 409:
          this._notify.error(err.message)
          break
        default:
          this._notify.error(this.serverError)
      }
    } else {
      this._notify.error(this.serverError)
    }

    return Promise.reject(err ? err : {})
  } catch (e) {
    console.error('-- HTTP HANDLE ERROR -- ', e)
    return Promise.reject({})
  }
}

  /**
   * Extract JSON Response
   *
   * @param {JSON} json [JSON Data]
   *
   * @return {Object|String} Extarcted value or Blank Object
   */
  extractJSON(json) {
    try {
      return JSON.parse(json)
    } catch (err) {
      return ''
    }
  }

  /**
   * Cancel Http Request
   */
  httpCancel() {
    try {
      if (_cancel) _cancel()
      _cancel = null
    } catch (e) { }
  }
}